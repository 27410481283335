import * as React from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import MuiChip from "@mui/material/Chip";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import NoPaper from "../../assets/NoPaper.png";
import PDFReporting from "../../assets/PDFReporting.png";
import Dashboard3 from "../../assets/payroll-images/Dashboard3.png";
import TimecardRateSelection3 from "../../assets/payroll-images/TimecardRateSelection3.png";
import LaborHoursEntry from "../../assets/payroll-images/LaborHoursEntry.png";
import LaborHoursEntry2 from "../../assets/payroll-images/LaborHoursEntry2.png";
import DevicesRoundedIcon from "@mui/icons-material/DevicesRounded";
import EdgesensorHighRoundedIcon from "@mui/icons-material/EdgesensorHighRounded";
import ViewQuiltRoundedIcon from "@mui/icons-material/ViewQuiltRounded";

const items = [
  {
    icon: <ViewQuiltRoundedIcon />,
    title: "No More Messy Timecards",
    description:
      "Save 5 min per employee per month. Eliminate messy timecards.",
    imageLight: `${NoPaper}`,
    imageMobile: `${NoPaper}`,
  },
  {
    icon: <EdgesensorHighRoundedIcon />,
    title: "Ensure Data Consistency",
    description:
      "Save 5 min per employee per month. Standardized Form Reduces Missing or Incomplete Data.  Back and forth emails is very inefficient.",
    imageLight: `${LaborHoursEntry2}`,
    imageMobile: `${LaborHoursEntry}`,
  },
  {
    icon: <DevicesRoundedIcon />,
    title: "Rate Finding",
    description:
      "Save 5 Minutes Per Month Per Employee. It is important to pay your workers at least prevailing wage.  Finding the correct rate shouldn't be hard. ",

    imageLight: `${TimecardRateSelection3}`,
    imageMobile: `${TimecardRateSelection3}`,
  },
  {
    icon: <DevicesRoundedIcon />,
    title: "Apprentice Ship Programs and Training Funds",
    description:
      "Save 5 minutes Per Month Per Employee.  Searching for monthly data by classification to pay Apprenticship Programs, all from the interactive dashboard.",
    imageLight: `${Dashboard3}`,
    imageMobile: `${Dashboard3}`,
  },
  {
    icon: <DevicesRoundedIcon />,
    title: "Payroll Costs and Taxes",
    description:
      "Save 10 minutes Per Month Per Employee. Filtering By Employee to track payroll costs.  State and Federal Tax Estimates Automatically Calculated to reflect tax bills. (We aim to be as accurat as possible but due to legal reasons, not for use on tax returns). PDF and Excel Exportable Reports",
    imageLight: `${PDFReporting}`,
    imageMobile: `${PDFReporting}`,
  },
];

const Chip = styled(MuiChip)(({ theme }) => ({
  variants: [
    {
      props: ({ selected }) => selected,
      style: {
        background:
          "linear-gradient(to bottom right, hsl(210, 98%, 48%), hsl(210, 98%, 35%))",
        color: "hsl(0, 0%, 100%)",
        borderColor: (theme.vars || theme).palette.primary.light,
        "& .MuiChip-label": {
          color: "hsl(0, 0%, 100%)",
        },
        ...theme.applyStyles("dark", {
          borderColor: (theme.vars || theme).palette.primary.dark,
        }),
      },
    },
  ],
}));

function MobileLayout({ selectedItemIndex, handleItemClick, selectedFeature }) {
  if (!items[selectedItemIndex]) {
    return null;
  }

  return (
    <Box
      sx={{
        display: { xs: "flex", sm: "none" },
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", gap: 2, overflow: "auto" }}>
        {items.map(({ title }, index) => (
          <Chip
            size="medium"
            key={index}
            label={title}
            onClick={() => handleItemClick(index)}
            selected={selectedItemIndex === index}
          />
        ))}
      </Box>
      <Card variant="outlined">
        <img
          src={items[selectedItemIndex].imageMobile}
          style={{
            maxHeight: 280,
            width: "100%",
            objectFit: "cover",
            margin: 2,
          }}
        />
        <Box sx={{ px: 2, pb: 2 }}>
          <Typography
            gutterBottom
            sx={{ color: "text.secondary", fontWeight: "medium" }}
          >
            {selectedFeature.title}
          </Typography>
          <Typography variant="body2" sx={{ color: "text.secondary", mb: 1.5 }}>
            {selectedFeature.description}
          </Typography>
        </Box>
      </Card>
    </Box>
  );
}

MobileLayout.propTypes = {
  handleItemClick: PropTypes.func.isRequired,
  selectedFeature: PropTypes.shape({
    description: PropTypes.string.isRequired,
    icon: PropTypes.element,
    imageMobile: PropTypes.string.isRequired,
    imageLight: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
  }).isRequired,
  selectedItemIndex: PropTypes.number.isRequired,
};

export { MobileLayout };

export default function EfficiencyFeatures() {
  const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

  const handleItemClick = (index) => {
    setSelectedItemIndex(index);
  };

  const selectedFeature = items[selectedItemIndex];

  return (
    <Container id="features" sx={{ py: { xs: 4, sm: 8 } }}>
      <Box sx={{ width: { sm: "100%", md: "60%" } }}>
        <Typography
          component="h2"
          variant="h4"
          gutterBottom
          sx={{ color: "primary.light" }}
        >
          Time Efficiency
        </Typography>
        <Typography
          variant="body1"
          sx={{ color: "white", mb: { xs: 2, sm: 4 } }}
        >
          Here are a list of time saving features.
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row-reverse" },
          gap: 2,
        }}
      >
        <div>
          <Box
            sx={{
              display: { xs: "none", sm: "flex" },
              flexDirection: "column",
              gap: 2,
              height: "100%",
            }}
          >
            {items.map(({ icon, title, description }, index) => (
              <Box
                key={index}
                component={Button}
                onClick={() => handleItemClick(index)}
                sx={[
                  (theme) => ({
                    p: 2,
                    height: "100%",
                    width: "100%",
                    "&:hover": {
                      backgroundColor: "white", //(theme.vars || theme).palette.action.hover,
                    },
                  }),
                  selectedItemIndex === index && {
                    backgroundColor: "white", //action.selected
                  },
                ]}
              >
                <Box
                  sx={[
                    {
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "left",
                      gap: 1,
                      textAlign: "left",
                      textTransform: "none",
                      color: "hsl(220, 20%, 35%)", //text.secondary
                    },
                    selectedItemIndex === index && {
                      color: "text.primary", //text.primary
                    },
                  ]}
                >
                  {icon}

                  <Typography variant="h6">{title}</Typography>
                  <Typography variant="body2">{description}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
          <MobileLayout
            selectedItemIndex={selectedItemIndex}
            handleItemClick={handleItemClick}
            selectedFeature={selectedFeature}
          />
        </div>

        {}
        <Box
          sx={{
            display: { xs: "none", sm: "flex" },
            width: { xs: "100%", md: "70%" },
            height: "var(--items-image-height)",
          }}
        >
          <Card
            variant="outlined"
            sx={{
              height: "100%",
              width: "100%",
              display: { xs: "none", sm: "flex" },
              pointerEvents: "none",
              backgroundColor: "black",
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                m: "auto",
                width: 420,
                height: 500,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                borderRadius: 2,
                backgroundColor: "black",
                overflow: "hidden",
              }}
            >
              {items[selectedItemIndex] && (
                <img
                  src={items[selectedItemIndex].imageLight}
                  alt="Selected Item"
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "contain",
                  }}
                />
              )}
            </Box>
            {/* <Box
              sx={(theme) => ({
                m: 'auto',
                width: 420,
                height: 500,
                backgroundSize: 'contain',
                backgroundImage: 'var(--items-imageLight)',
                ...theme.applyStyles('dark', {
                  backgroundImage: 'var(--items-imageMobile)',
                }),
              })}
              style={
                items[selectedItemIndex]
                  ? {
                      '--items-imageLight': items[selectedItemIndex].imageLight,
                      '--items-imageMobile': items[selectedItemIndex].imageMobile,
                    }
                  : {}
              }
            /> */}
          </Card>
        </Box>
      </Box>
    </Container>
  );
}

import { useEffect, useCallback, useRef } from "react";
import { useAuth } from "../context/AuthProvider";

const useIdleLogout = (logoutCallback, idleTime = 15 * 60 * 1000) => {
  const timerRef = useRef(null); // Ref to track the logout timer
  const alertTimerRef = useRef(null); // Ref to track the alert timer
  const { success } = useAuth();
  const resetTimer = useCallback(() => {
    if (process.env.NODE_ENV === "development") {
      console.log("Development mode: Idle timer disabled.");
      return;
    }

    if (!success) return;

    // Clear existing timers
    if (timerRef.current) clearTimeout(timerRef.current);
    if (alertTimerRef.current) clearTimeout(alertTimerRef.current);

    // Set the alert to show after `idleTime - 60 seconds`
    alertTimerRef.current = setTimeout(() => {
      alert("You will be logged out in 1 minute due to inactivity.");
    }, idleTime - 60 * 1000);

    // Set the logout timer for `idleTime`
    timerRef.current = setTimeout(() => {
      logoutCallback();
    }, idleTime);
  }, [logoutCallback, idleTime]);

  useEffect(() => {
    if (process.env.REACT_APP_NODE_ENV === "development") {
      console.log("Development mode: Idle logout disabled.");
      return;
    }

    // Attach event listeners to detect user activity
    const activityEvents = ["mousemove", "keypress", "click", "scroll"];
    activityEvents.forEach((event) =>
      window.addEventListener(event, resetTimer)
    );

    // Start the timer initially
    resetTimer();

    // Cleanup listeners and timers on unmount
    return () => {
      if (timerRef.current) clearTimeout(timerRef.current);
      if (alertTimerRef.current) clearTimeout(alertTimerRef.current);
      activityEvents.forEach((event) =>
        window.removeEventListener(event, resetTimer)
      );
    };
  }, [resetTimer]);

  return null; // The hook doesn't render anything
};

export default useIdleLogout;

//theme - front page v6
import React, { useState } from "react";
import { useAuth, user } from "../context/AuthProvider";
import {
  TextField,
  Button,
  Container,
  Typography,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import { ColorModeContext } from "../theme"; // ✅ Ensure correct import
import { useContext } from "react";

const SuggestionForm = () => {
  const [name, setName] = useState("");
  const [suggestion, setSuggestion] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);
  const { api, user } = useAuth();
  const { theme, toggleColorMode } = useContext(ColorModeContext);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!suggestion.trim()) {
      setError(true);
      return;
    }
    try {
      await api.post("/api/v1/suggestion", {
        name,
        suggestion,
      });
      setOpen(true);
      setName("");
      setSuggestion("");
    } catch (err) {
      console.error("Error submitting suggestion:", err);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h4" gutterBottom>
        Suggestion Box
      </Typography>
      <form onSubmit={handleSubmit}>
        <Box
          sx={{
            backgroundColor: theme.palette.neutral[100],
            p: 2,
            borderRadius: "5px",
            border: `2px solid ${theme.palette.neutral[200]}`,
          }}
        >
          <TextField
            label="Your Name (Optional)"
            fullWidth
            value={name}
            sx={{
              backgroundColor: theme.palette.neutral[100],
              borderRadius: "5px",
              border: `2px solid ${theme.palette.neutral[200]}`,
              "& .MuiOutlinedInput-root": {
                color: `${theme.palette.neutral[200]} !important`, // ✅ Text inside input
                "& fieldset": {
                  borderColor: `${theme.palette.neutral[200]} !important`, // ✅ Default border color
                },
                "&:hover fieldset": {
                  border: `2px solid ${theme.palette.primary[200]}`,
                },
                "&.Mui-focused fieldset": {
                  borderColor: `${theme.palette.primary[300]} !important`, // ✅ Border color when focused
                },
              },
              "& .MuiInputLabel-root": {
                color: "white !important", // ✅ Default label color
              },

              "& .MuiInputBase-input": {
                color: "white !important", // ✅ Ensures input text color
              },
            }}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            label="Your Suggestion.  Ask You Shall Receive."
            fullWidth
            multiline
            rows={4}
            value={suggestion}
            onChange={(e) => setSuggestion(e.target.value)}
            margin="normal"
            required
            error={error}
            helperText={error ? "Suggestion cannot be empty" : ""}
            sx={{
              backgroundColor: theme.palette.neutral[100],
              borderRadius: "5px",
              border: `2px solid ${theme.palette.neutral[200]}`,
              "& .MuiOutlinedInput-root": {
                color: `${theme.palette.neutral[200]} !important`, // ✅ Text inside input
                "& fieldset": {
                  borderColor: `${theme.palette.neutral[200]} !important`, // ✅ Default border color
                },
                "&:hover fieldset": {
                  border: `2px solid ${theme.palette.primary[200]}`,
                },
                "&.Mui-focused fieldset": {
                  borderColor: `${theme.palette.primary[300]} !important`, // ✅ Border color when focused
                },
              },
              "& .MuiInputLabel-root": {
                color: "white !important", // ✅ Default label color
              },

              "& .MuiInputBase-input": {
                color: "white !important", // ✅ Ensures input text color
              },
            }}
          />
          <Button variant="contained" color="primary" type="submit" fullWidth>
            Submit Suggestion
          </Button>
        </Box>
      </form>

      <Snackbar
        open={open}
        autoHideDuration={3000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity="success">
          Suggestion submitted successfully!
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default SuggestionForm;

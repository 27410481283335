import * as React from "react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import RateBook from "../ratebook";
import usePath from "../hooks/fetchPath";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function RatebookPage() {
  const { api } = useAuth();
  const { baseURL } = usePath("/api/v1/personnel/");
  const [ratebook, setRatebook] = useState([]);
  const [listData, setListData] = useState([]);
  const [sentMapping, setsentMapping] = useState(false);
  const [errState, setErrState] = useState(false);
  const [errMsg, seterrMsg] = useState("");

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    if (api.authenticated) {
      api
        .get(`/api/v1/ratebook/?sort=lastName&limit=1000`)
        .then((response) => {
          setRatebook(response.data.data);
          setsentMapping(false);
          setErrState(false);
          seterrMsg("");
        })
        .catch((err) => {
          console.log("FAILED", { err });
          setErrState(true);
          seterrMsg("Error Loading Data");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseURL, api]);

  useEffect(() => {
    if (api.authenticated) {
      Promise.all([
        api.get("/api/v1/ratebook/craftlist"),
        api.get("/api/v1/ratebook/classificationlist"),
        api.get("/api/v1/ratebook/booklist"),
        api.get("/api/v1/projects/list"),
      ])
        .then(
          checkFailed(([craft, classification, book, project]) => {
            setListData((prev) => ({
              ...prev,
              craft,
              classification,
              book,
              project,
            }));
            setErrState(false);
            seterrMsg("");
          })
        )
        .catch((err) => {
          console.log("FAIL", err);
          setErrState(true);
          seterrMsg("Error Loading Data");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <div>
      <Box sx={{ height: "100dvh" }}>
        {errState ? (
          <Alert
            icon={<RunningWithErrorsIcon fontSize="inherit" />}
            severity="error"
          >
            {errMsg}
          </Alert>
        ) : null}
        <RateBook
          data={ratebook}
          listData={listData}
          setsentMapping={setsentMapping}
        />
      </Box>
    </div>
  );
}

export default RatebookPage;

import React from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Stack,
} from "@mui/material";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";
const TimecardFilters = ({
  searchFilters,
  setSearchFilters,
  listData,
  weekEndingSaturdays,
}) => {
  const { theme, mode } = useContext(ColorModeContext);
  const handleChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      approved: event.target.value,
    }));
  };

  const handleStatusChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      status: event.target.value,
    }));
  };

  const handleProjectChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      project: event.target.value,
    }));
  };

  const handleWeekStartChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      weekNumberStart: weekEndingSaturdays.find(
        (week) => week.weekEnd === event.target.value
      ).weekNumber,
      weekStartString: event.target.value,
      yearStart: weekEndingSaturdays.find(
        (week) => week.weekEnd === event.target.value
      ).year,
    }));
  };

  const handleWeekEndChange = (event) => {
    setSearchFilters((prev) => ({
      ...prev,
      weekNumberEnd: weekEndingSaturdays.find(
        (week) => week.weekEnd === event.target.value
      ).weekNumber,
      weekEndString: event.target.value,
      yearEnd: weekEndingSaturdays.find(
        (week) => week.weekEnd === event.target.value
      ).year,
    }));
  };

  return (
    <Stack direction="row" spacing={2} sx={{ p: 1, width: "100%" }}>
      <FormControl variant="outlined" fullWidth>
        <InputLabel
          id="timecard-filter-label"
          sx={{
            color: theme.palette.neutral[300], // ✅ Custom label color
            fontSize: "0.9rem", // ✅ Adjust label size
            "&.Mui-focused": { color: theme.palette.neutral[300] }, // ✅ Change label color on focus
          }}
        >
          Approved
        </InputLabel>
        <Select
          labelId="timecard-filter-label"
          value={searchFilters?.approved}
          onChange={handleChange}
          label="Approved"
          sx={{
            backgroundColor: theme.palette.neutral[50], // ✅ Light blue background
            color: theme.palette.neutral[400], // ✅ Custom text color
            fontSize: "0.95rem", // ✅ Adjust text size
            height: "40px",
            borderRadius: "8px", // ✅ Rounded corners
            border: `2px solid ${theme.palette.neutral[100]}`, // ✅ Custom border
            "&:hover": {
              backgroundColor: theme.palette.neutral[100], // ✅ Slightly darker blue on hover
              border: `2px solid ${theme.palette.primary[300]}`,
            },
            "&.Mui-focused": {
              border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
            },
          }}
        >
          <MenuItem
            value={false}
            sx={{
              fontSize: "0.9rem", // ✅ Adjust menu item font size
              backgroundColor: "white", // ✅ Menu item background
              color: "black", // ✅ Text color
              "&.Mui-selected": {
                backgroundColor: "rgba(255, 87, 34, 0.3) !important", // ✅ Orange when selected
                color: "darkred",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "rgba(255, 87, 34, 0.4) !important", // ✅ Slightly darker orange on hover
              },
            }}
          >
            False
          </MenuItem>
          <MenuItem
            value={true}
            sx={{
              fontSize: "0.9rem",
              backgroundColor: "white",
              color: "black",
              "&.Mui-selected": {
                backgroundColor: "rgba(76, 175, 80, 0.3) !important", // ✅ Green when selected
                color: "darkgreen",
              },
              "&.Mui-selected:hover": {
                backgroundColor: "rgba(76, 175, 80, 0.4) !important", // ✅ Slightly darker green on hover
              },
            }}
          >
            True
          </MenuItem>
        </Select>
      </FormControl>

      <FormControl variant="outlined" fullWidth>
        <InputLabel
          id="timecard-filter-label"
          sx={{
            color: theme.palette.neutral[300], // ✅ Custom label color
            fontSize: "0.9rem", // ✅ Adjust label size
            "&.Mui-focused": { color: theme.palette.neutral[300] }, // ✅ Change label color on focus
          }}
        >
          Status
        </InputLabel>
        <Select
          labelId="timecard-filter-label"
          value={searchFilters?.status}
          onChange={handleStatusChange}
          label="Status"
          sx={{
            backgroundColor: theme.palette.neutral[50], // ✅ Light blue background
            color: theme.palette.neutral[400], // ✅ Custom text color
            fontSize: "0.95rem", // ✅ Adjust text size
            height: "40px",
            borderRadius: "8px", // ✅ Rounded corners
            border: `2px solid ${theme.palette.neutral[100]}`, // ✅ Custom border
            "&:hover": {
              backgroundColor: theme.palette.neutral[100], // ✅ Slightly darker blue on hover
              border: `2px solid ${theme.palette.primary[300]}`,
            },
            "&.Mui-focused": {
              border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
            },
          }}
        >
          <MenuItem value="Complete">Complete</MenuItem>
          <MenuItem value="Incomplete">Incomplete</MenuItem>
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <InputLabel
          id="timecard-filter-label"
          sx={{
            color: theme.palette.neutral[300], // ✅ Custom label color
            fontSize: "0.9rem", // ✅ Adjust label size
            "&.Mui-focused": { color: theme.palette.neutral[300] }, // ✅ Change label color on focus
          }}
        >
          Select A Project
        </InputLabel>
        <Select
          labelId="timecard-filter-label"
          value={searchFilters?.project}
          onChange={handleProjectChange}
          label="Project"
          sx={{
            backgroundColor: theme.palette.neutral[50], // ✅ Light blue background
            color: theme.palette.neutral[400], // ✅ Custom text color
            fontSize: "0.95rem", // ✅ Adjust text size
            height: "40px",
            borderRadius: "8px", // ✅ Rounded corners
            border: `2px solid ${theme.palette.neutral[100]}`, // ✅ Custom border
            "&:hover": {
              backgroundColor: theme.palette.neutral[100], // ✅ Slightly darker blue on hover
              border: `2px solid ${theme.palette.primary[300]}`,
            },
            "&.Mui-focused": {
              border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
            },
          }}
        >
          <MenuItem value="All Projects">All Projects</MenuItem>
          {listData?.projects?.data?.data.map((project, index) => (
            <MenuItem key={index} value={project}>
              {project}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <InputLabel
          id="timecard-filter-label"
          sx={{
            color: theme.palette.neutral[300], // ✅ Custom label color
            fontSize: "0.9rem", // ✅ Adjust label size
            "&.Mui-focused": { color: theme.palette.neutral[300] }, // ✅ Change label color on focus
          }}
        >
          Week Start
        </InputLabel>
        <Select
          labelId="timecard-filter-label"
          value={searchFilters?.weekStartString}
          onChange={handleWeekStartChange}
          label="Project"
          sx={{
            backgroundColor: theme.palette.neutral[50], // ✅ Light blue background
            color: theme.palette.neutral[400], // ✅ Custom text color
            fontSize: "0.95rem", // ✅ Adjust text size
            height: "40px",
            borderRadius: "8px", // ✅ Rounded corners
            border: `2px solid ${theme.palette.neutral[100]}`, // ✅ Custom border
            "&:hover": {
              backgroundColor: theme.palette.neutral[100], // ✅ Slightly darker blue on hover
              border: `2px solid ${theme.palette.primary[300]}`,
            },
            "&.Mui-focused": {
              border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
            },
          }}
        >
          <MenuItem value="All Projects">All Projects</MenuItem>
          {weekEndingSaturdays.map((project, index) => (
            <MenuItem key={index} value={project.weekEnd}>
              {project.weekEnd}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl variant="outlined" fullWidth>
        <InputLabel
          id="timecard-filter-label"
          sx={{
            color: theme.palette.neutral[300], // ✅ Custom label color
            fontSize: "0.9rem", // ✅ Adjust label size
            "&.Mui-focused": { color: theme.palette.neutral[300] }, // ✅ Change label color on focus
          }}
        >
          Week End
        </InputLabel>
        <Select
          labelId="timecard-filter-label"
          value={searchFilters?.weekEndString}
          onChange={handleWeekEndChange}
          label="Project"
          sx={{
            backgroundColor: theme.palette.neutral[50], // ✅ Light blue background
            color: theme.palette.neutral[400], // ✅ Custom text color
            fontSize: "0.95rem", // ✅ Adjust text size
            height: "40px",
            borderRadius: "8px", // ✅ Rounded corners
            border: `2px solid ${theme.palette.neutral[100]}`, // ✅ Custom border
            "&:hover": {
              backgroundColor: theme.palette.neutral[100], // ✅ Slightly darker blue on hover
              border: `2px solid ${theme.palette.primary[300]}`,
            },
            "&.Mui-focused": {
              border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
            },
          }}
        >
          <MenuItem value="All Projects">All Projects</MenuItem>
          {weekEndingSaturdays.map((project, index) => (
            <MenuItem key={index} value={project.weekEnd}>
              {project.weekEnd}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Stack>
  );
};

export default TimecardFilters;

//mui typography two tone inline text
import React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

const TwoToneInlineText = ({ text1, text2 }) => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  return (
    <Box>
      <Box display="inline">
        <Typography
          variant="body1"
          component="span"
          color={theme.palette.primary[300]}
        >
          {text1}
        </Typography>
        <Typography
          variant="body1"
          component="span"
          color={theme.palette.primary[300]}
        >
          {": "}
        </Typography>
        <Typography
          variant="body1"
          component="span"
          color={theme.palette.neutral[600]}
        >
          {text2}
        </Typography>
      </Box>
    </Box>
  );
};

export default TwoToneInlineText;

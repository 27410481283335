import * as React from "react";
import PropTypes from "prop-types";
import { Select as BaseSelect, selectClasses } from "@mui/base/Select";
import { Option as BaseOption, optionClasses } from "@mui/base/Option";
import { styled } from "@mui/system";
import UnfoldMoreRoundedIcon from "@mui/icons-material/UnfoldMoreRounded";
import { CssTransition } from "@mui/base/Transitions";
import { PopupContext } from "@mui/base/Unstable_Popup";
import { useTheme } from "@mui/material/styles"; // ✅ Get theme from MUI
import { ColorModeContext } from "../theme"; // ✅ Get mode toggle function

export default function CustomSelect({
  value,
  optionsArray,
  handleChange,
  keyLookup,
  error,
  width = "100px", // Default width
}) {
  return (
    <Select
      value={value ?? ""}
      error={error}
      sx={{ minWidth: width }} // Apply width with minWidth
      //fullWidth // Ensures Select fills the parent Box
      onChange={(event, newValue) => handleChange(newValue)}
    >
      <Option value={""}> Please Select</Option>
      {optionsArray &&
        optionsArray.length > 0 &&
        optionsArray.map((option, index) => (
          <Option key={index} value={option}>
            {keyLookup ? option[keyLookup] : option}
          </Option>
        ))}
    </Select>
  );
}

const Select = React.forwardRef(function CustomSelect(props, ref) {
  const { error, slots: userSlots, ...other } = props;
  const theme = useTheme(); // ✅ Get the current MUI theme

  const slots = {
    root: React.forwardRef((slotProps, slotRef) => (
      <StyledButton {...slotProps} ref={slotRef} error={error} />
    )),
    listbox: AnimatedListbox,
    popup: Popup,
    ...userSlots,
  };

  return (
    <BaseSelect
      ref={ref}
      slots={slots}
      {...other}
      sx={{
        backgroundColor:
          theme.palette.mode === "dark"
            ? theme.palette.neutral[50]
            : theme.palette.neutral[50],
        color: theme.palette.neutral[500],
        border:
          "1px solid" +
          (theme.palette.mode === "dark"
            ? theme.palette.neutral[100]
            : theme.palette.neutral[200]),
      }}
    />
  );
});

Select.propTypes = {
  /**
   * The components used for each slot inside the Select.
   * Either a string to use a HTML element or a component.
   * @default {}
   */
  slots: PropTypes.shape({
    listbox: PropTypes.elementType,
    popup: PropTypes.elementType,
    root: PropTypes.elementType,
  }),
};

const blue = {
  100: "#DAECFF",
  200: "#99CCF3",
  400: "#3399FF",
  500: "#007FFF",
  600: "#0072E5",
  700: "#0059B2",
  900: "#003A75",
};

const grey = {
  50: "#F3F6F9",
  100: "#E5EAF2",
  200: "#DAE2ED",
  300: "#C7D0DD",
  400: "#B0B8C4",
  500: "#9DA8B7",
  600: "#6B7A90",
  700: "#434D5B",
  800: "#303740",
  900: "#1C2025",
};

const red = {
  50: "#FEF6F6",
  100: "#FCE4E4",
  200: "#DAE2ED",
  300: "#F09898",
  400: "#E47474",
  500: "#C41C1C",
  600: "#A51818",
  700: "#7D1212",
  800: "#430A0A",
  900: "#240505",
};

const Button = React.forwardRef(function Button(props, ref) {
  const { ownerState, ...other } = props;
  return (
    <button type="button" {...other} ref={ref}>
      {other.children}
      <UnfoldMoreRoundedIcon />
    </button>
  );
});

Button.propTypes = {
  children: PropTypes.node,
  ownerState: PropTypes.object.isRequired,
};

const StyledButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "error",
})(({ theme, error }) => {
  const borderColor = error === true ? red[500] : grey[500];
  return `
  font-family: 'Inter', sans-serif;
  font-size: 0.6rem;
  box-sizing: border-box;
  min-width: 100px;
  padding: 8px 12px;
  border-radius: 8px;
  text-align: left;
  line-height: 1.5;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${borderColor};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  position: relative;
  box-shadow: 0 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 120ms;

  &:hover {
    background: ${theme.palette.mode === "dark" ? grey[800] : grey[50]};
    border-color: ${theme.palette.mode === "dark" ? grey[600] : grey[300]};
    color: ${theme.palette.mode === "dark" ? blue[800] : blue[50]};
  }

  &.${selectClasses.focusVisible} {
    outline: 0;
    border-color: ${blue[400]};
    box-shadow: 0 0 0 3px ${
      theme.palette.mode === "dark" ? blue[700] : blue[200]
    };
  }

  & > svg {
    font-size: 1rem;
    position: absolute;
    height: 100%; 
    top: 0;
    right: 10px;
  }
  `;
});

const Listbox = styled("ul")(
  ({ theme }) => `
  font-family: 'Inter', sans-serif;
  font-size: 0.6rem;
  box-sizing: border-box;
  padding: 6px;
  margin: 12px 0;
  min-width: 100px;
  border-radius: 12px;
  max-height: 400px;
  overflow: auto;
  outline: 0;
  background: ${theme.palette.mode === "dark" ? grey[900] : "#fff"};
  border: 1px solid ${theme.palette.mode === "dark" ? grey[700] : grey[200]};
  color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  box-shadow: 0 2px 4px ${
    theme.palette.mode === "dark" ? "rgba(0,0,0, 0.5)" : "rgba(0,0,0, 0.05)"
  };
  
  .closed & {
    opacity: 0;
    transform: scale(0.95, 0.8);
    transition: opacity 200ms ease-in, transform 200ms ease-in;
  }
  
  .open & {
    opacity: 1;
    transform: scale(1, 1);
    transition: opacity 100ms ease-out, transform 100ms cubic-bezier(0.43, 0.29, 0.37, 1.48);
  }

  .placement-top & {
    transform-origin: bottom;
  }

  .placement-bottom & {
    transform-origin: top;
  }
  `
);

const AnimatedListbox = React.forwardRef(function AnimatedListbox(props, ref) {
  const { ownerState, ...other } = props;
  const popupContext = React.useContext(PopupContext);

  if (popupContext == null) {
    throw new Error(
      "The `AnimatedListbox` component cannot be rendered outside a `Popup` component"
    );
  }

  const verticalPlacement = popupContext.placement.split("-")[0];

  return (
    <CssTransition
      className={`placement-${verticalPlacement}`}
      enterClassName="open"
      exitClassName="closed"
    >
      <Listbox {...other} ref={ref} />
    </CssTransition>
  );
});

AnimatedListbox.propTypes = {
  ownerState: PropTypes.object.isRequired,
};

const Option = styled(BaseOption)(
  ({ theme }) => `
  list-style: none;
  padding: 8px;
  border-radius: 8px;
  cursor: default;
  padding: 8px;
  white-space: nowrap; /* Prevent text from wrapping */
  overflow: hidden; /* Hide overflow */
  text-overflow: ellipsis; /* Show ellipsis for long text */
  &:hover {
    background-color: #f0f0f0;
  }
  &:last-of-type {
    border-bottom: none;
  }

  &.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.highlighted} {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }

  &:focus-visible {
    outline: 3px solid ${theme.palette.mode === "dark" ? blue[600] : blue[200]};
  }
  
  &.${optionClasses.highlighted}.${optionClasses.selected} {
    background-color: ${theme.palette.mode === "dark" ? blue[900] : blue[100]};
    color: ${theme.palette.mode === "dark" ? blue[100] : blue[900]};
  }

  &.${optionClasses.disabled} {
    color: ${theme.palette.mode === "dark" ? grey[700] : grey[400]};
  }

  &:hover:not(.${optionClasses.disabled}) {
    background-color: ${theme.palette.mode === "dark" ? grey[800] : grey[100]};
    color: ${theme.palette.mode === "dark" ? grey[300] : grey[900]};
  }
  `
);

const Popup = styled("div")`
  z-index: 1;
`;

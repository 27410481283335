import { useState, useEffect } from "react";
import DashBoardBox from "../components/DashBoardBox";
import AnalyticsBoxHeader3 from "../components/AnalyticsBoxHeader3";
import { Box, Stack, useTheme } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import {
  ResponsiveContainer,
  Pie,
  PieChart,
  Cell,
  Legend,
  Sector,
  XAxis,
  YAxis,
  Bar,
  CartesianGrid,
  Tooltip,
  BarChart,
} from "recharts";
import dayjs from "dayjs";
import { useAuth } from "../context/AuthProvider";
import threeWeekLookBack from "../hooks/three-week-lookback";
import CustomSelect from "../styledcomponents/CustomSelect";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

const Row1 = ({
  timecardData,
  error,
  errMsg,
  label,
  swData,
  quarterlyData,
  professionalSearchFilters,
}) => {
  const [transformedData, setTransformedData] = useState([]);
  const { theme, toggleColorMode } = useContext(ColorModeContext);

  useEffect(() => {
    if (!quarterlyData || !Array.isArray(quarterlyData)) {
      setTransformedData([]); // Ensure transformedData is always an array
      return;
    }

    setTransformedData(
      quarterlyData
        .map((item) => ({
          quarter: item._id.quarter && `Q${item._id.quarter}`,
          regHours: item?.regHours ?? 0,
          overtimeHours: item?.overtimeHours ?? 0,
          satHours: item?.satHours ?? 0,
          sunHours: item?.sunHours ?? 0,
          totalHours: item?.totalHours ?? 0,
        }))
        .reverse()
    );
  }, [quarterlyData]);

  const pastTwelveMonthsLabels = Array.from({ length: 12 }, (_, i) =>
    dayjs().subtract(i, "month").format("M/YYYY")
  ).reverse();

  const [tcDataRendered, settcDataRendered] = useState([]);
  const [activeIndex, setactiveIndex] = useState({
    activeIndex: 0,
  });
  const { sixWkBkDesc } = threeWeekLookBack();
  const { user, setUser } = useAuth();
  const [selectedPie, setSelectedPie] = useState([]);
  const [totalHoursFilter, setTotalHoursFilter] = useState(sixWkBkDesc[4]);
  const [monthSelect, setMonthSelect] = useState(pastTwelveMonthsLabels[11]);

  const weeksObject = threeWeekLookBack();
  const handleChange = (week) => {
    setTotalHoursFilter(week);
    //requery the data
  };

  //searches array by id prop, returns that object
  function searchById(array, id) {
    return array.find((item) => item.id === id);
  }

  function reformatData(input) {
    if (!input) return []; // Return null if input is null or undefined

    return [
      { name: "reg", value: input.reg || 0 },
      { name: "OT", value: input.ot || 0 },
      { name: "Sat", value: input.sat || 0 },
      { name: "Sun", value: input.sun || 0 },
    ];
  }

  const handleMonthChange = (key) => {
    const obj = searchById(swData, key); //returns object
    const pieData = reformatData(obj);
    setSelectedPie(pieData);
    setMonthSelect(key);
    // setSearchFilters((prev) => ({
    // 	...prev,
    // 	approved: event.target.value,
    //   }));
  };

  useEffect(() => {
    if (swData) {
      const pieData = reformatData(swData[0]);
      setSelectedPie(pieData);
    }
  }, [swData]);
  useEffect(() => {
    //setAllTimecards(data);
    let renderedData = [];
    let bargraphData = [];
    let result;
    //map through array of objectsw
    if (timecardData.length > 0) {
      renderedData = timecardData.map((timecard) => ({
        id: timecard._id,
        weekEndingString: timecard.weekEndingString,
        fullName: timecard.fullName,
        regularHours: timecard.calculations.regHours,
        OTHours: timecard.calculations.overtimeHours,
        saturdayHours: timecard.calculations.satHours,
        sundayHours: timecard.calculations.sunHours,
      }));
      // bargraphData = timecardData.map((timecard) => ({
      // 	id: timecard._id,
      // 	weekEnd: dayjs(timecard.weekEnd).format("MM/DD/YYYY"),
      // 	regHours: timecard.regHours,
      // 	overtimeHours: timecard.overtimeHours,
      // 	satHours: timecard.satHours,
      // 	sunHours: timecard.sunHours,
      // }));
      // result = filteredBarGraphData(sixWkBkDesc, bargraphData);
      settcDataRendered(renderedData);
    }
  }, [timecardData, user]);

  const timecardColumns = [
    {
      field: "weekEndingString",
      headerName: "Week Ending",
      width: 100,
    },
    {
      field: "fullName",
      headerName: "Name",
      flex: 0.2,
    },
    {
      field: "regularHours",
      headerName: "Reg Hours",
      flex: 0.2,
    },
    {
      field: "OTHours",
      headerName: "OT",
      flex: 0.2,
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "saturdayHours",
      headerName: "Sat",
      flex: 0.2,
      renderCell: (params) => `${params.value}`,
    },
    {
      field: "sundayHours",
      headerName: "Sun",
      flex: 0.2,
      renderCell: (params) => `${params.value}`,
    },
  ];

  //const returnArray = projectAnalytics(unfilteredData);

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "rgba(0, 0, 0, 0.75)", // ✅ Dark background for contrast
            color: "white",
            padding: "8px",
            borderRadius: "5px",
            fontSize: "0.8rem",
            fontFamily: "IBM Plex Sans Condensed",
            boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.5)", // ✅ Modern shadow effect
          }}
        >
          <p>{`Quarter: ${label}`}</p>
          {payload.map((entry, index) => (
            <p key={index} style={{ color: entry.color }}>
              {`${entry.name}: ${entry.value} hours`}
            </p>
          ))}
        </div>
      );
    }
    return null;
  };

  const COLORS = ["#97C3F0", "#4393E4", "#0B6BCB", "#185EA5"];

  const renderActiveShape = (props) => {
    const RADIAN = Math.PI / 180;
    const {
      cx,
      cy,
      midAngle,
      innerRadius,
      outerRadius,
      startAngle,
      endAngle,
      fill,
      payload,
      percent,
      value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";

    return (
      <g>
        <text x={cx} y={cy} dy={8} textAnchor="middle" fill={fill}>
          {payload.name}
        </text>
        <Sector
          cx={cx}
          cy={cy}
          innerRadius={innerRadius}
          outerRadius={outerRadius}
          startAngle={startAngle}
          endAngle={endAngle}
          fill={fill}
        />
        <Sector
          cx={cx}
          cy={cy}
          startAngle={startAngle}
          endAngle={endAngle}
          innerRadius={outerRadius + 6}
          outerRadius={outerRadius + 10}
          fill={fill}
        />
      </g>
    );
  };

  const onPieEnter = (_, index) => {
    setactiveIndex(index);
  };

  return (
    <>
      <DashBoardBox gridArea="a">
        <AnalyticsBoxHeader3
          title={`My Unapproved Timecards`}
          subtitle="Datagrid"
        />

        {errMsg && tcDataRendered.length === 0 ? (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            {errMsg}
          </Alert>
        ) : null}
        {tcDataRendered.length === 0 && !errMsg ? (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            No data exists
          </Alert>
        ) : null}

        <Box
          p="0 0.5rem"
          height="75%"
          sx={{
            "& .MuiDataGrid-root": {
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[600] // ✅ White text for dark mode
                  : theme.palette.neutral[900], // ✅ Dark text for light mode
              border: "none",
              backgroundColor:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[100] // ✅ Dark mode bg
                  : theme.palette.neutral[50], // ✅ Light mode bg
            },
            "& .MuiDataGrid-cell": {
              borderBottom: `1px solid ${theme.palette.neutral[300]} !important`,
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[600] // ✅ White text for dark mode
                  : theme.palette.neutral[400], // ✅ Dark text for light mode
            },
            "& .MuiDataGrid-columnHeaders": {
              color:
                theme.palette.mode === "dark"
                  ? theme.palette.neutral[600] // ✅ Light header text in dark mode
                  : theme.palette.neutral[500], // ✅ Dark header text in light mode
              fontWeight: "bold",
            },
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
          }}
        >
          {timecardData.length > 0 ? (
            <DataGrid
              rowHeight={25}
              columnHeaderHeight={25}
              hideFooter={true}
              rows={tcDataRendered}
              columns={timecardColumns}
            />
          ) : (
            <div />
          )}
        </Box>
      </DashBoardBox>
      <DashBoardBox gridArea="b">
        <Stack direction="row" spacing={1} justifyContent="space-between">
          <AnalyticsBoxHeader3
            title={professionalSearchFilters.project}
            subtitle={
              professionalSearchFilters.role +
              "-" +
              professionalSearchFilters.classification
            }
          />
          <Box sx={{ width: "120px", p: 1 }}>
            <CustomSelect
              value={totalHoursFilter || ""}
              optionsArray={sixWkBkDesc}
              handleChange={handleChange}
            />
          </Box>
        </Stack>
        {errMsg && transformedData.length === 0 ? (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            {errMsg}
          </Alert>
        ) : null}
        {timecardData.length === 0 && !errMsg ? (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            No Data Exists
          </Alert>
        ) : null}
        {transformedData?.length > 0 ? (
          <ResponsiveContainer width="90%" height="80%">
            <BarChart width={700} height={350} data={transformedData}>
              {/* ✅ Define Gradients & Drop Shadow */}
              <defs>
                {/* Regular Hours Gradient */}
                <linearGradient id="regGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#8884d8" stopOpacity={1} />
                  <stop offset="100%" stopColor="#8884d8" stopOpacity={0.6} />
                </linearGradient>

                {/* Overtime Hours Gradient */}
                <linearGradient
                  id="overtimeGradient"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor="#82ca9d" stopOpacity={1} />
                  <stop offset="100%" stopColor="#82ca9d" stopOpacity={0.6} />
                </linearGradient>

                {/* Saturday Hours Gradient */}
                <linearGradient id="satGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#FFBB28" stopOpacity={1} />
                  <stop offset="100%" stopColor="#FFBB28" stopOpacity={0.6} />
                </linearGradient>

                {/* Sunday Hours Gradient */}
                <linearGradient id="sunGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#FF8042" stopOpacity={1} />
                  <stop offset="100%" stopColor="#FF8042" stopOpacity={0.6} />
                </linearGradient>

                {/* Total Hours Gradient */}
                <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#FF7043" stopOpacity={1} />
                  <stop offset="100%" stopColor="#FF7043" stopOpacity={0.6} />
                </linearGradient>

                {/* Drop Shadow Filter */}
                <filter
                  id="shadow"
                  x="-20%"
                  y="-20%"
                  width="140%"
                  height="140%"
                >
                  <feDropShadow
                    dx="2"
                    dy="2"
                    stdDeviation="3"
                    floodColor="rgba(0, 0, 0, 0.3)"
                  />
                </filter>
              </defs>
              <XAxis
                dataKey="quarter"
                style={{
                  fontSize: ".75rem",
                  fontFamily: "IBM Plex Sans Condensed",
                }}
              />
              <YAxis
                style={{
                  fontSize: ".8rem",
                  fontFamily: "IBM Plex Sans Condensed",
                }}
              />
              <Tooltip content={<CustomTooltip />} />{" "}
              {/* ✅ Use custom tooltip */}
              <Bar
                dataKey="regHours"
                name="Regular Hours"
                fill="url(#regGradient)"
                stroke="#4a2abb" // ✅ Darker shade of #8884d8
                strokeWidth={2}
                filter="url(#shadow)"
              />
              <Bar
                dataKey="overtimeHours"
                name="Overtime Hours"
                fill="url(#overtimeGradient)"
                stroke="#2d7a4e" // ✅ Darker shade of #82ca9d
                strokeWidth={2}
                filter="url(#shadow)"
              />
              <Bar
                dataKey="satHours"
                name="Saturday Hours"
                fill="url(#satGradient)"
                stroke="#E69A00" // ✅ Darker shade of #FFBB28
                strokeWidth={2}
                filter="url(#shadow)"
              />
              <Bar
                dataKey="sunHours"
                name="Sunday Hours"
                fill="url(#sunGradient)"
                stroke="#D5642C" // ✅ Darker shade of #FF8042
                strokeWidth={2}
                filter="url(#shadow)"
              />
              <Bar
                dataKey="totalHours"
                name="Total Hours"
                fill="url(#totalGradient)"
                stroke="#C94B35" // ✅ Darker shade of #FF7043
                strokeWidth={2}
                filter="url(#shadow)"
              />
            </BarChart>
          </ResponsiveContainer>
        ) : (
          <Box sx={{ display: "flex", justifyContent: "center" }}></Box>
        )}
      </DashBoardBox>
      <DashBoardBox gridArea="c">
        <Stack
          direction="row"
          maxWidth="300px"
          spacing={1}
          justifyContent={"space-between"}
        >
          <Box>
            <AnalyticsBoxHeader3 title="Project" subtitle="Monthly Breakdown" />
          </Box>

          <Box sx={{ p: 1 }}>
            <CustomSelect
              value={monthSelect || ""}
              optionsArray={pastTwelveMonthsLabels}
              handleChange={handleMonthChange}
              width={"75px"}
            />
          </Box>

          {/* <Box sx={{ width: '120px', p: 1 }}>
            <CustomSelect value={sixWkBkDesc[5]} optionsArray={sixWkBkDesc} />
          </Box> */}
        </Stack>
        {selectedPie.length === 0 && (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            {errMsg || "No data exists"}
          </Alert>
        )}

        <Box
          mt="0.5rem"
          p="0 0.5rem"
          height="75%"
          sx={{
            "& .MuiDataGrid-root": {
              color: `${theme.palette.neutral[200]}`,
              border: "none",
            },
            "& .MuiDataGrid-cell": {
              borderBottom: `1px solid ${theme.palette.neutral[50]} !important`,
            },
            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },
          }}
        >
          <ResponsiveContainer width="90%" height="90%">
            <PieChart height={200} width={200}>
              {selectedPie.reduce((n, { value }) => n + value, 0) > 0 ? (
                <Pie
                  data={selectedPie}
                  cx="50%"
                  cy="50%"
                  innerRadius={40}
                  outerRadius={60}
                  label
                  labelKey="name"
                  stroke="none"
                  sx={{ border: "black" }}
                  dataKey="value"
                  activeIndex={activeIndex}
                  activeShape={renderActiveShape}
                  onMouseEnter={onPieEnter}
                  onMouseLeave={() => setactiveIndex(null)}
                >
                  {selectedPie.map((entry, index) => (
                    <Cell
                      key={`cell-${index}`} // Ensures a unique key
                      fill={COLORS[index % COLORS.length]}
                      sx={{ border: "none" }}
                    />
                  ))}
                </Pie>
              ) : (
                <text
                  x="50%"
                  y="50%"
                  textAnchor="middle"
                  dominantBaseline="middle"
                  fill={theme.palette.neutral[400]}
                >
                  No Data
                </text>
              )}
              {/* <Pie
								data={selectedPie}
								dataKey="value"
								nameKey="name"
								cx="50%"
								cy="50%"
								innerRadius={60}
								outerRadius={80}
								fill="#82ca9d"
								label
							/>
							<Tooltip /> */}
            </PieChart>

            {/* <PieChart width={230} height={250}>
							<Pie
								data={data01}
								dataKey="value"
								nameKey="name"
								cx="50%"
								cy="50%"
								outerRadius={50}
								fill="#8884d8"
							/>
							<Pie
								data={data02}
								dataKey="value"
								nameKey="name"
								cx="50%"
								cy="50%"
								innerRadius={60}
								outerRadius={80}
								fill="#82ca9d"
								label
							/>
						</PieChart> */}
          </ResponsiveContainer>
        </Box>
      </DashBoardBox>
    </>
  );
};

export default Row1;

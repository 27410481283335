import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";

import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAuth } from "../../context/AuthProvider";
//theme imports
import { ColorModeContext, useMode } from "../../theme";
import { useContext } from "react";

const VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().email().required().label("Email"),
});

const Settings = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const { api, user } = useAuth();
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const [userData, setUserData] = useState({
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
  });

  let formik = useFormik({
    initialValues: userData,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      setIsProcessing(true);

      api
        .put(`/api/v1/auth/updatedetails/user`, values)
        .then(({ data }) => {
          setUserData({
            ...data.data,
            password: "",
            passwordConfirmation: "",
          });
        })
        .catch((err) => {
          console.log("FAIL", err);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    formik.handleSubmit(formik.values);
  };

  const selectStyle = {
    backgroundColor: theme.palette.neutral[100],
    color: theme.palette.neutral[500], // ✅ Custom text color
    fontSize: "0.75rem", // ✅ Adjust text size
    borderRadius: "4px", // ✅ Rounded corners
    border: `1px solid ${theme.palette.neutral[400]}`, // ✅ Custom border
    "&:hover": {
      backgroundColor: theme.palette.primary[100], // ✅ Slightly darker blue on hover
      border: `2px solid ${theme.palette.primary[300]}`,
      color: theme.palette.neutral[600],
    },

    "& .MuiInputLabel-root": {
      color: `${theme.palette.neutral[600]}!important`, // ✅ Default label color
    },
    "& .MuiInputBase-input": {
      color: `${theme.palette.neutral[600]}!important`, // ✅ Ensures input text color
    },
    flexGrow: 1,
  };

  return (
    <Card raised>
      <CardHeader
        title="User Profile"
        sx={{ color: theme.palette.neutral[400] }}
      ></CardHeader>
      <Divider sx={{ marginBottom: "16px" }} />
      <CardContent>
        <FormControl sx={{ width: "100%" }}>
          <Stack spacing={4}>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="32px"
            >
              <TextField
                id="firstName"
                label="First Name"
                variant="outlined"
                name="firstName"
                required
                disabled={isProcessing}
                value={formik.values.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName && formik.errors.firstName}
                width="100%"
                sx={selectStyle}
              />

              <TextField
                id="lastName"
                label="Last Name"
                variant="outlined"
                name="lastName"
                required
                disabled={isProcessing}
                value={formik.values.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.firstName && formik.errors.lastName}
                width="100%"
                sx={selectStyle}
              />
            </Box>

            <TextField
              id="email"
              label="Email"
              variant="outlined"
              name="email"
              type="email"
              required
              disabled={isProcessing}
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
              sx={selectStyle}
            />

            <Button
              onClick={handleSubmit}
              disabled={isProcessing || !formik.dirty || !formik.isValid}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              sx={{
                backgroundColor: "blue", // ✅ Default background color
                color: "white", // ✅ Text color
                fontSize: "1rem", // ✅ Adjust font size
                fontWeight: "bold",
                padding: "10px 20px", // ✅ Adjust button size
                borderRadius: "8px", // ✅ Rounded corners
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // ✅ Drop shadow
                transition: "all 0.3s ease-in-out", // ✅ Smooth transition
                "&:hover": {
                  backgroundColor: "darkblue", // ✅ Darker color on hover
                  boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)", // ✅ Stronger shadow
                },
                "&:active": {
                  transform: "scale(0.98)", // ✅ Click effect
                },
                "&.Mui-disabled": {
                  backgroundColor: "gray", // ✅ Custom disabled color
                  color: "lightgray",
                },
              }}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="primary" />
              ) : (
                "Update User Profile"
              )}
            </Button>
          </Stack>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Settings;

import { generatePayrollPDF } from "./GeneratePayrollPDF";
import { generateProjectXML } from "./GeneratePayrollXML";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { getListOfUniqueProjects } from "../../utils/runpayroll";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { useState } from "react";
import {
  sumPropertyInArray,
  sumUniqueProjectList,
} from "../../utils/runpayroll";

const ReviewForPayroll2 = ({ payrollData }) => {
  console.log(payrollData);
  const projects = sumUniqueProjectList(payrollData);
  const [pdfDownload, setPdfDownload] = useState(false);
  const [xmlDownload, setXMLDownload] = useState(false);

  const handleOpenPDF = async (payrollData) => {
    setPdfDownload(true);
    const blob = await generatePayrollPDF(payrollData);
    const url = URL.createObjectURL(blob);
    window.open(url, "_blank");
  };

  const downloadXML = (xmlString, filename = "report.xml") => {
    setXMLDownload(true);
    const blob = new Blob([xmlString], { type: "application/xml" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = filename;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div>
      <Typography> Download Summary </Typography>
      <Stack direction="row" spacing={3}>
        <Typography>
          {" "}
          {"Total Wages: $" + sumPropertyInArray("totalWages", payrollData)}
        </Typography>
        <Typography>
          {" "}
          {"Total Hours: " +
            sumPropertyInArray("calculations.totalHours", payrollData)}
        </Typography>
        <Typography>
          {" "}
          {"Week Ending: " + payrollData[0].weekEndingString}
        </Typography>
        <Typography> {"Week Number: " + payrollData[0].weekNumber}</Typography>
      </Stack>
      <Stack direction="row" spacing={3} sx={{ flexWrap: "wrap" }}>
        {projects &&
          projects.map((project, index) => (
            <Box key={index} sx={{ p: 2 }}>
              <Typography noWrap>{"Project: " + project.name}</Typography>
              <Typography noWrap>
                {"Project Hours: " + project.hours}
              </Typography>
              <Typography noWrap>{"Wages: $" + project.wages}</Typography>
            </Box>
          ))}
      </Stack>
      <Stack direction="row" spacing={2} sx={{ margin: "auto" }}>
        <Button
          variant="contained"
          startIcon={
            !pdfDownload ? <FileDownloadIcon /> : <FileDownloadDoneIcon />
          }
          onClick={() => handleOpenPDF(payrollData)}
        >
          Download PDF
        </Button>
        <Button
          variant="contained"
          startIcon={
            !xmlDownload ? <FileDownloadIcon /> : <FileDownloadDoneIcon />
          }
          onClick={() => {
            const projectlist = getListOfUniqueProjects(payrollData);
            projectlist.forEach((projectName) => {
              const xml = generateProjectXML(projectName, payrollData);
              const safeName = projectName
                .replace(/\s+/g, "_")
                .replace(/[^\w\-]/g, "");
              downloadXML(
                xml,
                `${safeName}-${
                  payrollData[0] && payrollData[0].weekEndingString
                    ? payrollData[0].weekEndingString
                    : "report"
                }.xml`
              );
            });
            //const xml = generatePayrollXML(payrollData);
            //downloadXML(xml);
          }}
        >
          {" "}
          Generate XML
        </Button>
      </Stack>
    </div>
  );
};

export default ReviewForPayroll2;

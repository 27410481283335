import Popover from "@mui/material/Popover";
import { Box, Typography, Stack } from "@mui/material";
import dayjs from "dayjs";

const NotificationsPopover = ({
  idPopOut,
  openPopOut,
  anchorElPopOut,
  setAnchorElPopOut,
  message,
  notifications,
}) => {
  const handlePopOutClose = () => {
    setAnchorElPopOut(null);
  };
  var LocalizedFormat = require("dayjs/plugin/localizedFormat");
  dayjs.extend(LocalizedFormat);
  dayjs().format("llll");

  //   const id = open ? 'simple-popover' : undefined;
  return (
    <Box>
      {/* <Button aria-describedby={id} onClick={handleClick}>
        Query
      </Button> */}
      <Popover
        id={idPopOut}
        open={openPopOut}
        anchorEl={anchorElPopOut}
        onClose={handlePopOutClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Box sx={{ maxWidth: "300px" }}>
          {notifications.map((notification, index) => (
            <Stack key={index} sx={{ p: 1 }}>
              <Box sx={{ display: "flex" }}>
                <Typography sx={{ px: 1 }}>{index + 1}.</Typography>
                <Typography>{notification.message}</Typography>
              </Box>

              <Box sx={{ textAlign: "right" }}>
                <Typography
                  variant="joymed"
                  sx={{ color: "white", textAlign: "right" }}
                >
                  Created By: {notification.userFullName}
                </Typography>
              </Box>
              <Box sx={{ textAlign: "right" }}>
                <Typography
                  variant="joymed"
                  sx={{ color: "yellow", textAlight: "right" }}
                >
                  {dayjs(notification.createdAt).format("llll")}
                </Typography>
              </Box>
            </Stack>
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default NotificationsPopover;

//functional card component
import React, { useState, useMemo } from "react";
import AspectRatio from "@mui/joy/AspectRatio";
import Card from "@mui/joy/Card";
import CardContent from "@mui/joy/CardContent";
import CardOverflow from "@mui/joy/CardOverflow";
import Chip from "@mui/joy/Chip";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Link from "@mui/joy/Link";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import WorkspacePremiumRoundedIcon from "@mui/icons-material/WorkspacePremiumRounded";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import { CssVarsProvider } from "@mui/joy/styles";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import Root from "../../styled/Test";
import Button from "@mui/joy/Button";
import Avatar from "@mui/joy/Avatar";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import GroupIcon from "@mui/icons-material/Group";
import { useAuth } from "../../context/AuthProvider";
import ProjectEffectiveRates from "./ProjectEffectiveRates";
import WorkHistoryIcon from "@mui/icons-material/WorkHistory";
import dayjs from "dayjs";
import { useEffect } from "react";
import SearchRateBookModal from "./../SearchRateBookModal";
import AddBookFormMui from "../../components/AddBookFormMui";
import Modal from "@mui/joy/Modal";
import { counties } from "../../assets/counties";

//Modal Stuff
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 390,
  bgcolor: "white",
  border: "2px solid #000",
  borderRadius: 10,
  boxShadow: 24,
  // p: 4,
  overflowY: "auto",
  p: 1,
};

const SetRatesTimecard2 = ({
  payroll,
  onUpdate,
  listData,
  isLoading,
  setIsLoading,
  error,
  setError,
  errMsg,
  setErrMsg,
  ratebook,
  setRatebook,
  myRates,
  setMyRates,
  setCompletedIds,
}) => {
  const { user } = useAuth();
  const [isLiked, setIsLiked] = React.useState(true);
  const [firstInitial, setFirstInitial] = React.useState();
  const [lastInitial, setLastInitial] = React.useState();
  const [selectedRate, setSelectedRate] = useState(
    payroll?.selectedRate ? payroll.selectedRate : null
  );
  const [selectedRate2, setSelectedRate2] = useState(
    payroll?.selectedRate2 ? payroll.selectedRate2 : null
  );
  const [selectedRate3, setSelectedRate3] = useState(
    payroll?.selectedRate3 ? payroll.selectedRate3 : null
  );
  const [localPayroll, setLocalPayroll] = useState(payroll);
  const [localPayrollRates, setLocalPayrollRates] = useState(payroll.rates);
  const [openFiltersModal, setOpenFiltersModal] = useState(false);
  const [personnelId, setPersonnelId] = useState();
  const handleOpenFiltersModal = () => setOpenFiltersModal(true);
  const handleCloseFiltersModal = () => setOpenFiltersModal(false);
  const [openBook, setOpenBook] = useState(false);
  //const handleOpenBook = () => setOpenBook(true);
  const handleOpenBook = async () => {
    try {
      setOpenBook(true); // Assuming `setOpen` is your state setter
    } catch (error) {
      console.error("Error opening modal:", error);
    }
  };
  const handleCloseBook = () => setOpenBook(false);
  const filteredCounties = counties.filter((county) => county.state === "CA");

  useEffect(() => {
    setPersonnelId(payroll.personnelId);
  }, [payroll]);

  useEffect(() => {
    if (payroll?.firstName && payroll?.lastName) {
      setFirstInitial(payroll.firstName.charAt(0));
      setLastInitial(payroll.lastName.charAt(0));
    }
  }, [payroll]);

  const handleRateChange1 = (value) => {
    console.log("value", value);
    setSelectedRate(value);
  };

  const handleRateChange2 = (value) => {
    setSelectedRate2(value);
  };

  const handleRateChange3 = (value) => {
    setSelectedRate3(value);
  };

  const handleUpdate = (
    localPayroll,
    selectedRate,
    selectedRate2,
    selectedRate3
  ) => {
    onUpdate(localPayroll, selectedRate, selectedRate2, selectedRate3); // Notify parent of updates
  };

  useEffect(() => {
    console.log("fired");
    setCompletedIds([]);
  }, []); //resets completed ids at every step

  //separate useEffect into three separate rates
  useEffect(() => {
    if (selectedRate) {
      const fringe = selectedRate?.regRate - selectedRate?.basicRate;
      const effectiveIncrease1 =
        selectedRate?.dateInc1 && dayjs().isAfter(dayjs(selectedRate.dateInc1))
          ? selectedRate?.increase1
          : 0;
      const effectiveIncrease2 =
        selectedRate?.dateInc2 && dayjs().isAfter(dayjs(selectedRate.dateInc2))
          ? selectedRate?.increase2
          : 0;
      const effectiveIncrease3 =
        selectedRate?.dateInc3 && dayjs().isAfter(dayjs(selectedRate.dateInc3))
          ? selectedRate?.increase3
          : 0;
      const effectiveIncrease4 =
        selectedRate?.dateInc4 && dayjs().isAfter(dayjs(selectedRate.dateInc4))
          ? selectedRate?.increase4
          : 0;
      const effectiveIncrease5 =
        selectedRate?.dateInc5 && dayjs().isAfter(dayjs(selectedRate.dateInc5))
          ? selectedRate?.increase5
          : 0;
      const effectiveRegRate =
        selectedRate?.basicRate +
        effectiveIncrease1 +
        effectiveIncrease2 +
        effectiveIncrease3 +
        effectiveIncrease4 +
        effectiveIncrease5 +
        fringe;
      const effectiveOTRate = effectiveRegRate * 1.5;
      const effectiveSatRate = effectiveRegRate * 1.5;
      const effectiveSunRate = effectiveRegRate * 2;
      const finalTotalWages =
        effectiveRegRate * localPayroll.calculations.p1RegHours +
        effectiveOTRate * localPayroll.calculations.p1OTHours +
        effectiveSatRate * localPayroll.calculations.p1SatHours +
        effectiveSunRate * localPayroll.calculations.p1SunHours +
        localPayroll.calculations.p2TotalWages +
        localPayroll.calculations.p3TotalWages;

      if (selectedRate) {
        const newObject = {
          ...localPayroll,
          calculations: {
            ...localPayroll.calculations,
            p1RegRate: effectiveRegRate,
            p1OTRate: effectiveOTRate,
            p1SatRate: effectiveSatRate,
            p1SunRate: effectiveSunRate,
            p1RegWages: effectiveRegRate * localPayroll.calculations.p1RegHours,
            p1OTWages: effectiveOTRate * localPayroll.calculations.p1OTHours,
            p1SatWages: effectiveSatRate * localPayroll.calculations.p1SatHours,
            p1SunWages: effectiveSunRate * localPayroll.calculations.p1SunHours,
            p1TotalWages:
              effectiveRegRate * localPayroll.calculations.p1RegHours +
              effectiveOTRate * localPayroll.calculations.p1OTHours +
              effectiveSatRate * localPayroll.calculations.p1SatHours +
              effectiveSunRate * localPayroll.calculations.p1SunHours,
            finalTotalWages: finalTotalWages,
          },
          totalWages: finalTotalWages,
        };

        if (JSON.stringify(localPayroll) !== JSON.stringify(newObject)) {
          setLocalPayroll(newObject);
        }
      }
    } else {
      //update calculations.finalTotalWages
      const finalTotalWages =
        localPayroll.calculations.p2TotalWages +
        localPayroll.calculations.p3TotalWages;

      const newObject = {
        ...localPayroll,
        calculations: {
          ...localPayroll.calculations,
          p1RegRate: 0,
          p1OTRate: 0,
          p1SatRate: 0,
          p1SunRate: 0,
          p1RegWages: 0,
          p1OTWages: 0,
          p1SatWages: 0,
          p1SunWages: 0,
          p1TotalWages: 0,
          finalTotalWages: finalTotalWages,
        },
        totalWages: finalTotalWages,
      };

      if (JSON.stringify(localPayroll) !== JSON.stringify(newObject)) {
        setLocalPayroll(newObject);
      }
    }
  }, [selectedRate]);

  useEffect(() => {
    if (selectedRate2) {
      const fringe = selectedRate2.regRate - selectedRate2.basicRate;
      const effectiveIncreases = [
        { date: selectedRate2.dateInc1, increase: selectedRate2.increase1 },
        { date: selectedRate2.dateInc2, increase: selectedRate2.increase2 },
        { date: selectedRate2.dateInc3, increase: selectedRate2.increase3 },
        { date: selectedRate2.dateInc4, increase: selectedRate2.increase4 },
        { date: selectedRate2.dateInc5, increase: selectedRate2.increase5 },
      ];

      const totalIncreases = effectiveIncreases.reduce((sum, inc) => {
        return (
          sum +
          (inc.date && dayjs().isAfter(dayjs(inc.date)) ? inc.increase : 0)
        );
      }, 0);

      const effectiveRegRate =
        selectedRate2.basicRate + totalIncreases + fringe;
      const effectiveOTRate = effectiveRegRate * 1.5;
      const effectiveSatRate = effectiveRegRate * 1.5;
      const effectiveSunRate = effectiveRegRate * 2;
      const finalTotalWages =
        localPayroll.calculations.p1TotalWages +
        effectiveRegRate * localPayroll.calculations.p2RegHours +
        effectiveOTRate * localPayroll.calculations.p2OTHours +
        effectiveSatRate * localPayroll.calculations.p2SatHours +
        effectiveSunRate * localPayroll.calculations.p2SunHours +
        localPayroll.calculations.p3TotalWages;

      const newObject = {
        ...localPayroll,
        calculations: {
          ...localPayroll.calculations,
          p2RegRate: effectiveRegRate,
          p2OTRate: effectiveOTRate,
          p2SatRate: effectiveSatRate,
          p2SunRate: effectiveSunRate,
          p2RegWages: effectiveRegRate * localPayroll.calculations.p2RegHours,
          p2OTWages: effectiveOTRate * localPayroll.calculations.p2OTHours,
          p2SatWages: effectiveSatRate * localPayroll.calculations.p2SatHours,
          p2SunWages: effectiveSunRate * localPayroll.calculations.p2SunHours,
          p2TotalWages:
            effectiveRegRate * localPayroll.calculations.p2RegHours +
            effectiveOTRate * localPayroll.calculations.p2OTHours +
            effectiveSatRate * localPayroll.calculations.p2SatHours +
            effectiveSunRate * localPayroll.calculations.p2SunHours,
          finalTotalWages: finalTotalWages,
        },
        totalWages: finalTotalWages,
      };

      if (JSON.stringify(localPayroll) !== JSON.stringify(newObject)) {
        setLocalPayroll(newObject);
      }
    } else {
      //update calculations.finalTotalWages
      const finalTotalWages =
        localPayroll.calculations.p1TotalWages +
        localPayroll.calculations.p3TotalWages;

      const newObject = {
        ...localPayroll,
        calculations: {
          ...localPayroll.calculations,
          p2RegRate: 0,
          p2OTRate: 0,
          p2SatRate: 0,
          p2SunRate: 0,
          p2RegWages: 0,
          p2OTWages: 0,
          p2SatWages: 0,
          p2SunWages: 0,
          p2TotalWages: 0,
          finalTotalWages: finalTotalWages,
        },
        totalWages: finalTotalWages,
      };

      if (JSON.stringify(localPayroll) !== JSON.stringify(newObject)) {
        setLocalPayroll(newObject);
      }
    }
  }, [selectedRate2]);

  useEffect(() => {
    if (selectedRate3) {
      const fringe = selectedRate3.regRate - selectedRate3.basicRate;
      const effectiveIncreases = [
        { date: selectedRate3.dateInc1, increase: selectedRate3.increase1 },
        { date: selectedRate3.dateInc2, increase: selectedRate3.increase2 },
        { date: selectedRate3.dateInc3, increase: selectedRate3.increase3 },
        { date: selectedRate3.dateInc4, increase: selectedRate3.increase4 },
        { date: selectedRate3.dateInc5, increase: selectedRate3.increase5 },
      ];

      const totalIncreases = effectiveIncreases.reduce((sum, inc) => {
        return (
          sum +
          (inc.date && dayjs().isAfter(dayjs(inc.date)) ? inc.increase : 0)
        );
      }, 0);

      const effectiveRegRate =
        selectedRate3.basicRate + totalIncreases + fringe;
      const effectiveOTRate = effectiveRegRate * 1.5;
      const effectiveSatRate = effectiveRegRate * 1.5;
      const effectiveSunRate = effectiveRegRate * 2;
      const finalTotalWages =
        localPayroll.calculations.p1TotalWages +
        localPayroll.calculations.p2TotalWages +
        effectiveRegRate * localPayroll.calculations.p3RegHours +
        effectiveOTRate * localPayroll.calculations.p3OTHours +
        effectiveSatRate * localPayroll.calculations.p3SatHours +
        effectiveSunRate * localPayroll.calculations.p3SunHours;

      const newObject = {
        ...localPayroll,
        calculations: {
          ...localPayroll.calculations,
          p3RegRate: effectiveRegRate,
          p3OTRate: effectiveOTRate,
          p3SatRate: effectiveSatRate,
          p3SunRate: effectiveSunRate,
          p3RegWages: effectiveRegRate * localPayroll.calculations.p3RegHours,
          p3OTWages: effectiveOTRate * localPayroll.calculations.p3OTHours,
          p3SatWages: effectiveSatRate * localPayroll.calculations.p3SatHours,
          p3SunWages: effectiveSunRate * localPayroll.calculations.p3SunHours,
          p3TotalWages:
            effectiveRegRate * localPayroll.calculations.p3RegHours +
            effectiveOTRate * localPayroll.calculations.p3OTHours +
            effectiveSatRate * localPayroll.calculations.p3SatHours +
            effectiveSunRate * localPayroll.calculations.p3SunHours,
          finalTotalWages: finalTotalWages,
        },
        totalWages: finalTotalWages,
      };

      if (JSON.stringify(localPayroll) !== JSON.stringify(newObject)) {
        setLocalPayroll(newObject);
      }
    } else {
      //update calculations.finalTotalWages
      const finalTotalWages =
        localPayroll.calculations.p1TotalWages +
        localPayroll.calculations.p2TotalWages;

      const newObject = {
        ...localPayroll,
        calculations: {
          ...localPayroll.calculations,
          p3RegRate: 0,
          p3OTRate: 0,
          p3SatRate: 0,
          p3SunRate: 0,
          p3RegWages: 0,
          p3OTWages: 0,
          p3SatWages: 0,
          p3SunWages: 0,
          p3TotalWages: 0,
          finalTotalWages: finalTotalWages,
        },
        totalWages: finalTotalWages,
      };

      if (JSON.stringify(localPayroll) !== JSON.stringify(newObject)) {
        setLocalPayroll(newObject);
      }
    }
  }, [selectedRate3]);

  return (
    <CssVarsProvider disableTransitionOnChange>
      <Box sx={{ pt: 1, height: "100%" }}>
        <Card
          variant="outlined"
          orientation="horizontal"
          sx={{
            bgcolor: "neutral.softBg",
            display: "flex",
            flexDirection: { xs: "column", sm: "row" },
            "&:hover": {
              boxShadow: "lg",
              borderColor: "var(--joy-palette-neutral-outlinedDisabledBorder)",
            },
          }}
        >
          <CardOverflow
            sx={{
              mr: { xs: "var(--CardOverflow-offset)", sm: 0 },
              mb: { xs: 0, sm: "var(--CardOverflow-offset)" },
              "--AspectRatio-radius": {
                xs: "calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0",
                sm: "calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px)) 0 0 calc(var(--CardOverflow-radius) - var(--variant-borderWidth, 0px))",
              },
            }}
          >
            <AspectRatio
              ratio="1"
              flex
              sx={{
                minWidth: { sm: 80, md: 100 },
                "--AspectRatio-maxHeight": { xs: "160px", sm: "99px" },
              }}
            >
              <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                <Avatar sx={{ bgcolor: "grey", height: "60px", width: "60px" }}>
                  {firstInitial}
                  {lastInitial}
                </Avatar>
              </Box>
              {/* <img alt="" src={image} /> */}
              <Stack
                direction="row"
                sx={{
                  alignItems: "top",
                  position: "absolute",
                  top: 0,
                  width: "100%",
                  p: 1,
                }}
              ></Stack>
            </AspectRatio>
          </CardOverflow>
          <CardContent>
            <Stack
              spacing={1}
              direction="row"
              sx={{
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <div>
                <Typography
                  level="body-sm"
                  sx={{
                    color:
                      payroll?.status === "Incomplete"
                        ? "warning.400"
                        : "success.400",
                  }}
                >
                  {payroll?.status === "Incomplete"
                    ? "Pending Timecard"
                    : "Approved Timecard"}
                </Typography>

                <Typography level="title-md">
                  <Link
                    overlay
                    underline="none"
                    href="#interactive-card"
                    sx={{ color: "text.primary" }}
                  >
                    {payroll.name}
                  </Link>
                </Typography>
              </div>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  level="body-xs"
                  startDecorator={<CalendarMonthIcon />}
                >
                  Week Ending:
                  <Chip color="primary" size="md">
                    {payroll?.weekEndingString
                      ? payroll?.weekEndingString
                      : "None"}
                  </Chip>
                </Typography>
                <IconButton
                  variant="plain"
                  size="sm"
                  color={isLiked ? "danger" : "neutral"}
                  onClick={() => setIsLiked((prev) => !prev)}
                  sx={{
                    display: { xs: "none", sm: "flex" },
                    borderRadius: "50%",
                  }}
                >
                  <Root>
                    <MoreVertIcon />
                  </Root>
                </IconButton>
              </Box>
            </Stack>
            <Stack
              spacing="0.25rem 1rem"
              direction="row"
              useFlexGap
              sx={{ flexWrap: "wrap", my: 0.25 }}
            >
              <Typography
                level="body-xs"
                startDecorator={<PendingActionsIcon />}
              >
                Approved:{" "}
                {localPayroll?.approved ? (
                  <CheckIcon sx={{ color: "green" }} />
                ) : (
                  <CloseIcon sx={{ color: "red" }} />
                )}
              </Typography>
              <Typography
                level="body-xs"
                startDecorator={<PendingActionsIcon />}
              >
                <Box>
                  Status:{" "}
                  {localPayroll?.status === "Complete" ? (
                    <Chip
                      variant="soft"
                      color="success"
                      startDecorator={<WorkspacePremiumRoundedIcon />}
                      size="sm"
                    >
                      Complete
                    </Chip>
                  ) : (
                    // <Chip label="Active" color="primary" size="small" />
                    // <Chip label="Incomplete" color="warning" size="small" />
                    <Chip color="danger" size="sm">
                      Incomplete
                    </Chip>
                  )}
                </Box>
              </Typography>
              <Typography
                level="body-xs"
                startDecorator={<PendingActionsIcon />}
              >
                <Box>
                  Paid:{" "}
                  {localPayroll?.paid === true ? (
                    <Chip
                      variant="soft"
                      color="success"
                      startDecorator={<WorkspacePremiumRoundedIcon />}
                      size="sm"
                    >
                      True
                    </Chip>
                  ) : (
                    // <Chip label="Active" color="primary" size="small" />
                    // <Chip label="Incomplete" color="warning" size="small" />
                    <Chip color="danger" size="sm">
                      False
                    </Chip>
                  )}
                </Box>
              </Typography>
              <Typography level="body-xs" startDecorator={<GroupIcon />}>
                <Box>
                  Project:{" "}
                  {localPayroll?.paid === true ? (
                    <Chip
                      variant="soft"
                      color="success"
                      startDecorator={<WorkspacePremiumRoundedIcon />}
                      size="sm"
                    >
                      True
                    </Chip>
                  ) : (
                    // <Chip label="Active" color="primary" size="small" />
                    // <Chip label="Incomplete" color="warning" size="small" />
                    <Chip color="danger" size="sm">
                      False
                    </Chip>
                  )}
                </Box>
              </Typography>
            </Stack>
            <Stack
              spacing="0.25rem 1rem"
              direction="row"
              useFlexGap
              sx={{ flexWrap: "wrap", my: 0.25 }}
            >
              <Typography level="body-xs" startDecorator={<WorkHistoryIcon />}>
                Trade: {payroll.role}
              </Typography>
              <Typography level="body-xs">
                Classification: {payroll.classification}
              </Typography>
            </Stack>
            <Modal
              open={openBook}
              onClose={handleCloseBook}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <AddBookFormMui
                  listData={listData}
                  setLocalPayrollRates={setLocalPayrollRates}
                  personnelId={localPayroll.personnelId}
                />
              </Box>
            </Modal>

            <SearchRateBookModal
              personnelId={localPayroll?.personnelId}
              open={openFiltersModal}
              handleClose={handleCloseFiltersModal}
              setSelectedRate={setSelectedRate}
              setMyRates={setMyRates}
              setLocalPayrollRates={setLocalPayrollRates}
            />
            <ProjectEffectiveRates
              name={localPayroll.fullName}
              label={"Project 1 : " + payroll?.project1}
              selectedRate={selectedRate}
              regHours={localPayroll?.calculations.p1RegHours} //passed in through localPayroll object
              overtimeHours={localPayroll?.calculations.p1OTHours} //passed in through localPayroll object
              satHours={localPayroll?.calculations.p1SatHours} //passed in through localPayroll object
              sunHours={localPayroll?.calculations.p1SunHours} //passed in through localPayroll object
              ratebook={ratebook}
              handleRateChange={handleRateChange1}
              handleOpenFiltersModal={handleOpenFiltersModal}
              handleOpenBook={handleOpenBook}
              setRatebook={setRatebook}
              options={localPayrollRates} //
              setMyRates={setMyRates}
            />
            {localPayroll && localPayroll.calculations.p2Hours > 0 ? (
              <ProjectEffectiveRates
                name={localPayroll.fullName}
                label={"Project 2 : " + payroll?.project2}
                selectedRate={selectedRate2}
                regHours={localPayroll?.calculations.p2RegHours} //passed in through localPayroll object
                overtimeHours={localPayroll?.calculations.p2OTHours} //passed in through localPayroll object
                satHours={localPayroll?.calculations.p2SatHours} //passed in through localPayroll object
                sunHours={localPayroll?.calculations.p2SunHours} //passed in through localPayroll object
                ratebook={ratebook}
                handleRateChange={handleRateChange2}
                handleOpenFiltersModal={handleOpenFiltersModal}
                handleOpenBook={handleOpenBook}
                setRatebook={setRatebook}
                options={localPayrollRates} //
                setMyRates={setMyRates}
              />
            ) : null}
            {localPayroll && localPayroll.calculations.p3Hours > 0 ? (
              <ProjectEffectiveRates
                name={localPayroll.fullName}
                label={"Project 3 : " + payroll?.project3}
                selectedRate={selectedRate3}
                regHours={localPayroll?.calculations.p3RegHours} //passed in through localPayroll object
                overtimeHours={localPayroll?.calculations.p3OTHours} //passed in through localPayroll object
                satHours={localPayroll?.calculations.p3SatHours} //passed in through localPayroll object
                sunHours={localPayroll?.calculations.p3SunHours} //passed in through localPayroll object
                ratebook={ratebook}
                handleRateChange={handleRateChange3}
                handleOpenFiltersModal={handleOpenFiltersModal}
                handleOpenBook={handleOpenBook}
                setRatebook={setRatebook}
                options={localPayrollRates} //
                setMyRates={setMyRates}
              />
            ) : null}
            <Typography
              level="title-lg"
              sx={{ flexGrow: 1, textAlign: "right" }}
            >
              <strong>
                $
                {localPayroll?.totalWages
                  ? (localPayroll?.totalWages).toFixed(2)
                  : 0}
              </strong>{" "}
              <Typography level="body-md">total</Typography>
            </Typography>
            <Stack direction="row" spacing={1} sx={{ mt: "auto" }}>
              {user?.role === "Company Admin" ? (
                <Button
                  size="sm"
                  onClick={() => {
                    console.log("approve timecard");
                  }}
                >
                  Approve
                </Button>
              ) : null}

              <Button
                onClick={() => {
                  console.log("delete timecard");
                }}
                size="sm"
              >
                Delete
              </Button>
              <Button
                onClick={() => {
                  handleUpdate(
                    localPayroll,
                    selectedRate,
                    selectedRate2,
                    selectedRate3
                  );
                  setCompletedIds((prevIds) => [...prevIds, localPayroll.id]);
                  //send to next step
                  //handleNext();
                }}
                size="sm"
              >
                Mark Completed
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </CssVarsProvider>
  );
};

export default SetRatesTimecard2;

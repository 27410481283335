import { Card, Modal, useTheme } from "@mui/material";
import SignUpForm from "./SignUpForm";
import { tokens } from "../theme";

const SignUpModal = ({ open, onCancel, email, lookupKey }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const style = {
    position: "absolute",
    top: "100px",
    left: "50%",
    transform: "translateX(-50%)",
    width: 350,
    boxShadow: 24,
    backgroundColor: colors.primary[600],
    p: 2,
  };

  const handleCancel = () => {
    onCancel();
  };

  return (
    <Modal open={open} onClose={handleCancel}>
      <Card
        raised
        id="checkout"
        sx={{ ...style, flexDirection: "col", justifyContent: "space-between" }}
      >
        <SignUpForm email={email} lookupKey={lookupKey} />
      </Card>
    </Modal>
  );
};

export default SignUpModal;

import * as React from "react";
import { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import AppTheme from "../shared-theme/AppTheme";
import AppAppBar from "../components/Marketing/AppAppBar";
import Hero from "../components/Marketing/Hero";
import LogoCollection from "../components/Marketing/LogoCollection";
import Highlights from "../components/Marketing/Highlights";
import Pricing from "../components/Marketing/Pricing";
import Features from "../components/Marketing/Features";
import Testimonials from "../components/Marketing/Testimonials";
import FAQ from "../components/Marketing/FAQ";
import Footer from "../components/Marketing/Footer";
import LandingPageNavBar from "../components/LandingPageNavBar";
import LandingPageFooter from "../components/LandingPageFooter";
import SignUpModal from "../components/SignUpModal";
import CookieBanner from "../components/CookieBanner";

export default function Features2(props) {
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [email, setEmail] = useState("");
  const [lookupKey, setLookupKey] = useState("small_monthly");
  const handleTrialClick = (lookupKey) => {
    // navigate('/start-free-trial');
    console.log("handleTrialClick", lookupKey);
    setLookupKey(lookupKey);
    setShowSignUpModal(true);
  };
  const handleCancelSignUp = () => {
    setShowSignUpModal(false);
  };

  return (
    <AppTheme {...props}>
      {/* <CssBaseline enableColorScheme /> */}
      <LandingPageNavBar />
      {/* <AppAppBar /> */}
      <Hero
        handleTrialClick={handleTrialClick}
        email={email}
        setEmail={setEmail}
      />
      <CookieBanner />
      <div>
        <SignUpModal
          open={showSignUpModal}
          onCancel={handleCancelSignUp}
          email={email}
          setEmail={setEmail}
          lookupKey={lookupKey}
        />
        <LogoCollection />
        <Features />
        <Divider />
        <Testimonials />
        <Divider />
        <Highlights />
        <Divider />
        <Pricing
          handleTrialClick={handleTrialClick}
          setLookupKey={setLookupKey}
        />
        <Divider />
        <FAQ />
        <Divider />
        <Footer />
        <LandingPageFooter />
      </div>
    </AppTheme>
  );
}

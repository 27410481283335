import { Box, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "../components/Header";
import AddProjectForm from "../components/AddProjectForm";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState, useEffect } from "react";
import Modal from "@mui/material/Modal";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";
const Projects = ({
  selectedDataID,
  setselectedDataID,
  allProjects,
  setAllProjects,
  deleteManyProjects,
  pageState,
  setPageState,
}) => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  //modal
  const [openBook, setOpenBook] = useState(false);
  const handleOpenBook = () => setOpenBook(true);
  const handleCloseBook = () => setOpenBook(false);

  //Modal Stuff
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 390,
    backgroundColor: theme.palette.neutral[300],
    border: "1px solid #000",
    borderRadius: 1,
    boxShadow: 24,
    // p: 4,
    overflowY: "auto",
    p: 1,
  };

  const columns = [
    {
      field: "project",
      headerName: "Project",
      flex: 1,
    },
    {
      field: "city",
      headerName: "City",
    },
    {
      field: "county",
      headerName: "county",
      flex: 1,
    },
    {
      field: "state",
      headerName: "state",
      flex: 1,
    },
    {
      field: "biddate",
      headerName: "Bid Date",
      flex: 1,
    },
    {
      field: "book",
      headerName: "Book",
      flex: 1,
    },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
    },
    {
      field: "projectcode",
      headerName: "Code",
      width: 25,
    },
  ];

  const hoverStyles = [
    {
      "&:hover": {
        color: "red",
        backgroundColor: theme.palette.primary[400],
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer
        sx={{ backgroundColor: theme.palette.neutral[400] }}
      >
        <GridToolbarColumnsButton sx={hoverStyles} />
        <GridToolbarFilterButton sx={hoverStyles} />
        <GridToolbarDensitySelector sx={hoverStyles} />
        <GridToolbarExport sx={hoverStyles} />
        <Button
          sx={hoverStyles}
          startIcon={<PostAddIcon />}
          onClick={handleOpenBook}
        >
          Add Project
        </Button>
        <Button
          sx={hoverStyles}
          startIcon={<DeleteForeverIcon />}
          onClick={deleteManyProjects}
        >
          Delete
        </Button>
        <Modal
          open={openBook}
          onClose={handleCloseBook}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="center">
              <AddProjectForm
                handleCloseBook={handleCloseBook}
                setAllProjects={setAllProjects}
              />
            </div>
          </Box>
        </Modal>
      </GridToolbarContainer>
    );
  }

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      allProjects.find((row) => row.id === id)
    );
  };

  return (
    <Box m="10px 20px 20px 20px">
      <Header title="Project" subtitle="Managing the Project Members" />
      <Box
        width="100%"
        height="500px"
        sx={{
          borderRadius: "10px", // ✅ Ensure container matches DataGrid
          overflow: "hidden", // ✅ Prevents corners from being cut off

          // ✅ Soft Drop Shadow
          boxShadow:
            theme.palette.mode === "dark"
              ? "0px 4px 15px rgba(0, 0, 0, 0.2)" // Dark mode shadow
              : "0px 4px 15px rgba(202, 188, 188, 0.4)", // Light mode shadow

          // ✅ DataGrid Styling
          "& .MuiDataGrid-root": {
            border: "1px solid",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[100] // ✅ Dark grid background
                : theme.palette.neutral[50], // ✅ Dark mode CELL background
          },

          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${theme.palette.neutral[500]} !important`,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[700] // ✅ Light mode white text
                : theme.palette.neutral[500], // ✅ Ensures white text
          },

          "& .MuiDataGrid-columnHeaders": {
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400] //DARK MODE HEADERS
                : theme.palette.primary[200],
            borderBottom: "none",
            fontWeight: "bold",
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400] //DARK MODE FOOTERS
                : theme.palette.primary[200],
            borderBottomLeftRadius: "10px", // ✅ Ensure footer matches border
            borderBottomRightRadius: "10px",
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "white !important",
          },

          "& .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },

          "& .MuiCheckbox-root": {
            color:
              theme.palette.mode === "dark"
                ? theme.palette.primary[300]
                : theme.palette.primary[500], // ✅ White checkbox in light mode
          },
        }}
      >
        <DataGrid
          rows={allProjects}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            onRowsSelectionHandler(ids);
            setselectedDataID(ids);
          }}
          sx={{
            borderRadius: "10px",
            overflow: "hidden", // ✅ Prevents border clipping
            "& .MuiDataGrid-root": {
              backgroundColor: "transparent", // ✅ Keeps transparency within Box bg
            },

            "& .MuiDataGrid-columnHeaders": {
              color: "white", // ✅ Column headers are white
            },

            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: "white !important",
            },
            "& .super-app-theme--approved": {
              backgroundColor: "rgba(76, 175, 80, 0.3)", // ✅ DARK MODE ROW STYLING
              color: "#1B5R20 !important", // ✅ Dark Green text
            },
            "& .super-app-theme--notApproved": {
              backgroundColor: "rgba(76, 175, 80, 0.3)", // ✅ DARK MODE ROW STYLING
              color: "#1B5E20 !important", // ✅ Dark Green text
            },
          }}
          disableSelectionOnClick
          rowCount={pageState?.total}
          loading={pageState.isLoading}
          pagination
          page={pageState.page}
          pageSize={pageState.pageSize}
          pageSizeOptions={[50, 100]}
          onPageChange={(newPage) =>
            setPageState((old) => ({ ...old, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          paginationMode="server"
        />
        <p> {selectedDataID} </p>
      </Box>
    </Box>
  );
};

export default Projects;

import { useState, Fragment, useEffect, useCallback } from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import ChooseTimeCardsForPayroll from "./../components/RunPayroll/ChooseTimeCardsForPayroll";
import SetRatesTimecard2 from "./../components/RunPayroll/SetRatesTimecard2";
import SetTaxSettings from "../components/RunPayroll/SetTaxSettings";
import { useAuth } from "../context/AuthProvider";
import ReviewForPayroll2 from "../components/RunPayroll/ReviewForPayroll2";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";
const steps = [
  "Choose Timecards For Payroll",
  "Select Rate Types",
  "Edit Tax Settings",
  "Approve",
];

const RunPayrollPage = () => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const [activeStep, setActiveStep] = useState(0);
  const [finalStepTouched, setFinalStepTouched] = useState(false);
  const [skipped, setSkipped] = useState(new Set());
  const [statusMessage, setStatusMessage] = useState("");
  const [sentPayroll, setSentPayroll] = useState(false);
  const isStepOptional = (step) => {
    return step === 1;
  };

  const isStepSkipped = (step) => {
    return skipped.has(step);
  };
  const { api } = useAuth();
  const isoWeek = require("dayjs/plugin/isoWeek");
  dayjs.extend(isoWeek);
  const [timecards, setTimecards] = useState([]);
  const lastWeekend = dayjs().day() === 6 ? dayjs() : dayjs().day(6 - 7);
  const [searchFilters, setSearchFilters] = useState({
    approved: false,
    status: "Incomplete",
    project: "All Projects",
    weekNumberStart: dayjs().subtract(12, "week").isoWeek(),
    weekStartString: dayjs().subtract(4, "week").day(6).format("MM/DD/YYYY"),
    yearStart: dayjs().subtract(4, "week").year(),
    weekNumberEnd: lastWeekend.isoWeek(),
    weekEndString: lastWeekend.day(6).format("MM/DD/YYYY"),
    yearEnd: lastWeekend.year(),
  });
  const [filteredTimecards, setFilteredTimecards] = useState([]);
  const [completedIds, setCompletedIds] = useState([]);
  const [finalArray, setFinalArray] = useState([]);
  const [taxArray, setTaxArray] = useState([]);
  const [selectedDataID, setselectedDataID] = useState([]);
  const [selectedDataID2, setselectedDataID2] = useState([]);
  const [errMsg, seterrMsg] = useState("");
  const [ratebook, setRatebook] = useState([]); //array?
  const [myRates, setMyRates] = useState([]); //array
  const [errState, setErrState] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    totaldocuments: 0,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  //snackbar position
  const [state, setState] = useState({
    open: false,
    vertical: "top",
    horizontal: "center",
  });
  const [listData, setListData] = useState(null);
  const handleNext = () => {
    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
    if (activeStep + 1 === 3) {
      setFinalStepTouched(true);
    }
    if (activeStep + 1 === 4) {
      console.log(activeStep + 1);
      submitPayroll(finalArray);
      setSentPayroll(true);
      setStatusMessage("Payroll Sent");
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      // You probably want to guard against something like this,
      // it should never occur unless someone's actively trying to break something.
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const handleClick = (newState) => () => {
    setState({ ...newState, open: true });
  };

  const handleReset = () => {
    setActiveStep(0);
    setFinalArray([]);
  };

  useEffect(() => {
    // Check for null or undefined arrays
    if (timecards && selectedDataID) {
      const filteredArray = timecards.filter(
        (obj) => obj && obj.id !== undefined && selectedDataID.includes(obj.id)
      );
      setFilteredTimecards(filteredArray);
    } else {
      console.log("One of the arrays is null or undefined.");
    }
  }, [selectedDataID, timecards]);

  useEffect(() => {
    setIsLoading(true);
    let renderedData = [];
    if (api.authenticated) {
      api

        .get(
          `/api/v1/timecardsv3/payroll?page=1&limit=100&approved=${searchFilters.approved}&status=${searchFilters.status}&project=${searchFilters.project}&weekNumberStart=${searchFilters.weekNumberStart}&yearStart=${searchFilters.yearStart}&weekNumberEnd=${searchFilters.weekNumberEnd}&yearEnd=${searchFilters.yearEnd}`
        )
        .then((response) => {
          if (response?.data?.data[0]?.results) {
            renderedData = response?.data?.data[0].results.map((timecard) => {
              const formattedTimecard = {
                ...timecard,
                id: timecard._id,
                calculations: {
                  ...timecard.calculations,
                  p1regRate: 0, //Add new properties
                  p1OTRate: 0,
                  p1SatRate: 0,
                  p1SunRate: 0,
                  p1regWages: 0,
                  p1OTWages: 0,
                  p1SatWages: 0,
                  p1SunWages: 0,
                  p1TotalWages: 0,
                  p2regRate: 0, //Add new properties
                  p2OTRate: 0,
                  p2SatRate: 0,
                  p2SunRate: 0,
                  p2regWages: 0,
                  p2OTWages: 0,
                  p2SatWages: 0,
                  p2SunWages: 0,
                  p2TotalWages: 0,
                  p3regRate: 0,
                  p3OTRate: 0,
                  p3SatRate: 0,
                  p3SunRate: 0,
                  p3regWages: 0,
                  p3OTWages: 0,
                  p3SatWages: 0,
                  p3SunWages: 0,
                  p3TotalWages: 0,
                  healthAndWelfare: 0,
                  pension: 0,
                  vacation: 0,
                  other: 0,
                  totalDeductions: 0,
                  finalTotalWages: 0,
                },
                trainingRate: 0,
                deductions: 0,
                totalWages: 0,
                companyName: "Testing ", //Add new properties
                FEIN: "45335", //Add new properties
                street: "123 Fake", //Add new properties
                city: "SF", //Add new properties
                state: "CA", //Add new properties
                zip: 94564, //Add new properties
                email: "c@yahoo.com", //Add new properties
                personnelId: timecard.personnelId,
                rates: timecard.rates.map((rate) => ({
                  ...rate,
                  id: rate._id,
                })), //make sure id defined
              };
              console.log("formatted timecards", formattedTimecard);
              return formattedTimecard;
            });

            setPagination((prev) => ({
              ...prev,
              page: response.data.data[0].page,
              limit: response.data.data[0].limit,
              totaldocuments: response.data.data[0].totaldocuments,
            }));
          }
        })
        .catch((err) => {
          console.log(err);
          setTimecards([]);
          setPagination((prev) => ({
            ...prev,
            page: 1,
            totaldocuments: 0,
          }));
        })
        .finally(() => {
          setIsLoading(false);
          setTimecards(renderedData);
          if (renderedData.length === 0) {
            setError(true);
            seterrMsg("No Timecards Found");
            setPagination((prev) => ({
              ...prev,
              page: 1,
              totaldocuments: 0,
            }));
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, searchFilters]);

  const handleChildData = (
    payroll,
    selectedRate,
    selectedRate2,
    selectedRate3
  ) => {
    console.log("payroll", payroll);
    console.log("clicked");
    setselectedDataID((prevData) => {
      const updatedData = [...prevData];
      const index = updatedData.findIndex(
        (item) => item.timecardID === payroll.timecardID
      );

      if (index > -1) {
        updatedData[index] = {
          ...payroll,
          selectedRate,
          selectedRate2,
          selectedRate3,
        };
        setFinalArray((prevFinalArray) => [
          ...prevFinalArray,
          { id: payroll._id, ...updatedData[index] },
        ]);
      } else {
        updatedData.push({
          ...payroll,
          selectedRate,
          selectedRate2,
          selectedRate3,
        });
        setFinalArray((prevFinalArray) => [
          ...prevFinalArray,
          {
            id: updatedData[updatedData.length - 1]._id,
            ...updatedData[updatedData.length - 1],
          },
        ]);
      }

      return updatedData;
    });
  };

  const handleTaxUpdate = (payroll) => {
    console.log("payroll", payroll);
    console.log("clicked");
    setselectedDataID2((prevData) => {
      const updatedData = [...prevData];
      const index = updatedData.findIndex(
        (item) => item.timecardID === payroll.timecardID
      );

      if (index > -1) {
        updatedData[index] = {
          ...payroll,
        };
        setTaxArray((prevFinalArray) => [
          ...prevFinalArray,
          { id: payroll._id, ...updatedData[index] },
        ]);
      } else {
        updatedData.push({
          ...payroll,
        });
        setTaxArray((prevFinalArray) => [
          ...prevFinalArray,
          {
            id: updatedData[updatedData.length - 1]._id,
            ...updatedData[updatedData.length - 1],
          },
        ]);
      }

      return updatedData;
    });
  };

  // const handleTaxData = (taxData) => {
  //   console.log("taxData", taxData);
  //   setTaxArray((prevData) => {
  //     const updatedData = [...taxData];
  //     return updatedData;
  //   });
  // };

  // Mapping Create Stuff
  const submitPayroll = async (array) => {
    await api
      .post("/api/v1/payroll", JSON.stringify(array))
      .then((response) => {
        // const updatedPayroll = [...allTimecards, response.data.data];
        // setBookDetails(updatedPayroll);
        console.log("Payroll Created!");
        console.log(array);
        console.log(response);
        setStatusMessage("RateBook Created!");
      })
      .catch((error) => {
        console.log(error);
        setStatusMessage("An Error Occurred!");
        setErrState(true);
      });
  };

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response?.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    setIsLoading(true);
    if (api.authenticated) {
      Promise.all([
        api.get("/api/v1/personnel?sort=fullName&limit=1000"),
        api.get("/api/v1/projects/list"),
        api.get("/api/v1/ratebook/craftlist"),
        api.get("/api/v1/ratebook/classificationlist"),
        api.get("/api/v1/ratebook/booklist"),
      ])
        .then(
          checkFailed(([personnel, project, craft, classification, book]) => {
            setListData((prev) => ({
              ...prev,
              personnel,
              project,
              craft,
              classification,
              book,
            }));
          })
        )
        .catch((err) => {
          console.log("FAIL", err);
          setError(true);
          seterrMsg("Error Loading Data");
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  useEffect(() => {
    if (api.authenticated) {
      api
        .get(`/api/v1/ratebook/company?page=1&limit=500`)
        .then((response) => {
          console.log("ratebook", response.data.data[0].results);
          setRatebook(response.data.data[0].results);

          setError(false);
          seterrMsg("");
        })
        .catch((err) => {
          console.log("FAILED", { err });
          setError(true);
          seterrMsg("Error Loading Data");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  console.log(selectedDataID2);
  console.log(activeStep);
  return (
    <div>
      <Box
        sx={{
          alignItems: "center",
          p: 1,
        }}
      >
        <Box
          sx={{
            margin: "auto",
            width: "70%",
            height: "100%", // ✅ critical to fix PDF rendering

            flexDirection: "column",
          }}
        >
          <Stepper
            activeStep={activeStep}
            sx={{
              "& .MuiStep-root": {
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral[300]
                    : theme.palette.neutral[700],
              },

              // ✅ Connector (Line between steps)
              "& .MuiStepConnector-line": {
                borderColor:
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral[200] //LIGHT MODE
                    : theme.palette.neutral[500],
                borderWidth: "2px",
              },

              // ✅ Active step style
              "& .MuiStepLabel-label.Mui-active": {
                color: theme.palette.primary.main,
                fontWeight: "bold",
              },

              // ✅ Completed step style
              "& .MuiStepLabel-label.Mui-completed": {
                color: theme.palette.success.main,
                fontWeight: "bold",
              },

              // ✅ Inactive steps
              "& .MuiStepLabel-label": {
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral[400]
                    : theme.palette.neutral[600],
                transition: "color 0.3s ease-in-out",
              },

              // ✅ Step Icons
              "& .MuiStepIcon-root": {
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral[300]
                    : theme.palette.neutral[300],
                fontSize: "1.5rem", // ✅ Bigger step icon
                backgroundColor: "transparent", // ✅ Ensure no background color
                borderRadius: "50%", // ✅ Makes the icon perfectly round
              },

              // ✅ Active Step Icon
              "& .MuiStepIcon-root.Mui-active": {
                color: theme.palette.primary[300],
                boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)", // ✅ Adds a modern glow effect
              },

              // ✅ Completed Step Icon
              "& .MuiStepIcon-root.Mui-completed": {
                color: theme.palette.success[400],
              },
            }}
          >
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepOptional(index)) {
                labelProps.optional = (
                  <Typography
                    variant="caption"
                    sx={{ color: theme.palette.info.main, fontSize: "0.75rem" }}
                  >
                    Optional
                  </Typography>
                );
              }
              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }

              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>

          {activeStep === 0 && (
            <Box>
              <ChooseTimeCardsForPayroll
                timecards={timecards}
                isLoading={isLoading}
                selectedDataID={selectedDataID} //one source of truth data
                setselectedDataID={setselectedDataID}
                searchFilters={searchFilters}
                setSearchFilters={setSearchFilters}
              />
            </Box>
          )}
          {activeStep === 1 ? (
            <Box>
              {
                <Typography>
                  {completedIds?.length ? completedIds.length : 0} /{" "}
                  {selectedDataID?.length ? selectedDataID.length : 0}
                </Typography>
              }
              {selectedDataID?.length > 0 &&
                selectedDataID.map((payroll, index) => (
                  <SetRatesTimecard2
                    key={index}
                    onUpdate={handleChildData}
                    payroll={payroll}
                    listData={listData}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    error={error}
                    setError={setError}
                    errMsg={errMsg}
                    seterrMsg={seterrMsg}
                    ratebook={ratebook}
                    setRatebook={setRatebook}
                    myRates={myRates}
                    setMyRates={setMyRates}
                    setCompletedIds={setCompletedIds} //updated count
                  />
                ))}
            </Box>
          ) : null}
          {activeStep === 2 ? (
            <Box>
              {finalArray?.length > 0 && (
                <SetTaxSettings
                  payrollData={finalArray}
                  selectedDataID2={selectedDataID2}
                  setselectedDataID2={setselectedDataID2}
                />
              )}
            </Box>
          ) : null}
          {typeof window !== "undefined" && activeStep === 3 ? (
            <Box>
              <ReviewForPayroll2 payrollData={selectedDataID2 || []} />
            </Box>
          ) : null}

          {activeStep === steps.length ? (
            <Fragment>
              {sentPayroll && !errState ? (
                <Box>
                  <Alert
                    icon={<CheckIcon fontSize="inherit" />}
                    severity="success"
                  >
                    Congratulations! You have sent in the payroll.
                  </Alert>
                  <Typography sx={{ mt: 2, mb: 1 }}>
                    All steps completed - you&apos;re finished
                  </Typography>
                </Box>
              ) : (
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="warning"
                >
                  An Error Occurred. Please resubmit.
                </Alert>
              )}

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  pt: 2,
                  overflow: "auto",
                }}
              >
                <Box sx={{ flex: "1 1 auto" }} />
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </Fragment>
          ) : (
            <Fragment>
              <Typography>Step {activeStep + 1}</Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  pt: 2,
                  overflow: "auto",
                }}
              >
                <Button
                  color="inherit"
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: "1 1 auto" }} />
                {isStepOptional(activeStep) && (
                  <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                    Skip
                  </Button>
                )}
                <Button onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Submit" : "Next"}
                </Button>
              </Box>
            </Fragment>
          )}
        </Box>
      </Box>
    </div>
  );
};

export default RunPayrollPage;

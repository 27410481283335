//payroll create modal
import { Box } from "@mui/material";
import React, { useState, useEffect } from "react";
import Autocomplete from "@mui/joy/Autocomplete";
import Stack from "@mui/joy/Stack";
import Modal from "@mui/joy/Modal";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import { useMediaQuery } from "@mui/material";
import Typography from "@mui/joy/Typography";
import Alert from "@mui/joy/Alert";
import List from "@mui/joy/List";
import Input from "@mui/joy/Input";
import useDebounce from "../hooks/useDebounce";
import { useAuth } from "../context/AuthProvider";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import { styled } from "@mui/joy/styles";
import { IconButton } from "@mui/joy";
import StarRateIcon from "@mui/icons-material/StarRate";
import Directory from "./Directory";

const SearchRateBookModal = ({
  open,
  handleClose,
  personnelId,
  setSelectedRate,
  setLocalPayrollRates,
  setMyRates,
}) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [errMsg, setErrMsg] = React.useState("");
  const { api } = useAuth();
  const [query, setQuery] = useState("");
  const [options, setOptions] = useState([]);
  const debouncedQuery = useDebounce(query, 500);

  const updateMyRates = async (id) => {
    setErrMsg(null);
    const data = { personnelId, rates: [id] };
    console.log(data);
    try {
      const response = await api.put("/api/v1/personnel/updaterates", data);
      const newData = response.data.data;
      setLocalPayrollRates((prevRates) => [...prevRates, newData]);
    } catch (error) {
      console.log("Error saving rates:", error);
      setErrMsg("Error saving rates");
      throw error; // Rethrow error for handling in `handleStarClick`
    }
  };

  const CustomIconButton = styled(IconButton)({
    //Default styles
    transition: "all 0.2s ease-in-out",
    "&:hover": {
      backgroundColor: "rgba(0, 0, 255, 0.1)", //light blue hover
      transform: "scale(1.05)", //slight zoom
    },
  });

  useEffect(() => {
    if (debouncedQuery) {
      console.log("Searching for:", debouncedQuery);
      // Perform API call or filtering

      if (api.authenticated) {
        api
          .get(`/api/v1/ratebook/search?name=${debouncedQuery}`)
          .then((response) => {
            let renderedOptions;
            renderedOptions = response.data.map((timecard, i) => ({
              ...timecard,
              liked: false,
            }));
            setOptions(renderedOptions);
            console.log(renderedOptions);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            console.log("done");
          });
      }
    }
  }, [debouncedQuery]);

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: isMobile ? "390px" : "450px",
    width: "100%",
    height: "500px",
    bgcolor: "background.body",
    border: "2px solid #000",
    borderRadius: "10px",
    boxShadow: 24,
    p: 4,
    overflow: "auto",
    zIndex: 1000000,
  };

  const handleStarClick = async (item) => {
    console.log(item);

    // Optimistically update UI
    setOptions((prevOptions) => {
      const index = prevOptions.findIndex((option) => option._id === item._id);

      if (index !== -1) {
        // Item exists, toggle liked
        return prevOptions.map((option) =>
          option._id === item._id ? { ...option, liked: !option.liked } : option
        );
      } else {
        // Item doesn't exist, add it with liked = true
        return [...prevOptions, { ...item, liked: true }];
      }
    });

    try {
      await updateMyRates(item._id);

      // Fetch updated data from API to ensure state is in sync
      const response = await api.get(
        `/api/v1/personnel/getrates/${personnelId}`
      );
      setMyRates(response.data);
    } catch (error) {
      console.error("Failed to update rates:", error);

      // Revert UI change on error
      setOptions((prevOptions) => {
        const exists = prevOptions.some((option) => option._id === item._id);
        if (exists) {
          return prevOptions.map((option) =>
            option._id === item._id ? { ...option, liked: item.liked } : option
          );
        } else {
          // Remove the item if it was added and failed
          return prevOptions.filter((option) => option._id !== item._id);
        }
      });
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Box sx={modalStyle}>
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          justifyContent={"space-between"}
        >
          <FormControl sx={{ width: "100%", textAlign: "center" }}>
            <Typography> Search For Rates</Typography>
            <Autocomplete
              id="size-small-outlined"
              size="sm"
              options={options}
              //defaultValue={ratebook[13]}
              onChange={(event, newValue) => {
                //handleRateChange(newValue);
                setQuery(newValue ? newValue.name || newValue : "");
              }}
              freeSolo // Allows users to input custom values even if not in options
              isOptionEqualToValue={(option, value) => option.name === value} // Fix invalid value warning
              noOptionsText="No results found" // Custom message when no options match
              value={query} //ensure string
              onInputChange={(event, newInputValue) => {
                // add this prop
                setQuery(newInputValue || ""); // update query as user types, ensure string
              }}
              placeholder="Search Over 20,000 Rates"
              renderInput={(params) => (
                <Input
                  {...params}
                  sx={{
                    backgroundColor: "white",
                  }}
                />
              )}
              getOptionLabel={(option) =>
                typeof option === "string" ? option : option.name || ""
              } // Ensure it always returns a string
              renderOption={(props, option) => (
                <List
                  component="li"
                  {...props}
                  sx={{
                    fontSize: "12px", // Adjust dropdown text size
                    padding: "4px 6px",
                  }}
                >
                  <Typography level="body-xs">{option.name}</Typography>
                </List>
              )}
              slotProps={{
                root: {
                  sx: {
                    fontSize: "12px", // Ensures the font size of the selected value
                  },
                },
              }}
            />
          </FormControl>
        </Stack>
        <Box sx={{ width: "100%" }}>
          <Stack direction="column" justifyContent={"space-between"}>
            <Stack direction="row" justifyContent={"space-between"}>
              <Typography level="body-xs" sx={{ color: "grey" }} align="center">
                Click Star To Save Rates
              </Typography>
              <Typography level="body-xs" sx={{ color: "grey" }} align="center">
                Results: {options.length}
              </Typography>
            </Stack>
            {options?.map((item, index) => (
              <Box
                key={index} // Ensure each item has a unique key
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: "5px",
                  border: "1px solid #000",
                  borderRadius: "5px",
                  marginBottom: "10px",
                }}
              >
                <CustomIconButton onClick={() => handleStarClick(item)}>
                  {item.liked ? (
                    <StarRateIcon
                      sx={{
                        color: "rgb(255, 191, 0)",
                      }}
                    />
                  ) : (
                    <StarOutlineIcon />
                  )}
                </CustomIconButton>
                <Stack
                  direction="row"
                  onClick={() => {
                    setSelectedRate(item);
                    handleClose();
                  }}
                  sx={{ top: "50%" }}
                >
                  {/* Ensure space is maintained even if the value is empty */}
                  <Typography level="body-xs" sx={{ minWidth: "100px" }}>
                    {item.book || "\u00A0"}
                  </Typography>

                  <Typography level="body-xs" sx={{ minWidth: "100px" }}>
                    {item.craft || "\u00A0"}
                  </Typography>

                  <Typography
                    level="body-xs"
                    sx={{ minWidth: "200px", overflow: "hidden" }}
                  >
                    {item.classification || "\u00A0"}
                  </Typography>
                </Stack>
              </Box>
            ))}
          </Stack>
          <Directory />
        </Box>

        {errMsg ? <Alert color="danger">{errMsg}</Alert> : null}
      </Box>
    </Modal>
  );
};

export default SearchRateBookModal;

import { Box, Grid, Chip } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import Row1 from "../dashboard3/Row1";
import Row2 from "../dashboard3/Row2";
import Row3 from "../dashboard3/Row3";
import dashboardConfig from "../fakedata/dashboardConfig";
import useFetch from "../hooks/fetchAPI";
import threeWeekLookBack from "../hooks/three-week-lookback";
import filteredBarGraphData from "../hooks/fx-create-bar-graph-array";
import { useEffect, useState } from "react";
import LoadingCircle from "../components/LoadingCircle";
import dayjs from "dayjs";
import { useAuth } from "../context/AuthProvider";
import TopBarDashboardSettings from "../components/TopBarDashboardSettings";
import { useTheme } from "@mui/material";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

var weekOfYear = require("dayjs/plugin/weekOfYear");
dayjs.extend(weekOfYear);

const Dashboard3 = () => {
  const {
    gridTemplateLargeScreens,
    gridTemplateMediumScreens,
    gridTemplateSmallScreens,
    gridTemplateAdminMediumScreens,
    gridTemplateAdminSmallScreens,
  } = dashboardConfig();

  const { api, user } = useAuth();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "lg"));
  const isAdmin = user.role === "Company Admin" ? true : false;

  const gridStyles = isLargeScreen
    ? {
        gridTemplateColumns: "repeat(3, minmax(330px, 1fr))",
        gridTemplateRows: "repeat(10, minmax(30px, 1fr))",
        gridTemplateAreas: gridTemplateLargeScreens,
        padding: "1rem",
      }
    : isMediumScreen
    ? {
        gridTemplateColumns: "repeat(2, minmax(330px, 1fr))",
        gridTemplateRows: "repeat(15, minmax(30px, 1fr))",
        gridTemplateAreas: isAdmin
          ? gridTemplateAdminMediumScreens
          : gridTemplateMediumScreens,
        padding: "2rem",
      }
    : {
        gridAutoColumns: "1fr",
        gridAutoRows: "55px",
        gridTemplateAreas: isAdmin
          ? gridTemplateAdminSmallScreens
          : gridTemplateSmallScreens,

        width: "90%", // ✅ Apply 90% width on small screens
        margin: "0 auto", // Optional: center the grid horizontally
      };

  const PrevWeekNumber = dayjs().day(-1).week();
  const [listData, setListData] = useState([]);
  const [changeOrderData, setChangeOrderData] = useState([]);
  const [rfiData, setRfiData] = useState([]);
  const [swData, setSwData] = useState([]);
  const [payrollData, setPayrollData] = useState([]);
  const [quarterlyData, setQuarterlyData] = useState([]);
  const isoWeek = require("dayjs/plugin/isoWeek");
  dayjs.extend(isoWeek);
  const lastWeekend = dayjs().day() === 6 ? dayjs() : dayjs().day(6 - 7);
  const [searchFilters, setSearchFilters] = useState({
    approved: false,
    status: "Incomplete",
    project: "All Projects",
    weekNumberStart: dayjs().subtract(4, "week").isoWeek(),
    weekStartString: dayjs().subtract(4, "week").day(6).format("MM/DD/YYYY"),
    yearStart: dayjs().subtract(4, "week").year(),
    weekNumberEnd: lastWeekend.isoWeek(),
    weekEndString: lastWeekend.day(6).format("MM/DD/YYYY"),
    yearEnd: lastWeekend.year(),
  });

  const [payrollLoaded, setPayrollLoaded] = useState(false);
  const [loadingOne, setLoadingOne] = useState(true);
  const [loadingTwo, setLoadingTwo] = useState(true);
  const [loadingThree, setLoadingThree] = useState(true);
  const [loadingFour, setLoadingFour] = useState(true);
  const [loadingFive, setLoadingFive] = useState(true);
  const [loadingSix, setLoadingSix] = useState(true);
  const [loadingSeven, setLoadingSeven] = useState(true);

  const [progress, setProgress] = useState(0);

  // Unified flag (optional)
  const isLoaded =
    !loadingOne &&
    !loadingTwo &&
    !loadingThree &&
    !loadingFour &&
    !loadingFive &&
    !loadingSix &&
    !loadingSeven;

  // Optional: Update progress bar based on how many are done
  useEffect(() => {
    const doneCount = [
      loadingOne,
      loadingTwo,
      loadingThree,
      loadingFour,
      loadingFive,
      loadingSix,
      loadingSeven,
    ].filter((l) => !l).length;
    const total = 3;
    const newProgress = (doneCount / total) * 100;
    setProgress(newProgress);
  }, [
    loadingOne,
    loadingTwo,
    loadingThree,
    loadingFour,
    loadingFive,
    loadingSix,
    loadingSeven,
  ]);

  const [searchPayrollFilters, setSearchPayrollFilters] = useState({
    paid: true,
    weekNumberStart: null,
    yearStart: null,
    weekNumberEnd: null,
    yearEnd: null,
  });
  const [monthlySearchFilters, setMonthlySearchFilters] = useState({
    startYear: dayjs().subtract(1, "year").year(),
    startMonth: dayjs().subtract(11, "month").month() + 1,
    endYear: dayjs().year(),
    endMonth: dayjs().month() + 1,
  });
  const [professionalSearchFilters, setProfessionalSearchFilters] = useState({
    project: "All Projects",
    role: "All Trades",
    classification: "All Classes",
    approved: false,
  });
  const [timecards, setTimecards] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 20,
    totaldocuments: 0,
  });
  const [error, setError] = useState({
    timecards: false,
    quarterly: false,
    rfi: false,
    co: false,
    sw: false,
    payroll: false,
    projectlist: false,
    craftlist: false,
    classificationlist: false,
  });
  const [errMsg, seterrMsg] = useState({
    timecards: "No Data Exists",
    rfi: "No Data Exists",
    co: "No Data Exists",
    sw: "No Data Exists",
    payroll: "No Data Exists",
    quarterly: "No Data Exists",
  });

  const weeksObject = threeWeekLookBack();

  useEffect(() => {
    setSearchPayrollFilters({
      paid: true,
      weekNumberStart: dayjs().subtract(7, "week").week(),
      yearStart: dayjs().subtract(7, "week").year(),
      weekNumberEnd: dayjs().week(),
      yearEnd: dayjs().year(),
    });
    setPayrollLoaded(true);
  }, []);

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    setLoadingOne(true);
    async function fetchMultipleDataStreams(...prevPayroll) {
      await Promise.all([
        api.get(
          `/api/v1/rfi/?page=1&limit=20&project=${professionalSearchFilters.project}`
        ),
        api.get(
          `/api/v1/changeorder/?page=1&limit=20&project=${professionalSearchFilters.project}`
        ),
        api.get("/api/v1/projects/list"),
        api.get("/api/v1/payroll/weekly"),
        api.get("/api/v1/ratebook/craftlist"),
        api.get("/api/v1/ratebook/classificationlist"),
      ])
        .then(
          checkFailed(
            ([
              rfi,
              co,
              projectList,
              payroll,
              craftlist,
              classificationlist,
            ]) => {
              setListData((prev) => ({
                ...prev,
                rfi,
                co,
                projectList,
                payroll,
                craftlist,
                classificationlist,
              }));
            }
          )
        )

        .catch((err) => {
          console.log("FAIL", err);
        })
        .finally(setLoadingOne(false));
    }

    if (api.authenticated) {
      fetchMultipleDataStreams();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api?.authenticated]);

  useEffect(() => {
    //setIsLoading(true);
    let renderedData = [];
    setLoadingTwo(true);
    if (
      api.authenticated &&
      payrollLoaded === true &&
      searchPayrollFilters.yearStart !== NaN
    ) {
      api

        .get(
          `/api/v1/payroll/weekly?page=1&limit=20&paid=${searchPayrollFilters.paid}&project=${professionalSearchFilters.project}&trade=${professionalSearchFilters.role}&classification=${professionalSearchFilters.classification}&weekNumberStart=${searchPayrollFilters.weekNumberStart}&yearStart=${searchPayrollFilters.yearStart}&weekNumberEnd=${searchPayrollFilters.weekNumberEnd}&yearEnd=${searchPayrollFilters.yearEnd}`
        )
        .then((response) => {
          setPayrollData(response.data.data);
        })
        .catch((err) => {
          console.log(err);
          setPayrollData([]);
        })
        .finally(() => {
          //setIsLoading(false);
          // setTimecards(renderedData);
          if (renderedData.length === 0) {
            setError((prev) => ({
              ...prev,
              payroll: true,
            }));
            seterrMsg((prev) => ({
              ...prev,
              payroll: "No Payroll Data found",
            }));
            setPagination((prev) => ({
              ...prev,
              page: 1,
              totaldocuments: 0,
            }));
          }
          setLoadingTwo(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, searchPayrollFilters, payrollLoaded]);

  //const result = filteredBarGraphData(sixWkBkDesc, data);

  //useEffect for change in search filters
  useEffect(() => {
    let renderedData = [];
    setLoadingThree(true);
    if (api.authenticated) {
      api

        .get(
          `/api/v1/timecardsv3/mytimecards/?page=1&limit=20&approved=${searchFilters.approved}&status=${searchFilters.status}&project=${searchFilters.project}&weekNumberStart=${searchFilters.weekNumberStart}&yearStart=${searchFilters.yearStart}&weekNumberEnd=${searchFilters.weekNumberEnd}&yearEnd=${searchFilters.yearEnd}`
        )
        .then((response) => {
          setTimecards(response.data.data[0].results);
        })
        .catch((err) => {
          console.log(err);
          setTimecards([]);
          setPagination((prev) => ({
            ...prev,
            page: 1,
            totaldocuments: 0,
          }));
        })
        .finally(() => {
          setLoadingThree(false);

          if (renderedData.length === 0) {
            setError((prev) => ({
              ...prev,
              timecards: true,
            }));
            seterrMsg((prev) => ({
              ...prev,
              timecards: "No timecards found",
            }));
            setPagination((prev) => ({
              ...prev,
              page: 1,
              totaldocuments: 0,
            }));
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, searchFilters]);

  useEffect(() => {
    let renderedData = [];
    setLoadingFour(true);
    if (!api.authenticated) return;

    api
      .get(
        `/api/v1/timecardsv3/quarterly?year=2025&project=${professionalSearchFilters.project}`
      )
      .then((response) => {
        console.log(response);
        setQuarterlyData(response.data.data);

        // Reset error state if data exists
        if (response.data.data.length > 0) {
          setError((prev) => ({ ...prev, quarterly: false }));
          seterrMsg((prev) => ({ ...prev, quarterly: "" }));
        }
      })
      .catch((err) => {
        console.error(err);
        setQuarterlyData([]);
        setError((prev) => ({ ...prev, quarterly: true }));
        seterrMsg((prev) => ({
          ...prev,
          quarterly: "Failed to fetch timecards",
        }));
      })
      .finally(() => {
        setLoadingFour(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, professionalSearchFilters.project]);

  //useEffect for change in search filters
  useEffect(() => {
    let renderedCOData = [];
    setLoadingFive(true);
    if (api.authenticated) {
      api

        .get(
          `/api/v1/changeorder/?page=1&limit=20&project=${professionalSearchFilters.project}`
        )
        .then((response) => {
          setChangeOrderData(response.data.data[0].results);
          setError((prev) => ({
            ...prev,
            co: false,
          }));
        })
        .catch((err) => {
          console.error(err);
          setError((prev) => ({
            ...prev,
            co: true,
          }));
        })
        .finally(() => {
          setLoadingFive(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  //useEffect for change in search filters
  useEffect(() => {
    let renderedRFIData = [];
    setLoadingSix(true);
    seterrMsg((prev) => ({
      ...prev,
      rfi: "",
    }));
    if (api.authenticated) {
      api

        .get(
          `/api/v1/rfi/?page=1&limit=20&project=${professionalSearchFilters.project}`
        )
        .then((response) => {
          setRfiData(response.data.data[0].results);
          setError((prev) => ({
            ...prev,
            rfi: false,
          }));
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setLoadingSix(false);

          if (renderedRFIData.length === 0) {
            setError((prev) => ({
              ...prev,
              rfi: true,
            }));
            seterrMsg((prev) => ({
              ...prev,
              rfi: "No rfis found",
            }));
          }
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  //useEffect for change in search filters
  useEffect(() => {
    let renderedSWData = [];
    setLoadingSeven(true);
    seterrMsg((prev) => ({
      ...prev,
      sw: "",
    }));
    if (api.authenticated) {
      api
        .get(
          `/api/v1/timecardsv3/sandw/?page=1&limit=20&startYear=${monthlySearchFilters.startYear}&startMonth=${monthlySearchFilters.startMonth}&endYear=${monthlySearchFilters.endYear}&endMonth=${monthlySearchFilters.endMonth}&project=${professionalSearchFilters.project}&approved=false`
        )
        .then((response) => {
          renderedSWData = response.data.data.map((sw) => ({
            id: `${sw._id.month}/${sw._id.year}`,
            month: sw._id.month,
            year: sw._id.year,
            key: `${sw._id.month}/${sw._id.year}`,
            trade: sw._id.trade,
            reg: sw.regHours,
            ot: sw.overtimeHours,
            sat: sw.satHours,
            sun: sw.sunHours,
            totalHours: sw.totalHours,
          }));

          setError((prev) => ({
            ...prev,
            sw: false,
          }));
        })

        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          setSwData(renderedSWData);
          if (renderedSWData.length === 0) {
            setError((prev) => ({
              ...prev,
              sw: true,
            }));
            seterrMsg((prev) => ({
              ...prev,
              sw: "No S&W Data found",
            }));
          }
          setLoadingSeven(false);
        });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, monthlySearchFilters, professionalSearchFilters]);

  return (
    <Box width="100%">
      {!isLoaded ? (
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={3} elevation={3}>
            <LoadingCircle
              progress={progress}
              setProgress={setProgress}
              isLoaded={isLoaded}
            />
          </Grid>
        </Grid>
      ) : (
        <>
          <Box
            sx={{
              backgroundColor:
                theme.palette.mode === "dark" ? "white" : "black",
            }}
          >
            <TopBarDashboardSettings
              monthlySearchFilters={monthlySearchFilters}
              setMonthlySearchFilters={setMonthlySearchFilters}
              professionalSearchFilters={professionalSearchFilters}
              setProfessionalSearchFilters={setProfessionalSearchFilters}
              listData={listData}
            />
            {user ? (
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 1,
                  justifyContent: "center",
                  pt: 2,
                }}
              >
                {user?.management.map((item, index) => (
                  <Chip
                    key={index}
                    label={item}
                    variant="outlined"
                    color="primary"
                  />
                ))}
              </Box>
            ) : null}
            <Box width="100%" display="grid" gap="1.5rem" sx={gridStyles}>
              <Row1
                timecardData={timecards}
                error={error.timecards}
                errMsg={errMsg.timecards}
                label={PrevWeekNumber}
                swData={swData}
                quarterlyData={quarterlyData}
                professionalSearchFilters={professionalSearchFilters}
              />
              <Row2
                listData={listData}
                swData={swData}
                error={error}
                errMsg={errMsg}
                monthlySearchFilters={monthlySearchFilters}
                professionalSearchFilters={professionalSearchFilters}
              />
              <Row3
                changeOrderData={changeOrderData}
                rfiData={rfiData}
                listData={listData}
                error={error}
                setError={setError}
                errMsg={errMsg}
                seterrMsg={seterrMsg}
                user={user}
                payrollData={payrollData}
                searchPayrollFilters={searchPayrollFilters}
                setSearchPayrollFilters={setSearchPayrollFilters}
              />
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default Dashboard3;

import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const CancelPage = () => {
  return (
    <div>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography> Payment Canceled </Typography>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Typography> You may try again. </Typography>
      </Box>
    </div>
  );
};

export default CancelPage;

import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CelebrationIcon from "@mui/icons-material/Celebration";

const SuccessPage = () => {
  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get("session_id");
  const [loading, setLoading] = useState(true);
  const [paymentStatus, setPaymentStatus] = useState(null);
  const { api } = useAuth();

  useEffect(() => {
    if (!sessionId) {
      setLoading(false); // No session_id? Stop loading
      return;
    }
    console.log(sessionId);
    api
      .get(`/api/v1/subscription/session/${sessionId}`)
      .then((res) => {
        console.log("API Response:", res.data); // Log response data
        setPaymentStatus(res.data.status); // Assuming API returns { status: "complete" }
        setLoading(false);
      })

      .catch(() => {
        setPaymentStatus("error");
        setLoading(false);
      });
  }, [sessionId]);

  if (loading) return <p>Loading...</p>;

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      {paymentStatus === "paid" ? (
        <>
          <Stack sx={{ justifyContent: "center", margin: "auto" }}>
            <Box sx={{ justifyContent: "center", margin: "auto" }}>
              <Stack direction="row" spacing={1}>
                <CelebrationIcon sx={{ color: "green" }} />
                <h1 className="text-2xl font-bold text-green-600">
                  Payment Successful!
                </h1>
                <CelebrationIcon sx={{ color: "green" }} />
              </Stack>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <p>Your subscription has been activated.</p>
            </Box>
          </Stack>
        </>
      ) : (
        <>
          <h1 className="text-2xl font-bold text-red-600">Payment Failed</h1>
          <p>We couldn't verify your payment. Please try again.</p>
          <a href="/" className="text-blue-600 underline mt-4">
            Try Again
          </a>
        </>
      )}
    </div>
  );
};

export default SuccessPage;

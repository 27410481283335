import { Grid } from "@mui/material";
import Login from "../components/Login";
import LandingPageNavBar from "../components/LandingPageNavBar";

function SignInPage() {
  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "100vh" }}
    >
      <LandingPageNavBar />
      <Grid
        item
        xs={3}
        sx={{
          width: "300px",
        }}
        elevation={3}
      >
        {" "}
        <Login />{" "}
      </Grid>
    </Grid>
  );
}

export default SignInPage;

import { createContext, useState, useMemo, useEffect } from "react";
import { createTheme } from "@mui/material/styles";

// color design tokens export
export const tokens = (mode) => ({
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#c2c2c2",
          300: "#a3a3a3",
          400: "#858585",
          500: "#666666",
          600: "#525252",
          700: "#3d3d3d",
          800: "#292929",
          900: "#141414",
        },
        primary: {
          100: "#d0d1d5",
          200: "#a1a4ab",
          300: "#727681",
          400: "#1F2A40",
          500: "#141b2d",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#040509",
        },
        greenAccent: {
          100: "#dbf5ee",
          200: "#b7ebde",
          300: "#94e2cd",
          400: "#70d8bd",
          500: "#4cceac",
          600: "#3da58a",
          700: "#2e7c67",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#f8dcdb",
          200: "#f1b9b7",
          300: "#e99592",
          400: "#e2726e",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#832f2c",
          800: "#58201e",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#e1e2fe",
          200: "#c3c6fd",
          300: "#a4a9fc",
          400: "#868dfb",
          500: "#6870fa",
          600: "#535ac8",
          700: "#3e4396",
          800: "#2a2d64",
          900: "#151632",
        },
      }
    : {
        grey: {
          100: "#141414",
          200: "#292929",
          300: "#3d3d3d",
          400: "#525252",
          500: "#666666",
          600: "#858585",
          700: "#a3a3a3",
          800: "#c2c2c2",
          900: "#e0e0e0",
        },
        primary: {
          100: "#040509",
          200: "#080b12",
          300: "#0c101b",
          400: "#f2f0f0", // manually changed
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#a1a4ab",
          900: "#d0d1d5",
        },
        greenAccent: {
          100: "#0f2922",
          200: "#1e5245",
          300: "#2e7c67",
          400: "#3da58a",
          500: "#4cceac",
          600: "#70d8bd",
          700: "#94e2cd",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#2c100f",
          200: "#58201e",
          300: "#832f2c",
          400: "#af3f3b",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#e99592",
          800: "#f1b9b7",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#151632",
          200: "#2a2d64",
          300: "#3e4396",
          400: "#535ac8",
          500: "#6870fa",
          600: "#868dfb",
          700: "#a4a9fc",
          800: "#c3c6fd",
          900: "#e1e2fe",
        },
      }),
});

// mui theme settings
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  console.log(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.greenAccent[500],
              50: "#EDF5FD",
              100: "#E3EFFB",
              200: "#C7DFF7",
              300: "#97C3F0",
              400: "#4393E4",
              500: "#0B6BCB",
              600: "#185EA5",
              700: "#12467B",
              800: "#0A2744",
              900: "#051423",
            },
            secondary: {
              main: colors.blueAccent[400],
            },
            background: {
              default: colors.primary[500],
              neutral: "#171A1C", //MUI joy
            },
            neutral: {
              50: "#FBFCFE", //MUI joy
              100: "#F0F4F8", //MUI joy
              200: "#DDE7EE",
              300: "#CDD7E1",
              400: "#9FA6AD",
              500: "#636B74",
              600: "#555E68",
              700: "#32383E",
              800: "#171A1C",
              900: "#0B0D0E",
            },
            warning: {
              50: "#FEFAF6", //MUI joy
              100: "#FDF0E1", //MUI joy
              200: "#FCE1C2",
              300: "#F3C896",
              400: "#EA9A3E",
              500: "#9A5B13",
              600: "#72430D",
              700: "#492B08",
              800: "#2E1B05",
              900: "#1D1002",
            },
            success: {
              50: "#F6FEF6", //MUI joy
              100: "#E3FBE3", //MUI joy
              200: "#C7F7C7",
              300: "#A1E8A1",
              400: "#51BC51",
              500: "#1F7A1F",
              600: "#136C13",
              700: "#0A470A",
              800: "#042F04",
              900: "#021D02",
            },
            background: {
              paper: "#FBFCFE",
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.blueAccent[300],
            },
            secondary: {
              main: colors.primary[100],
            },
            background: {
              default: "#fcfcfc",
            },
            neutral: {
              900: "#FBFCFE", //MUI joy
              800: "#F0F4F8", //MUI joy
              700: "#DDE7EE",
              600: "#CDD7E1",
              500: "#9FA6AD",
              400: "#636B74",
              300: "#555E68",
              200: "#32383E",
              100: "#171A1C",
              50: "#0B0D0E",
            },
            background: {
              paper: "#0B0D0E",
            },
            success: {
              900: "#F6FEF6", //MUI joy
              800: "#E3FBE3", //MUI joy
              700: "#C7F7C7",
              600: "#A1E8A1",
              500: "#51BC51",
              400: "#1F7A1F",
              300: "#136C13",
              200: "#0A470A",
              100: "#042F04",
              50: "#021D02",
            },
            primary: {
              900: "#EDF5FD",
              800: "#E3EFFB",
              700: "#C7DFF7",
              600: "#97C3F0",
              500: "#4393E4",
              400: "#0B6BCB",
              300: "#185EA5",
              200: "#12467B",
              100: "#0A2744",
              50: "#051423",
            },
          }),
    },
    typography: {
      fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
      fontSize: 12,
      h1: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        //fontSize: 40,
        fontSize: "2.5rem",
        "@media (min-width:600px)": {
          fontSize: "3rem",
        },
      },
      h2: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        //fontSize: 32,
        fontSize: "2rem",
        "@media (min-width:600px)": {
          fontSize: "2.5rem",
        },
        "@media (min-width:900px)": {
          fontSize: "3.5rem",
        },
      },
      h3: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        //fontSize: 24,
        fontSize: "1.75rem",
        "@media (min-width:600px)": {
          fontSize: "2rem",
        },
        "@media (min-width:900px)": {
          fontSize: "2.5rem",
        },
      },
      h4: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        //fontSize: 20,
        fontSize: "1.5rem",
        "@media (min-width:600px)": {
          fontSize: "1.75rem",
        },
        "@media (min-width:900px)": {
          fontSize: "2rem",
        },
      },
      h5: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        //fontSize: 16,
        fontSize: "1.25rem",
        "@media (min-width:600px)": {
          fontSize: "1.5rem",
        },
        "@media (min-width:900px)": {
          fontSize: "1.75rem",
        },
      },
      h6: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        //fontSize: 14,
        fontSize: "1rem",
        "@media (min-width:600px)": {
          fontSize: "1.125rem",
        },
        "@media (min-width:900px)": {
          fontSize: "1.25rem",
        },
      },
      h7: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        //fontSize: 12,
        fontSize: "1rem",
        "@media (min-width:600px)": {
          fontSize: "1.125rem",
        },
        "@media (min-width:900px)": {
          fontSize: "1.25rem",
        },
      },
      h9: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        //fontSize: 10,
        fontSize: "0.875rem",
        "@media (min-width:600px)": {
          fontSize: "1rem",
        },
        "@media (min-width:900px)": {
          fontSize: "1.125rem",
        },
      },
      h11: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        //fontSize: 8,
        fontSize: "0.8rem",
        "@media (min-width:600px)": {
          fontSize: "0.9rem",
        },
        "@media (min-width:900px)": {
          fontSize: "1.125rem",
        },
      },
      body1: {
        fontSize: ".75rem",
        "@media (min-width:600px)": {
          fontSize: "0.8rem",
        },
        "@media (min-width:900px)": {
          fontSize: "0.9rem",
        },
      },
      body2: {
        fontSize: "0.65rem",
        "@media (min-width:600px)": {
          fontSize: ".70rem",
        },
        "@media (min-width:900px)": {
          fontSize: ".80rem",
        },
      },
      joymed: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: "0.65rem",
        "@media (min-width:600px)": {
          fontSize: ".60rem",
        },
        "@media (min-width:900px)": {
          fontSize: ".75rem",
        },
      },
      joysmall: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: "0.45rem",
        "@media (min-width:600px)": {
          fontSize: ".50rem",
        },
        "@media (min-width:900px)": {
          fontSize: ".70rem",
        },
      },
      joybody: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: "0.35rem",
        "@media (min-width:600px)": {
          fontSize: ".40rem",
        },
        "@media (min-width:900px)": {
          fontSize: ".60rem",
        },
      },
    },
  };
};

export const ColorModeContext = createContext();

export const useMode = () => {
  const [mode, setMode] = useState(() => {
    // ✅ Persist theme mode using localStorage
    return localStorage.getItem("theme") || "dark";
  });

  useEffect(() => {
    localStorage.setItem("theme", mode); // ✅ Save theme mode to prevent loops
  }, [mode]);

  const toggleColorMode = () => {
    console.log("flipped");
    setMode((prev) => {
      const newMode = prev === "light" ? "dark" : "light";

      console.log("New Theme Mode:", newMode); // ✅ Debugging
      return newMode;
    });
  };

  // Create theme only when mode changes
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);

  return { theme, mode, toggleColorMode };
};

import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../context/AuthProvider";
import Box from "@mui/material/Box";
import { Button } from "@mui/material";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CheckoutButton = ({ priceId, subscriptionId }) => {
	const [loading, setLoading] = useState(false);
	const { api } = useAuth();
	const [Url, setUrl] = useState(null);

	const goToExternalSite = (url) => {
		window.location.href = url;
	};

	const handleCheckout = async () => {
		setLoading(true);
		const stripe = await stripePromise;

		try {
			const response = await api.post(
				"/api/v1/subscription/create-checkout-session",
				{ priceId }
			);

			setUrl(response.data.url);
			const { error } = await stripe.redirectToCheckout({
				sessionId: response.data.sessionId, // Directly passing sessionId from response
			});

			if (error) {
				console.error(error);
			}
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	return (
		<Box sx={{ display: "flex", flexDirection: "column" }}>
			<Button
				onClick={handleCheckout}
				variant={"outlined"}
				color={"inherit"}
				disabled={loading}
				className="bg-blue-600 text-white px-4 py-2 rounded"
			>
				{loading ? "Redirecting..." : "checkout through stripe"}
			</Button>
			{Url ? (
				<Button onClick={() => goToExternalSite(Url)}>
					{"Checkout Through Stripe"}
				</Button>
			) : null}
		</Box>
	);
};

export default CheckoutButton;

import { Box, Typography, useTheme } from "@mui/material";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridFooterContainer,
  GridFooter,
} from "@mui/x-data-grid";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Header from "../components/Header";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  FormControl,
  InputLabel,
  Select,
  Modal,
  Chip,
  MenuItem,
  Alert,
} from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import AddUserForm from "../components/AddUserForm";
import { useAuth } from "../context/AuthProvider";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

//Modal Stuff
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 250,
  bgcolor: "background.paper",
  border: "1px solid #000",
  borderRadius: 1,
  boxShadow: 24,
  // p: 4,
  overflowY: "auto",
  p: 1,
};

const Team = ({
  pageState,
  setPageState,
  deleteManyUsers,
  projectList,
  setselectedDataID,
}) => {
  projectList = [...projectList, "All Projects"];
  const { api } = useAuth();
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const [openUser, setOpenUser] = useState(false);
  const handleOpenUser = () => setOpenUser(true);
  const handleCloseUser = () => setOpenUser(false);
  const [errMsg, setErrMsg] = useState("");
  const [touched, setTouched] = useState(false);
  const [refresh, setRefresh] = useState(0);

  const [outputArray, setOutputArray] = useState([]);
  const [sent, setSent] = useState(false);

  const columns = [
    {
      field: "lastName",
      headerName: "Last Name",
      flex: 0.75,
      maxWidth: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "firstName",
      headerName: "First Name",
      flex: 0.75,
      maxWidth: 100,
      cellClassName: "name-column--cell",
    },
    {
      field: "email",
      headerName: "Email",
      maxWidth: 150,
      flex: 0.75,
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      maxWidth: 150,
      renderCell: ({ row: { role } }) => {
        return (
          <Box
            width="100%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            sx={{ maxWidth: 150, color: "white" }}
            backgroundColor={
              role === "Admin"
                ? theme.palette.primary[400]
                : theme.palette.primary[500]
            }
            borderRadius="4px"
          >
            {role === "Admin" && (
              <AdminPanelSettingsOutlinedIcon
                sx={{ fontSize: "17px", pt: 0.2, pr: 0.5 }}
              />
            )}
            {role === "Project Manager" && (
              <SecurityOutlinedIcon
                sx={{ fontSize: "17px", pt: 0.2, pr: 0.5 }}
              />
            )}
            {role === "Taper" && (
              <LockOpenOutlinedIcon
                sx={{ fontSize: "17px", pt: 0.2, pr: 0.5 }}
              />
            )}
            {role === "Carpenter" && (
              <LockOpenOutlinedIcon
                sx={{ fontSize: "17px", pt: 0.2, pr: 0.5 }}
              />
            )}
            <Typography
              sx={{ maxWidth: 100, overflow: "hidden" }}
              variant={"joysm"}
            >
              {role}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "classification",
      headerName: "Classification",
      maxWidth: 125,
      flex: 0.75,
    },
    {
      field: "management",
      headerName: "Management",
      flex: 1.25,
      editable: true,
      renderCell: (params) => {
        return (
          <Box>
            {params.value.map((item) => (
              <Chip key={item} label={item} sx={{ mr: 0.5 }} />
            ))}
          </Box>
        );
      },
      renderEditCell: (params) => {
        const { id, value } = params;
        return (
          <Box
            sx={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: theme.palette.neutral[400],
            }}
          >
            <Select
              multiple
              value={value || []} // Extract project values
              onChange={(event) => {
                const newValue = event.target.value;
                handleManagementChange(id, newValue);
              }}
              sx={{
                width: "95%",
                height: "30px", // Reduce height
                minHeight: "30px",
                backgroundColor: theme.palette.neutral[400],

                borderRadius: "8px",
              }}
            >
              {projectList.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </Box>
        );
      },
    },
  ];

  const hoverStyles = {
    "&:hover": {
      color: "white",
      backgroundColor: theme.palette.primary[400], // ✅ Modern hover color
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // ✅ Soft drop shadow
      transition: "all 0.3s ease-in-out", // ✅ Smooth transition effect
    },
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={hoverStyles} />
        <GridToolbarFilterButton sx={hoverStyles} />
        <GridToolbarDensitySelector sx={hoverStyles} />
        <GridToolbarExport sx={hoverStyles} />
        <Button
          sx={hoverStyles}
          startIcon={<PersonAddIcon />}
          onClick={handleOpenUser}
        >
          Add User
        </Button>
        <Button
          sx={hoverStyles}
          startIcon={<DeleteForeverIcon />}
          onClick={deleteManyUsers}
        >
          Delete
        </Button>
        {touched && (
          <Button
            sx={hoverStyles}
            startIcon={<SaveIcon />}
            onClick={() => {
              handleSave(outputArray);
            }}
          >
            Save
          </Button>
        )}

        <Modal
          open={openUser}
          onClose={handleCloseUser}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <AddUserForm />
          </Box>
        </Modal>
      </GridToolbarContainer>
    );
  }

  function CustomFooter() {
    let count;
    if (pageState.data.length) {
      count = pageState.data.length;
    }
    return (
      <GridFooterContainer>
        {/* Add what you want here */}
        <Typography variant="H6" sx={{ ml: "10px" }}>
          {" "}
          Count: {count}
        </Typography>

        <GridFooter
          sx={{
            border: "none", // To delete double border.
          }}
        />
      </GridFooterContainer>
    );
  }

  const [renderedData, setRenderedData] = useState([]);

  useEffect(() => {
    setRenderedData(
      pageState.data.map((datum) => ({
        id: datum._id,
        firstName: datum.firstName,
        lastName: datum.lastName,
        role: datum.role,
        classification: datum.classification,
        email: datum.email,
        management: Array.isArray(datum.management) ? datum.management : [],
        // actions: { payroll, handleSubmit },
      }))
    );
  }, [pageState.data]);

  const handleManagementChange = (id, newArray) => {
    setTouched(true);
    const foundItem = renderedData.find((item) => item.id === id);

    if (foundItem) {
      const updatedOutput = [...outputArray].map((item) => {
        if (item.id === foundItem.id)
          return { ...item, management: newArray || [] };
        return item;
      });

      // Check if an object with the same id already exists in the array
      const existingIndex = outputArray.findIndex(
        (item) => item.id === foundItem.id
      );

      if (existingIndex !== -1) {
        // Update the existing object
        updatedOutput[existingIndex] = {
          ...updatedOutput[existingIndex],
          management: newArray,
        };
      } else {
        // Push the new object to the array
        updatedOutput.push({ id: foundItem.id, management: newArray });
      }

      setOutputArray(updatedOutput);
    }

    setRenderedData((prevRows) => [
      ...prevRows.map((row) =>
        row.id === id ? { ...row, management: newArray } : row
      ),
    ]);

    setRefresh((prev) => prev + 1);
  };

  // TimeClock Post
  const handleSave = async (array) => {
    console.log("saving users:", array);

    try {
      await api.put(`/api/v1/users/management`, array);
      setSent(true);
      //update timecard in state
    } catch (error) {
      console.log("Error saving:", error);
      setErrMsg("Error Saving Data");
      setSent(false);
    }
  };

  return (
    <Box m="10px 20px 20px 20px">
      <Header title="User Accounts" subtitle="Managing the Team Members" />
      <Box
        m="0 20px 0 0"
        height="75vh"
        sx={{
          color:
            theme.palette.mode === "dark"
              ? theme.palette.neutral[600] // ✅ White text for dark mode
              : theme.palette.neutral[900], // ✅ Dark text for light mode
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.neutral[100] // ✅ Dark mode bg
              : theme.palette.neutral[50], // ✅ Light mode bg

          // ✅ Rounded Corners & Light Border
          borderRadius: "12px",
          border: `1px solid ${
            theme.palette.mode === "dark"
              ? theme.palette.neutral[300] // Dark mode border
              : theme.palette.neutral[200] // Light mode border
          }`,

          // ✅ Soft Drop Shadow
          boxShadow:
            theme.palette.mode === "dark"
              ? "0px 4px 15px rgba(0, 0, 0, 0.3)" // Dark mode shadow
              : "0px 4px 15px rgba(171, 160, 160, 0.1)", // Light mode shadow

          // ✅ Remove default borders
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .name-column--cell": {
            color: theme.palette.neutral[700],
          },

          "& .MuiDataGrid-columnHeaders": {
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400]
                : theme.palette.primary[100],
            borderBottom: "none",
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400]
                : theme.palette.primary[100],
            borderRadius: "0 0 12px 12px", // ✅ Rounds only the bottom corners
          },
          "& .MuiTablePagination-root": {
            color:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[100] // Light text for dark mode
                : theme.palette.neutral[800], // Dark text for light mode
            fontSize: "0.9rem",
            fontFamily: "IBM Plex Sans Condensed",
          },

          // ✅ Style "Rows Per Page" dropdown
          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${theme.palette.neutral[300]} !important`,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[800] // ✅ White text for dark mode
                : theme.palette.neutral[400], // ✅ Dark text for light mode
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.neutral[500]} !important`,
          },

          "& .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },
        }}
      >
        {/* ✅ Alerts for Status Messages */}
        <Alert severity="success" sx={{ display: sent ? "flex" : "none" }}>
          Data Saved
        </Alert>
        <Alert severity="error" sx={{ display: errMsg ? "flex" : "none" }}>
          {errMsg}
        </Alert>

        {/* ✅ DataGrid Component */}
        <DataGrid
          key={refresh} // Forces re-render
          rows={renderedData}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          pagination
          page={pageState.page}
          pageSize={pageState.pageSize}
          pageSizeOptions={[50, 100]}
          paginationMode="server"
          processRowUpdate={(newRow) => {
            setRenderedData((prevRows) =>
              prevRows.map((row) =>
                row.id === newRow.id ? { ...newRow } : row
              )
            );
            return { ...newRow }; // Ensure new object reference
          }}
          onPageChange={(newPage) =>
            setPageState((old) => ({ ...old, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          columns={columns}
          components={{
            Toolbar: CustomToolbar,
            Footer: CustomFooter,
          }}
          onSelectionModelChange={(ids) => {
            setselectedDataID(ids);
          }}
          checkboxSelection
          sx={{
            "& .MuiCheckbox-colorPrimary": {
              color: theme.palette.neutral[300],
            },
          }}
          disableSelectionOnClick
        />
      </Box>
    </Box>
  );
};

export default Team;

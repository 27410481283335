//functional MUI datagrid component to display timecards for payroll

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography, MenuItem, Select, TextField } from "@mui/material";
import { darken, lighten, styled } from "@mui/material/styles";
import {
  federalIncomeTax,
  stateIncomeTax,
  calculateFICA,
  calculateSDI,
} from "../../utils/tax";
import { useAuth } from "../../context/AuthProvider";
//theme imports
import { ColorModeContext } from "../../theme";
import { useContext } from "react";
import useDebounce from "../../hooks/useDebounce";

const SetTaxSettings = ({
  payrollData,
  selectedDataID2,
  setselectedDataID2,
  //handleTaxData,
}) => {
  //api request grabbing
  // marital status
  // dependants
  //
  console.log(selectedDataID2);
  const { api } = useAuth();
  const [rows, setRows] = useState();
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  //debounced
  const [query, setQuery] = useState({ id: "", field: "", value: "" });
  const debouncedQuery = useDebounce(query, 500);

  useEffect(() => {
    if (debouncedQuery) {
      console.log("Searching for:", debouncedQuery);
      // Perform API call or filtering

      if (api.authenticated) {
        handleUpdate(query.id, query.field, query.value);
      }
    }
  }, [debouncedQuery]);

  // ✅ Fix: Ensure rows update dynamically when user changes values
  const handleRowUpdate = (id, field, value) => {
    console.log(field);
    console.log(value);
    setRows((prevRows) => {
      return prevRows.map((row) =>
        row.id === id ? { ...row, [field]: value } : row
      );
    });
  };

  // ✅ Fix: Ensure rows update Number dynamically when user changes values
  const handleNumberUpdate = (id, field, value) => {
    setRows((prevRows) => {
      return prevRows.map((row) =>
        row.id === id ? { ...row, [field]: Number(value) } : row
      );
    });
  };
  // ✅ MUI's recommended way to update rows dynamically
  const processRowUpdate = (newRow) => {
    setRows((prevRows) =>
      prevRows.map((row) => (row.id === newRow.id ? newRow : row))
    );
    return newRow;
  };

  // useEffect(() => {
  //   handleTaxData(rows);
  // }, [rows]);

  const handleUpdate = async (id, field, value) => {
    console.log(`Updating ${field} for personnel ${id}: ${value}`);

    try {
      const response = await api.put(
        `/api/v1/personnel/updatetax/${id}`,
        { [field]: value }, // ✅ Dynamically send field name and value
        { headers: { "Content-Type": "application/json" } }
      );

      console.log("Update successful:", response.data);
    } catch (err) {
      console.error(
        "Update failed:",
        err.response ? err.response.data : err.message
      );
    }
  };

  useEffect(() => {
    let response;
    const fetchApi = async (updatedPayroll) => {
      try {
        response = await api.post(
          "/api/v1/personnel/taxstatus",
          updatedPayroll
        );
        console.log(response);
        if (Array.isArray(response.data)) {
          setRows(
            response.data.map((item) => ({
              ...item,
              id: item._id,
            }))
          );
        } else {
          console.error("Response data is not an array:", response.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    let updatedPayroll;
    if (payrollData && payrollData.length > 0) {
      updatedPayroll = payrollData.map((item) => ({
        ...item,
        id: item.timecardID,
        dependents: 0, // Default value
        totalWages: item.totalWages ?? 0,
      }));
    }

    if (updatedPayroll && updatedPayroll.length > 0) {
      fetchApi(updatedPayroll);
    }
  }, [payrollData]); // ✅ Set initial settings based on payrollData

  const getBackgroundColor = (color, theme, coefficient) => ({
    backgroundColor: darken(color, coefficient),
    ...theme.applyStyles("light", {
      backgroundColor: lighten(color, coefficient),
    }),
  });

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .super-app-theme--odd": {
      ...getBackgroundColor(theme.palette.info.main, theme, 0.7),
      "&:hover": {
        ...getBackgroundColor(theme.palette.info.main, theme, 0.6),
      },
      "&.Mui-selected": {
        ...getBackgroundColor(theme.palette.info.main, theme, 0.5),
        "&:hover": {
          ...getBackgroundColor(theme.palette.info.main, theme, 0.4),
        },
      },
    },
    "& .super-app-theme--true": {
      ...getBackgroundColor(theme.palette.success.main, theme, 0.7),
      "&:hover": {
        ...getBackgroundColor(theme.palette.success.main, theme, 0.6),
      },
      "&.Mui-selected": {
        ...getBackgroundColor(theme.palette.success.main, theme, 0.5),
        "&:hover": {
          ...getBackgroundColor(theme.palette.success.main, theme, 0.4),
        },
      },
    },
    "& .super-app-theme--even": {
      ...getBackgroundColor(theme.palette.warning.main, theme, 0.7),
      "&:hover": {
        ...getBackgroundColor(theme.palette.warning.main, theme, 0.6),
      },
      "&.Mui-selected": {
        ...getBackgroundColor(theme.palette.warning.main, theme, 0.5),
        "&:hover": {
          ...getBackgroundColor(theme.palette.warning.main, theme, 0.4),
        },
      },
    },
    "& .super-app-theme--false": {
      ...getBackgroundColor(theme.palette.error.main, theme, 0.7),
      "&:hover": {
        ...getBackgroundColor(theme.palette.error.main, theme, 0.6),
      },
      "&.Mui-selected": {
        ...getBackgroundColor(theme.palette.error.main, theme, 0.5),
        "&:hover": {
          ...getBackgroundColor(theme.palette.error.main, theme, 0.4),
        },
      },
    },
  }));

  const columns = [
    { field: "firstName", headerName: "First Name", width: 70 },
    { field: "lastName", headerName: "Last Name", width: 70 },
    { field: "weekNumber", headerName: "Wk", width: 40 },
    {
      field: "totalWages",
      headerName: "Wages",
      width: 70,
    },
    {
      field: "maritalStatus",
      headerName: "maritalStatus",
      width: 110,
      renderEditCell: (params) => {
        return (
          <Select
            value={params.row.maritalStatus || ""}
            onChange={(event) => {
              handleRowUpdate(params.id, "maritalStatus", event.target.value);
              handleUpdate(
                params.row.personnelId,
                "maritalStatus",
                event.target.value
              ); //updaating backend
              handleNumberUpdate(
                params.id,
                "federal",
                federalIncomeTax(
                  event.target.value,
                  params.row.dependents,
                  params.row.ytd + params.row.seed,
                  params.row.totalWages
                )
              );

              handleNumberUpdate(
                params.id,
                "state",
                stateIncomeTax(
                  "CA",
                  event.target.value,
                  params.row.dependents,
                  params.row.ytd + params.row.seed,
                  params.row.totalWages
                )
              );
            }}
            size="small"
            fullWidth
            sx={{
              backgroundColor: theme.palette.neutral[100], // ✅ Light green background
              color: theme.palette.primary[300],
              "& .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.primary[300],
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: theme.palette.neutral[100],
              },
              "& .MuiSelect-icon": {
                color: theme.palette.primary[500],
              },
            }}
          >
            <MenuItem
              value={"single"}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "rgba(0, 128, 0, 0.2) !important", // ✅ Green background when selected
                  color: "darkgreen !important",
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "rgba(0, 128, 0, 0.3) !important", // ✅ Slightly darker green when hovering
                },
              }}
            >
              Single
            </MenuItem>
            <MenuItem
              value={"married"}
              sx={{
                color: theme.palette.neutral[400],
                "&.Mui-selected": {
                  backgroundColor: "rgba(0, 128, 0, 0.2) !important", // ✅ Green background when selected
                  color: "darkgreen !important",
                },
                "&.Mui-selected:hover": {
                  backgroundColor: "rgba(0, 128, 0, 0.3) !important", // ✅ Slightly darker green when hovering
                },
                "&:hover": { backgroundColor: "lightgray" }, // ✅ Hover effect
              }}
            >
              Married
            </MenuItem>
          </Select>
        );
      },
    },
    {
      field: "dependents",
      headerName: "Dependents",
      width: 70,
      editable: true, // ✅ Enables editing directly in DataGrid
      renderEditCell: (params) => (
        <TextField
          value={params.row.dependents}
          type="number"
          sx={{
            "& .MuiInputBase-input": {
              color: theme.palette.neutral[600], // ✅ input text color
            },
          }}
          onChange={(event) => {
            const newValue = event.target.value;
            setQuery({
              id: params.row.personnelId,
              field: "dependents",
              value: event.target.value,
            });
            params.api.setEditCellValue({
              id: params.id,
              field: "dependents",
              value: newValue,
            });
            params.api.commitCellChange({ id: params.id, field: "dependents" });
            handleNumberUpdate(params.id, "dependents", Number(newValue));
            handleNumberUpdate(
              params.id,
              "federal",
              federalIncomeTax(
                params.row.maritalStatus,
                newValue,
                params.row.ytd + params.row.seed,
                params.row.totalWages
              )
            );
            handleNumberUpdate(
              params.id,
              "state",
              stateIncomeTax(
                "CA",
                params.row.maritalStatus,
                newValue,
                params.row.ytd + params.row.seed,
                params.row.totalWages
              )
            );
          }}
          size="small"
          fullWidth
        />
      ),
    },
    {
      field: "seed",
      headerName: "Seed",
      width: 90,
      editable: true, // ✅ Enables editing directly in DataGrid
      renderEditCell: (params) => (
        <TextField
          value={params.row.seed}
          type="number"
          sx={{
            "& .MuiInputBase-input": {
              color: theme.palette.neutral[600], // ✅ input text color
            },
          }}
          onChange={(event) => {
            const newValue = event.target.value;
            console.log(params);
            //set backend
            setQuery({
              id: params.row.personnelId,
              field: "seed",
              value: event.target.value,
            });
            params.api.setEditCellValue({
              id: params.id,
              field: "seed",
              value: newValue,
            });
            params.api.commitCellChange({ id: params.id, field: "seed" });
            //update seed
            handleNumberUpdate(params.id, "seed", Number(newValue));
            //update federal tax
            handleNumberUpdate(
              params.id,
              "federal",
              federalIncomeTax(
                params.row.maritalStatus,
                params.row.dependents,
                params.row.ytd + newValue,
                params.row.totalWages
              )
            );
            //update state tax
            handleNumberUpdate(
              params.id,
              "state",
              stateIncomeTax(
                "CA",
                params.row.maritalStatus,
                params.row.dependents,
                params.row.ytd + newValue,
                params.row.totalWages
              )
            );
            //update fica
            handleNumberUpdate(
              params.id,
              "fica",
              calculateFICA(params.row.totalWages, params.row.ytd + newValue)
            );
            //update sdi
            handleNumberUpdate(
              params.id,
              "sdi",
              calculateSDI(
                "CA",
                params.row.totalWages,
                params.row.ytd + newValue
              )
            );
          }}
          size="small"
          fullWidth
        />
      ),
    },
    {
      field: "ytd",
      headerName: "YTD",
      width: 100,
      editable: true, // ✅ Enables editing directly in DataGrid
      renderEditCell: (params) => (
        <TextField
          value={params.row.ytd}
          type="number"
          sx={{
            "& .MuiInputBase-input": {
              color: theme.palette.neutral[600], // ✅ input text color
            },
          }}
          onChange={(event) => {
            const newValue = event.target.value;
            setQuery({
              id: params.row.personnelId,
              field: "ytd",
              value: event.target.value,
            });

            params.api.setEditCellValue({
              id: params.id,
              field: "ytd",
              value: newValue,
            });
            params.api.commitCellChange({ id: params.id, field: "ytd" });
            handleRowUpdate(params.id, "ytd", Number(newValue));
            handleNumberUpdate(
              params.id,
              "fica",
              calculateFICA(params.row.totalWages, newValue + params.row.seed)
            );
            handleNumberUpdate(
              params.id,
              "state",
              stateIncomeTax(
                "CA",
                params.row.maritalStatus,
                params.row.dependents,
                newValue + params.row.seed,
                params.row.totalWages
              )
            );
            handleNumberUpdate(
              params.id,
              "federal",
              federalIncomeTax(
                params.row.maritalStatus,
                params.row.dependents,
                newValue + params.row.seed,
                params.row.totalWages
              )
            );

            handleNumberUpdate(
              params.id,
              "sdi",
              calculateSDI(
                "CA",
                params.row.totalWages,
                event.target.value + params.row.seed
              )
            );
          }}
          size="small"
          fullWidth
        />
      ),
    },

    {
      field: "federal",
      headerName: "Federal",
      width: 60,
      renderCell: (params) => {
        return Number(params.row.federal);
      },
    },
    {
      field: "fica",
      headerName: "FICA",
      width: 60,
      renderCell: (params) => {
        return Number(params.row.fica);
      },
    },
    {
      field: "state",
      headerName: "State",
      width: 60,
      renderCell: (params) => {
        return Number(params.row.state);
      },
    },
    {
      field: "sdi",
      headerName: "SDI",
      width: 60,
      renderCell: (params) => {
        return Number(params.row.sdi);
      },
    },
  ];

  return (
    <Box width="100%">
      <Box width="100%" display="flex" justifyContent="center">
        <Typography variant="h7" align="center">
          Edit Tax Settings
        </Typography>
      </Box>

      <Box
        width="100%"
        height="500px"
        sx={{
          borderRadius: "10px", // ✅ Ensure container matches DataGrid
          overflow: "hidden", // ✅ Prevents corners from being cut off

          // ✅ Soft Drop Shadow
          boxShadow:
            theme.palette.mode === "dark"
              ? "0px 4px 15px rgba(0, 0, 0, 0.2)" // Dark mode shadow
              : "0px 4px 15px rgba(202, 188, 188, 0.4)", // Light mode shadow

          // ✅ DataGrid Styling
          "& .MuiDataGrid-root": {
            borderRadius: "10px", // ✅ Apply to root container
            border: "1px solid",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[100] // ✅ Dark grid background
                : theme.palette.neutral[50], // ✅ Dark mode CELL background
          },

          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${theme.palette.neutral[500]} !important`,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[700] // ✅ Light mode white text
                : theme.palette.neutral[500], // ✅ Ensures white text
          },

          "& .MuiDataGrid-columnHeaders": {
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400] //DARK MODE HEADERS
                : theme.palette.primary[200],
            borderBottom: "none",
            fontWeight: "bold",
            borderTopLeftRadius: "10px", // ✅ Ensure headers match border
            borderTopRightRadius: "10px",
          },
          "& .MuiDataGrid-viewport": {
            borderRadius: "10px", // ✅ Ensures viewport matches border
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400] //DARK MODE FOOTERS
                : theme.palette.primary[200],
            borderBottomLeftRadius: "10px", // ✅ Ensure footer matches border
            borderBottomRightRadius: "10px",
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "white !important",
          },

          "& .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },

          "& .MuiCheckbox-root": {
            color:
              theme.palette.mode === "dark"
                ? theme.palette.primary[300]
                : theme.palette.primary[500], // ✅ White checkbox in light mode
          },
        }}
      >
        <DataGrid
          rows={rows || []}
          columns={columns}
          pageSize={20}
          loading={false}
          checkboxSelection
          rowsPerPageOptions={[20, 50, 100]}
          //   error={error}
          keepNonExistentRowsSelected
          rowHeight={40}
          onSelectionModelChange={(selectedDataID2) => {
            const selectedRows = rows.filter((row) =>
              selectedDataID2.includes(row.id)
            ); // ✅ Get full row data
            setselectedDataID2(selectedRows); // ✅ Store selected rows in state
          }}
          selectionModel={selectedDataID2.map((row) => row.id)} // ✅ Keep selection controlled
          processRowUpdate={processRowUpdate}
          getRowClassName={(params) =>
            `super-app-theme--${params.row.id & 1 ? "odd" : "even"}`
          }
          {...rows}
          sx={{
            boxShadow: 2,
            border: 2,
            borderColor: "black",
            "& .MuiDataGrid-cell:hover": {
              color: "primary.main",
            },
            borderRadius: 3,
            backgroundColor: "#3d3d3d",
          }}
        ></DataGrid>
      </Box>
    </Box>
  );
};

export default SetTaxSettings;

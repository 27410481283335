import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import Projects from "../projects";
import { Box } from "@mui/material";
import usePath from "../hooks/fetchPath";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import dayjs from "dayjs";

function AdminPageProjects() {
  const { api } = useAuth();
  //load all user data
  //const { data, loading, error } = useFetch('/api/v1/projects');

  const [allProjects, setAllProjects] = useState([]);
  const [selectedDataID, setselectedDataID] = useState([]);
  const { baseURL } = usePath("/api/v1/auth/updatedetails/admin");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 100,
  });
  const [errState, setErrState] = useState(false);
  const [errMsg, seterrMsg] = useState("");

  useEffect(() => {
    async function getProjects() {
      const pagelimit = `&limit=${pageState.pageSize}`;
      try {
        setErrState(false);
        seterrMsg("");
        const response = await api.get(
          `/api/v1/projects/company/?page=${pageState.page + 1}` + pagelimit
        );

        console.log(response, "Response");

        const renderedData = response.data.data[0].results.map((project) => ({
          id: project._id,
          project: project.project,
          city: project.city,
          county: project.county,
          state: project.state,
          projectcode: project.projectcode,
          biddate: project.biddate
            ? dayjs(project.biddate).format("MM-DD-YYYY")
            : null,
          book: project.book,
          address: project.address,
          start: project.start
            ? dayjs(project.start).format("MM-DD-YYYY")
            : null,
          end: project.end ? dayjs(project.end).format("MM-DD-YYYY") : null,
        }));

        setAllProjects(renderedData);

        const update = {
          data: response.data.data[0].results,
          pageSize: pageState.pageSize,
          total: response.data.data[0].pagination[0]?.totaldocuments,
        };

        setPageState((old) => ({
          ...old,
          ...update,
        }));
      } catch (err) {
        console.log(err);
        setErrState(true);
        seterrMsg("Error Loading Data");
      }
    }
    console.log("getting projects");
    getProjects();
  }, [api]);

  // Delete Many Projects By Id
  async function deleteManyProjects() {
    console.log("deleting");
    try {
      await Promise.all(
        selectedDataID.map((id) => api.delete(`/api/v1/projects/${id}`))
      );

      // Call handleRemoveMultipleItems *after* successful API deletion
      handleRemoveMultipleItems();
    } catch (err) {
      console.error("Error deleting projects:", err);
    }
  }

  // Delete a single project from state
  const deleteById = (id) => {
    setAllProjects((prevProjects) =>
      prevProjects.filter((item) => item._id !== id)
    );
  };

  // Remove multiple projects from state
  const handleRemoveMultipleItems = () => {
    console.log("removing multiple");
    console.log(selectedDataID);
    setAllProjects((prevProjects) =>
      prevProjects.filter((item) => !selectedDataID.includes(item._id))
    );
  };

  return (
    <Box sx={{ height: "100dvh" }}>
      {errState ? (
        <Alert
          icon={<RunningWithErrorsIcon fontSize="inherit" />}
          severity="error"
        >
          {errMsg}
        </Alert>
      ) : null}
      <Projects
        selectedDataID={selectedDataID}
        setselectedDataID={setselectedDataID}
        allProjects={allProjects}
        setAllProjects={setAllProjects}
        deleteManyProjects={deleteManyProjects}
        pageState={pageState}
        setPageState={setPageState}
      />
    </Box>
  );
}

export default AdminPageProjects;

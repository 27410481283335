import { useAuth } from "../../context/AuthProvider";
import { closeSidebar } from "../utils";
import { BrowserRouter as useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import EngineeringIcon from "@mui/icons-material/Engineering";
import React, { Fragment } from "react";
import GlobalStyles from "@mui/joy/GlobalStyles";
import Avatar from "@mui/joy/Avatar";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import Chip from "@mui/joy/Chip";
import Divider from "@mui/joy/Divider";
import IconButton from "@mui/joy/IconButton";
import Input from "@mui/joy/Input";
import LinearProgress from "@mui/joy/LinearProgress";
import List from "@mui/joy/List";
import ListItem from "@mui/joy/ListItem";
import ListItemButton from "@mui/joy/ListItemButton";
import { listItemButtonClasses } from "@mui/joy/ListItemButton";
import ListItemContent from "@mui/joy/ListItemContent";
import Typography from "@mui/joy/Typography";
import Sheet from "@mui/joy/Sheet";
import Stack from "@mui/joy/Stack";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import DashboardRoundedIcon from "@mui/icons-material/DashboardRounded";
import ShoppingCartRoundedIcon from "@mui/icons-material/ShoppingCartRounded";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import QuestionAnswerRoundedIcon from "@mui/icons-material/QuestionAnswerRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import SupportRoundedIcon from "@mui/icons-material/SupportRounded";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import LogoutRoundedIcon from "@mui/icons-material/LogoutRounded";
import BrightnessAutoRoundedIcon from "@mui/icons-material/BrightnessAutoRounded";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ColorSchemeToggle from "./ColorSchemeToggle";
import BlankUser from "../../assets/BlankUser.png";
import { CssVarsProvider, useColorScheme } from "@mui/joy/styles";
import CssBaseline from "@mui/joy/CssBaseline";
import PunchClockIcon from "@mui/icons-material/PunchClock";
import { useMode } from "../../theme";
import { ColorModeContext } from "../../theme"; // ✅ Ensure correct import
import { useContext } from "react";

function Toggler(props) {
  const { defaultExpanded = false, renderToggle, children } = props;
  const [open, setOpen] = React.useState(defaultExpanded);

  return (
    <Fragment>
      {renderToggle({ open, setOpen })}
      <Box
        sx={[
          {
            display: "grid",
            transition: "0.2s ease",
            "& > *": {
              overflow: "hidden",
            },
          },
          open ? { gridTemplateRows: "1fr" } : { gridTemplateRows: "0fr" },
        ]}
      >
        {children}
      </Box>
    </Fragment>
  );
}

const Sidebar = ({ theme, location }) => {
  const { user } = useAuth();
  const { toggleColorMode } = useContext(ColorModeContext); // ✅ Get toggle function
  console.log(user);
  return (
    <CssVarsProvider disableTransitionOnChange>
      <CssBaseline />
      <Sheet
        className="Sidebar"
        sx={{
          position: { xs: "fixed", md: "sticky" },
          transform: {
            xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))",
            md: "none",
          },
          transition: "transform 0.4s, width 0.4s",
          zIndex: 10000,
          height: "100dvh",
          width: "var(--Sidebar-width)",
          top: 0,
          p: 2,
          flexShrink: 0,
          display: "flex",
          flexDirection: "column",
          gap: 2,
          borderRight: "1px solid",
          borderColor: "divider",
        }}
      >
        <GlobalStyles
          styles={(theme) => ({
            ":root": {
              "--Sidebar-width": "220px",
              [theme.breakpoints.up("lg")]: {
                "--Sidebar-width": "240px",
              },
            },
          })}
        />
        <Box
          className="Sidebar-overlay"
          sx={{
            position: "fixed",
            zIndex: 9998,
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            opacity: "var(--SideNavigation-slideIn)",
            backgroundColor: theme.palette.neutral[50],
            transition: "opacity 0.4s",
            transform: {
              xs: "translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))",
              lg: "translateX(-100%)",
            },
          }}
          onClick={() => closeSidebar()}
        />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <IconButton
            variant="soft"
            color="primary"
            size="sm"
            onClick={() => console.log("fired")}
          >
            <BrightnessAutoRoundedIcon />
          </IconButton>
          <Typography level="title-lg">
            {user?.company?.companyName || "default company"}
          </Typography>
          <ColorSchemeToggle
            sx={{ ml: "auto" }}
            onClick={() => {
              console.log("ok");
              toggleColorMode();
            }}
          />
        </Box>
        <Input
          size="sm"
          startDecorator={<SearchRoundedIcon />}
          placeholder="Search"
        />
        <Box
          sx={{
            minHeight: 0,
            overflow: "hidden auto",
            flexGrow: 1,
            display: "flex",
            flexDirection: "column",
            [`& .${listItemButtonClasses.root}`]: {
              gap: 1.5,
            },
          }}
        >
          <List
            size="sm"
            sx={{
              gap: 1,
              "--List-nestedInsetStart": "30px",
              "--ListItem-radius": (theme) => theme.vars.radius.sm,
            }}
          >
            {/* <ListItem>
              <ListItemButton
                role="menuitem"
                component="a"
                href="/joy-ui/getting-started/templates/messages/"
                disabled
              >
                <QuestionAnswerRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Notifications</Typography>
                </ListItemContent>
                <Chip size="sm" color="primary" variant="solid">
                  4
                </Chip>
              </ListItemButton>
            </ListItem> */}
            <ListItem>
              <ListItemButton role="menuitem" component="a" href="/Dashboard3">
                <HomeRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Home</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem>

            {/* <ListItem onClick={() => console.log("clicked me")}>
              <ListItemButton
                role="menuitem"
                component="a"
                href="/TestTimelinePage"
                selected={
                  location?.pathname === "/TestTimelinePage" ? true : null
                }
              >
                <DashboardRoundedIcon />
                <ListItemContent>
                  <Typography level="title-sm">Timeline (Beta)</Typography>
                </ListItemContent>
              </ListItemButton>
            </ListItem> */}

            <ListItem nested>
              <Toggler
                defaultExpanded={
                  location?.pathname === "/TestDataGridPage" ? true : null
                }
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <PunchClockIcon />
                    <ListItemContent>
                      <Typography level="title-sm">Timecards</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={[
                        open
                          ? { transform: "rotate(180deg)" }
                          : { transform: "none" },
                      ]}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <ListItem sx={{ mt: 0.5 }}>
                    <ListItemButton
                      role="menuitem"
                      component="a"
                      href="/PayrollCreatePageV3"
                      selected={
                        location?.pathname === "/PayrollCreatePageV3" ||
                        location?.pathname === "/TestDataGridPage"
                          ? true
                          : null
                      }
                    >
                      Create
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      role="menuitem"
                      component="a"
                      href="/MobileViewPage"
                      selected={
                        location?.pathname === "/MobileViewPage" ? true : null
                      }
                    >
                      My Timecards
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      role="menuitem"
                      component="a"
                      href="/AdminPageTimecardsV3"
                      selected={
                        location?.pathname === "/AdminPageTimecardsV3"
                          ? true
                          : null
                      }
                    >
                      Approve
                    </ListItemButton>
                  </ListItem>
                  <ListItem>
                    <ListItemButton
                      role="menuitem"
                      component="a"
                      href="/RunPayrollPage"
                      selected={
                        location?.pathname === "/RunPayrollPage" ? true : null
                      }
                    >
                      Run Payroll
                    </ListItemButton>
                  </ListItem>
                </List>
              </Toggler>
            </ListItem>
            <ListItem nested>
              <Toggler
                defaultExpanded={
                  location?.pathname === "/TestDataGridPage" ? true : null
                }
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <EngineeringIcon />
                    <ListItemContent>
                      <Typography level="title-sm">Projects</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={[
                        open
                          ? { transform: "rotate(180deg)" }
                          : { transform: "none" },
                      ]}
                    />
                  </ListItemButton>
                )}
              >
                {" "}
                <List sx={{ gap: 0.5 }}>
                  <ListItem sx={{ mt: 0.5 }}>
                    <ListItemButton
                      role="menuitem"
                      component="a"
                      href="/AdminPageProjects"
                      selected={
                        location?.pathname === "/AdminPageProjects"
                          ? true
                          : null
                      }
                    >
                      Project
                    </ListItemButton>
                  </ListItem>
                </List>
              </Toggler>
            </ListItem>
            <ListItem nested>
              <Toggler
                defaultExpanded={
                  location?.pathname === "/TestGroundsPage" ? true : null
                }
                renderToggle={({ open, setOpen }) => (
                  <ListItemButton onClick={() => setOpen(!open)}>
                    <GroupRoundedIcon />
                    <ListItemContent>
                      <Typography level="title-sm">Users</Typography>
                    </ListItemContent>
                    <KeyboardArrowDownIcon
                      sx={[
                        open
                          ? { transform: "rotate(180deg)" }
                          : { transform: "none" },
                      ]}
                    />
                  </ListItemButton>
                )}
              >
                <List sx={{ gap: 0.5 }}>
                  <ListItem sx={{ mt: 0.5 }}>
                    <ListItemButton
                      role="menuitem"
                      component="a"
                      href="/AdminPagePersonnel"
                      selected={
                        location?.pathname === "/AdminPagePersonnel"
                          ? true
                          : null
                      }
                    >
                      Personnel
                    </ListItemButton>
                  </ListItem>
                  <ListItem sx={{ mt: 0.5 }}>
                    <ListItemButton
                      role="menuitem"
                      component="a"
                      href="/AdminPageUsers"
                      selected={
                        location?.pathname === "/AdminPageUsers" ? true : null
                      }
                    >
                      Data Accounts
                    </ListItemButton>
                  </ListItem>
                </List>
              </Toggler>
            </ListItem>
          </List>
          <List
            size="sm"
            sx={{
              mt: "auto",
              flexGrow: 0,
              "--ListItem-radius": (theme) => theme.vars.radius.sm,
              "--List-gap": "8px",
              mb: 2,
            }}
          >
            {/* <ListItem>
						<ListItemButton>
							<SupportRoundedIcon />
							Support
						</ListItemButton>
					</ListItem> */}
            <ListItem>
              <ListItemButton role="menuitem" component="a" href="/settings">
                <SettingsRoundedIcon />
                Settings
              </ListItemButton>
            </ListItem>
          </List>
          {/* <Card
            invertedColors
            variant="soft"
            color="warning"
            size="sm"
            sx={{ boxShadow: "none" }}
          >
            <Stack
              direction="row"
              sx={{ justifyContent: "space-between", alignItems: "center" }}
            >
              <Typography level="title-sm">Used space</Typography>
              <IconButton size="sm">
                <CloseRoundedIcon />
              </IconButton>
            </Stack>
            <Typography level="body-xs">
              Your team has used 80% of your available space. Need more?
            </Typography>
            <LinearProgress
              variant="outlined"
              value={80}
              determinate
              sx={{ my: 1 }}
            />
            <Button size="sm" variant="solid">
              Upgrade plan
            </Button>
          </Card> */}
        </Box>
        <Divider />
        <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
          <Avatar variant="outlined" size="sm" src={BlankUser} />
          <Box sx={{ minWidth: 0, flex: 1 }}>
            <Typography level="title-sm">
              {user?.firstName || "John"} {user?.lastName || "Doe"}
            </Typography>
            <Typography level="body-xs" noWrap>
              {user?.email || "defaultemail"}
            </Typography>
          </Box>
          <IconButton size="sm" variant="plain" color="neutral">
            <LogoutRoundedIcon />
          </IconButton>
        </Box>
      </Sheet>
    </CssVarsProvider>
  );
};

export default Sidebar;

import { Box, Typography, Stack, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import dayjs from "dayjs";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const EditTimeCardWeekLabels = ({
  show,
  height,
  weekEnding,
  project,
  handleChange,
  touched,
  projectList,
  isMobile,
}) => {
  const [displayShown, setDisplayShown] = useState({});
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const [sunday, setSunday] = useState(
    dayjs(weekEnding).day(0).format("MM/DD")
  );
  useEffect(() => {
    setDisplayShown(show === true ? "block" : "none");
  }, [show]);

  useEffect(() => {
    setSunday(dayjs(weekEnding).day(0).format("MM/DD"));
  }, [weekEnding]);

  const selectStyle = {
    backgroundColor: theme.palette.neutral[100],
    color: theme.palette.neutral[400], // ✅ Custom text color
    fontSize: "0.75rem", // ✅ Adjust text size
    height: "35px",
    borderRadius: "4px", // ✅ Rounded corners
    border: `1px solid ${theme.palette.neutral[400]}`, // ✅ Custom border
    "&:hover": {
      backgroundColor: theme.palette.primary[100], // ✅ Slightly darker blue on hover
      border: `2px solid ${theme.palette.primary[300]}`,
      color: theme.palette.neutral[600],
    },
    "&.Mui-focused": {
      border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
    },
    "& .MuiSelect-icon": {
      color: theme.palette.primary[300], // 🎨 Change dropdown arrow color
    },
  };

  const menuProps = {
    PaperProps: {
      sx: {
        backgroundColor: theme.palette.neutral[200], // ✅ Dropdown menu background
        borderRadius: "8px",
        color: theme.palette.neutral[600],
        boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
      },
    },
    MenuListProps: {
      sx: {
        padding: "4px 0",
        fontSize: "0.75rem", // ✅ Adjust text size
      },
    },
  };

  return (
    <Box sx={{ mt: "1rem", display: displayShown }}>
      <Stack spacing={1.65} sx={{ height: height, pr: 1 }}>
        <FormControl sx={{ width: isMobile ? "120px" : "100%" }} size="small">
          <InputLabel
            id="demo-select-small-label"
            sx={{ color: theme.palette.neutral[400], fontSize: "0.75rem" }}
          >
            {isMobile ? "Project" : "Select A Project"}
          </InputLabel>

          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={project ?? ""} // ✅ Ensure it's always defined
            label="Project"
            onChange={handleChange}
            sx={selectStyle}
            MenuProps={menuProps}
          >
            {projectList?.length > 0 &&
              projectList.map((proj, index) => (
                <MenuItem
                  key={index}
                  value={proj}
                  sx={{
                    fontSize: "0.75rem",
                    color: theme.palette.neutral[600],
                  }}
                >
                  {proj}
                </MenuItem>
              ))}
            <MenuItem
              value={""}
              disabled={true}
              sx={{ fontSize: "0.75rem", color: theme.palette.primary[600] }}
            >
              <em>Please Select A Project</em>
            </MenuItem>
          </Select>
        </FormControl>
        <Box>
          <Typography sx={{ color: theme.palette.neutral[600] }}>
            Sunday
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary[500],
              fontSize: "0.8rem", // ✅ Correct font size (8px)
              lineHeight: "1", // ✅ Prevents extra spacing
              pr: 0.5,
            }}
          >
            {sunday}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: theme.palette.neutral[600] }}>
            Monday
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary[500],
              fontSize: "0.8rem", // ✅ Correct font size (8px)
              lineHeight: "1", // ✅ Prevents extra spacing
              pr: 0.5,
            }}
          >
            {dayjs(weekEnding).day(1).format("MM/DD")}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: theme.palette.neutral[600] }}>
            Tuesday
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary[500],
              fontSize: "0.8rem", // ✅ Correct font size (8px)
              lineHeight: "1", // ✅ Prevents extra spacing
              pr: 0.5,
            }}
          >
            {dayjs(weekEnding).day(2).format("MM/DD")}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: theme.palette.neutral[600] }}>
            Wednesday
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary[500],
              fontSize: "0.8rem", // ✅ Correct font size (8px)
              lineHeight: "1", // ✅ Prevents extra spacing
              pr: 0.5,
            }}
          >
            {dayjs(weekEnding).day(3).format("MM/DD")}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: theme.palette.neutral[600] }}>
            Thursday
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary[500],
              fontSize: "0.8rem", // ✅ Correct font size (8px)
              lineHeight: "1", // ✅ Prevents extra spacing
              pr: 0.5,
            }}
          >
            {dayjs(weekEnding).day(4).format("MM/DD")}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: theme.palette.neutral[600] }}>
            Friday
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary[500],
              fontSize: "0.8rem", // ✅ Correct font size (8px)
              lineHeight: "1", // ✅ Prevents extra spacing
              pr: 0.5,
            }}
          >
            {dayjs(weekEnding).day(5).format("MM/DD")}
          </Typography>
        </Box>
        <Box>
          <Typography sx={{ color: theme.palette.neutral[600] }}>
            Saturday
          </Typography>
          <Typography
            sx={{
              color: theme.palette.primary[500],
              fontSize: "0.8rem", // ✅ Correct font size (8px)
              lineHeight: "1", // ✅ Prevents extra spacing
              pr: 0.5,
            }}
          >
            {dayjs(weekEnding).day(6).format("MM/DD")}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default EditTimeCardWeekLabels;

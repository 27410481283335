import { useState, useEffect } from "react";
import Team from "../team";
import { Box } from "@mui/material";
import usePath from "../hooks/fetchPath";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function AdminPageUsers() {
	const { api } = useAuth();
	const [errState, setErrState] = useState(false);
	const [errMsg, seterrMsg] = useState("");
	const [selectedDataID, setselectedDataID] = useState([]);
	const [projectList, setProjectList] = useState([]);
	const { baseURL } = usePath("/api/v1/users");
	const [pageState, setPageState] = useState({
		isLoading: false,
		data: [],
		total: 0,
		page: 0,
		pageSize: 50,
	});

	//updating state
	const deleteById = (id) => {
		const copy = [...pageState.data];
		//filter keeps all id thats not selected
		const filteredData = copy.filter((item) => item._id !== id);
		// Do something with the filtered data
		setPageState({ ...pageState, data: filteredData });
	};

	//update state
	const handleRemoveMultipleItems = () => {
		selectedDataID.forEach((id) => deleteById(id));
	};

	//create call back to edit payrollz using an array of id
	async function deleteManyUsers() {
		var cannotDelete = false;
		//Check if any of the selected are already approved
		selectedDataID.map((id) => {
			const search = pageState.data.find((user) => user._id === id);
			if (!search) {
				seterrMsg("Cannot Delete Approved Timecards");
				cannotDelete = true;
			}
			return search;
		});
		if (!cannotDelete) {
			await Promise.all(
				selectedDataID.map((id) => api.delete(`/api/v1/users/${id}`))
			)
				.catch(function (err) {})
				.finally(handleRemoveMultipleItems());
		}
	}

	useEffect(() => {
		if (api.authenticated) {
			const pageLimit = `&limit=${pageState.pageSize}`;
			api
				.get(`/api/v1/users/mycompany?page=${pageState.page + 1}` + pageLimit)
				.then((response) => {
					console.log(response);
					const update = {
						data: response.data.data[0].results,
						pageSize: pageState.pageSize,
						total: response.data.data[0].totalDocuments,
					};

					setPageState((old) => ({
						...old,
						...update,
					}));

					setErrState(false);
					seterrMsg("");
				})
				.catch((err) => {
					console.log("Failed", { err });
					setErrState(true);
					seterrMsg("Error Loading Data");
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [baseURL, pageState.page, pageState.pageSize, api]);

	useEffect(() => {
		api
			.get(`/api/v1/projects/list`)
			.then((response) => {
				setProjectList(response.data.data);
				setErrState(false);
				seterrMsg("");
			})
			.catch((err) => {
				console.log("Failed", { err });
				setErrState(true);
				seterrMsg("Error Loading Data");
			});
	}, [api]);

  return (
    <Box sx={{ height: "900px" }}>
      {errState ? (
        <Alert
          icon={<RunningWithErrorsIcon fontSize="inherit" />}
          severity="error"
        >
          {errMsg}
        </Alert>
      ) : null}
      <Team
        selectedDataID={selectedDataID}
        setselectedDataID={setselectedDataID}
        deleteManyUsers={deleteManyUsers}
        pageState={pageState}
        setPageState={setPageState}
        projectList={projectList}
      />
    </Box>
  );

}

export default AdminPageUsers;

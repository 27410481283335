import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
dayjs.extend(duration);

export const calculateDailyHours = (
  p1Start,
  p1End,
  p2Start,
  p2End,
  p3Start,
  p3End,
  breakDuration = 0,
  breakThreshold = 5,
  overtimeThreshold = 8,
  doubleTimeThreshold = 12
) => {
  const toDayjs = (value) => (value ? dayjs(value) : null);

  // Clean valid periods
  const periods = [
    { project: "p1", start: toDayjs(p1Start), end: toDayjs(p1End) },
    { project: "p2", start: toDayjs(p2Start), end: toDayjs(p2End) },
    { project: "p3", start: toDayjs(p3Start), end: toDayjs(p3End) },
  ].filter(({ start, end }) => start && end && end.isAfter(start));

  // Sort by start time
  periods.sort((a, b) => a.start.diff(b.start));

  let totalWorked = 0;
  const results = {
    p1: { reg: 0, ot: 0, dt: 0 },
    p2: { reg: 0, ot: 0, dt: 0 },
    p3: { reg: 0, ot: 0, dt: 0 },
  };

  let breakApplied = false;

  for (const { project, start, end } of periods) {
    let duration = end.diff(start, "minute") / 60;

    // Apply break only once if threshold is met
    if (!breakApplied && totalWorked + duration > breakThreshold) {
      duration -= breakDuration;
      duration = Math.max(duration, 0);
      breakApplied = true;
    }

    let reg = 0,
      ot = 0,
      dt = 0;

    if (totalWorked < overtimeThreshold) {
      const regTime = Math.min(overtimeThreshold - totalWorked, duration);
      reg += regTime;
      duration -= regTime;
      totalWorked += regTime;
    }

    if (
      totalWorked >= overtimeThreshold &&
      totalWorked < doubleTimeThreshold &&
      duration > 0
    ) {
      const otTime = Math.min(doubleTimeThreshold - totalWorked, duration);
      ot += otTime;
      duration -= otTime;
      totalWorked += otTime;
    }

    if (totalWorked >= doubleTimeThreshold && duration > 0) {
      dt += duration;
      totalWorked += duration;
      duration = 0;
    }

    results[project].reg += reg;
    results[project].ot += ot;
    results[project].dt += dt;
  }

  // Round the results
  const round = (n) => parseFloat(n.toFixed(2));
  for (const key of ["p1", "p2", "p3"]) {
    results[key].reg = round(results[key].reg);
    results[key].ot = round(results[key].ot);
    results[key].dt = round(results[key].dt);
  }

  return {
    p1regHours: results.p1.reg,
    p1overtimeHours: results.p1.ot,
    p1doubletimeHours: results.p1.dt,
    p2regHours: results.p2.reg,
    p2overtimeHours: results.p2.ot,
    p2doubletimeHours: results.p2.dt,
    p3regHours: results.p3.reg,
    p3overtimeHours: results.p3.ot,
    p3doubletimeHours: results.p3.dt,
  };
};

export const getListOfUniqueProjects = (array) => {
  const allProjects = array.flatMap((item) => [
    item.project1,
    item.project2,
    item.project3,
  ]);

  const uniqueProjects = [...new Set(allProjects.filter(Boolean))];
  return uniqueProjects;
};

export const getProjectSelector = (entry, projectName) => {
  if (entry.project1 === projectName) return "p1";
  if (entry.project2 === projectName) return "p2";
  if (entry.project3 === projectName) return "p3";
  return null; // no match
};

export const calculateDollarAmt = (rate, hours) => {
  if (rate > 0 && hours > 0) {
    return parseFloat((rate * hours).toFixed(2));
  }
  return 0;
};

export const formatNumber = (number) => {
  if (number) {
    return parseFloat(number.toFixed(2));
  }
  return 0;
};

export const calculateTotalBenefits = (timecard) => {
  const rateKeys = ["selectedRate", "selectedRate2", "selectedRate3"];
  let totalSum = 0;

  rateKeys.forEach((key) => {
    const rate = timecard[key];
    if (!rate) return;

    const hours =
      timecard.calculations?.totalHours ||
      timecard.calculations?.[`p${key.slice(-1)}Hours`] || // fallback for p1/p2/p3Hours
      0;

    const vac = calculateDollarAmt(rate.vacationAndHoliday, hours);
    const health = calculateDollarAmt(rate.healthAndWelfare, hours);
    const pension = calculateDollarAmt(rate.pension, hours);
    const train = calculateDollarAmt(rate.training, hours);
    const other = calculateDollarAmt(rate.otherPayments, hours);

    totalSum += vac + health + pension + train + other;
  });

  return parseFloat(totalSum.toFixed(2));
};

export const sumPropertyInArray = (property, array) => {
  return array.reduce((acc, current) => {
    let currentValue = current;
    property.split(".").forEach((prop) => {
      currentValue = currentValue[prop];
    });
    return acc + currentValue;
  }, 0);
};

export const sumUniqueProjectList = (array) => {
  const projectNameToTotals = {};

  array.forEach((item) => {
    const projects = [item.project1, item.project2, item.project3];

    projects.forEach((project, i) => {
      if (project) {
        if (!projectNameToTotals[project]) {
          projectNameToTotals[project] = { wages: 0, hours: 0 };
        }

        const calc = item.calculations;
        const wagesKey = `p${i + 1}TotalWages`;
        const hoursKey = `p${i + 1}Hours`;

        projectNameToTotals[project].wages += Number(calc[wagesKey] || 0);
        projectNameToTotals[project].hours += Number(calc[hoursKey] || 0);
      }
    });
  });

  return Object.entries(projectNameToTotals).map(
    ([name, { wages, hours }]) => ({
      name,
      wages: Number(wages.toFixed(2)),
      hours: Number(hours.toFixed(2)),
    })
  );
};

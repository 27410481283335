import { Box, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { tokens } from "../theme";
import Header from "../components/Header";
import AddRFIForm from "../components/AddRFIForm";
import ViewRFIPopover from "../components/ViewRFIPopover";
import PostAddIcon from "@mui/icons-material/PostAdd";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import Modal from "@mui/material/Modal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";

const RFIs = ({
  selectedDataID,
  setselectedDataID,
  data,
  setAllProjects,
  projectList,
  project,
  setProject,
  deleteManyProjects,
  listData,
}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  //modal
  const [openBook, setOpenBook] = useState(false);
  const handleOpenBook = () => setOpenBook(true);
  const handleCloseBook = () => setOpenBook(false);

  //Modal Stuff
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 390,
    bgcolor: "background.paper",
    border: "1px solid #000",
    borderRadius: 1,
    boxShadow: 24,
    // p: 4,
    overflowY: "auto",
    p: 1,
  };

  //project select dropdown

  const handleChange = (event) => {
    setProject(event.target.value);
  };

  // start view RFI
  const [anchoreElViewRFI, setanchoreElViewRFI] = useState(null);
  const openViewRFI = Boolean(anchoreElViewRFI);
  const idViewRFI = openViewRFI ? "Pop Out" : undefined;

  const handlePopOutClickRFI = (event, details) => {
    setanchoreElViewRFI(event.currentTarget);

    // setShowPayrollEdit(true);
    // setpopOverDetails(details.row.row);
  };

  //end view RFI

  const columns = [
    {
      field: "RFINumber",
      headerName: "RFI",
      flex: 0.5,
    },
    {
      field: "project",
      headerName: "Project",
      flex: 1,
    },
    {
      field: "to",
      headerName: "To",
    },
    {
      field: "from",
      headerName: "from",
      flex: 1,
    },
    {
      field: "cc",
      headerName: "cc",
      flex: 1,
    },
    {
      headerName: "Actions",
      width: 60,
      renderCell: (row) => {
        return (
          <Box>
            <VisibilityIcon
              onClick={(e) => {
                handlePopOutClickRFI(e, { row });
              }}
            />
          </Box>
        );
      },
    },
    {
      field: "response",
      headerName: "Response",
      flex: 1,
    },
    {
      field: "approved",
      headerName: "Approved By",
      flex: 1,
    },
  ];

  const hoverStyles = [
    {
      "&:hover": {
        color: "red",
        backgroundColor: colors.greenAccent[600],
      },
    },
  ];

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={hoverStyles} />
        <GridToolbarFilterButton sx={hoverStyles} />
        <GridToolbarDensitySelector sx={hoverStyles} />
        <GridToolbarExport sx={hoverStyles} />
        <Button
          sx={hoverStyles}
          startIcon={<PostAddIcon />}
          onClick={handleOpenBook}
        >
          Add RFI
        </Button>
        <Button
          sx={hoverStyles}
          startIcon={<DeleteForeverIcon />}
          onClick={deleteManyProjects}
        >
          Delete
        </Button>
        <Modal
          open={openBook}
          onClose={handleCloseBook}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="center">
              <AddRFIForm
                handleCloseBook={handleCloseBook}
                setAllProjects={setAllProjects}
                listData={listData}
              />
            </div>
          </Box>
        </Modal>
      </GridToolbarContainer>
    );
  }

  const renderedData = data.map((ref) => ({
    id: ref._id,
    date: ref.date,
    to: ref.to,
    from: ref.from,
    cc: ref.cc,
    project: ref.project,
    RFINumber: ref.no,
    subject: ref.subject,
    scope: ref.scope,
    reason: ref.reason,
    background: ref.background,
    recordOfNegotiation: ref.recordOfNegotiation,
    timeImpact: ref.timeImpact,
    timeExtension: ref.timeExtension,
    recommendation: ref.recommendation,
    approved: ref.approved,
    approvedBy: ref.approvedBy,
  }));

  const onRowsSelectionHandler = (ids) => {
    const selectedRowsData = ids.map((id) =>
      renderedData.find((row) => row.id === id)
    );
  };

  return (
    <Box m="10px 20px 20px 20px">
      <Stack direction="row" justifyContent="space-between">
        <Header title="RFI" subtitle="Manage RFI's" />
        <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
          <InputLabel id="demo-select-small-label-1">Project</InputLabel>
          <Select
            labelId="demo-select-small-label"
            id="demo-select-small"
            value={project}
            label="Project"
            onChange={handleChange}
          >
            <MenuItem value="All Projects" key="projects">
              <em>All Projects</em>
            </MenuItem>
            {projectList.map((elem) => (
              <MenuItem key={elem.project} value={elem}>
                {elem}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Stack>

      <Box
        m="20px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .name=column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${colors.grey[100]} !important`,
          },
        }}
      >
        <DataGrid
          rows={renderedData || []}
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            onRowsSelectionHandler(ids);
            setselectedDataID(ids);
          }}
          sx={{
            "& .MuiCheckbox-colorPrimary": {
              color: colors.grey[100],
            },
          }}
          disableSelectionOnClick
        />
        <div>
          <ViewRFIPopover
            message={"Hi"}
            anchor={anchoreElViewRFI}
            open={openViewRFI}
            id={idViewRFI}
            setAnchor={setanchoreElViewRFI}
          />
        </div>
        <p> {selectedDataID} </p>
      </Box>
    </Box>
  );
};

export default RFIs;

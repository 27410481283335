import { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import Dashboard2 from "./Dashboard2";
import useMediaQuery from "@mui/material/useMediaQuery";

function AdminHomePage() {
  const isAboveMediumScreens = useMediaQuery("(min-width: 1200px)");
  const isAboveiPhone = useMediaQuery("(min-width: 390px)");
  const isAboveiPhonePlus = useMediaQuery("(min-width: 415px)");
  const isAboveiPad = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    if (isAboveMediumScreens) {
      console.error("This is above 1200px");
    } else if (isAboveiPad) {
      console.error("This is above 768px");
    } else if (isAboveiPhonePlus) {
      console.error("This is above 415px");
    } else if (isAboveiPhone) {
      console.error("This is above 390px");
    }
  }, [isAboveMediumScreens, isAboveiPad, isAboveiPhone, isAboveiPhonePlus]);

  return (
    <Box width="95%">
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
        sx={{ minHeight: "100vh" }}
      >
        <Grid item xs={3}>
          <Box sx={{ px: 2, height: "90%", width: "80%" }}>
            <Dashboard2 />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AdminHomePage;

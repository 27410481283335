import { createContext, useEffect, useState, useContext } from "react";
import { useAuth } from "./AuthProvider";
import { useNavigate } from "react-router-dom";

const SubscriptionContext = createContext({});

export const SubscriptionProvider = ({ children }) => {
  const [products, setProducts] = useState(null);
  const [prices, setPrices] = useState([]);
  const [subscription, setSubscription] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { api, success } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    console.log("SubscriptionProvider mounted");
  }, []);

  useEffect(() => {
    if (!products) {
      api
        .get("/api/v1/subscription/products")
        .then(({ data }) => {
          setProducts(data.products);
          setPrices(data.prices);
        })
        .catch(() => {
          console.log("Error fetching products");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  // Using separate effects for fetching and checking the subscription status
  useEffect(() => {
    setIsLoading(true);

    if (api?.authenticated) {
      api
        .get("/api/v1/subscription")
        .then((res) => {
          console.log("Subscription", res.data.subscription);
          setSubscription(res.data.subscription);
        })
        .catch((err) => console.log("Error getting subscription", err))
        .finally(() => setIsLoading(false));
    }
  }, [success]);

  //   useEffect(() => {
  //     const validStatuses = ["trialing", "active"];
  //     if (!subscription || !validStatuses.includes(subscription.status)) {
  //       console.log("Attempt to navigate to /payment");
  //       navigate("/payment");
  //     }
  //   }, [subscription]);

  return (
    <SubscriptionContext.Provider
      value={{
        products,
        prices,
        subscription,
        isLoading,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionContext;

const useSubscription = () => {
  const context = useContext(SubscriptionContext);
  if (context === undefined) {
    throw new Error(
      "useSubscription must be used within a SubscriptionProvider"
    );
  }
  return context;
};

export { useSubscription };

import LandingPageNavBar from "../components/LandingPageNavBar";
import LandingPageFooter from "../components/LandingPageFooter";
import React from "react";
import { useState } from "react";
import AppTheme from "../shared-theme/AppTheme";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import EfficiencyFeatures from "../components/Marketing/EfficiencyFeatures";
import ROIFeatures from "../components/Marketing/ROIFeatures";
import Pricing from "../components/Marketing/Pricing";
import SignUpModal from "../components/SignUpModal";

const timecardfeatures = [
  {
    name: "Time Sheet Entries",
    basic: true,
    pro: true,
    premium: true,
  },
  {
    name: "CPR Optimization",
    basic: true,
    pro: true,
    premium: true,
  },
  { name: "Employee Breaks", basic: true, pro: true, premium: true },
  { name: "Custom Overtime Rules", basic: true, pro: true, premium: true },
  {
    name: "Database of Prevailing Wages (DIR)",
    basic: true,
    pro: true,
    premium: true,
  },
  {
    name: "Regular Wage Calculations",
    basic: true,
    pro: true,
    premium: true,
  },
  {
    name: "Overtime Wage Calculations",
    basic: true,
    pro: true,
    premium: true,
  },
  {
    name: "Saturday and Sunday Work",
    basic: true,
    pro: true,
    premium: true,
  },
  {
    name: "Double Time Calculations",
    basic: true,
    pro: true,
    premium: true,
  },
  {
    name: "Predetermined Increases (DIR)",
    basic: true,
    pro: true,
    premium: true,
  },
  { name: "Fringe Benefits (DIR)", basic: true, pro: true, premium: true },
  { name: "Pension Rates (DIR)", basic: true, pro: true, premium: true },
  { name: "Vacation Rates (DIR)", basic: true, pro: true, premium: true },
  {
    name: "Federal Tax (Estimate Only)",
    basic: true,
    pro: true,
    premium: true,
  },
  {
    name: "CA State Tax (Estimate Only)",
    basic: true,
    pro: true,
    premium: true,
  },
  { name: "FICA Tax (Estimate Only)", basic: true, pro: true, premium: true },
  { name: "SDI Tax (Estimate Only)", basic: true, pro: true, premium: true },
  { name: "PDF & Excel Reports", basic: true, pro: true, premium: true },
];

const appfeatures = [
  {
    name: "Interactive Dashboard",
    basic: true,
    pro: true,
    premium: true,
  },
  { name: "Query Data By Project", basic: true, pro: true, premium: true },
  {
    name: "Query Data for Training Funds",
    basic: false,
    pro: false,
    premium: true,
  },
  {
    name: "Project Based Data Permissions",
    basic: false,
    pro: false,
    premium: true,
  },
];

const pricingfeatures = [
  {
    name: "Payments By Stripe",
    basic: true,
    pro: true,
    premium: true,
  },
  { name: "Invoicing", basic: false, pro: false, premium: true },
  {
    name: "Up to 10 Users",
    basic: true,
    pro: true,
    premium: true,
  },
  {
    name: "Up to 20 Users",
    basic: false,
    pro: true,
    premium: true,
  },
  {
    name: "Up to 40 Users",
    basic: false,
    pro: false,
    premium: true,
  },
  {
    name: "$20 per month",
    basic: true,
    pro: false,
    premium: false,
  },
  {
    name: "$40 per month",
    basic: false,
    pro: true,
    premium: false,
  },
  {
    name: "$60 per month",
    basic: false,
    pro: false,
    premium: true,
  },
];

const renderFeatures = (array, title) => {
  return (
    <Box sx={{ pt: 2 }}>
      <Typography
        gutterBottom
        component="h2"
        variant="h4"
        sx={{ color: "primary.main", fontWeight: "medium" }}
      >
        {title}
      </Typography>
      <TableContainer component={Paper} sx={{ mt: 4 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Feature</TableCell>
              <TableCell align="center">Basic</TableCell>
              <TableCell align="center">Pro</TableCell>
              <TableCell align="center">Premium</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {array.map((feature) => (
              <TableRow key={feature.name}>
                <TableCell>{feature.name}</TableCell>
                <TableCell align="center">
                  {feature.basic && (
                    <CheckCircleIcon sx={{ color: "primary.main" }} />
                  )}
                </TableCell>
                <TableCell align="center">
                  {feature.pro && (
                    <CheckCircleIcon sx={{ color: "primary.main" }} />
                  )}
                </TableCell>
                <TableCell align="center">
                  {feature.premium && (
                    <CheckCircleIcon sx={{ color: "primary.main" }} />
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

const PricingPage = (props) => {
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [lookupKey, setLookupKey] = useState("small_monthly");
  const handleTrialClick = (lookupKey) => {
    // navigate('/start-free-trial');
    console.log("handleTrialClick", lookupKey);
    setLookupKey(lookupKey);
    setShowSignUpModal(true);
  };
  const handleCancelSignUp = () => {
    setShowSignUpModal(false);
  };
  const [email, setEmail] = useState("");
  return (
    <AppTheme {...props}>
      {/* <CssBaseline enableColorScheme /> */}
      <LandingPageNavBar />
      <SignUpModal
        open={showSignUpModal}
        onCancel={handleCancelSignUp}
        email={email}
        setEmail={setEmail}
        lookupKey={lookupKey}
      />
      <Container maxWidth="md" sx={{ textAlign: "center", mt: 5, p: 10 }}>
        {renderFeatures(timecardfeatures, "Timecard Features")}
        {renderFeatures(pricingfeatures, "Monthly Pricing")}
      </Container>
      <EfficiencyFeatures />
      <ROIFeatures />
      <Pricing
        handleTrialClick={handleTrialClick}
        setLookupKey={setLookupKey}
      />
      <LandingPageFooter />
    </AppTheme>
  );
};

export default PricingPage;

import { Typography, Stack, useTheme } from "@mui/material";
import { tokens } from "../theme";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

const Header = ({ title, subtitle }) => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  return (
    <Stack spacing={0.1}>
      <Typography
        variant="h9"
        color={theme.palette.neutral[800]}
        sx={{ mb: "5px" }}
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Typography variant="h9" color={theme.palette.primary[400]}>
        {subtitle}
      </Typography>
    </Stack>
  );
};

export default Header;

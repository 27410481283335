import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import RFI from "../rfi";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function AdminPageRFI() {
  const { api } = useAuth();
  const [project, setProject] = useState("All Projects");
  const [projectList, setProjectList] = useState([]);
  const [rfi, setrfi] = useState([]);
  const [selectedDataID, setselectedDataID] = useState([]);
  const [listData, setListData] = useState([]);
  const [errState, setErrState] = useState(false);
  const [errMsg, seterrMsg] = useState("");

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    if (api.authenticated) {
      api
        .get(`/api/v1/rfi/?page=1&limit=20&project=${project}`)
        .then(({ data }) => {
          setErrState(false);
          seterrMsg("");
          setrfi(data.data[0].results);
        })
        .catch((err) => {
          console.log("FAIL", err);
          setErrState(true);
          seterrMsg("Error Loading Data");
        });
    }
  }, [api, project]);

  useEffect(() => {
    if (api.authenticated) {
      api
        .get(`/api/v1/projects/list`)
        .then(({ data }) => {
          setProjectList(data.data);
          //setProject(data.data[0]);
        })
        .catch((err) => {
          console.log("FAIL", err);
          setErrState(true);
          seterrMsg("Error Loading Data");
        });
    }
  }, [api]);

  useEffect(() => {
    if (api.authenticated) {
      Promise.all([
        api.get("/api/v1/projects/list"),
        api.get("/api/v1/personnel/?sort=fullName"),
      ])
        .then(
          checkFailed(([projects, personnel]) => {
            setListData((prev) => ({
              ...prev,
              projects,
              personnel,
            }));
          })
        )
        .catch((err) => {
          console.log("FAIL", err);
          setErrState(true);
          seterrMsg("Error Loading Data");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api?.authenticated]);

  return (
    <Box sx={{ height: "100dvh" }}>
      {errState ? (
        <Alert
          icon={<RunningWithErrorsIcon fontSize="inherit" />}
          severity="error"
        >
          {errMsg}
        </Alert>
      ) : null}

      <RFI
        selectedDataID={selectedDataID}
        setselectedDataID={setselectedDataID}
        data={rfi}
        setrfi={setrfi}
        projectList={projectList}
        project={project}
        setProject={setProject}
        listData={listData}
        //deleteManyProjects={deleteManyProjects}
      />
    </Box>
  );
}

export default AdminPageRFI;

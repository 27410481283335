import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from "@mui/material";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import Visibility from "@mui/icons-material/Visibility";
import { useAuth } from "../../context/AuthProvider";
//theme imports
import { ColorModeContext, useMode } from "../../theme";
import { useContext } from "react";

const VALIDATION_SCHEMA = Yup.object().shape({
  password: Yup.string().min(6).max(16).label("Password"),
  passwordConfirmation: Yup.string().oneOf(
    [Yup.ref("password"), null],
    "Passwords must match"
  ),
});

const Settings = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirmation, setShowPasswordConfirmation] =
    useState(false);
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowPasswordConfirmation = () =>
    setShowPasswordConfirmation((show) => !show);
  const handleMouseDownPassword = (event) => event.preventDefault();

  const { api } = useAuth();
  const selectStyle = {
    backgroundColor: theme.palette.neutral[100],
    color: theme.palette.neutral[500], // ✅ Custom text color
    fontSize: "0.75rem", // ✅ Adjust text size
    borderRadius: "4px", // ✅ Rounded corners
    border: `1px solid ${theme.palette.neutral[400]}`, // ✅ Custom border
    "&:hover": {
      backgroundColor: theme.palette.primary[100], // ✅ Slightly darker blue on hover
      border: `2px solid ${theme.palette.primary[300]}`,
      color: theme.palette.neutral[600],
    },

    "& .MuiInputLabel-root": {
      color: `${theme.palette.neutral[600]}!important`, // ✅ Default label color
    },
    "& .MuiInputBase-input": {
      color: `${theme.palette.neutral[600]}!important`, // ✅ Ensures input text color
    },
    flexGrow: 1,
  };
  const [userData, setUserData] = useState({
    password: "",
    passwordConfirmation: "",
  });

  let formik = useFormik({
    initialValues: userData,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      setIsProcessing(true);
      const { password } = values;
      api
        .put(`/api/v1/auth/updatepassword`, { newPassword: password })
        .then(({ data }) => {
          setUserData({
            ...data.data,
            password: "",
            passwordConfirmation: "",
          });
        })
        .catch((err) => {
          console.log("FAIL", err);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { password } = formik.values;
    formik.handleSubmit({ password: password });
  };

  return (
    <Card raised>
      <CardHeader
        title="Change Password"
        sx={{ color: theme.palette.neutral[600] }}
      ></CardHeader>
      <Divider sx={{ marginBottom: "16px" }} />
      <CardContent sx={{ color: theme.palette.neutral[600] }}>
        <FormControl sx={{ width: "100%" }}>
          <Stack spacing={4}>
            <Box
              width="100%"
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              gap="32px"
            >
              <TextField
                id="password"
                label="Password"
                variant="outlined"
                name="password"
                type={showPassword ? "text" : "password"}
                disabled={isProcessing}
                value={formik.values.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
                width="100%"
                sx={selectStyle}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                id="password-confirmation"
                label="Confirm Password"
                variant="outlined"
                name="passwordConfirmation"
                type={showPasswordConfirmation ? "text" : "password"}
                disabled={isProcessing}
                value={formik.values.passwordConfirmation}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.passwordConfirmation &&
                  Boolean(formik.errors.passwordConfirmation)
                }
                helperText={
                  formik.touched.passwordConfirmation &&
                  formik.errors.passwordConfirmation
                }
                width="100%"
                sx={selectStyle}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password confirmation visibility"
                        onClick={handleClickShowPasswordConfirmation}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPasswordConfirmation ? (
                          <VisibilityOff />
                        ) : (
                          <Visibility />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  "data-1p-ignore": true,
                }}
              />
            </Box>
            <Button
              onClick={handleSubmit}
              disabled={isProcessing || !formik.dirty || !formik.isValid}
              variant="contained"
              color="primary"
              type="submit"
              size="large"
              sx={{
                backgroundColor: "blue", // ✅ Default background color
                color: "white", // ✅ Text color
                fontSize: "1rem", // ✅ Adjust font size
                fontWeight: "bold",
                padding: "10px 20px", // ✅ Adjust button size
                borderRadius: "8px", // ✅ Rounded corners
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // ✅ Drop shadow
                transition: "all 0.3s ease-in-out", // ✅ Smooth transition
                "&:hover": {
                  backgroundColor: "darkblue", // ✅ Darker color on hover
                  boxShadow: "0px 6px 12px rgba(0, 0, 0, 0.3)", // ✅ Stronger shadow
                },
                "&:active": {
                  transform: "scale(0.98)", // ✅ Click effect
                },
                "&.Mui-disabled": {
                  backgroundColor: "gray", // ✅ Custom disabled color
                  color: "lightgray",
                },
              }}
            >
              {isProcessing ? (
                <CircularProgress size={24} color="primary" />
              ) : (
                "Update Password"
              )}
            </Button>
          </Stack>
        </FormControl>
      </CardContent>
    </Card>
  );
};

export default Settings;

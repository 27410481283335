import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useFormik } from "formik";
import { useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import { tokens } from "../theme";
import { useAuth } from "../context/AuthProvider";
import { useNavigate } from "react-router";
import SubscriptionContext from "../context/SubscriptionProvider";
import { use } from "react";
import { Link } from "react-router-dom";

const VALIDATION_SCHEMA = Yup.object().shape({
  companyName: Yup.string().required().label("Company Name"),
  firstName: Yup.string().required().label("First Name"),
  lastName: Yup.string().required().label("Last Name"),
  email: Yup.string().email().required().label("Email"),
  password: Yup.string().min(6).max(16).required().label("Password"),
  lookupKey: Yup.string().required().label("Lookup Key"),
});

function SignUpForm({ onSuccess, onCancel, email, lookupKey }) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [errMsg, setErrMsg] = useState("");
  const [errState, setErrState] = useState(false);

  const INITIAL_DATA = {
    firstName: "",
    lastName: "",
    companyName: "",
    email: email || "",
    password: "",
    lookupKey: lookupKey || "",
  };
  console.log("INITIAL_DATA", INITIAL_DATA);

  const [data] = useState(INITIAL_DATA);
  const [sent, setSent] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedPrice, setSelectedPrice] = useState(null);
  const { api, login } = useAuth();

  const navigate = useNavigate();

  useEffect(() => {
    console.log(lookupKey);
    if (lookupKey === "small_monthly") {
      setSelectedPrice({
        product: { name: "Starter" },
        unit_amount: 2000,
      });
    } else if (lookupKey === "medium_monthly") {
      setSelectedPrice({
        product: { name: "Premium" },
        unit_amount: 4000,
      });
    } else if (lookupKey === "large_monthly") {
      setSelectedPrice({
        product: { name: "Professional" },
        unit_amount: 6000,
      });
    }
  }, [lookupKey]);

  // Mapping Create Stuff
  const createUserAndCompany = async (details, onSuccess) => {
    api
      .post("/api/v1/auth/register", JSON.stringify(details))
      .then((data) => {
        console.log("SUCCESS", data);
        setIsProcessing(false);
        setSent(true);
        const accessToken = data.data.token;
        console.log("accesstoken", accessToken);
        login(accessToken);
        navigate("/trial-account-created");
      })
      .catch((err) => {
        console.log("FAIL", err);
        setErrState(true);
        setErrMsg("Failed to sign up user");
      })
      .finally(() => {
        setErrState(false);
      });
  };

  const formik = useFormik({
    initialValues: { ...INITIAL_DATA },
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      createUserAndCompany(values);
    },
  });

  const handleSubmit = async (e) => {
    setErrState(false);
    setErrMsg("");
    e.preventDefault();
    console.log(formik.values);
    formik.handleSubmit(formik.values);
    setIsProcessing(true);
  };

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-evenly"
        sx={{ mb: 4 }}
      >
        <Typography
          variant="h3"
          textAlign="center"
          sx={{ color: colors.greenAccent[500], mb: 2 }}
        >
          Sign Up For Free Trial
        </Typography>
        {selectedPrice && (
          <Typography
            variant="body2"
            textAlign="center"
            sx={{ color: colors.grey[800] }}
          >
            You will be signed up for the ({selectedPrice?.product?.name}) plan
            for 30 days, and will be billed ${selectedPrice?.unit_amount / 100}{" "}
            after the trial period. You do not need to provide your payment
            information at this time.
          </Typography>
        )}
      </Box>
      {errState ? <Alert severity="warning">{errMsg}</Alert> : null}
      <FormControl sx={{ width: "100%" }}>
        <Stack spacing={2}>
          <TextField
            id="companyName"
            label="Company's Name"
            variant="outlined"
            name="companyName"
            required
            size="small"
            disabled={isProcessing}
            value={formik.values.companyName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.companyName && Boolean(formik.errors.companyName)
            }
            helperText={formik.touched.companyName && formik.errors.companyName}
          />

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            gap="10px"
          >
            <TextField
              id="firstName"
              label="First Name"
              variant="outlined"
              name="firstName"
              required
              size="small"
              disabled={isProcessing}
              value={formik.values.firstName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.firstName && Boolean(formik.errors.firstName)
              }
              helperText={formik.touched.firstName && formik.errors.firstName}
              width="100%"
              sx={{ flexGrow: 1 }}
            />

            <TextField
              id="lastName"
              label="Last Name"
              variant="outlined"
              name="lastName"
              required
              size="small"
              disabled={isProcessing}
              value={formik.values.lastName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              helperText={formik.touched.firstName && formik.errors.lastName}
              width="100%"
              sx={{ flexGrow: 1 }}
            />
          </Box>

          <TextField
            id="email"
            label="Email"
            variant="outlined"
            name="email"
            type="email"
            required
            size="small"
            disabled={isProcessing}
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />

          <TextField
            id="password"
            label="Password"
            variant="outlined"
            name="password"
            type="password"
            required
            size="small"
            disabled={isProcessing}
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.password && Boolean(formik.errors.password)}
            helperText={formik.touched.password && formik.errors.password}
          />

          {sent ? null : (
            <Button
              onClick={handleSubmit}
              disabled={isProcessing}
              variant="contained"
              color="primary"
              type="submit"
              size="small"
            >
              {isProcessing ? (
                <CircularProgress size={24} color="primary" />
              ) : (
                "Sign Up"
              )}
            </Button>
          )}
          {sent ? (
            <Box display="flex" flexDirection="column" alignItems="center">
              <Alert severity="success">
                'You have successfully signed up for a free trial. Please Log
                In.'
              </Alert>
            </Box>
          ) : null}
        </Stack>
      </FormControl>
    </Box>
  );
}

export default SignUpForm;

import LandingPageNavBar from "../components/LandingPageNavBar";
import LandingPageFooter from "../components/LandingPageFooter";
import React from "react";
import {
  Box,
  useTheme,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  useMediaQuery,
  Container,
  Paper,
} from "@mui/material";
import { tokens } from "../theme";
import CircleIcon from "@mui/icons-material/Circle";
import AppTheme from "../shared-theme/AppTheme";

const AboutPage = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <AppTheme {...props}>
      <LandingPageNavBar />
      <Grid
        container
        spacing={0}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          xs={3}
          sx={{
            width: isMobile ? "80%" : "50%",
            mt: "75px",
            mb: "35px",
          }}
          elevation={3}
        >
          <Container maxWidth="md" sx={{ mt: 5 }}>
            <Paper elevation={3} sx={{ p: 4, textAlign: "center" }}>
              <Typography
                variant="h3"
                sx={{ color: "primary.main" }}
                gutterBottom
              >
                About TimeCard Pro
              </Typography>
              <Typography variant="body1" paragraph>
                TimeCard Pro is a modern time-tracking application designed to
                help businesses and employees efficiently log, manage, and
                automste prevailing wage work with ease. Whether you're a a
                small business, or a large enterprise, our intuitive platform
                simplifies payroll and enhances productivity.
              </Typography>
              <Typography
                variant="h3"
                sx={{ color: "primary.main" }}
                gutterBottom
              >
                Mission Statement
              </Typography>
              <Typography variant="body1" paragraph>
                TimeCard Pro mission is to provide value to businesses in the
                form of increased software efficiency in the modern age of AI
                and SaaS. We are always building new features. Please leave a
                suggestion on how we can improve.
              </Typography>
              <Typography variant="h5" gutterBottom>
                Key Features
              </Typography>
              <Box
                sx={{
                  textAlign: "left",
                  maxWidth: "600px",
                  margin: "0 auto",
                }}
              >
                <Typography variant="body1">
                  ✔️ Save 30 Minutes Per Employee Per Month
                </Typography>
                <Typography variant="body1">
                  ✔️ 20,000+ Prevailing Wage Rates (DIR)
                </Typography>
                <Typography variant="body1">
                  ✔️ Automated timesheet calculations
                </Typography>
                <Typography variant="body1">
                  ✔️ Customizable work schedules
                </Typography>
                <Typography variant="body1">
                  ✔️ Secure cloud-based storage
                </Typography>
                <Typography variant="body1">
                  ✔️ Payroll integration support
                </Typography>
              </Box>
            </Paper>
          </Container>
        </Grid>
      </Grid>

      <LandingPageFooter />
    </AppTheme>
  );
};

export default AboutPage;

import React from "react";
import { useEffect } from "react";
import AppTheme from "../shared-theme/AppTheme";
import LandingPageNavBar from "../components/LandingPageNavBar";
import { Container, Stack, Button, Box, Grow, Typography } from "@mui/material";
import Success from "../assets/Success.png";
import { useNavigate } from "react-router";

const icon = (
  <Box sx={{ m: 1, width: 100, height: 100 }} elevation={4}>
    <img src={Success} alt="Account Created Successfully" />
  </Box>
);

const AccountCreatedSuccessfully = (props) => {
  const [checked, setChecked] = React.useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    const intervalId = setInterval(() => {
      setChecked((prev) => !prev);
    }, 2000); // toggle every 2 seconds

    return () => clearInterval(intervalId); // clean up
  }, []);

  return (
    <AppTheme {...props}>
      <LandingPageNavBar />
      <Box
        id="hero"
        sx={(theme) => ({
          width: "100%",
          backgroundRepeat: "no-repeat",
          backgroundImage:
            "radial-gradient(ellipse 80% 50% at 50% -20%, hsl(210, 100%, 90%), transparent)",
        })}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: { xs: 14, sm: 20 },
            pb: { xs: 8, sm: 12 },
          }}
        >
          <Stack
            spacing={2}
            useFlexGap
            sx={{ alignItems: "center", width: { xs: "100%", sm: "70%" } }}
          >
            <Box
              sx={{
                height: "60vh",
                width: "100%",
                display: "flex",
                margin: "auto",
                alignItems: "center", //align vertically
                justifyContent: "center", //align horizontally
              }}
            >
              <Box sx={{ height: 180 }}>
                <Box sx={{ display: "flex" }}>
                  <Grow in={checked}>{icon}</Grow>
                  {/* Conditionally applies the timeout prop to change the entry speed. */}

                  <Box
                    className="account-created-successfully"
                    sx={{ margin: "auto", justifyContent: "center" }}
                  >
                    <Typography sx={{ color: "primary.light" }}>
                      Account Created Successfully!
                    </Typography>
                    <p>You can now log in to your account.</p>
                    <Box
                      sx={{ display: "flex", justifyContent: "center", pt: 1 }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        sx={{ minWidth: "fit-content", fontSize: 12 }}
                        onClick={() => navigate("/login")}
                      >
                        Login
                      </Button>
                    </Box>
                  </Box>
                  <Grow
                    in={checked}
                    style={{ transformOrigin: "0 0 0" }}
                    {...(checked ? { timeout: 1000 } : {})}
                  >
                    {icon}
                  </Grow>
                </Box>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Box>
    </AppTheme>
  );
};

export default AccountCreatedSuccessfully;

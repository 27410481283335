import { Box, FormControl, useTheme, InputLabel } from "@mui/material";
import Button from "@mui/material/Button";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import { useState } from "react";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import Header from "../components/Header";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddPersonnelModal from "../components/AddPersonnelModal";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { useAuth } from "../context/AuthProvider";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

const Personnel = ({ pageState, setPageState, deleteManyUsers, listData }) => {
  const { api } = useAuth();
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  //modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("Carpenter");
  const [classification, setClassification] = useState("");

  function checkInList(key) {
    if (listData?.classification?.data?.data !== undefined) {
      return listData.classification.data.data.includes(key);
    }
  }

  //put request
  const updateBackEndByID = async (id, update) => {
    const payload = { ...update };
    try {
      // Use the imported function
      const response = await api
        .put(`/api/v1/personnel/${id}`, payload)
        .then((res) => {
          setPageState((old) => ({
            ...old,
            data: [
              ...old.data.map((p) => (p._id === id ? { ...p, ...update } : p)),
            ],
          }));
        });
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //datagrid
  const columns = [
    {
      field: "employeeID",
      headerName: "Employee ID",
      width: 25,
      cellClassName: "name-column--cell",
    },
    {
      field: "fullName",
      headerName: "Full Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "role",
      headerName: "Role",
      flex: 1,
      renderCell: ({ row: { id, role } }) => {
        return (
          <FormControl sx={{ width: "80%" }}>
            <InputLabel id="roleLabel">Craft</InputLabel>
            <Select
              labelId="roleLabel"
              size="small"
              id="role"
              value={role}
              onChange={(e) => updateBackEndByID(id, { role: e.target.value })}
              sx={{
                width: "95%",
                height: "30px", // Reduce height
                minHeight: "30px",
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral[600] // Dark mode border
                    : theme.palette.neutral[500], // Light mode border
                backgroundColor: theme.palette.neutral[200],
                border: `1px solid ${
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral[300] // Dark mode border
                    : theme.palette.neutral[200] // Light mode border
                }`,
                borderRadius: "8px",
              }}
            >
              {listData?.craft?.data.data.map((name, index) => (
                <MenuItem
                  key={index}
                  value={name}
                  sx={{ color: theme.palette.neutral[600] }}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "classification",
      headerName: "Classification",
      flex: 1,
      renderCell: ({ row: { id, classification } }) => {
        checkInList(classification);
        return checkInList(classification) ? (
          <FormControl sx={{ width: "80%" }}>
            <InputLabel id="classificationLabel">Classification</InputLabel>
            <Select
              labelId="classificationLabel"
              size="small"
              id="classification"
              value={classification}
              onChange={(e) =>
                updateBackEndByID(id, { classification: e.target.value })
              }
              sx={{
                width: "95%",
                height: "30px", // Reduce height
                minHeight: "30px",
                color:
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral[600] // Dark mode border
                    : theme.palette.neutral[500], // Light mode border
                backgroundColor: theme.palette.neutral[200],
                border: `1px solid ${
                  theme.palette.mode === "dark"
                    ? theme.palette.neutral[300] // Dark mode border
                    : theme.palette.neutral[200] // Light mode border
                }`,
                borderRadius: "8px",
              }}
            >
              {listData?.classification?.data.data.map((name, index) => (
                <MenuItem
                  key={index}
                  value={name}
                  sx={{ color: theme.palette.neutral[600] }}
                >
                  {name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : null;
      },
    },
  ];

  const hoverStyles = {
    "&:hover": {
      color: "white",
      backgroundColor: theme.palette.primary[400], // ✅ Modern hover color
      boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)", // ✅ Soft drop shadow
      transition: "all 0.3s ease-in-out", // ✅ Smooth transition effect
    },
  };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton sx={hoverStyles} />
        <GridToolbarFilterButton sx={hoverStyles} />
        <GridToolbarDensitySelector sx={hoverStyles} />
        <GridToolbarExport sx={hoverStyles} />
        <Button
          sx={hoverStyles}
          onClick={handleOpen}
          startIcon={<PersonAddIcon />}
        >
          Add Personnel
        </Button>
        <Button
          sx={hoverStyles}
          startIcon={<DeleteForeverIcon />}
          onClick={deleteManyUsers}
        >
          Delete
        </Button>
      </GridToolbarContainer>
    );
  }

  //row is really payroll lol
  const renderedData = pageState.data.map((datum) => ({
    id: datum._id,
    employeeID: datum.employeeID,
    fullName: datum.fullName,
    role: datum.role,
    classification: datum.classification,
    // actions: { payroll, handleSubmit },
  }));

  return (
    <Box m="10px 20px 20px 20px">
      <Header title="PERSONNEL" subtitle="Managing the Pesonnel" />
      <Box
        m="0 20px 0 0"
        height="75vh"
        sx={{
          color:
            theme.palette.mode === "dark"
              ? theme.palette.neutral[600] // ✅ White text for dark mode
              : theme.palette.neutral[900], // ✅ Dark text for light mode
          backgroundColor:
            theme.palette.mode === "dark"
              ? theme.palette.neutral[100] // ✅ Dark mode bg
              : theme.palette.neutral[50], // ✅ Light mode bg

          // ✅ Rounded Corners & Light Border
          borderRadius: "12px",
          border: `1px solid ${
            theme.palette.mode === "dark"
              ? theme.palette.neutral[300] // Dark mode border
              : theme.palette.neutral[200] // Light mode border
          }`,

          // ✅ Soft Drop Shadow
          boxShadow:
            theme.palette.mode === "dark"
              ? "0px 4px 15px rgba(0, 0, 0, 0.3)" // Dark mode shadow
              : "0px 4px 15px rgba(171, 160, 160, 0.1)", // Light mode shadow

          // ✅ Remove default borders
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },

          "& .name-column--cell": {
            color: theme.palette.neutral[700],
          },

          "& .MuiDataGrid-columnHeaders": {
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400]
                : theme.palette.primary[100],
            borderBottom: "none",
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400]
                : theme.palette.primary[100],
            borderRadius: "0 0 12px 12px", // ✅ Rounds only the bottom corners
          },
          "& .MuiTablePagination-root": {
            color:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[100] // Light text for dark mode
                : theme.palette.neutral[800], // Dark text for light mode
            fontSize: "0.9rem",
            fontFamily: "IBM Plex Sans Condensed",
          },

          // ✅ Style "Rows Per Page" dropdown
          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${theme.palette.neutral[300]} !important`,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[800] // ✅ White text for dark mode
                : theme.palette.neutral[400], // ✅ Dark text for light mode
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: `${theme.palette.neutral[500]} !important`,
          },

          "& .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },
        }}
      >
        <AddPersonnelModal
          open={open}
          handleClose={handleClose}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          role={role}
          setRole={setRole}
          classification={classification}
          setClassification={setClassification}
        />
        <DataGrid
          rows={renderedData || []}
          rowCount={pageState.total}
          loading={pageState.isLoading}
          pagination
          page={pageState.page}
          pageSize={pageState.pageSize}
          pageSizeOptions={[50, 100]}
          paginationMode="server"
          onPageChange={(newPage) =>
            setPageState((old) => ({ ...old, page: newPage }))
          }
          onPageSizeChange={(newPageSize) =>
            setPageState((old) => ({ ...old, pageSize: newPageSize }))
          }
          columns={columns}
          components={{ Toolbar: CustomToolbar }}
          checkboxSelection={true}
          onSelectionModelChange={(ids) => {}}
          sx={{
            "& .MuiCheckbox-colorPrimary": {
              color: theme.palette.neutral[100],
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default Personnel;

//https://www.google.com/search?q=mui+pagination+datagrid+example&sca_esv=581829575&rlz=1C1CHBF_enUS910US910&sxsrf=AM9HkKnAjCVDX9FSeknXlir8CfPTqADP_w%3A1699852113302&ei=Ua9RZf2LEozNkPIPwI6d2Ak&ved=0ahUKEwi9-IjLmsCCAxWMJkQIHUBHB5sQ4dUDCBA&uact=5&oq=mui+pagination+datagrid+example&gs_lp=Egxnd3Mtd2l6LXNlcnAiH211aSBwYWdpbmF0aW9uIGRhdGFncmlkIGV4YW1wbGUyBhAAGAgYHjIGEAAYCBgeMgYQABgIGB4yCBAAGIoFGIYDMggQABiKBRiGAzIIEAAYigUYhgMyCBAAGIoFGIYDSN8QUPIFWK4PcAF4AJABAJgBhgKgAa0JqgEFNS40LjG4AQPIAQD4AQHCAgoQABhHGNYEGLADwgIKEAAYigUYsAMYQ8ICBxAjGLACGCfCAgcQABgNGIAEwgIIEAAYCBgHGB7CAgYQABgeGA3CAggQABgFGB4YDcICCBAAGAgYHhgN4gMEGAAgQYgGAZAGCg&sclient=gws-wiz-serp#fpstate=ive&vld=cid:a1446d67,vid:FdISUQrdmuo,st:0

import React, { useState, useEffect } from "react";
import Box from "@mui/joy/Box";
import IconButton from "@mui/joy/IconButton";
import Autocomplete from "@mui/joy/Autocomplete";
import Stack from "@mui/joy/Stack";
import Typography from "@mui/joy/Typography";
import TwoToneInlineMui from "../TwoToneInlineMui";
import dayjs from "dayjs";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CalculateIcon from "@mui/icons-material/Calculate";
import PaidIcon from "@mui/icons-material/Paid";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import TimerIcon from "@mui/icons-material/Timer";
import Chip from "@mui/joy/Chip";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import List from "@mui/joy/List";
import Input from "@mui/joy/Input";
import useDebounce from "../../hooks/useDebounce";

const ProjectEffectiveRates = ({
  name,
  label,
  selectedRate,
  regHours,
  overtimeHours,
  satHours,
  sunHours,
  ratebook,
  handleRateChange,
  handleOpenFiltersModal,
  handleOpenBook,
  setRatebook,
  options,
  setMyRates,
}) => {
  const [regRate, setRegRate] = useState(
    selectedRate?.regRate ? selectedRate?.regRate : 0
  );
  const [OTRate, setOTRate] = useState(
    selectedRate?.OTRate ? selectedRate?.OTRate : 0
  );
  const [satRate, setSatRate] = useState(
    selectedRate?.satRate ? selectedRate?.satRate : 0
  );
  const [sunRate, setSunRate] = useState(
    selectedRate?.sunRate ? selectedRate?.sunRate : 0
  );
  const [regWages, setRegWages] = useState(
    selectedRate?.regRate && regHours ? regHours * regRate : 0
  );
  const [OTWages, setOTWages] = useState(
    overtimeHours && OTRate ? overtimeHours * OTRate : 0
  );
  const [satWages, setSatWages] = useState(
    satHours && satRate ? satHours * satRate : 0
  );
  const [sunWages, setSunWages] = useState(
    sunHours && sunRate ? sunHours * sunRate : 0
  );

  useEffect(() => {
    if (selectedRate) {
      const fringe = selectedRate?.regRate - selectedRate?.basicRate;
      const effectiveIncrease1 =
        selectedRate?.dateInc1 && dayjs().isAfter(dayjs(selectedRate.dateInc1))
          ? selectedRate?.increase1
          : 0;
      const effectiveIncrease2 =
        selectedRate?.dateInc2 && dayjs().isAfter(dayjs(selectedRate.dateInc2))
          ? selectedRate?.increase2
          : 0;
      const effectiveIncrease3 =
        selectedRate?.dateInc3 && dayjs().isAfter(dayjs(selectedRate.dateInc3))
          ? selectedRate?.increase3
          : 0;
      const effectiveIncrease4 =
        selectedRate?.dateInc4 && dayjs().isAfter(dayjs(selectedRate.dateInc4))
          ? selectedRate?.increase4
          : 0;
      const effectiveIncrease5 =
        selectedRate?.dateInc5 && dayjs().isAfter(dayjs(selectedRate.dateInc5))
          ? selectedRate?.increase5
          : 0;
      let effectiveRegRate =
        selectedRate?.basicRate +
        effectiveIncrease1 +
        effectiveIncrease2 +
        effectiveIncrease3 +
        effectiveIncrease4 +
        effectiveIncrease5 +
        fringe;
      const effectiveOTRate = effectiveRegRate * 1.5;
      const effectiveSatRate = effectiveRegRate * 1.5;
      const effectiveSunRate = effectiveRegRate * 2;
      const regWages = regHours * effectiveRegRate;
      const OTWages = overtimeHours * effectiveOTRate;
      const satWages = satHours * effectiveSatRate;
      const sunWages = sunHours * effectiveSunRate;

      setRegRate(effectiveRegRate);
      setOTRate(effectiveOTRate);
      setSatRate(effectiveSatRate);
      setSunRate(effectiveSunRate);
      setRegWages(regWages);
      setOTWages(OTWages);
      setSatWages(satWages);
      setSunWages(sunWages);
    } else {
      setRegRate(0);
      setOTRate(0);
      setSatRate(0);
      setSunRate(0);
      setRegWages(0);
      setOTWages(0);
      setSatWages(0);
      setSunWages(0);
    }
  }, [selectedRate, regHours, overtimeHours, satHours, sunHours, regRate]);

  return (
    <Box height="100%" width="100%" sx={{ p: 1 }}>
      <Typography level="body-xs">{label}</Typography>
      <Stack direction="row">
        <Autocomplete
          id="size-small-outlined"
          size="sm"
          options={options || []}
          placeholder={`Saved Rates for ${name}`}
          value={selectedRate}
          //defaultValue={ratebook[13]}
          onChange={(event, newValue) => {
            handleRateChange(newValue);
          }}
          onInputChange={(event, newInputValue) => {
            // add this prop
            //filters myRates
          }}
          renderInput={(params) => (
            <Input
              {...params}
              sx={{
                backgroundColor: "white",
              }}
            />
          )}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <List
              component="li"
              {...props}
              sx={{
                fontSize: "12px", // Adjust dropdown text size
                padding: "4px 6px",
              }}
            >
              <Typography level="body-xs">{option.name}</Typography>
            </List>
          )}
          slotProps={{
            root: {
              sx: {
                fontSize: "12px", // Ensures the font size of the selected value
              },
            },
          }}
        />

        <IconButton onClick={() => handleOpenFiltersModal()}>
          <FilterAltIcon />
        </IconButton>
        <IconButton onClick={() => handleOpenBook()}>
          <AddCircleIcon />
        </IconButton>
      </Stack>

      <Stack
        spacing="0.25rem 1rem"
        direction="row"
        useFlexGap
        sx={{ flexWrap: "wrap", my: 0.25 }}
      >
        <Typography level="body-xs" startDecorator={<CalculateIcon />}>
          Basic Rates:
        </Typography>

        <TwoToneInlineMui
          text1="Reg"
          text2={selectedRate?.regRate ? selectedRate.regRate.toFixed(2) : 0}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            regRate > 0
              ? "success.400"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
        <TwoToneInlineMui
          text1="OT"
          text2={selectedRate?.OTRate ? selectedRate.OTRate.toFixed(2) : 0}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            selectedRate?.OTRate > 0
              ? "success.400"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
        <TwoToneInlineMui
          text1="Sat"
          text2={selectedRate?.satRate ? selectedRate.satRate.toFixed(2) : 0}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            selectedRate?.satRate > 0
              ? "success.400"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
        <TwoToneInlineMui
          text1="Sun"
          text2={selectedRate?.sunRate ? selectedRate.sunRate.toFixed(2) : 0}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            selectedRate?.sunRate > 0
              ? "success.400"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
      </Stack>
      <Stack
        spacing="0.25rem 1rem"
        direction="row"
        useFlexGap
        sx={{ flexWrap: "wrap", my: 0.25 }}
      >
        <Typography level="body-xs" startDecorator={<ShowChartIcon />}>
          Rate Increases:
        </Typography>
        {selectedRate?.dateInc1 &&
        dayjs().isAfter(dayjs(selectedRate.dateInc1)) ? (
          <Typography level="body-xs">
            ${selectedRate?.increase1} on{" "}
            <Chip color={"primary"} size="sm">
              {dayjs(selectedRate?.dateInc1).format("MM/DD/YYYY")}
            </Chip>
          </Typography>
        ) : null}
        {selectedRate?.dateInc2 &&
        dayjs().isAfter(dayjs(selectedRate.dateInc2)) ? (
          <Typography level="body-xs">
            ${selectedRate?.increase2} on{" "}
            <Chip color={"primary"} size="sm">
              {dayjs(selectedRate?.dateInc2).format("MM/DD/YYYY")}
            </Chip>
          </Typography>
        ) : null}
        {selectedRate?.dateInc3 &&
        dayjs().isAfter(dayjs(selectedRate.dateInc3)) ? (
          <Typography level="body-xs">
            ${selectedRate?.increase3} on{" "}
            <Chip color={"primary"} size="sm">
              {dayjs(selectedRate?.dateInc3).format("MM/DD/YYYY")}
            </Chip>
          </Typography>
        ) : null}
        {selectedRate?.dateInc4 &&
        dayjs().isAfter(dayjs(selectedRate.dateInc4)) ? (
          <Typography level="body-xs">
            ${selectedRate?.increase4} on{" "}
            <Chip color={"primary"} size="sm">
              {dayjs(selectedRate?.dateInc4).format("MM/DD/YYYY")}
            </Chip>
          </Typography>
        ) : null}
        {selectedRate?.dateInc5 &&
        dayjs().isAfter(dayjs(selectedRate.dateInc5)) ? (
          <Typography level="body-xs">
            ${selectedRate?.increase5} on{" "}
            <Chip color={"primary"} size="sm">
              {dayjs(selectedRate?.dateInc5).format("MM/DD/YYYY")}
            </Chip>
          </Typography>
        ) : null}
      </Stack>
      <Stack
        spacing="0.25rem 1rem"
        direction="row"
        useFlexGap
        sx={{ flexWrap: "wrap", my: 0.25 }}
      >
        <Typography level="body-xs" startDecorator={<TimerIcon />}>
          Net Hours:
        </Typography>
        <Typography level="body-xs" sx={{ pl: 0.5 }}>
          Reg:
          <Chip color={regHours > 0 ? "primary" : "neutral"} size="sm">
            {regHours ? regHours.toFixed(2) : 0}
          </Chip>
        </Typography>
        <Typography level="body-xs">
          OT:
          <Chip color={overtimeHours > 0 ? "primary" : "neutral"} size="sm">
            {overtimeHours ? overtimeHours.toFixed(2) : 0}
          </Chip>
        </Typography>
        <Typography level="body-xs">
          Sat:
          <Chip color={satHours > 0 ? "primary" : "neutral"} size="sm">
            {satHours ? satHours.toFixed(2) : 0}
          </Chip>
        </Typography>
        <Typography level="body-xs">
          Sun:
          <Chip color={sunHours > 0 ? "primary" : "neutral"} size="sm">
            {sunHours ? sunHours.toFixed(2) : 0}
          </Chip>
        </Typography>
      </Stack>
      <Stack
        spacing="0.25rem 1rem"
        direction="row"
        useFlexGap
        sx={{ flexWrap: "wrap", my: 0.25 }}
      >
        <Typography level="body-xs" startDecorator={<CalculateIcon />}>
          Effective Rates:
        </Typography>

        <TwoToneInlineMui
          text1="Reg"
          text2={regRate.toFixed(2)}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            regRate > 0
              ? "success.400"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
        <TwoToneInlineMui
          text1="OT"
          text2={OTRate.toFixed(2)}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            OTRate > 0
              ? "success.400"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
        <TwoToneInlineMui
          text1="Sat"
          text2={satRate.toFixed(2)}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            satRate > 0
              ? "success.400"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
        <TwoToneInlineMui
          text1="Sun"
          text2={sunRate.toFixed(2)}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            sunRate > 0
              ? "success.400"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
      </Stack>
      <Stack
        spacing="0.25rem 1rem"
        direction="row"
        useFlexGap
        sx={{ flexWrap: "wrap", my: 0.25 }}
      >
        <Typography level="body-xs" startDecorator={<PaidIcon />}>
          Wages:
        </Typography>

        <TwoToneInlineMui
          text1="Reg"
          text2={regWages.toFixed(2)}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            regWages > 0
              ? "var(--joy-palette-success-plainColor)"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
        <TwoToneInlineMui
          text1="OT"
          text2={OTWages.toFixed(2)}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            OTWages > 0
              ? "var(--joy-palette-success-plainColor)"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
        <TwoToneInlineMui
          text1="Sat"
          text2={satWages.toFixed(2)}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            satWages > 0
              ? "var(--joy-palette-success-plainColor)"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
        <TwoToneInlineMui
          text1="Sun"
          text2={sunWages.toFixed(2)}
          color1={"var(--joy-palette-neutral-600, #555E68)"}
          color2={
            sunWages > 0
              ? "var(--joy-palette-success-plainColor)"
              : "var(--joy-palette-neutral-600, #555E68)"
          }
        />
      </Stack>
    </Box>
  );
};

export default ProjectEffectiveRates;

import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import AppTheme from "../../shared-theme/AppTheme";
//theme imports
import { ColorModeContext, useMode } from "../../theme";
import { useContext } from "react";
import { useSubscription } from "../../context/SubscriptionProvider";
import { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../../context/AuthProvider";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

export default function Pricing({ props }) {
  const { subscription } = useSubscription();
  const mode = process.env.REACT_APP_NODE_ENV;
  const [loading, setLoading] = useState(false);
  const { api } = useAuth();
  const [Url, setUrl] = useState(null);

  const goToExternalSite = (url) => {
    window.location.href = url;
  };

  const handleCheckout = async (priceId) => {
    console.log(priceId);
    setLoading(true);
    const stripe = await stripePromise;

    try {
      const response = await api.post(
        "/api/v1/subscription/create-checkout-session",
        { priceId }
      );

      setUrl(response.data.url);
      window.location.href = response.data.url;
      const { error } = await stripe.redirectToCheckout({
        sessionId: response.data.sessionId, // Directly passing sessionId from response
      });

      if (error) {
        console.error(error);
      }
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const tiers = [
    {
      title: "Starter",
      price: "20",
      description: ["10 users included", "Unlimited storage", "Email support"],
      buttonText:
        subscription?.plan?.product?.name === "Starter" &&
        subscription?.plan?.amount === 2000
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonColor: "primary",
      lookupKey: "small_monthly",
      id:
        mode === "production"
          ? "price_1PlDrrHiJ3dXu5VeG0eBXOni"
          : "price_1OorqeHiJ3dXu5VeluFZjYW8",
    },
    {
      title: "Premium",
      subheader: "Recommended",
      price: "40",
      description: ["25 users included", "Unlimited storage", "Email support"],
      buttonText:
        subscription?.plan?.product?.name === "Premium" &&
        subscription?.plan?.amount === 4000
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonColor: "primary",
      lookupKey: "medium_monthly",
      id:
        mode === "production"
          ? "price_1PhyvWHiJ3dXu5Ve9vLkFTCx" //1 mo pro production
          : "price_1Oorv2HiJ3dXu5Vetfq5y9h0", //1 mo pro dev
    },
    {
      title: "Professional",
      price: "60",
      description: [
        "80 users included",
        "Unlimited storage",
        "Priority email support",
      ],
      buttonText:
        subscription?.plan?.product?.name === "Premium Pro" &&
        subscription?.plan?.amount === 6000
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonVariant: "contained",
      buttonColor: "secondary",
      lookupKey: "large_monthly",
      id:
        mode === "production"
          ? "price_1PhyHIHiJ3dXu5VeA4DD53bX" //1 mo premium production
          : "price_1OorviHiJ3dXu5VeezPKJGqB", //1 mo premium dev
    },
  ];

  const sixmotiers = [
    {
      title: "Starter",
      price: "110",
      description: ["10 users included", "Unlimited storage", "Email support"],
      buttonText:
        subscription?.plan?.product?.name === "Starter" &&
        subscription?.plan?.amount === 11000
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonColor: "primary",
      lookupKey: "small_biannually",
      id:
        mode === "production"
          ? "price_1PhyTgHiJ3dXu5VeHCcjtoTg" //Starter mo dev
          : "price_1OpI1kHiJ3dXu5Veqyko2QtM", //Starter mo dev
    },
    {
      title: "Premium",
      subheader: "Recommended",
      price: "220",
      description: ["25 users included", "Unlimited storage", "Email support"],
      buttonText:
        subscription?.plan?.product?.name === "Premium" &&
        subscription?.plan?.amount === 22000
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonColor: "primary",
      lookupKey: "medium_monthly",
      id:
        mode === "production"
          ? "price_1PlDjBHiJ3dXu5Vec9LeRWJ8" //6 mo premium production
          : "price_1OpIHGHiJ3dXu5VeaD74NuvS", //6 mo premium dev
    },
    {
      title: "Professional",
      price: "330",
      description: [
        "80 users included",
        "Unlimited storage",

        "Priority email support",
      ],
      buttonText:
        subscription?.plan?.product?.name === "Premium Pro" &&
        subscription?.plan?.amount === 33000
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonColor: "primary",
      buttonVariant: "contained",
      buttonColor: "secondary",
      lookupKey: "large_monthly",
      id:
        mode === "production"
          ? "price_1PlDsNHiJ3dXu5Vega0s8mZI" //6 mo premium production
          : "price_1OpIHhHiJ3dXu5VeU4F9YA6F", //6 mo premium dev
    },
  ];
  const twelvemotiers = [
    {
      title: "Starter",
      price: "200",
      description: ["10 users included", "Unlimited storage", "Email support"],
      buttonText:
        subscription?.plan?.product?.name === "Starter" &&
        subscription?.plan?.amount === 20000
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonColor: "primary",
      lookupKey: "small_monthly",
      id:
        mode === "production"
          ? "price_1Phyk8HiJ3dXu5VebCu6m49M" //12 mo premium production
          : "price_1OpI1zHiJ3dXu5VemsouXqH0", // Starter 12 mo premium dev
    },
    {
      title: "Premium",
      subheader: "Recommended",
      price: "400",
      description: ["25 users included", "Unlimited Storage", "Email support"],
      buttonText:
        subscription?.plan?.product?.name === "Premium" &&
        subscription?.plan?.amount === 40000
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonColor: "primary",
      lookupKey: "medium_monthly",
      id:
        mode === "production"
          ? "price_1PlDkRHiJ3dXu5Ve58QF5Vp3" //12 mo premium production
          : "price_1OpIHQHiJ3dXu5VeBF081Uz9", //12 mo premium dev
    },
    {
      title: "Professional",
      price: "600",
      description: ["80 users included", "Unlimited storage", "Email support"],
      buttonText:
        subscription?.plan?.product?.name === "Premium Pro" &&
        subscription?.plan?.amount === 60000
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonColor: "primary",
      buttonVariant: "contained",
      buttonColor: "secondary",
      lookupKey: "large_monthly",
      id:
        mode === "production"
          ? "price_1PlDsxHiJ3dXu5VeMlirjNRX" //12 mo pro
          : "price_1OpIHqHiJ3dXu5VeW8UsDbcN", //12 mo pro dec
    },
  ];

  const devtiers = [
    {
      title: "Development",
      price: "200",
      description: ["10 users included", "Unlimited storage", "Email support"],
      buttonText:
        subscription?.status === "active" &&
        subscription?.plan?.product?.plan === "Development"
          ? "Selected"
          : subscription?.status === "active"
          ? "Switch Plans"
          : "Sign Up",
      buttonVariant: "outlined",
      buttonColor: "primary",
      lookupKey: "Development",
    },
  ];

  const handleClick = (id) => {
    console.log(id);
    return id;
  };

  return (
    <AppTheme {...props}>
      <Stack direction="row" spacing={0.5}>
        <Typography sx={{ pl: 1 }}>Plan: </Typography>

        <Chip
          label={subscription?.plan?.product?.name ?? "Error"}
          color="primary"
          size="small"
        />

        {subscription?.status ? (
          <Chip
            label={subscription?.status}
            color={subscription?.status === "active" ? "success" : "warning"}
            size="small"
          />
        ) : null}
      </Stack>
      <Container id="pricing" sx={{ maxWidth: "100%", padding: 0 }}>
        <Typography sx={{ textAlign: "center" }}>Monthly Plans</Typography>
        <Grid
          container
          spacing={3}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {tiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={6}
              md={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                sx={[
                  {
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    maxWidth: "450px",
                  },
                  tier.title === subscription?.plan?.product?.name &&
                    ((theme) => ({
                      border: "none",
                      background:
                        "radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))",
                      boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                      ...theme.applyStyles("dark", {
                        background:
                          "radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))",
                        boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                      }),
                    })),
                ]}
              >
                <CardContent>
                  <Box
                    sx={[
                      {
                        mb: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                      },
                      tier.title === "Professional"
                        ? { color: "grey.100" }
                        : { color: "" },
                    ]}
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                      sx={{
                        color:
                          tier.title === subscription?.plan?.product?.name
                            ? "white"
                            : "black",
                      }}
                    >
                      {tier.title}
                    </Typography>
                    {tier.title === "Professional" && (
                      <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                    )}
                  </Box>
                  <Box
                    sx={[
                      {
                        display: "flex",
                        alignItems: "baseline",
                      },
                      tier.title === "Professional"
                        ? { color: "grey.50" }
                        : { color: null },
                    ]}
                  >
                    <Typography
                      component="h3"
                      variant="h4"
                      sx={{
                        color:
                          tier.title === subscription?.plan?.product?.name
                            ? "white"
                            : "black",
                      }}
                    >
                      ${tier.price}
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h8"
                      sx={{
                        color:
                          tier.title === subscription?.plan?.product?.name
                            ? "white"
                            : "black",
                      }}
                    >
                      &nbsp; per month
                    </Typography>
                  </Box>
                  <Divider
                    sx={{ my: 2, opacity: 0.8, borderColor: "divider" }}
                  />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: "flex",
                        gap: 1.5,
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={[
                          {
                            width: 20,
                          },
                          tier.title === "Professional"
                            ? { color: "primary.light" }
                            : { color: "primary.main" },
                        ]}
                      />
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        sx={{
                          color:
                            tier.title === subscription?.plan?.product?.name
                              ? "white"
                              : "black",
                        }}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color={tier.buttonColor}
                    disabled={
                      tier.title === subscription?.plan?.product?.name &&
                      subscription?.status !== "trialing"
                        ? true
                        : false
                    }
                    onClick={() => {
                      handleClick(tier.id);
                      handleCheckout(tier.id);
                    }}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container id="pricing" sx={{ maxWidth: "100%", padding: 0 }}>
        <Typography sx={{ textAlign: "center" }}>6 Month Plans</Typography>
        <Grid
          container
          spacing={3}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {sixmotiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={6}
              md={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                sx={[
                  {
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    maxWidth: "450px",
                  },
                  tier.title === tier.title &&
                    subscription?.plan?.product?.plan === tier.title &&
                    ((theme) => ({
                      border: "none",
                      background:
                        "radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))",
                      boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                      ...theme.applyStyles("dark", {
                        background:
                          "radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))",
                        boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                      }),
                    })),
                ]}
              >
                <CardContent>
                  <Box
                    sx={[
                      {
                        mb: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                      },
                      tier.title === "Professional"
                        ? { color: "grey.100" }
                        : { color: "" },
                    ]}
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                      sx={{
                        color:
                          tier.title === tier.title &&
                          subscription?.plan?.product?.plan === tier.lookupKey
                            ? "white"
                            : "black",
                      }}
                    >
                      {tier.title}
                    </Typography>
                    {tier.title === "Professional" && (
                      <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                    )}
                  </Box>
                  <Box
                    sx={[
                      {
                        display: "flex",
                        alignItems: "baseline",
                      },
                      tier.title === "Professional"
                        ? { color: "grey.50" }
                        : { color: null },
                    ]}
                  >
                    <Typography
                      component="h3"
                      variant="h4"
                      sx={{
                        color:
                          tier.title === tier.title &&
                          subscription?.plan?.product?.plan === tier.lookupKey
                            ? "white"
                            : "black",
                      }}
                    >
                      ${tier.price}
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h8"
                      sx={{
                        color:
                          tier.title === tier.title &&
                          subscription?.plan?.product?.plan === tier.lookupKey
                            ? "white"
                            : "black",
                      }}
                    >
                      &nbsp; per 6 months
                    </Typography>
                  </Box>
                  <Divider
                    sx={{ my: 2, opacity: 0.8, borderColor: "divider" }}
                  />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: "flex",
                        gap: 1.5,
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={[
                          {
                            width: 20,
                          },
                          tier.title === "Professional"
                            ? { color: "primary.light" }
                            : { color: "primary.main" },
                        ]}
                      />
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        sx={{
                          color:
                            tier.title === tier.title &&
                            subscription?.plan?.product?.plan === tier.lookupKey
                              ? "white"
                              : "black",
                        }}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color={tier.buttonColor}
                    disabled={
                      tier.title === subscription?.plan?.product?.plan
                        ? true
                        : false
                    }
                    onClick={() => handleClick(tier.id)}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      <Container id="pricing" sx={{ maxWidth: "100%", padding: 0 }}>
        <Typography sx={{ textAlign: "center" }}>12 Month Plans</Typography>
        <Grid
          container
          spacing={3}
          sx={{
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          {twelvemotiers.map((tier) => (
            <Grid
              item
              key={tier.title}
              xs={12}
              sm={6}
              md={4}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Card
                sx={[
                  {
                    p: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 4,
                    maxWidth: "450px",
                  },
                  tier.title === tier.title &&
                    subscription?.plan?.product?.plan === tier.title &&
                    ((theme) => ({
                      border: "none",
                      background:
                        "radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))",
                      boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                      ...theme.applyStyles("dark", {
                        background:
                          "radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))",
                        boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                      }),
                    })),
                ]}
              >
                <CardContent>
                  <Box
                    sx={[
                      {
                        mb: 1,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        gap: 2,
                      },
                      tier.title === "Professional"
                        ? { color: "grey.100" }
                        : { color: "" },
                    ]}
                  >
                    <Typography
                      component="h3"
                      variant="h6"
                      sx={{
                        color:
                          tier.title === tier.title &&
                          subscription?.plan?.product?.plan === tier.lookupKey
                            ? "white"
                            : "black",
                      }}
                    >
                      {tier.title}
                    </Typography>
                    {tier.title === "Professional" && (
                      <Chip icon={<AutoAwesomeIcon />} label={tier.subheader} />
                    )}
                  </Box>
                  <Box
                    sx={[
                      {
                        display: "flex",
                        alignItems: "baseline",
                      },
                      tier.title === "Professional"
                        ? { color: "grey.50" }
                        : { color: null },
                    ]}
                  >
                    <Typography
                      component="h3"
                      variant="h4"
                      sx={{
                        color:
                          tier.title === tier.title &&
                          subscription?.plan?.product?.plan === tier.lookupKey
                            ? "white"
                            : "black",
                      }}
                    >
                      ${tier.price}
                    </Typography>
                    <Typography
                      component="h3"
                      variant="h8"
                      sx={{
                        color:
                          tier.title === tier.title &&
                          subscription?.plan?.product?.plan === tier.lookupKey
                            ? "white"
                            : "black",
                      }}
                    >
                      &nbsp; per 12 months
                    </Typography>
                  </Box>
                  <Divider
                    sx={{ my: 2, opacity: 0.8, borderColor: "divider" }}
                  />
                  {tier.description.map((line) => (
                    <Box
                      key={line}
                      sx={{
                        py: 1,
                        display: "flex",
                        gap: 1.5,
                        alignItems: "center",
                      }}
                    >
                      <CheckCircleRoundedIcon
                        sx={[
                          {
                            width: 20,
                          },
                          tier.title === "Professional"
                            ? { color: "primary.light" }
                            : { color: "primary.main" },
                        ]}
                      />
                      <Typography
                        variant="subtitle2"
                        component={"span"}
                        sx={{
                          color:
                            tier.title === tier.title &&
                            subscription?.plan?.product?.plan === tier.lookupKey
                              ? "white"
                              : "black",
                        }}
                      >
                        {line}
                      </Typography>
                    </Box>
                  ))}
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    variant={tier.buttonVariant}
                    color={tier.buttonColor}
                    disabled={
                      tier.title === subscription?.plan?.product?.plan
                        ? true
                        : false
                    }
                    onClick={() => handleClick(tier.id)}
                  >
                    {tier.buttonText}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
      {mode === "development" ? (
        <Container id="pricing" sx={{ maxWidth: "100%", padding: 0 }}>
          <Typography sx={{ textAlign: "center" }}>Dev Plans</Typography>
          <Grid
            container
            spacing={3}
            sx={{
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            {devtiers.map((tier) => (
              <Grid
                item
                key={tier.title}
                xs={12}
                sm={6}
                md={4}
                sx={{ display: "flex", justifyContent: "center" }}
              >
                <Card
                  sx={[
                    {
                      p: 2,
                      display: "flex",
                      flexDirection: "column",
                      gap: 4,
                      maxWidth: "450px",
                    },
                    tier.title === subscription?.plan?.product?.plan &&
                      ((theme) => ({
                        border: "none",
                        background:
                          "radial-gradient(circle at 50% 0%, hsl(220, 20%, 35%), hsl(220, 30%, 6%))",
                        boxShadow: `0 8px 12px hsla(220, 20%, 42%, 0.2)`,
                        ...theme.applyStyles("dark", {
                          background:
                            "radial-gradient(circle at 50% 0%, hsl(220, 20%, 20%), hsl(220, 30%, 16%))",
                          boxShadow: `0 8px 12px hsla(0, 0%, 0%, 0.8)`,
                        }),
                      })),
                  ]}
                >
                  <CardContent>
                    <Box
                      sx={[
                        {
                          mb: 1,
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: 2,
                        },
                        tier.title === "Professional"
                          ? { color: "grey.100" }
                          : { color: "" },
                      ]}
                    >
                      <Typography
                        component="h3"
                        variant="h6"
                        sx={{
                          color:
                            tier.title === tier.title &&
                            subscription?.plan?.product?.plan === tier.lookupKey
                              ? "white"
                              : "black",
                        }}
                      >
                        {tier.title}
                      </Typography>
                      {tier.title === "Professional" && (
                        <Chip
                          icon={<AutoAwesomeIcon />}
                          label={tier.subheader}
                        />
                      )}
                    </Box>
                    <Box
                      sx={[
                        {
                          display: "flex",
                          alignItems: "baseline",
                        },
                        tier.title === "Professional"
                          ? { color: "grey.50" }
                          : { color: null },
                      ]}
                    >
                      <Typography
                        component="h3"
                        variant="h4"
                        sx={{
                          color:
                            tier.title === tier.title &&
                            subscription?.plan?.product?.plan === tier.lookupKey
                              ? "white"
                              : "black",
                        }}
                      >
                        ${tier.price}
                      </Typography>
                      <Typography
                        component="h3"
                        variant="h8"
                        sx={{
                          color:
                            tier.title === tier.title &&
                            subscription?.plan?.product?.plan === tier.lookupKey
                              ? "white"
                              : "black",
                        }}
                      >
                        &nbsp; per 12 months
                      </Typography>
                    </Box>
                    <Divider
                      sx={{ my: 2, opacity: 0.8, borderColor: "divider" }}
                    />
                    {tier.description.map((line) => (
                      <Box
                        key={line}
                        sx={{
                          py: 1,
                          display: "flex",
                          gap: 1.5,
                          alignItems: "center",
                        }}
                      >
                        <CheckCircleRoundedIcon
                          sx={[
                            {
                              width: 20,
                            },
                            tier.title === "Professional"
                              ? { color: "primary.light" }
                              : { color: "primary.main" },
                          ]}
                        />
                        <Typography
                          variant="subtitle2"
                          component={"span"}
                          sx={{
                            color:
                              tier.title === tier.title &&
                              subscription?.plan?.product?.plan ===
                                tier.lookupKey
                                ? "white"
                                : "black",
                          }}
                        >
                          {line}
                        </Typography>
                      </Box>
                    ))}
                  </CardContent>
                  <CardActions>
                    <Button
                      fullWidth
                      variant={tier.buttonVariant}
                      color={tier.buttonColor}
                      disabled={
                        tier.title === subscription?.plan?.product?.plan
                          ? true
                          : false
                      }
                      onClick={() => handleClick(tier.id)}
                    >
                      {tier.buttonText}
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      ) : null}
    </AppTheme>
  );
}

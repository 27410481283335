export const federalIncomeTax = (
  maritalStatus,
  dependents,
  ytdWages,
  currentPaycheck
) => {
  // Define the 2023 US federal income tax brackets for single and married filers
  const taxBrackets = {
    single: [
      { min: 0, max: 11000, rate: 0.1 },
      { min: 11001, max: 44725, rate: 0.12 },
      { min: 44726, max: 95375, rate: 0.22 },
      { min: 95376, max: 182100, rate: 0.24 },
      { min: 182101, max: 231250, rate: 0.32 },
      { min: 231251, max: 578125, rate: 0.35 },
      { min: 578126, max: Infinity, rate: 0.37 },
    ],
    married: [
      { min: 0, max: 22000, rate: 0.1 },
      { min: 22001, max: 89450, rate: 0.12 },
      { min: 89451, max: 190750, rate: 0.22 },
      { min: 190751, max: 364200, rate: 0.24 },
      { min: 364201, max: 462500, rate: 0.32 },
      { min: 462501, max: 693750, rate: 0.35 },
      { min: 693751, max: Infinity, rate: 0.37 },
    ],
  };

  // Select the correct tax bracket based on marital status
  const brackets =
    maritalStatus === "married" ? taxBrackets.married : taxBrackets.single;

  // Standard deduction (2023)
  const standardDeduction = maritalStatus === "married" ? 27700 : 13850;

  // Dependent deduction (Assume $2,000 per dependent)
  const dependentDeduction = dependents * 2000;

  // Taxable income calculation
  const taxableIncome = Math.max(
    ytdWages - standardDeduction - dependentDeduction,
    0
  );

  // Find the applicable tax rate
  let applicableRate = 0.1; // Default to lowest bracket
  for (const bracket of brackets) {
    if (taxableIncome >= bracket.min && taxableIncome <= bracket.max) {
      applicableRate = bracket.rate;
      break;
    }
  }

  // Apply the tax rate to the current paycheck
  const paycheckTax = currentPaycheck * applicableRate;

  return parseFloat(Number(paycheckTax).toFixed(2));
};

export const stateIncomeTax = (
  state,
  maritalStatus,
  dependents,
  ytdWages,
  currentPaycheck
) => {
  // Example: 2023 California state tax brackets
  const stateTaxBrackets = {
    CA: {
      single: [
        { min: 0, max: 9325, rate: 0.01 },
        { min: 9326, max: 22107, rate: 0.02 },
        { min: 22108, max: 34892, rate: 0.04 },
        { min: 34893, max: 48435, rate: 0.06 },
        { min: 48436, max: 61214, rate: 0.08 },
        { min: 61215, max: 312686, rate: 0.093 },
        { min: 312687, max: 375221, rate: 0.103 },
        { min: 375222, max: 625369, rate: 0.113 },
        { min: 625370, max: Infinity, rate: 0.123 },
      ],
      married: [
        { min: 0, max: 18650, rate: 0.01 },
        { min: 18651, max: 44214, rate: 0.02 },
        { min: 44215, max: 69784, rate: 0.04 },
        { min: 69785, max: 96870, rate: 0.06 },
        { min: 96871, max: 122428, rate: 0.08 },
        { min: 122429, max: 625372, rate: 0.093 },
        { min: 625373, max: 750442, rate: 0.103 },
        { min: 750443, max: 1250738, rate: 0.113 },
        { min: 1250739, max: Infinity, rate: 0.123 },
      ],
    },
    // You can add other states here...
  };

  if (!stateTaxBrackets[state]) {
    throw new Error(`State tax brackets for ${state} not available.`);
  }

  // Select the correct state tax brackets
  const brackets =
    maritalStatus === "married"
      ? stateTaxBrackets[state].married
      : stateTaxBrackets[state].single;

  // Standard deduction for California (example)
  const standardDeduction = maritalStatus === "married" ? 9606 : 4803;

  // Dependent deduction (example: $400 per dependent in CA)
  const dependentDeduction = dependents * 400;

  // Calculate taxable income
  const taxableIncome = Math.max(
    ytdWages - standardDeduction - dependentDeduction,
    0
  );

  // Find the applicable state tax rate
  let applicableRate = 0.01; // Default to lowest bracket
  for (const bracket of brackets) {
    if (taxableIncome >= bracket.min && taxableIncome <= bracket.max) {
      applicableRate = bracket.rate;
      break;
    }
  }

  // Apply the tax rate to the current paycheck
  const paycheckTax = currentPaycheck * applicableRate;

  return parseFloat(Number(paycheckTax).toFixed(2));
};

export const calculateSDI = (state, currentPaycheck, ytdWages) => {
  // Example SDI rates & wage caps (2024 estimates, adjust as needed)
  const sdiRates = {
    CA: { rate: 0.009, wageCap: 153164 }, // 0.9% up to $153,164 (2024)
    NJ: { rate: 0.0042, wageCap: 156800 }, // 0.42% up to $156,800
    NY: { rate: 0.005, wageCap: 85000 }, // 0.5% up to $85,000
    RI: { rate: 0.013, wageCap: 84000 }, // 1.3% up to $84,000
    HI: { rate: 0.005, wageCap: 72500 }, // 0.5% up to $72,500
  };

  // Check if state has SDI
  if (!sdiRates[state]) {
    return 0; // No SDI for this state
  }

  const { rate, wageCap } = sdiRates[state];

  // Determine taxable wages remaining under the cap
  const taxableIncome = Math.max(
    0,
    Math.min(currentPaycheck, wageCap - ytdWages)
  );

  // Calculate SDI tax
  const sdiTax = taxableIncome * rate;

  return parseFloat(Number(sdiTax).toFixed(2));
};

export const calculateFICA = (currentPaycheck, ytdWages) => {
  // 2024 FICA tax rates & caps
  const socialSecurityRate = 0.062; // 6.2% Social Security Tax
  const medicareRate = 0.0145; // 1.45% Medicare Tax
  const additionalMedicareRate = 0.009; // 0.9% extra for high earners
  const socialSecurityWageCap = 168600; // Social Security tax cap (2024)
  const additionalMedicareThreshold = 200000; // Extra Medicare for earnings over this

  // Calculate Social Security Tax (only up to the wage cap)
  const taxableSocialSecurity = Math.max(
    0,
    Math.min(currentPaycheck, socialSecurityWageCap - ytdWages)
  );
  const socialSecurityTax = taxableSocialSecurity * socialSecurityRate;

  // Calculate Medicare Tax (applies to all wages)
  const medicareTax = currentPaycheck * medicareRate;

  // Additional Medicare Tax for wages over $200,000
  const additionalMedicareTax =
    ytdWages + currentPaycheck > additionalMedicareThreshold
      ? (ytdWages + currentPaycheck - additionalMedicareThreshold) *
        additionalMedicareRate
      : 0;

  // Total FICA tax
  const totalFICATax = socialSecurityTax + medicareTax + additionalMedicareTax;

  return parseFloat(Number(totalFICATax).toFixed(2));
};

import React, { useState } from "react";
import Pricing from "../components/Settings/Plans";

const PaymentPage2 = () => {
  return (
    <div>
      <Pricing />
      <h1> You will be redirected to Stripe checkout</h1>
    </div>
  );
};

export default PaymentPage2;

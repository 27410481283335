import React, { useEffect, useState } from "react";
import { Box, Typography, Stack } from "@mui/material";
import { ColorModeContext } from "../theme";
import { useContext } from "react";

const AnalyticsBoxHeader = ({ title, subtitle, sidetext, color }) => {
  const { theme } = useContext(ColorModeContext);
  return (
    <Box sx={{ p: 1 }}>
      <Stack direction="row">
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          <Typography color={theme.palette.neutral[500]}> {title} </Typography>
          <Typography color={theme.palette.neutral[300]} variant={"joysmall"}>
            {" "}
            {subtitle}{" "}
          </Typography>
        </Box>
      </Stack>
    </Box>
  );
};

export default AnalyticsBoxHeader;

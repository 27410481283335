import React from "react";
import { Box, Typography, Stack } from "@mui/joy";
import { tokens } from "../theme";
import Select from "@mui/joy/Select";
import Input from "@mui/joy/Input";
import Option from "@mui/joy/Option";
import Button from "@mui/joy/Button";
import FormControl from "@mui/joy/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState, useEffect } from "react";
import Header from "./Header";
import SendIcon from "@mui/icons-material/Send";
import { DateField } from "@mui/x-date-pickers/DateField";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useAuth } from "../context/AuthProvider";
import { CssVarsProvider, extendTheme } from "@mui/joy/styles";
import { extendTheme as extendJoyTheme } from "@mui/joy/styles";
import { ThemeProvider, createTheme } from "@mui/material/styles";

import {
  Experimental_CssVarsProvider as MaterialCssVarsProvider,
  experimental_extendTheme as extendMaterialTheme,
  THEME_ID,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import Chip from "@mui/joy/Chip";
import FavoriteBorder from "@mui/icons-material/FavoriteBorder";

const joyTheme = extendJoyTheme({
  colorSchemes: {
    light: {
      palette: {
        primary: {
          main: "#007FFF",
        },
        neutral: {
          plainColor: "#000",
          main: "#666",
        },
      },
    },
  },
});

const muiTheme = createTheme({
  palette: {
    primary: {
      main: "#007FFF",
    },
  },
});

const details = {
  craft: null,
  classification: null,
  book: "",
  issuedate: null,
  expirationDate: null,
  training: "",
  hours: 8,
  basicRate: "",
  regRate: "",
  OTRate: "",
  satRate: "",
  sunRate: "",
  dateInc1: null,
  increase1: "",
  dateInc2: null,
  increase2: "",
  dateInc3: null,
  increase3: "",
  dateInc4: null,
  increase4: "",
  dateInc5: null,
  increase5: "",
  custom: true,
  healthAndWelfare: "",
  pension: "",
  vacationAndHoliday: "",
  otherPayments: "",
  personnelId: null,
};

const typesList = ["By Role", "Custom"];

const AddBookSchema = Yup.object().shape({
  craft: Yup.string().required("Required"),
  classification: Yup.string().required("Required"),
  book: Yup.string().required("Required"),
  issuedate: Yup.date().required("Required"),
  expirationDate: Yup.date().required("Required"),
  training: Yup.number().required("Required"),
  hours: Yup.number(),
  basicRate: Yup.number().required("Required"),
  regRate: Yup.number().required("Required"),
  OTRate: Yup.number().required("Required"),
  satRate: Yup.number().required("Required"),
  sunRate: Yup.number().required("Required"),
  dateInc1: Yup.date().nullable(true),
  increase1: Yup.number().nullable(true),
  dateInc2: Yup.date().nullable(true),
  increase2: Yup.number().nullable(true),
  dateInc3: Yup.date().nullable(true),
  increase3: Yup.number().nullable(true),
  dateInc4: Yup.date().nullable(true),
  increase4: Yup.number().nullable(true),
  dateInc5: Yup.date().nullable(true),
  increase5: Yup.number().nullable(true),
  custom: Yup.bool(),
  healthAndWelfare: Yup.number().nullable(true),
  pension: Yup.number().nullable(true),
  vacationAndHoliday: Yup.number().nullable(true),
  otherPayments: Yup.number().nullable(true),
  personnelId: Yup.string().required("Required"),
});

const AddBookFormMui = ({ listData, setLocalPayrollRates, personnelId }) => {
  console.log(personnelId);
  const { api } = useAuth();
  const materialTheme = extendMaterialTheme();
  const [isCustom, setIsCustom] = useState(false);
  const [sent, setSent] = useState(false);
  const [statusMessage, setStatusMessage] = useState(null);
  const [bookDetails, setBookDetails] = useState(details);
  const [validationSchema, setValidationSchema] = useState(AddBookSchema);
  const handleInputNumberChange = (field) => (e) => {
    setSent(false);
    const value = e.target.value;
    formik.setFieldValue(field, value ? Number(value) : "");
  };
  const formik = useFormik({
    initialValues: bookDetails,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      console.log(values, "values");
      createRatebook(values);
    },
  });

  useEffect(() => {
    if (bookDetails) {
      setBookDetails({ ...bookDetails, personnelId });
    }
  }, [personnelId]);

  // Mapping Create Stuff
  const createRatebook = async (details) => {
    console.log("clicked");
    api
      .post("/api/v1/ratebook", JSON.stringify(details))
      .then((response) => {
        // const updatedPayroll = [...allTimecards, response.data.data];
        // setBookDetails(updatedPayroll);
        setStatusMessage(`RateBook ${response.data.data.name} Created`);
        //set rate local
        console.log(response);
        const item = response.data.data;

        setLocalPayrollRates((prevRates) => [...prevRates, item]);
        setSent(true);
      })
      .catch((error) => {
        console.log(error);
        setSent(true);
        setStatusMessage("An Error Occurred!");

        setLocalPayrollRates((prev) => [...prev]);
      });
  };

  return (
    <MaterialCssVarsProvider theme={{ [THEME_ID]: materialTheme }}>
      <CssVarsProvider theme={joyTheme}>
        <CssBaseline />
        <ThemeProvider theme={muiTheme}>
          <Box sx={{ bgcolor: "white", borderRadius: 2 }}>
            <Header title={"Create A New Ratebook"} subtitle={"Enter Below"} />
            <Typography variant="h6" sx={{ mt: 2, color: "green" }}>
              {" "}
              Required
            </Typography>
            <form onSubmit={formik.handleSubmit}>
              <FormControl sx={{ width: "100%" }}>
                {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
                <Input
                  id="book"
                  placeholder="Enter Name For Rate Book"
                  name="book"
                  variant="outlined"
                  slotProps={{
                    style: {
                      padding: 8,
                    },
                  }}
                  onChange={(e) => {
                    setSent(false);
                    formik.setFieldValue("book", e.target.value);
                  }}
                  value={formik.values.book}
                  error={formik.touched.book && Boolean(formik.errors.book)}
                />
              </FormControl>
              {isCustom ? null : (
                <Select
                  labelId="tradeLabel"
                  variant="outlined"
                  placeholder="Select From Personnel List"
                  slotProps={{
                    listbox: {
                      variant: "outlined",
                    },
                  }}
                  id="craft"
                  name="craft"
                  value={formik.values.craft}
                  error={formik.touched.craft && Boolean(formik.errors.craft)}
                  helperText={formik.touched.craft && formik.errors.craft}
                  onChange={(e, newValue) => {
                    setSent(false);
                    formik.setFieldValue("craft", newValue);
                  }}
                >
                  {listData.craft.data.data.map((name, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}

              {isCustom ? null : (
                <Select
                  labelId="classificationLabel"
                  id="classification"
                  placeholder="Classification"
                  name="classification"
                  value={formik.values.classification}
                  error={
                    formik.touched.classification &&
                    Boolean(formik.errors.classification)
                  }
                  helperText={
                    formik.touched.classification &&
                    formik.errors.classification
                  }
                  onChange={(e, newValue) => {
                    setSent(false);
                    formik.setFieldValue("classification", newValue);
                  }}
                >
                  {listData.classification.data.data.map((name, index) => (
                    <Option key={index} value={name}>
                      {name}
                    </Option>
                  ))}
                </Select>
              )}
              <Stack direction="row">
                <FormControl sx={{ width: "50%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label="Issue Date"
                      id="issuedate"
                      name="issuedate"
                      slotProps={{
                        textField: {
                          size: "small", // Size customization
                          variant: "outlined", // Ensure consistent style
                        },
                      }}
                      value={formik.values.issuedate}
                      onChange={(newValue) => {
                        setSent(false);
                        formik.setFieldValue("issuedate", newValue);
                      }}
                      format="MM-DD-YYYY"
                      error={
                        formik.touched.issuedate &&
                        Boolean(formik.errors.issuedate)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: "50%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      id="expirationDate"
                      name="expirationDate"
                      label="Expiration Date"
                      size="small"
                      slotProps={{
                        textField: { size: "small" },
                      }}
                      // onChange={(value) =>
                      //   updateBookDetails({
                      //     expirationDate: value,
                      //   })
                      // }
                      value={formik.values.expirationDate}
                      onChange={(newValue) => {
                        setSent(false);
                        formik.setFieldValue("expirationDate", newValue);
                      }}
                      format="MM-DD-YYYY"
                      error={
                        formik.touched.expirationDate &&
                        Boolean(formik.errors.expirationDate)
                      }
                    />
                  </LocalizationProvider>
                </FormControl>
              </Stack>
              <Stack direction="row">
                <FormControl sx={{ width: "33.33%" }}>
                  <Input
                    id="training"
                    placeholder="Training Rate"
                    type="number"
                    name="training"
                    variant="outlined"
                    slotProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    // onChange={(e) => updateBookDetails({ training: e.target.value })}
                    value={formik.values.training}
                    error={
                      formik.touched.training && Boolean(formik.errors.training)
                    }
                    onChange={handleInputNumberChange("training")}
                  />
                </FormControl>
                <FormControl sx={{ width: "33.33%" }}>
                  <Input
                    id="basicRate"
                    placeholder="Basic Rate"
                    type="number"
                    name="basicRate"
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.basicRate}
                    error={
                      formik.touched.basicRate &&
                      Boolean(formik.errors.basicRate)
                    }
                    onChange={handleInputNumberChange("basicRate")}
                  />
                </FormControl>
                <FormControl sx={{ width: "33.33%" }}>
                  <Input
                    id="regRate"
                    placeholder="Regular Rate"
                    type="number"
                    variant="outlined"
                    name="regRate"
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.regRate}
                    error={
                      formik.touched.regRate && Boolean(formik.errors.regRate)
                    }
                    onChange={handleInputNumberChange("regRate")}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row">
                <FormControl sx={{ width: "33.33%" }}>
                  <Input
                    id="OTRate"
                    placeholder="OT Rate"
                    type="number"
                    variant="outlined"
                    name="OTRate"
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.OTRate}
                    error={
                      formik.touched.OTRate && Boolean(formik.errors.OTRate)
                    }
                    onChange={handleInputNumberChange("OTRate")}
                  />
                </FormControl>
                <FormControl sx={{ width: "33.33%" }}>
                  <Input
                    id="satRate"
                    placeholder="Saturday Rate"
                    type="number"
                    name="satRate"
                    variant="outlined"
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.satRate}
                    error={
                      formik.touched.satRate && Boolean(formik.errors.satRate)
                    }
                    onChange={handleInputNumberChange("satRate")}
                  />
                </FormControl>
                <FormControl sx={{ width: "33.33%" }}>
                  <Input
                    id="sunRate"
                    placeholder="Sunday Rate"
                    type="number"
                    variant="outlined"
                    name="sunRate"
                    inputProps={{
                      style: {
                        padding: 9,
                      },
                    }}
                    value={formik.values.sunRate}
                    error={
                      formik.touched.sunRate && Boolean(formik.errors.sunRate)
                    }
                    onChange={handleInputNumberChange("sunRate")}
                  />
                </FormControl>
              </Stack>
              <Typography variant="h6" sx={{ mt: 2, color: "green" }}>
                {" "}
                Optional Predetermined Increases
              </Typography>
              <Stack direction="row">
                <FormControl sx={{ width: "30%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label="Date 1"
                      slotProps={{
                        textField: { size: "small" },
                      }}
                      name="dateInc1"
                      value={formik.values.dateInc1}
                      onChange={(newValue) => {
                        setSent(false);
                        formik.setFieldValue("dateInc1", newValue);
                      }}
                      format="MM-DD-YYYY"
                      error={Boolean(formik.errors.dateInc1)}
                    ></DateField>
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: "20%" }}>
                  <Input
                    id="increase1"
                    placeholder="Rate 1"
                    type="number"
                    sx={{
                      "--Input-minHeight": "40px",
                    }}
                    variant="outlined"
                    name="increase1"
                    value={formik.values.increase1}
                    onChange={handleInputNumberChange("increase1")}
                    format="MM-DD-YYYY"
                    error={Boolean(formik.errors.increase1)}
                  />
                </FormControl>
                <FormControl sx={{ width: "30%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label="Date 2"
                      slotProps={{
                        textField: { size: "small" },
                      }}
                      name="dateInc2"
                      value={formik.values.dateInc2}
                      onChange={(newValue) => {
                        setSent(false);
                        formik.setFieldValue("dateInc2", newValue);
                      }}
                      format="MM-DD-YYYY"
                      error={Boolean(formik.errors.dateInc2)}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: "20%" }}>
                  <Input
                    id="increase2"
                    placeholder="Rate 2"
                    type="number"
                    variant="outlined"
                    sx={{
                      "--Input-minHeight": "40px",
                    }}
                    name="increase2"
                    value={formik.values.increase2}
                    onChange={handleInputNumberChange("increase2")}
                    format="MM-DD-YYYY"
                    error={Boolean(formik.errors.increase2)}
                  />
                </FormControl>
              </Stack>

              <Stack direction="row">
                <FormControl sx={{ width: "30%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label="Date 3"
                      name="dateInc3"
                      value={formik.values.dateInc3}
                      onChange={(newValue) => {
                        setSent(false);
                        formik.setFieldValue("dateInc3", newValue);
                      }}
                      slotProps={{
                        textField: { size: "small" },
                      }}
                      error={false}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: "20%" }}>
                  <Input
                    id="increase3"
                    placeholder="Rate 3"
                    type="number"
                    variant="outlined"
                    name="increase3"
                    sx={{
                      "--Input-minHeight": "40px",
                    }}
                    value={formik.values.increase3}
                    onChange={handleInputNumberChange("increase3")}
                  />
                </FormControl>
                <FormControl sx={{ width: "30%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label="Date 4"
                      slotProps={{
                        textField: { size: "small" },
                      }}
                      error={false}
                      name="dateInc4"
                      value={formik.values.dateInc4}
                      onChange={(newValue) => {
                        setSent(false);
                        formik.setFieldValue("dateInc4", newValue);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: "20%" }}>
                  <Input
                    id="outlined-basic"
                    placeholder="Rate 4"
                    type="number"
                    variant="outlined"
                    sx={{
                      "--Input-minHeight": "40px",
                    }}
                    name="increase4"
                    value={formik.values.increase4}
                    onChange={handleInputNumberChange("increase4")}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row">
                <FormControl sx={{ width: "30%" }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateField
                      label="Date 5"
                      slotProps={{
                        textField: { size: "small" },
                      }}
                      error={false}
                      name="dateInc5"
                      value={formik.values.dateInc5}
                      onChange={(newValue) => {
                        setSent(false);
                        formik.setFieldValue("dateInc5", newValue);
                      }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ width: "20%" }}>
                  <Input
                    id="increase5"
                    placeholder="Rate 5"
                    type="number"
                    variant="outlined"
                    sx={{
                      "--Input-minHeight": "40px",
                    }}
                    name="increase5"
                    value={formik.values.increase5}
                    onChange={handleInputNumberChange("increase5")}
                  />
                </FormControl>
                <FormControl sx={{ width: "25%" }}>
                  <Input
                    id="healthAndWelfare"
                    placeholder="Health & Welfare"
                    type="number"
                    variant="outlined"
                    sx={{
                      "--Input-minHeight": "40px",
                    }}
                    name="healthAndWelfare"
                    value={formik.values.healthAndWelfare}
                    onChange={handleInputNumberChange("healthAndWelfare")}
                  />
                </FormControl>
                <FormControl sx={{ width: "25%" }}>
                  <Input
                    id="vacationAndHoliday"
                    placeholder="Vacation"
                    type="number"
                    variant="outlined"
                    sx={{
                      "--Input-minHeight": "40px",
                    }}
                    name="vacationAndHoliday"
                    value={formik.values.vacationAndHoliday}
                    onChange={handleInputNumberChange("vacationAndHoliday")}
                  />
                </FormControl>
              </Stack>
              <Stack direction="row">
                <FormControl sx={{ width: "25%" }}>
                  <Input
                    id="otherPayments"
                    placeholder="Other"
                    type="number"
                    variant="outlined"
                    sx={{
                      "--Input-minHeight": "40px",
                    }}
                    name="otherPayments"
                    value={formik.values.otherPayments}
                    onChange={handleInputNumberChange("otherPayments")}
                  />
                </FormControl>
                <FormControl sx={{ width: "25%" }}>
                  <Input
                    id="pension"
                    placeholder="Pension"
                    type="number"
                    variant="outlined"
                    sx={{
                      "--Input-minHeight": "40px",
                    }}
                    name="pension"
                    value={formik.values.pension}
                    onChange={handleInputNumberChange("pension")}
                  />
                </FormControl>
              </Stack>
              <Box textAlign="center">
                <Typography variant="h6" sx={{ mt: 2, color: "green" }}>
                  {statusMessage}
                </Typography>
                <Button
                  variant="contained"
                  sx={{ backgroundColor: "primary", m: 1 }}
                  onClick={formik.handleSubmit}
                  endIcon={<SendIcon />}
                >
                  Send
                </Button>
              </Box>
            </form>
          </Box>
        </ThemeProvider>
      </CssVarsProvider>
    </MaterialCssVarsProvider>
  );
};

export default AddBookFormMui;

import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import ChangeOrders from "../changeorders";
import { Box } from "@mui/material";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";

function AdminPageChangeOrders() {
  const { api } = useAuth();
  const [changeOrders, setchangeOrders] = useState([]);
  const [selectedDataID, setselectedDataID] = useState([]);
  const [project, setProject] = useState("All Projects");
  const [projectList, setProjectList] = useState([]);
  const [errState, setErrState] = useState(false);
  const [errMsg, seterrMsg] = useState("");
  const [listData, setListData] = useState([]);

  function checkFailed(then) {
    return function (responses) {
      const someFailed = responses.some((response) => response.error);

      if (someFailed) {
        throw responses;
      }

      return then(responses);
    };
  }

  useEffect(() => {
    if (api.authenticated) {
      async function GetCO() {
        await api
          .get(`/api/v1/changeorder/?page=1&limit=20&project=${project}`)
          .catch(() => console.log("Error"))
          .then((data) => {
            // console.log(data);
            // if (data?.data[0]?.results) {
            //   setchangeOrders(data.data[0].results);
            // }
          });
      }
      GetCO();
    }
  }, [api, project]);

  useEffect(() => {
    if (api.authenticated) {
      async function GetProjectList(data) {
        await api
          .get(`/api/v1/projects/list`)
          .catch((err) => {
            console.log("Error", err);
            setErrState(true);
            seterrMsg("Error Loading Data");
          })
          .then((response) => {
            setErrState(false);
            seterrMsg("");
            console.log(response);
            if (response?.data?.data) {
              setProjectList(response.data.data);
              setProject(response.data.data[0]);
            }
          });
      }
      GetProjectList();
    }
  }, [api]);

  useEffect(() => {
    if (api.authenticated) {
      Promise.all([
        api.get("/api/v1/projects/list"),
        api.get("/api/v1/personnel/?sort=fullName"),
      ])
        .then(
          checkFailed(([projects, personnel]) => {
            setListData((prev) => ({
              ...prev,
              projects,
              personnel,
            }));
          })
        )
        .catch((err) => {
          console.log("FAIL", err);
          setErrState(true);
          seterrMsg("Error Loading Data");
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api?.authenticated]);

  return (
    <Box sx={{ height: "100dvh" }}>
      {errState ? (
        <Alert
          icon={<RunningWithErrorsIcon fontSize="inherit" />}
          severity="error"
        >
          {errMsg}
        </Alert>
      ) : null}
      <ChangeOrders
        selectedDataID={selectedDataID}
        setselectedDataID={setselectedDataID}
        data={changeOrders}
        setchangeOrders={setchangeOrders}
        project={project}
        setProject={setProject}
        projectList={projectList}
        listData={listData}
      />
    </Box>
  );
}

export default AdminPageChangeOrders;

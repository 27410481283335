import React from "react";
import TextField from "@mui/material/TextField";
import {
  Box,
  Typography,
  useTheme,
  Stack,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { tokens } from "../theme";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState, useEffect } from "react";
import { useAuth } from "../context/AuthProvider";
import Header from "../components/Header";
import SendIcon from "@mui/icons-material/Send";
import { useFormik } from "formik";
import * as Yup from "yup";
import { DateField } from "@mui/x-date-pickers/DateField";
import { states } from "../assets/states";
import { cities } from "../assets/cities";
import { counties } from "../assets/counties";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ClearIcon from "@mui/icons-material/Clear";
import { useNavigate } from "react-router-dom";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

const details = {
  project: "",
  city: "",
  county: "",
  state: "California",
  projectcode: "",
  projectmanager: {},
  biddate: null,
  book: "",
  address: "",
  start: null,
  end: null,
  id: "1",
};

const AddProjectSchema = Yup.object().shape({
  project: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  county: Yup.string().required("Required"),
  projectcode: Yup.number().integer().required("Required"),
  biddate: Yup.date().nullable(true).required("Required"),
  address: Yup.string().required("Required"),
  start: Yup.date().nullable(true).required("Required"),
  end: Yup.date().nullable(true).required("Required"),
  id: Yup.number().integer().required("Required"),
});

const AddProjectForm = ({ open, handleCloseBook, setAllProjects }) => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const { api } = useAuth();
  const [projectDetails] = useState(details);
  const [statusMessage, setStatusMessage] = useState(null);
  const [isErr, setIsErr] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [bookList, setBookList] = useState([]);

  //Form Styles
  const formStyles = {
    borderRadius: 1,
    "& .MuiSelect-select": {
      paddingY: 1,
      paddingX: 2,
      backgroundColor: theme.palette.neutral[50],
      color: theme.palette.primary[300],
    },
    backgroundColor: theme.palette.neutral[100],
    "&:hover": {
      border: ".1px solid",
      borderRadius: 1,
      borderColor: theme.palette.neutral[500],
    },
    "& .MuiSelect-icon": {
      color: theme.palette.primary[400], // 🎯 arrow color
      right: 10, // adjust arrow spacing
      top: "calc(50% - 0.5em)", // vertically center
    },

    border: `1px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.neutral[600] // Dark mode border
        : theme.palette.neutral[400] // Light mode border
    }`,
    borderRadius: 1,
    color: theme.palette.primary[600],
  };
  const formik = useFormik({
    initialValues: projectDetails,
    validationSchema: AddProjectSchema,
    onSubmit: async (values, { resetForm }) => {
      await createProject(values);
      resetForm(); // Reset form fields after submission
    },
  });

  // Function to create a new project
  const createProject = async (details) => {
    setIsErr(false);
    setIsSent(false);
    try {
      // Send the request to create a new project
      const response = await api.post(
        "/api/v1/projects",
        JSON.stringify(details)
      );

      console.log(response);
      // Extract the project returned from the API (which includes the generated _id)
      const newProject = response.data.data;
      //assign id prop
      newProject.id = newProject._id;
      // Ensure the new project has an _id before adding it to state
      if (!newProject._id) {
        throw new Error("New project did not receive an ID from the server");
      }

      // Update state with the new project, now containing the backend ID
      setAllProjects((prevProjects) => [...prevProjects, newProject]);

      setStatusMessage("Success!");
      setIsSent(true);
    } catch (error) {
      console.error("Error creating project:", error);
      setStatusMessage("Error Occurred");
      setIsErr(true);
    }
  };

  useEffect(() => {
    api
      .get("/api/v1/ratebook/booklist")
      .then(({ data }) => {
        setBookList(data.data);
        //setIsLoading(false);
      })
      .catch(() => {
        //setIsLoading(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      <Header title={"Create A New Project"} subtitle={"Enter Below"} />
      <Typography
        variant="h7"
        sx={{ mt: 2, color: theme.palette.neutral[500] }}
      >
        {" "}
        Required
      </Typography>
      <form onSubmit={formik.handleSubmit}>
        <FormControl sx={{ width: "99%" }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            id="outlined-basic"
            label="Enter Project Name"
            name="project"
            variant="outlined"
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            sx={{
              // Input text color
              "& .MuiInputBase-input": {
                color: theme.palette.neutral[500],
                backgroundColor: theme.palette.neutral[100],
                borderRadius: "4px",
              },
              // Label color
              "& label": {
                color: theme.palette.neutral[500],
              },
              // Label when focused
              "& label.Mui-focused": {
                color: theme.palette.primary[400],
              },
              // Outline border
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: theme.palette.neutral[500],
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.primary[300],
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary[300],
                },
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.project}
            error={formik.touched.project && Boolean(formik.errors.project)}
            helperText={formik.touched.project && formik.errors.project}
          />
        </FormControl>
        <FormControl sx={{ width: "99%" }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            id="outlined-basic"
            label="Project Address"
            name="address"
            variant="outlined"
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            sx={{
              // Input text color
              "& .MuiInputBase-input": {
                color: theme.palette.neutral[500],
                backgroundColor: theme.palette.neutral[100],
                borderRadius: "4px",
              },
              // Label color
              "& label": {
                color: theme.palette.neutral[500],
              },
              // Label when focused
              "& label.Mui-focused": {
                color: theme.palette.primary[400],
              },
              // Outline border
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: theme.palette.neutral[500],
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.primary[300],
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary[300],
                },
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.address}
            error={formik.touched.address && Boolean(formik.errors.address)}
            helperText={formik.touched.address && formik.errors.address}
          />
        </FormControl>

        <FormControl sx={{ width: "33%" }}>
          <InputLabel id="cityLabel" sx={{ color: theme.palette.neutral[400] }}>
            City
          </InputLabel>
          <Select
            labelId="cityLabel"
            size="small"
            id="city"
            value={formik.values.city}
            onChange={(e) => {
              formik.setFieldValue("city", e.target.value);
            }}
            sx={formStyles}
            error={formik.touched.city && Boolean(formik.errors.city)}
            helperText={formik.touched.city && formik.errors.city}
          >
            {cities.map((city, index) => (
              <MenuItem
                key={index}
                value={city}
                sx={{ color: theme.palette.neutral[400] }}
              >
                {city}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "33%" }}>
          <InputLabel
            id="countyLabel"
            sx={{ color: theme.palette.neutral[400] }}
          >
            County
          </InputLabel>
          <Select
            labelId="countyLabel"
            size="small"
            id="county"
            value={formik.values.county}
            onChange={(e) => {
              formik.setFieldValue("county", e.target.value);
            }}
            sx={formStyles}
            format="MM-DD-YYYY"
            error={formik.touched.county && Boolean(formik.errors.county)}
          >
            {counties.map((county, index) =>
              county.state === "CA" ? (
                <MenuItem
                  key={index}
                  value={county.county}
                  sx={{ color: theme.palette.neutral[400] }}
                >
                  {county.county}
                </MenuItem>
              ) : null
            )}
          </Select>
        </FormControl>

        <FormControl sx={{ width: "33%" }}>
          <InputLabel
            id="stateLabel"
            sx={{ color: theme.palette.neutral[400] }}
          >
            State
          </InputLabel>
          <Select
            labelId="stateLabel"
            size="small"
            id="state"
            value={formik.values.state}
            onChange={(e) => {
              formik.setFieldValue("state", e.target.value);
            }}
            sx={formStyles}
            format="MM-DD-YYYY"
            error={formik.touched.state && Boolean(formik.errors.state)}
          >
            {states.map((state, index) => (
              <MenuItem
                key={index}
                value={state.name}
                sx={{ color: theme.palette.neutral[400] }}
              >
                {state.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl sx={{ width: "49.5%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label="Project Start"
              name="start"
              value={formik.values.start}
              onChange={(newValue) => {
                formik.setFieldValue("start", newValue);
              }}
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  helperText: formik.touched.biddate && formik.errors.biddate,
                  sx: {
                    backgroundColor: theme.palette.neutral[100],
                    borderRadius: 1,
                    "& .MuiInputBase-input": {
                      paddingY: 1.2,
                      paddingX: 2,
                      fontSize: "0.75rem",
                      color: theme.palette.neutral[500],
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.palette.primary[400],
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary[300],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.primary[400],
                      },
                    },
                    "& label": {
                      color: theme.palette.neutral[500],
                    },
                    "& label.Mui-focused": {
                      color: theme.palette.primary[400],
                    },
                  },
                },
              }}
              format="MM-DD-YYYY"
              error={formik.touched.start && Boolean(formik.errors.start)}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ width: "49.5%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label="Project End"
              name="end"
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  helperText: formik.touched.biddate && formik.errors.biddate,
                  sx: {
                    backgroundColor: theme.palette.neutral[100],
                    borderRadius: 1,
                    "& .MuiInputBase-input": {
                      paddingY: 1.2,
                      paddingX: 2,
                      fontSize: "0.75rem",
                      color: theme.palette.neutral[500],
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.palette.primary[400],
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary[300],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.primary[400],
                      },
                    },
                    "& label": {
                      color: theme.palette.neutral[500],
                    },
                    "& label.Mui-focused": {
                      color: theme.palette.primary[400],
                    },
                  },
                },
              }}
              value={formik.values.end}
              onChange={(newValue) => {
                formik.setFieldValue("end", newValue);
              }}
              format="MM-DD-YYYY"
              error={formik.touched.end && Boolean(formik.errors.end)}
            />
          </LocalizationProvider>
        </FormControl>
        <FormControl sx={{ width: "49.5%" }}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DateField
              label="Bid Date"
              name="biddate"
              slotProps={{
                textField: {
                  size: "small",
                  fullWidth: true,
                  helperText: formik.touched.biddate && formik.errors.biddate,
                  sx: {
                    backgroundColor: theme.palette.neutral[100],
                    borderRadius: 1,
                    "& .MuiInputBase-input": {
                      paddingY: 1.2,
                      paddingX: 2,
                      fontSize: "0.75rem",
                      color: theme.palette.neutral[500],
                    },
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: theme.palette.primary[400],
                      },
                      "&:hover fieldset": {
                        borderColor: theme.palette.primary[300],
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: theme.palette.primary[400],
                      },
                    },
                    "& label": {
                      color: theme.palette.neutral[500],
                    },
                    "& label.Mui-focused": {
                      color: theme.palette.primary[400],
                    },
                  },
                },
              }}
              value={formik.values.biddate}
              onChange={(newValue) => {
                formik.setFieldValue("biddate", newValue);
              }}
              format="MM-DD-YYYY"
              error={formik.touched.biddate && Boolean(formik.errors.biddate)}
            />
          </LocalizationProvider>
        </FormControl>

        <FormControl sx={{ width: "49.5%" }}>
          {/* <InputLabel id='mappingTypeLabel'>Rate Book Name</InputLabel> */}
          <TextField
            id="outlined-basic"
            label="Enter Project Code"
            name="projectcode"
            variant="outlined"
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            sx={{
              // Input text color
              "& .MuiInputBase-input": {
                color: theme.palette.neutral[500],
                backgroundColor: theme.palette.neutral[100],
                borderRadius: "4px",
              },
              // Label color
              "& label": {
                color: theme.palette.neutral[500],
              },
              // Label when focused
              "& label.Mui-focused": {
                color: theme.palette.primary[400],
              },
              // Outline border
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: theme.palette.neutral[500],
                },
                "&:hover fieldset": {
                  borderColor: theme.palette.primary[300],
                },
                "&.Mui-focused fieldset": {
                  borderColor: theme.palette.primary[300],
                },
              },
            }}
            onChange={formik.handleChange}
            value={formik.values.projectcode}
            error={
              formik.touched.projectcode && Boolean(formik.errors.projectcode)
            }
            helperText={formik.touched.projectcode && formik.errors.projectcode}
          />
        </FormControl>

        <FormControl sx={{ width: "50%" }}>
          <InputLabel id="bookLabel" sx={{ color: theme.palette.primary[300] }}>
            Book
          </InputLabel>
          <Select
            labelId="bookLabel"
            size="small"
            id="book"
            value={formik.values.book}
            onChange={(e) => {
              formik.setFieldValue("book", e.target.value);
            }}
            sx={formStyles}
            error={formik.touched.book && Boolean(formik.errors.book)}
            helperText={formik.touched.book && formik.errors.book}
          >
            {bookList.map((book, index) => (
              <MenuItem
                key={index}
                value={book}
                sx={{ color: theme.palette.neutral[400], width: "150px" }}
              >
                {book}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </form>
      <Box sx={{ margin: "auto", width: "25%", mt: "10px" }}>
        <Button
          variant="contained"
          sx={{ backgroundColor: theme.palette.primary[400], m: 1 }}
          onClick={formik.handleSubmit}
          endIcon={<SendIcon />}
        >
          Send
        </Button>
        {isSent ? (
          <Box>
            <Stack direction="row">
              <DoneOutlineIcon sx={{ color: theme.palette.primary[400] }} />
              <Typography> {statusMessage} </Typography>
            </Stack>
          </Box>
        ) : null}
        {isErr ? (
          <Stack direction="row" textAlign="center">
            <ClearIcon sx={{ color: theme.palette.primary[300] }} />
            <Typography> {statusMessage} </Typography>
          </Stack>
        ) : null}
      </Box>
    </Box>
  );
};

export default AddProjectForm;

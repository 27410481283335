import { useState, useEffect } from "react";
import PayrollCreateV3Body from "./PayrollCreateV3Body";
import dayjs from "dayjs";
import { useAuth } from "../context/AuthProvider";
import { Box } from "@mui/material";

const weekEndingString = dayjs().day(-1).hour(0).minute(0).format("MM/DD/YYYY");

const initialDetails = {
  //required
  name: "Data, Test_12-17-2024",
  firstName: "",
  lastName: "",
  fullName: "",
  employeeID: 22,
  role: "Test",
  classification: "Test",
  weekNumber: dayjs().day(-1).week(),
  month: dayjs().day(-1).month(),
  year: dayjs().day(-1).year(),
  weekEnding: dayjs().day(-1).hour(0),
  //all unrequired
  sunp1start: null,
  sunp1end: null,
  monp1start: dayjs().day(-6).hour(7).minute(0),
  monp1end: dayjs().day(-6).hour(15).minute(30),
  tuep1start: dayjs().day(-5).hour(7).minute(0),
  tuep1end: dayjs().day(-5).hour(15).minute(30),
  wedp1start: dayjs().day(-4).hour(7).minute(0),
  wedp1end: dayjs().day(-4).hour(15).minute(30),
  thurp1start: dayjs().day(-3).hour(7).minute(0),
  thurp1end: dayjs().day(-3).hour(15).minute(30),
  frip1start: dayjs().day(-2).hour(7).minute(0),
  frip1end: dayjs().day(-2).hour(15).minute(30),
  satp1start: null,
  satp1end: null,
  sunp2start: null,
  sunp2end: null,
  monp2start: null,
  monp2end: null,
  tuep2start: null,
  tuep2end: null,
  wedp2start: null,
  wedp2end: null,
  thurp2start: null,
  thurp2end: null,
  frip2start: null,
  frip2end: null,
  satp2start: null,
  satp2end: null,
  sunp3start: null,
  sunp3end: null,
  monp3start: null,
  monp3end: null,
  tuep3start: null,
  tuep3end: null,
  wedp3start: null,
  wedp3end: null,
  thurp3start: null,
  thurp3end: null,
  frip3start: null,
  frip3end: null,
  satp3start: null,
  satp3end: null,
  breaksun: 0,
  breakmon: 0.5,
  breaktue: 0.5,
  breakwed: 0.5,
  breakthur: 0.5,
  breakfri: 0.5,
  breaksat: 0,
  breakHourApplied: 5,
  overtimeHourApplied: 8.5,
  weekEndingString: weekEndingString,
  project1: "",
  project2: "",
  project3: "",
  calculations: {},
  personnelId: null,
};

const PayrollCreateV3 = ({ listData, prevTimecards, isCollapsed }) => {
  const [weekEnding, setWeekEnding] = useState(
    dayjs(weekEndingString).hour(0).minute(0).format("MM/DD/YYYY")
  );
  const { user } = useAuth();
  const [details, setDetails] = useState({});

  useEffect(() => {
    setDetails(initialDetails);
  }, []);

  return (
    <Box>
      <PayrollCreateV3Body
        initialDetails={details}
        setDetails={setDetails}
        listData={listData}
        weekEnding={weekEnding}
        setWeekEnding={setWeekEnding}
        prevTimecards={prevTimecards}
        user={user}
      />
    </Box>
  );
};

export default PayrollCreateV3;

import React, { useRef, useEffect, useState } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Typography from "@mui/joy/Typography";
import AccordionGroup from "@mui/joy/AccordionGroup";
import Accordion from "@mui/joy/Accordion";
import AccordionDetails from "@mui/joy/AccordionDetails";
import AccordionSummary from "@mui/joy/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";
import { CssVarsProvider } from "@mui/joy/styles";
import { useAuth } from "../context/AuthProvider";

const alphabet = [..."ABCDEFGHIJKLMNOPQRSTUVWXYZ"];

const sampleData = {
  A: ["Alice", "Aaron"],
  B: ["Bob", "Bella"],
  C: ["Charlie", "Catherine"],
  D: ["David"],
  // Add more data...
};
const sampleData2 = {
  A: [
    { trade: "Apple Layer", classification: ["Brick Layer", "Brick Tender"] },
    { trade: "Aaron", classification: ["Fun"] },
  ],
  B: [
    { trade: "Brick Layer", classification: ["Brick Layer", "Brick Tender"] },
    { trade: "Barry", classification: ["Fun"] },
  ],
  C: [
    { trade: "Charism", classification: ["Chart", "Chart Tender"] },
    { trade: "Chem", classification: ["Carry"] },
  ],
  // Add more data...
};

export default function Directory() {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const sectionRefs = useRef({});
  const [expanded, setExpanded] = React.useState([]);
  const { api } = useAuth();
  const [directory, setDirectory] = useState({});

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(
      isExpanded
        ? [...expanded, panel]
        : expanded.filter((item) => item !== panel)
    );
  };

  const scrollToLetter = (letter) => {
    const section = sectionRefs.current[letter];
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  useEffect(() => {
    if (api.authenticated) {
      api
        .get(`/api/v1/ratebook/directorylist`)
        .then((response) => {
          console.log(response);
          setDirectory(response.data);
        })
        .catch((err) => {
          console.log("FAILED", { err });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api]);

  return (
    <CssVarsProvider disableTransitionOnChange>
      <Box sx={{ borderRadius: "20px" }}>
        <Typography sx={{ textAlign: "center" }}> Directory</Typography>

        <Box
          sx={{
            display: "flex",
            flexDirection: isMobile ? "column" : "row",
            height: "100vh",

            border: `1px solid ${theme.palette.neutral[400]}`,
            borderRadius: "10px",
            boxShadow: 2,
            scrollBehavior: "smooth",
            transition: "background-color 0.3s ease",
          }}
        >
          {/* A-Z index */}
          <Box
            sx={{
              display: "flex",
              flexDirection: isMobile ? "row" : "column",
              overflowX: isMobile ? "auto" : "hidden",
              overflowY: isMobile ? "hidden" : "auto",
              p: 1,
              bgcolor: "#f5f5f5",
              borderRadius: "10px",
              gap: 1,
              backgroundColor: theme.palette.neutral[50],
            }}
          >
            {alphabet.map((letter) => (
              <Button
                key={letter}
                variant="text"
                size="large"
                onClick={() => scrollToLetter(letter)}
              >
                {letter}
              </Button>
            ))}
          </Box>

          {/* Directory Content */}
          <Box
            sx={{
              flex: 1,
              overflowY: "auto",
              p: 2,
              backgroundColor: theme.palette.neutral[50],
            }}
          >
            {alphabet.map((letter) => (
              <Box
                key={letter}
                ref={(el) => (sectionRefs.current[letter] = el)}
                sx={{ mb: 3 }}
              >
                <Typography
                  variant="h6"
                  sx={{ borderBottom: "1px solid #ddd", mb: 1 }}
                >
                  {letter}
                </Typography>
                {(directory[letter] || []).map((obj, index) => (
                  <Box key={`panel-${letter}-${index}`}>
                    <Accordion
                      expanded={expanded.includes(`panel-${letter}-${index}`)}
                      onChange={handleChange(`panel-${letter}-${index}`)}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel-${letter}-${index}-content`}
                        id={`panel-${letter}-${index}-header`}
                      >
                        <Typography
                          sx={{ pl: 1, color: theme.palette.primary[500] }}
                        >
                          {obj.trade}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        {obj.classification.map((classification, index) => (
                          <Typography
                            key={index}
                            sx={{ pl: 2, color: theme.palette.neutral[600] }}
                          >
                            {`${index + 1}. ${classification}`}
                          </Typography>
                        ))}
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                ))}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </CssVarsProvider>
  );
}

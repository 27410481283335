const React = require("react");
const { useColorScheme } = require("@mui/joy/styles");
const IconButton = require("@mui/joy/IconButton").default;

const DarkModeRoundedIcon =
  require("@mui/icons-material/DarkModeRounded").default;
const LightModeIcon = require("@mui/icons-material/LightMode").default;

function ColorSchemeToggle(props) {
  const { onClick, sx, ...other } = props;
  const { mode, setMode } = useColorScheme();
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return (
      <IconButton
        size="sm"
        variant="outlined"
        color="neutral"
        {...other}
        sx={sx}
        disabled
      />
    );
  }

  return (
    <IconButton
      data-screenshot="toggle-mode"
      size="sm"
      variant="outlined"
      color="neutral"
      {...props}
      onClick={(event) => {
        if (mode === "light") {
          setMode("dark");
        } else {
          setMode("light");
        }
        onClick?.(event);
      }}
      sx={[
        mode === "dark"
          ? { "& > *:first-of-type": { display: "none" } }
          : { "& > *:first-of-type": { display: "initial" } },
        mode === "light"
          ? { "& > *:last-of-type": { display: "none" } }
          : { "& > *:last-of-type": { display: "initial" } },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <LightModeIcon />
      <DarkModeRoundedIcon />
    </IconButton>
  );
}

export default ColorSchemeToggle;

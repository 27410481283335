import { Grid, Box, Stack } from "@mui/material";
import SuggestionForm from "../components/SuggestionForm";
import { Tab, Tabs } from "@mui/material";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import WatchVideoModal from "../components/Marketing/WatchVideoModal";
import { ColorModeContext } from "../theme"; // ✅ Ensure correct import
import { useContext } from "react";
const SettingsTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{ maxWidth: "sm", marginX: "auto" }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
};

function SuggestionPage() {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => setSelectedTab(newValue);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const handleOpen = () => setOpen(true);
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  return (
    <Box sx={{ width: "100%", height: "100dvh" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", p: 1 }}>
        <Tabs
          sx={{
            backgroundColor: theme.palette.primary[100], // ✅ Light blue background
            borderRadius: "8px", // ✅ Rounded borders
            borderBottom: `2px solid ${theme.palette.primary[500]}`, // ✅ Custom bottom border
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.neutral[300], // ✅ Custom indicator color
              height: "4px", // ✅ Make the indicator line thicker
            },
          }}
          value={selectedTab}
          onChange={handleChange}
        >
          <Tab
            sx={{
              color: theme.palette.neutral[500], // ✅ Default text color
              "&:hover": {
                color: theme.palette.primary[600], // ✅ Change text color on hover
              },
            }}
            label="Suggestions"
          />
          <Tab
            sx={{
              color: theme.palette.neutral[500], // ✅ Default text color
              "&:hover": {
                color: theme.palette.primary[600], // ✅ Change text color on hover
              },
            }}
            label="Tutorial Video (Company Admin)"
          />
        </Tabs>
      </Box>{" "}
      <SettingsTabPanel value={selectedTab} index={0}>
        <Stack spacing={0}>
          <SuggestionForm />{" "}
        </Stack>
      </SettingsTabPanel>
      <SettingsTabPanel value={selectedTab} index={1}>
        <Stack spacing={0}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            sx={{ minWidth: "fit-content" }}
            onClick={handleOpen}
          >
            Watch Demo (For Company Admin)
          </Button>
          <WatchVideoModal open={open} handleClose={handleClose} />
        </Stack>
      </SettingsTabPanel>
    </Box>
  );
}

export default SuggestionPage;

import { useMemo, useState, useEffect } from "react";
import DashBoardBox from "../components/DashBoardBox";
import AnalyticsBoxHeader from "../components/AnalyticsBoxHeader";
import { useTheme, Box, Stack, Button } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import useFetch from "../hooks/fetchAPI";
import dayjs from "dayjs";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CustomSelect from "../styledcomponents/CustomSelect";
import AnalyticsBoxHeader3 from "../components/AnalyticsBoxHeader3";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Line,
  LineChart,
  Legend,
  Bar,
  BarChart,
  AreaChart,
  Area,
} from "recharts";

//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

const fakedata2 = [
  {
    id: 1,
    name: "May",
    rfis: 4000,
    change_orders: 2400,
    non_compliance_reports: 2400,
  },
  {
    id: 2,
    name: "Jun",
    rfis: 3000,
    change_orders: 1398,
    non_compliance_reports: 2210,
  },
  {
    id: 3,
    name: "Jul",
    rfis: 2000,
    change_orders: 9800,
    non_compliance_reports: 2290,
  },
  {
    id: 4,
    name: "Aug",
    rfis: 2780,
    change_orders: 3908,
    non_compliance_reports: 2000,
  },
  {
    id: 5,
    name: "Sep",
    rfis: 1890,
    change_orders: 4800,
    non_compliance_reports: 2181,
  },
  {
    id: 6,
    name: "Oct",
    rfis: 1590,
    change_orders: 9000,
    non_compliance_reports: 3500,
  },
];

const fakeColumns = [
  {
    field: "name",
    headerName: "Name",
    flex: 0.4,
  },
  {
    field: "rfis",
    headerName: "RFIs",
    flex: 0.4,
  },
  {
    field: "change_orders",
    headerName: "Change Orders",
    flex: 0.4,
  },
  {
    field: "non_compliance_reports",
    headerName: "Non Compliance Reports",
    flex: 0.4,
  },
];

const COColumns = [
  {
    field: "project",
    headerName: "Project",
    flex: 0.4,
  },
  {
    field: "scope",
    headerName: "Scope",
    flex: 0.2,
    renderCell: (params) => `${params.value}`,
  },
  {
    field: "background",
    headerName: "Background",
    flex: 0.2,
    renderCell: (params) => `${params.value}`,
  },
];

const RFIColumns = [
  {
    field: "to",
    headerName: "To",
    flex: 0.4,
  },
  {
    field: "from",
    headerName: "From",
    flex: 0.4,
  },
  {
    field: "question",
    headerName: "Question",
    flex: 0.2,
    renderCell: (params) => `${params.value}`,
  },
  {
    field: "project",
    headerName: "Project",
    flex: 0.2,
    renderCell: (params) => `${params.value}`,
  },
];

const Row3 = ({
  changeOrderData,
  rfiData,
  listData,
  error,
  errMsg,
  user,
  payrollData,
  searchPayrollFilters,
  setSearchPayrollFilters,
  setError,
  seterrMsg,
}) => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  console.log(theme);
  const [renderedCOData, setRenderedCOData] = useState([]);
  const [renderedRFIData, setRenderedRFIData] = useState([]);
  const last52Saturdays = Array.from({ length: 52 }, (_, i) =>
    dayjs().endOf("week").subtract(i, "week").format("MM/DD/YYYY")
  );
  //api call in week number but value in string
  const handleWeekStartChange = (value) => {
    const year = dayjs(value, "MM/DD/YYYY").year();
    const weekNumber = dayjs(value, "MM/DD/YYYY").week();
    setSearchPayrollFilters((prevState) => ({
      ...prevState, // Keep the other existing fields
      yearStart: year, // Update field1
      weekNumberStart: weekNumber, // Update field2
    }));
  };

  //api call in week number but value in string
  const handleWeekEndChange = (value) => {
    const year = dayjs(value, "MM/DD/YYYY").year();
    const weekNumber = dayjs(value, "MM/DD/YYYY").week();
    setSearchPayrollFilters((prevState) => ({
      ...prevState, // Keep the other existing fields
      yearEnd: year, // Update field1
      weekNumberEnd: weekNumber, // Update field2
    }));
  };

  useEffect(() => {
    if (rfiData.length === 0) {
      seterrMsg({ ...errMsg, rfi: "No RFI data available" });
      setError({ ...error, rfi: true });
    }
    if (changeOrderData.length === 0) {
      seterrMsg({ ...errMsg, co: "No Change Order data available" });
      setError({ ...error, co: true });
    }
  }, [rfiData, changeOrderData]);

  useEffect(() => {
    //setAllTimecards(data);
    let renderedData = [];
    let renderedData2 = [];
    //map through array of objectsw
    if (listData?.co?.data.data) {
      renderedData = listData?.co?.data.data[0].results.map((co) => ({
        id: co._id,
        project: co.project,
        scope: co.scope,
        reason: co.reason,
        background: co.background,
        createdAt: dayjs(co.createdAt).format("MM-DD-YYYY"),
      }));

      renderedData2 = listData?.rfi?.data.data[0].results.map((rfi) => ({
        id: rfi._id,
        to: rfi.to,
        from: rfi.from,
        question: rfi.question,
        project: rfi.project,
        createdAt: dayjs(rfi.createdAt).format("MM-DD-YYYY"),
      }));

      setRenderedCOData(renderedData);
      setRenderedRFIData(renderedData2);
    }
  }, [listData?.co?.data.data, listData?.rfi?.data.data]);
  return (
    <>
      <DashBoardBox gridArea="g" sx={{ minHeight: "200px" }}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <AnalyticsBoxHeader3 title="Change Order" subtitle="(Summary)" />
        </Stack>
        {error && error?.co === true ? (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            {errMsg.co}
          </Alert>
        ) : null}
        <ResponsiveContainer width="95%" height="150px">
          {changeOrderData.length === 0 ? (
            <Box />
          ) : (
            <Box
              p="0 0.5rem"
              height="85%"
              sx={{
                "& .MuiDataGrid-root": {
                  color: `${theme.palette.neutral[600]}`,
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: `1px solid ${theme.palette.neutral[600]} !important`,
                },
                "& .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
              }}
            >
              <DataGrid
                rowHeight={25}
                columnHeaderHeight={25}
                hideFooter={true}
                rows={renderedCOData || []}
                columns={COColumns}
              />
            </Box>
          )}
        </ResponsiveContainer>
      </DashBoardBox>
      <DashBoardBox gridArea="h" sx={{ minHeight: "200px" }}>
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <AnalyticsBoxHeader3 title="RFI" subtitle="(Summary)" />
        </Stack>
        {error?.rfi === true && rfiData.length === 0 ? (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            {errMsg.rfi}
          </Alert>
        ) : null}
        <ResponsiveContainer width="95%" height="150px">
          {rfiData.length === 0 ? (
            <Box />
          ) : (
            <Box
              p="0 0.5rem"
              height="85%"
              sx={{
                "& .MuiDataGrid-root": {
                  color: `${theme.palette.neutral[600]}`,
                  border: "none",
                },
                "& .MuiDataGrid-cell": {
                  borderBottom: `1px solid ${theme.palette.neutral[600]} !important`,
                },
                "& .MuiDataGrid-columnSeparator": {
                  visibility: "hidden",
                },
              }}
            >
              <DataGrid
                rowHeight={25}
                columnHeaderHeight={25}
                hideFooter={true}
                rows={renderedRFIData || []}
                columns={RFIColumns}
              />
            </Box>
          )}
        </ResponsiveContainer>
      </DashBoardBox>

      {/* Payroll */}
      {user.role === "Company Admin" ? (
        <DashBoardBox gridArea="i" sx={{ minHeight: "200px" }}>
          <Stack direction="row">
            <AnalyticsBoxHeader3 title="Total Wages" subtitle="($)" />
            <Box sx={{ py: 1 }}>
              <CustomSelect
                value={last52Saturdays[7]}
                optionsArray={last52Saturdays}
                handleChange={(value) => handleWeekStartChange(value)}
              />
            </Box>
            <Box sx={{ py: 1 }}>
              <CustomSelect
                value={last52Saturdays[0]}
                optionsArray={last52Saturdays}
                handleChange={(value) => handleWeekEndChange(value)}
              />
            </Box>
          </Stack>
          {error?.sw === true ? (
            <Alert
              severity="warning"
              sx={{
                backgroundColor:
                  theme.palette.mode === "dark"
                    ? "rgba(255, 165, 0, 0.2)"
                    : "rgba(255, 165, 0, 0.1)",
                color: theme.palette.mode === "dark" ? "black" : "white",
              }}
            >
              {errMsg.sw}
            </Alert>
          ) : null}
          {error?.sw === true ? (
            <Box height="0%" />
          ) : (
            <ResponsiveContainer width="100%" height="80%">
              <AreaChart
                width={730}
                height={250}
                data={payrollData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="totalWages" x1="0" y1="0" x2="0" y2="1">
                    <stop
                      offset="5%"
                      stopColor={theme.palette.success[500]}
                      stopOpacity={0.8}
                    />
                    <stop
                      offset="95%"
                      stopColor={theme.palette.success[600]}
                      stopOpacity={0}
                    />
                  </linearGradient>
                </defs>

                <CartesianGrid
                  strokeDasharray="0"
                  stroke={theme.palette.neutral[100]}
                />
                <XAxis
                  dataKey="weekLabel"
                  stroke={theme.palette.neutral[300]}
                  scale="point" // Ensures even spacing
                  minTickGap={5} // Prevents overlapping
                  ticks={
                    payrollData
                      ?.filter(
                        (_, index) =>
                          index % Math.ceil(payrollData.length / 6) === 0
                      ) // Pick 6 evenly spaced ticks
                      .map((entry) => entry.weekLabel) // Extract only labels for X-Axis
                  }
                  tick={({ x, y, payload }) => {
                    const dataItem = payrollData?.find(
                      (item) => item.weekLabel === payload.value
                    );

                    return (
                      <text
                        x={x}
                        y={y}
                        dy={16}
                        textAnchor="middle"
                        fill={
                          dataItem?.yearPaid === dayjs().year()
                            ? theme.palette.success[500]
                            : theme.palette.neutral[500]
                        }
                        style={{ fontSize: 10 }}
                      >
                        {payload.value}
                      </text>
                    );
                  }}
                />
                <YAxis
                  tickLine={true}
                  axisLine={false}
                  stroke={theme.palette.neutral[400]}
                  style={{
                    fontSize: ".75rem",
                    fontFamily: "IBM Plex Sans Condensed",
                  }}
                />

                <Tooltip
                  content={({ payload }) => {
                    // Check if there's data in the tooltip payload
                    if (payload && payload.length > 0) {
                      const { weekLabel, totalWages } = payload[0].payload; // Access the weekLabel (tick value)
                      return (
                        <div className="custom-tooltip">
                          <p>
                            <strong>Week: {weekLabel}</strong>
                          </p>{" "}
                          <p>
                            <strong>Total Wages: ${totalWages}</strong>
                          </p>{" "}
                        </div>
                      );
                    }
                    return null; // Return nothing if no data in payload
                  }}
                />
                <Legend
                  iconSize={10}
                  layout="horizontal"
                  verticalAlign="bottom"
                  align="center"
                  wrapperStyle={{
                    marginTop: 10,
                    fontSize: "0.75rem",
                    color: theme.palette.neutral[500], // dynamic based on theme
                  }}
                  formatter={(value) => (
                    <span
                      style={{
                        color: theme.palette.neutral[500],
                        fontWeight: 500,
                      }}
                    >
                      {value === "totalWages" ? "Total Wages" : null}
                    </span>
                  )}
                />

                <Area
                  type="monotone"
                  dataKey="totalWages"
                  stroke={theme.palette.success[500]}
                  fillOpacity={1}
                  fill="url(#totalWages)"
                />
              </AreaChart>
              {/* </LineChart> */}
            </ResponsiveContainer>
          )}
        </DashBoardBox>
      ) : null}
    </>
  );
};

export default Row3;

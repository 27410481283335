import { Box } from "@mui/material";
import { styled } from "@mui/system";

const DashBoardBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.neutral[50],
  borderRadius: "1rem",
  boxShadow:
    theme.palette.mode === "dark"
      ? "0px 4px 15px rgba(144, 136, 136, 0.5)" // ✅ Dark mode shadow
      : "0px 4px 15px rgba(153, 146, 230, 0.3)", // ✅ Light mode shadow
  transition: "all 0.3s ease-in-out", // ✅ Smooth transition when mode changes
  border:
    "1px solid" +
    (theme.palette.mode === "dark"
      ? theme.palette.neutral[100]
      : theme.palette.neutral[200]),
}));

export default DashBoardBox;

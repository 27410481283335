//functional MUI datagrid component to display timecards for payroll

import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box, Typography } from "@mui/material";
import { darken, lighten, styled } from "@mui/material/styles";
import CustomSelect from "./../../styledcomponents/CustomSelect";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import threeWeekLookBack from "../../hooks/three-week-lookback";
//theme imports
import { ColorModeContext, useMode } from "../../theme";
import { useContext } from "react";
const columns = [
  { field: "id", headerName: "ID", display: false, hidden: true },
  { field: "firstName", headerName: "First Name", width: 130 },
  { field: "lastName", headerName: "Last Name", width: 130 },
  { field: "weekNumber", headerName: "Week", width: 50 },
  {
    field: "regHours",
    headerName: "Reg",
    width: 50,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.calculations.regHours}</div>;
    },
  },
  {
    field: "overtimeHours",
    headerName: "OT",
    width: 50,
    renderCell: (params) => {
      return (
        <div className="rowitem">{params.row.calculations.overtimeHours}</div>
      );
    },
  },
  {
    field: "satHours",
    headerName: "Sat",
    width: 50,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.calculations.satHours}</div>;
    },
  },
  {
    field: "sunHours",
    headerName: "Sun",
    width: 50,
    renderCell: (params) => {
      return <div className="rowitem">{params.row.calculations.sunHours}</div>;
    },
  },
  {
    field: "totalHours",
    headerName: "Total",
    width: 50,
    renderCell: (params) => {
      return (
        <div className="rowitem">{params.row.calculations.totalHours}</div>
      );
    },
  },
  {
    field: "approved",
    headerName: "Approved",
    width: 130,
    getCellClassName: (params) => {
      console.log("Approved value:", params.row.approved); // ✅ Debugging
      return Boolean(params.value)
        ? "super-app-theme--approved"
        : "super-app-theme--notApproved";
    },
  },
];

const ChooseTimeCardsForPayroll = ({
  timecards,
  isLoading,
  selectedDataID,
  setselectedDataID,
  searchFilters,
  setSearchFilters,
}) => {
  const { sixWkBkDesc } = threeWeekLookBack();
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  console.log(timecards);
  const handleWeekChange = (e) => {
    console.log(e);
  };
  const handleApprovedChange = () => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      approved: !prevFilters.approved,
    }));
  };
  const handleStatusChange = () => {
    setSearchFilters((prevFilters) => ({
      ...prevFilters,
      status: prevFilters.status === "Complete" ? "Incomplete" : "Complete",
    }));
  };

  return (
    <Box width="100%">
      <Box width="100%" display="flex" justifyContent="center">
        <Typography variant="h7" align="center">
          Choose Timecards for Payroll
        </Typography>
      </Box>
      <Box width="100%" display="flex" justifyContent="center" sx={{ p: 1 }}>
        <CustomSelect
          value={sixWkBkDesc[5]}
          optionsArray={sixWkBkDesc.slice(4, 6)}
          handleChange={handleWeekChange}
        />

        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={searchFilters.approved}
              onChange={handleApprovedChange}
              style={{
                color: theme.palette.neutral[400],
              }}
            />
          }
          label={
            <Typography variant="body2" color={theme.palette.neutral[400]}>
              Approved
            </Typography>
          }
          sx={{ pl: 2 }}
        />
        <FormControlLabel
          control={
            <Checkbox
              size="small"
              checked={searchFilters.status === "Complete" ? true : false}
              onChange={handleStatusChange}
              style={{
                color: theme.palette.neutral[400],
              }}
            />
          }
          label={
            <Typography variant="body2" color={theme.palette.neutral[400]}>
              Complete
            </Typography>
          }
        />
      </Box>
      <Box
        width="100%"
        height="500px"
        sx={{
          borderRadius: "10px", // ✅ Ensure container matches DataGrid
          overflow: "hidden", // ✅ Prevents corners from being cut off

          // ✅ Soft Drop Shadow
          boxShadow:
            theme.palette.mode === "dark"
              ? "0px 4px 15px rgba(0, 0, 0, 0.2)" // Dark mode shadow
              : "0px 4px 15px rgba(202, 188, 188, 0.4)", // Light mode shadow

          // ✅ DataGrid Styling
          "& .MuiDataGrid-root": {
            borderRadius: "10px", // ✅ Apply to root container
            border: "1px solid",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[100] // ✅ Dark grid background
                : theme.palette.neutral[50], // ✅ Dark mode CELL background
          },

          "& .MuiDataGrid-cell": {
            borderBottom: `1px solid ${theme.palette.neutral[500]} !important`,
            color:
              theme.palette.mode === "dark"
                ? theme.palette.neutral[700] // ✅ Light mode white text
                : theme.palette.neutral[500], // ✅ Ensures white text
          },

          "& .MuiDataGrid-columnHeaders": {
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400] //DARK MODE HEADERS
                : theme.palette.primary[200],
            borderBottom: "none",
            fontWeight: "bold",
            borderTopLeftRadius: "10px", // ✅ Ensure headers match border
            borderTopRightRadius: "10px",
          },
          "& .MuiDataGrid-viewport": {
            borderRadius: "10px", // ✅ Ensures viewport matches border
          },

          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            color: "white",
            backgroundColor:
              theme.palette.mode === "dark"
                ? theme.palette.primary[400] //DARK MODE FOOTERS
                : theme.palette.primary[200],
            borderBottomLeftRadius: "10px", // ✅ Ensure footer matches border
            borderBottomRightRadius: "10px",
          },

          "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
            color: "white !important",
          },

          "& .MuiDataGrid-columnSeparator": {
            visibility: "hidden",
          },

          "& .MuiCheckbox-root": {
            color:
              theme.palette.mode === "dark"
                ? theme.palette.primary[300]
                : theme.palette.primary[500], // ✅ White checkbox in light mode
          },
        }}
      >
        <DataGrid
          rows={timecards}
          columns={columns}
          pageSize={20}
          loading={isLoading}
          checkboxSelection
          rowsPerPageOptions={[20, 50, 100]}
          keepNonExistentRowsSelected
          rowHeight={40}
          onSelectionModelChange={(selectedIDs) => {
            const selectedRows = timecards.filter((row) =>
              selectedIDs.includes(row.id)
            ); // ✅ Get full row data
            setselectedDataID(selectedRows); // ✅ Store selected rows in state
          }}
          selectionModel={selectedDataID.map((row) => row.id)} // ✅ Keep selection controlled
          getRowClassName={(params) =>
            params.indexRelativeToCurrentPage % 2 === 0
              ? "super-app-theme--even"
              : "super-app-theme--odd"
          }
          sx={{
            borderRadius: "10px",
            overflow: "hidden", // ✅ Prevents border clipping
            "& .MuiDataGrid-root": {
              backgroundColor: "transparent", // ✅ Keeps transparency within Box bg
            },

            "& .MuiDataGrid-columnHeaders": {
              color: "white", // ✅ Column headers are white
            },

            "& .MuiDataGrid-columnSeparator": {
              visibility: "hidden",
            },

            "& .MuiDataGrid-toolbarContainer .MuiButton-text": {
              color: "white !important",
            },
            "& .super-app-theme--approved": {
              backgroundColor: "rgba(76, 175, 80, 0.3)", // ✅ DARK MODE ROW STYLING
              color: "#1B5R20 !important", // ✅ Dark Green text
            },
            "& .super-app-theme--notApproved": {
              backgroundColor: "rgba(76, 175, 80, 0.3)", // ✅ DARK MODE ROW STYLING
              color: "#1B5E20 !important", // ✅ Dark Green text
            },
          }}
        />
      </Box>
    </Box>
  );
};

export default ChooseTimeCardsForPayroll;

import dayjs from "dayjs";
import {
  calculateDailyHours,
  getProjectSelector,
  calculateDollarAmt,
} from "../../utils/runpayroll";

export const generateProjectXML = (projectName, data) => {
  const companyName = "Test";
  const FEIN = 5666;
  const street = "123 Fake St";
  const city = "San Francisco";
  const state = "CA";
  const zip = 12345;
  const email = "admin@yess.com";
  const days = ["sun", "mon", "tue", "wed", "thur", "fri", "sat"];
  const weekEndingString = "2025-03-15";
  const endDate = dayjs(weekEndingString, "YYYY-MM-DD");

  // Generate Sunday through Friday before the Saturday
  const weekDays = Array.from({ length: 6 }, (_, i) =>
    endDate.subtract(6 - i, "day").format("YYYY-MM-DD")
  );

  const filteredData = data.filter(
    (entry) =>
      entry.project1 === projectName ||
      entry.project2 === projectName ||
      entry.project3 === projectName
  ); //filter by projectname
  const selectedData = filteredData
    .map((entry) => {
      const selector = getProjectSelector(entry, projectName);
      return selector
        ? {
            ...entry,
            projectSelector: selector,
            rateSelector:
              selector === "p1"
                ? "selectedRate"
                : selector === "p2"
                ? "selectedRate2"
                : "selectedRate3",
          }
        : null;
    })
    .filter(Boolean);

  console.log(selectedData);
  const xml = `
    <PayrollReport>
       <CPR:eCPR xmlns:CPR="http://www.dir.ca.gov/dlse/CPR-Prod-Test/CPR.xsd">
                <CPR:contractorInfo>
                    <CPR:contractorName>${companyName}</CPR:contractorName>
                <CPR:contractorLicense>
                <CPR:licenseType>OTHER</CPR:licenseType>
                <CPR:licenseNum></CPR:licenseNum>
                </CPR:contractorLicense>
                <CPR:contractorPWCR>NA</CPR:contractorPWCR>
                    <CPR:contractorFEIN>${FEIN}</CPR:contractorFEIN>
                <CPR:contractorAddress>
                    <CPR:street>${street}</CPR:street>
                    <CPR:city>${city}</CPR:city>
                    <CPR:state>${state}</CPR:state>
                    <CPR:zip>${zip}</CPR:zip>
                </CPR:contractorAddress>
                <CPR:insuranceNum>INS101</CPR:insuranceNum>
                    <CPR:contractorEmail>${email}</CPR:contractorEmail>
                </CPR:contractorInfo>
                <CPR:projectInfo>
                <CPR:awardingBody/>
                <CPR:contractAgencyID/>
                <CPR:contractAgency>CA-DIR</CPR:contractAgency>
                <CPR:projectName></CPR:projectName>
                    <CPR:projectID>${projectName}</CPR:projectID>
                <CPR:awardingBodyID/>
                <CPR:projectNum/>
                <CPR:contractID/>
                <CPR:projectLocation>
                <CPR:description/>
                <CPR:street/>
                <CPR:city/>
                <CPR:county/>
                <CPR:state/>
                <CPR:zip/>
                </CPR:projectLocation>
                </CPR:projectInfo>
                <CPR:payrollInfo>
                <CPR:statementOfNP>false</CPR:statementOfNP> 
                <CPR:payrollNum/>
                <CPR:amendmentNum/>
                    <CPR:forWeekEnding>${weekEndingString}</CPR:forWeekEnding>
                <CPR:employees>
                    ${selectedData
                      .map((entry) => {
                        const dailyHours = {};
                        days.forEach((day) => {
                          dailyHours[day] = calculateDailyHours(
                            entry[`${day}p1start`],
                            entry[`${day}p1end`],
                            entry[`${day}p2start`],
                            entry[`${day}p2end`],
                            entry[`${day}p3start`],
                            entry[`${day}p3end`],
                            entry[`break${day}`] // optional
                          );
                        });

                        const vac = calculateDollarAmt(
                          entry[`${entry.rateSelector}`].vacationAndHoliday,
                          entry.calculations.totalHours
                        );
                        const health = calculateDollarAmt(
                          entry[`${entry.rateSelector}`].healthAndWelfare,
                          entry.calculations.totalHours
                        );
                        const pension = calculateDollarAmt(
                          entry[`${entry.rateSelector}`].pension,
                          entry.calculations.totalHours
                        );
                        const train = calculateDollarAmt(
                          entry[`${entry.rateSelector}`].training,
                          entry.calculations.totalHours
                        );
                        const other = calculateDollarAmt(
                          entry[`${entry.rateSelector}`].otherPayments,
                          entry.calculations.totalHours
                        );

                        const deductions = [
                          entry.federal,
                          entry.fica,
                          entry.state,
                          entry.sdi,
                          vac,
                          health,
                          pension,
                          train,
                          other,
                        ];
                        const deductionsTotal = deductions.reduce(
                          (accumulator, currentValue) =>
                            accumulator + currentValue,
                          0
                        );
                        console.log(dailyHours);
                        return `
                    <CPR:employee>
                        <CPR:name id="n/a">${entry.firstName} ${
                          entry.lastName
                        }</CPR:name>
                        <CPR:address>
                        <CPR:street></CPR:street>
                        <CPR:city></CPR:city>
                        <CPR:state>CA</CPR:state>
                        <CPR:zip>91234</CPR:zip>
                        </CPR:address>
                        <CPR:ssn></CPR:ssn>
                        <CPR:numWithholdingExemp>2</CPR:numWithholdingExemp>
                        <CPR:workClass>Carrier Driver</CPR:workClass>
                        <CPR:payroll>
                            <CPR:hrsWorkedEachDay>
                        <CPR:day id="1">
                            <CPR:date>${weekDays[0]}</CPR:date>
                            <CPR:straightTime>${
                              dailyHours.sun[`${entry.projectSelector}regHours`]
                            }</CPR:straightTime>
                            <CPR:overtime> ${
                              dailyHours.sun[
                                `${entry.projectSelector}overtimeHours`
                              ]
                            }</CPR:overtime>
                            <CPR:doubletime> ${
                              dailyHours.sun[
                                `${entry.projectSelector}doubletimeHours`
                              ]
                            }</CPR:doubletime>
                        </CPR:day>
                        <CPR:day id="2">
                            <CPR:date>${weekDays[1]}</CPR:date>
                            <CPR:straightTime>${
                              dailyHours.mon[`${entry.projectSelector}regHours`]
                            }</CPR:straightTime>
                            <CPR:overtime> ${
                              dailyHours.mon[
                                `${entry.projectSelector}overtimeHours`
                              ]
                            }</CPR:overtime>
                            <CPR:doubletime> ${
                              dailyHours.mon[
                                `${entry.projectSelector}doubletimeHours`
                              ]
                            }</CPR:doubletime>
                        </CPR:day>
                        <CPR:day id="3">
                            <CPR:date>${weekDays[2]}</CPR:date>
                            <CPR:straightTime>${
                              dailyHours.tue[`${entry.projectSelector}regHours`]
                            }</CPR:straightTime>
                            <CPR:overtime> ${
                              dailyHours.tue[
                                `${entry.projectSelector}overtimeHours`
                              ]
                            }</CPR:overtime>
                            <CPR:doubletime> ${
                              dailyHours.tue[
                                `${entry.projectSelector}doubletimeHours`
                              ]
                            }</CPR:doubletime>
                        </CPR:day>
                        <CPR:day id="4">
                            <CPR:date>${weekDays[3]}</CPR:date>
                            <CPR:straightTime>${
                              dailyHours.wed[`${entry.projectSelector}regHours`]
                            }</CPR:straightTime>
                            <CPR:overtime> ${
                              dailyHours.wed[
                                `${entry.projectSelector}overtimeHours`
                              ]
                            }</CPR:overtime>
                            <CPR:doubletime> ${
                              dailyHours.wed[
                                `${entry.projectSelector}doubletimeHours`
                              ]
                            }</CPR:doubletime>
                        </CPR:day>
                        <CPR:day id="5">
                            <CPR:date>${weekDays[4]}</CPR:date>
                            <CPR:straightTime>${
                              dailyHours.thur[
                                `${entry.projectSelector}regHours`
                              ]
                            }</CPR:straightTime>
                            <CPR:overtime> ${
                              dailyHours.thur[
                                `${entry.projectSelector}overtimeHours`
                              ]
                            }</CPR:overtime>
                            <CPR:doubletime> ${
                              dailyHours.thur[
                                `${entry.projectSelector}doubletimeHours`
                              ]
                            }</CPR:doubletime>
                        </CPR:day>
                        <CPR:day id="6">
                            <CPR:date>${weekDays[5]}</CPR:date>
                            <CPR:straightTime>${
                              dailyHours.fri[`${entry.projectSelector}regHours`]
                            }</CPR:straightTime>
                            <CPR:overtime> ${
                              dailyHours.fri[
                                `${entry.projectSelector}overtimeHours`
                              ]
                            }</CPR:overtime>
                            <CPR:doubletime> ${
                              dailyHours.fri[
                                `${entry.projectSelector}doubletimeHours`
                              ]
                            }</CPR:doubletime>
                        </CPR:day>
                        <CPR:day id="7">
                            <CPR:date>${entry.weekEndingString}</CPR:date>
                            <CPR:straightTime>${
                              dailyHours.sat[`${entry.projectSelector}regHours`]
                            }</CPR:straightTime>
                            <CPR:overtime> ${
                              dailyHours.sat[
                                `${entry.projectSelector}overtimeHours`
                              ]
                            }</CPR:overtime>
                            <CPR:doubletime> ${
                              dailyHours.sat[
                                `${entry.projectSelector}doubletimeHours`
                              ]
                            }</CPR:doubletime>
                        </CPR:day>
                        </CPR:hrsWorkedEachDay>
                        <CPR:totHrs>
                            <CPR:totHrsStraightTime>${
                              entry.calculations.regHours
                            }</CPR:totHrsStraightTime>
                            <CPR:totHrsOvertime>${
                              entry.calculations.overtimeHours
                            }</CPR:totHrsOvertime>
                            <CPR:totHrsDoubletime>${
                              entry.calculations.satHours +
                              entry.calculations.sunHours
                            }</CPR:totHrsDoubletime>
                        </CPR:totHrs>
                        <CPR:hrlyPayRate>
                            <CPR:hrlyPayRateStraightTime>${
                              entry[`${entry.rateSelector}`].regRate
                            }</CPR:hrlyPayRateStraightTime>
                            <CPR:hrlyPayRateOvertime>${
                              entry[`${entry.rateSelector}`].OTRate
                            }</CPR:hrlyPayRateOvertime>       
                            <CPR:hrlyPayRateSat>${
                              entry[`${entry.rateSelector}`].satRate
                            }</CPR:hrlyPayRateSat>
                                <CPR:hrlyPayRateSun>${
                                  entry[`${entry.rateSelector}`].sunRate
                                }</CPR:hrlyPayRateSun>
                            <CPR:hrlyPayRateDoubletime>${
                              entry[`${entry.rateSelector}`].sunRate
                            }</CPR:hrlyPayRateDoubletime>
                        </CPR:hrlyPayRate>
                        <CPR:grossAmountEarned>
                            <CPR:thisProject>${
                              entry.calculations[
                                `${entry.projectSelector}TotalWages`
                              ]
                            }</CPR:thisProject>
                            <CPR:allWork>${entry.totalWages}</CPR:allWork>
                        </CPR:grossAmountEarned>
                        <CPR:deductionsContribPay>
                            <CPR:fedTax>${entry.federal}</CPR:fedTax>
                            <CPR:FICA>${entry.fica}</CPR:FICA>
                            <CPR:stateTax>${entry.state}</CPR:stateTax>
                            <CPR:SDI>${entry.sdi}</CPR:SDI>
                            <CPR:vacationHoliday>${vac}</CPR:vacationHoliday>
                            <CPR:healthWelfare>${health}</CPR:healthWelfare>
                            <CPR:pension>${pension}</CPR:pension>
                            <CPR:training>${train}</CPR:training>
                            <CPR:fundAdmin>0.0</CPR:fundAdmin>
                            <CPR:dues>0.0</CPR:dues>
                            <CPR:travelSubs>0.0</CPR:travelSubs>
                            <CPR:savings>0.0</CPR:savings>
                            <CPR:other>${other}</CPR:other>
                            <CPR:total>${deductionsTotal}</CPR:total>
                            <CPR:notes></CPR:notes>
                        </CPR:deductionsContribPay>
                            <CPR:netWagePaidWeek>${
                              parseFloat(entry.totalWages.toFixed(2)) -
                              parseFloat(deductionsTotal.toFixed(2))
                            }</CPR:netWagePaidWeek>
                            <CPR:checkNum>1001</CPR:checkNum>
                        </CPR:payroll>
                    </CPR:employee>
                      `;
                      })
                      .join("")}
                </CPR:employees>
            </CPR:payrollInfo>
        </CPR:eCPR>
    </PayrollReport>
  `;

  return xml.trim();
};

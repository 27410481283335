import { Modal, Button, Box, Typography } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { ColorModeContext, useMode } from "../../theme";

const WatchVideoModal = ({ open, handleClose }) => {
  const videoId = "sI_yprtYrt0";
  const { theme, colorMode } = useMode();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            backgroundColor: "rgba(210, 212, 217, 0.9)",
            padding: 2,
            borderRadius: 1,
            width: isSmallScreen ? "390px" : "600px",
            height: isSmallScreen ? "350px" : "500px",
            maxWidth: "100vw",
            maxHeight: "100%",
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            overflowY: "auto",
          }}
        >
          <Typography id="modal-modal-title" variant="h6" color="black">
            Watch Video
          </Typography>

          <iframe
            width="100%"
            height="90%"
            src={`https://www.youtube.com/embed/${videoId}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        </Box>
      </Modal>
    </div>
  );
};

export default WatchVideoModal;

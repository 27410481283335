import { useState, useEffect } from "react";
import Logout from "./components/Logout";
import UserPage from "./pages/UserPage";
import RegisterPage from "./pages/RegisterPage";
import SignInPage from "./pages/SignInPage";
import AdminPage from "./pages/AdminPage";
import AdminPageUsers from "./pages/AdminPageUsers";
import AdminPageTimecards from "./pages/AdminPageTimecards";
import AdminPageTimecardsV3 from "./pages/AdminPageTimecardsV3";
import AdminPageProjects from "./pages/AdminPageProjects";
import Dashboard2 from "./pages/Dashboard2";
import Dashboard3 from "./pages/Dashboard3";
import ForgotPasswordPage from "./pages/ForgotPassword";
import ResetPasswordPage from "./pages/ResetPassword";
import PayrollCreatePage from "./pages/PayrollCreatePage";
import PayrollCreatePageV3 from "./pages/PayrollCreatePageV3";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { ColorModeContext, useMode } from "./theme";
import { CssBaseline, ThemeProvider, Box, Alert, Stack } from "@mui/material";
import SettingsPage from "./pages/SettingsPage";
import SideBarAdmin from "./components/SideBarAdmin";
import SideBarPM from "./components/SideBarPM";
import SideBarUser from "./components/SideBarUser";
import TopBarAdmin from "./components/TopBarAdmin";
import PMHomePage from "./pages/PMPage";
import PMPageProjects from "./pages/PMPageProjects";
import PMPageTimecards from "./pages/PMPageTimecards";
import RatebookPage from "./pages/RatebookPage";
import UserPageTimecards from "./pages/UserPageTimecards";
import useMediaQuery from "@mui/material/useMediaQuery";
import PrivateRoutes from "./utils/PrivateRoutes";
import AdminPagePersonnel from "./pages/AdminPagePersonnel";
import LandingPage from "./pages/LandingPage";
import TimeClockPage from "./pages/TimeClockPage";
import Email from "./pages/Email";
import LandingFeaturesPage from "./pages/LandingFeaturesPage";
import PrivacyPolicyPage from "./pages/PrivacyPolicyPage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import FAQPage from "./pages/FAQPage";
import TermsAndAgreementPage from "./pages/TermsAndAgreementPage";
import SubscriptionSuccessPage from "./pages/SubscriptionSuccessPage";
import MappingPageDraggable from "./pages/MappingPageDrag";
import LanguagePage from "./pages/LanguagePage";
import AdminChangeOrders from "./pages/AdminChangeOrders";
import AdminPageRFI from "./pages/AdminPageRFI";
import PrivatePaidRoutes from "./utils/PrivatePaidRoutes";
import dayjs from "dayjs";
import { useAuth } from "./context/AuthProvider";
import { LicenseInfo } from "@mui/x-license";
import MobileAdminPage from "./pages/MobileAdminPage";
import MobilePMPage from "./pages/MobilePMPage";
import PaymentPage from "./pages/PaymentPage";
import PaymentPage2 from "./pages/PaymentPage2";
import useMeasure from "react-use-measure";
import TestDataGridPage from "./pages/TestDataGridPage";
import TestPayrollCreatePage from "./pages/TestPayrollCreatePage";
import TestTimelinePage from "./pages/TestTimelinePage";
import MobileViewPage from "./pages/MobileViewPage";
import useIdleLogout from "./hooks/useIdleLogout";
import AdminDashboard from "./pages/AdminDashboard";
import MobileAdminDashboard from "./pages/MobileAdminDashboard";
import RunPayrollPage from "./pages/RunPayrollPage";
import CancelPage from "./pages/CancelPage";
import SuccessPage from "./pages/SuccessPage";
import { SubscriptionProvider } from "./context/SubscriptionProvider";
import Features2 from "./pages/Features";
import SuggestionPage from "./pages/SuggestionPage";
import AccountCreatedSuccessfully from "./pages/AccountCreatedSuccessfully";
import PricingPage from "./pages/PricingPage";
import SidebarAdminJoy from "./joy/components/SidebarAdminJoy";
import SidebarPMJoy from "./joy/components/SidebarPMJoy";

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI);
//handleSearchSubmit is for top search bar
//createPayroll
function App() {
  //set initial states
  const {
    success,
    logout,
    user,
    token,
    subscription,
    setSubscription,
    isLandingPage,
    setIsLandingPage,
  } = useAuth();

  //initially load getPayroll
  const { theme, mode, toggleColorMode } = useMode(); // ✅ Get the correct state & function
  const [showSidebar, setShowSidebar] = useState(false);
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [showSubscriptionAlert, setShowSubscriptionAlert] = useState(null);
  const [ref, { height }] = useMeasure();
  const [isCollapsed, setIsCollapsed] = useState(true); //sidebar
  const navigate = useNavigate();
  window.disableGTM = false;
  const location = useLocation();

  useIdleLogout(logout, 15 * 60 * 1000); //n minutes

  useEffect(() => {
    const landingPages = [
      "/",
      "/TermsAndAgreementPage",
      "/PrivacyPolicyPage",
      "/AboutPage",
      "/ContactPage",
      "/FAQPage",
      "/start-free-trial",
      "/features",
      "/SubscriptionSuccessPage",
      "/CheckOutPage",
      "/ForgotPassword",
      "/ResetPassword",
      "/DashboardTest",
      "/TestTimelinePage",
      "/trial-account-created",
      "/pricing",
      "/Login",
      "/login",
      "",
    ];

    if (
      landingPages.includes(location.pathname) ||
      landingPages
        .filter((page) => page !== "/")
        .find((page) => location.pathname.startsWith(page))
    ) {
      setIsLandingPage(true);
      setShowSidebar(false);
    } else {
      setIsLandingPage(false);
      setShowSidebar(true);
    }
  }, [location.pathname, setIsLandingPage]);

  useEffect(() => {
    setShowSidebar(!isSmallScreen);
  }, [isSmallScreen]);

  useEffect(() => {
    if (!success) {
      setSubscription(null);
      setShowSubscriptionAlert(null);
    } else if (subscription?.status === "trialing") {
      const trialEndDate = dayjs
        .unix(subscription.trial_end)
        .diff(dayjs(), "days");

      setShowSubscriptionAlert(
        <Box sx={{ backgroundColor: mode === "dark" ? "white" : "black" }}>
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                mode === "dark"
                  ? "rgba(255, 165, 0, 0.5)"
                  : "rgba(151, 108, 29, 0.5)",
              color: mode === "dark" ? "black" : "white",
            }}
            action={<Link to="/payment">Pay Now</Link>}
          >
            You Have {trialEndDate} Days Remaining In The Trial Version
          </Alert>
        </Box>
      );
    } else if (subscription?.status === "canceled") {
      const expireOrRenewalDate = dayjs.unix(subscription?.ended_at);

      if (expireOrRenewalDate.isAfter(dayjs())) {
        setShowSubscriptionAlert(
          <Alert
            severity="warning"
            action={<Link to="/company/subscription">Renew Subscription</Link>}
          >
            Your subscription has been canceled but you will have continue
            access until {expireOrRenewalDate.format("MMMM D, YYYY")}.
          </Alert>
        );
      } else {
        setShowSubscriptionAlert(null);
      }
    } else {
      setShowSubscriptionAlert(null);
    }
  }, [
    success,
    subscription?.ended_at,
    subscription?.status,
    subscription?.trial_end,
    setSubscription,
    mode,
  ]);

  //   useEffect(() => {
  //     if (!isLandingPage && !success) {
  //       navigate("/login");
  //     }
  //   }, [success, isLandingPage, navigate]);

  // Navigate to login if subscription is not found
  // useEffect(() => {
  //   if (subscription && Object.keys(subscription).length === 0) {
  //     navigate('/');
  //   }
  //   if (subscription?.status !== true) {
  //     navigate('/');
  //     setSuccess(false);
  //   }
  // }, [navigate, setSuccess, subscription]);

  function requireAuth(nextState, replace, next) {
    if (!token) {
      replace({
        pathname: "/login",
        state: { nextPathname: nextState.location.pathname },
      });
    }
    next();
  }

  return (
    <SubscriptionProvider>
      <ColorModeContext.Provider value={{ theme, mode, toggleColorMode }}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <Box
            ref={ref}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <div className="app">
              {success &&
              !isLandingPage &&
              (user.role === "Company Admin" || user.role === "Admin") ? (
                // <SideBarAdmin
                //   isSidebar={showSidebar}
                //   height={height}
                //   showSideBar={!isSmallScreen}
                // />
                <SidebarAdminJoy theme={theme} />
              ) : null}
              {success &&
              !isLandingPage &&
              user.role !== "Company Admin" &&
              user.role !== "Admin" ? (
                <SidebarPMJoy showSideBar={!isSmallScreen} height={height} />
              ) : null}

              <Box
                sx={{
                  width: "100%",
                }}
              >
                <Box sx={{ width: 1 }}>
                  <Stack
                    sx={{ width: "100%", justifyContent: "center" }}
                    spacing={2}
                  >
                    {!isLandingPage && showSubscriptionAlert}
                  </Stack>
                  <TopBarAdmin
                    mode={mode}
                    theme={theme}
                    isLandingPage={isLandingPage}
                  />
                  <Routes>
                    <Route path="/Login" element={<SignInPage />}></Route>
                    <Route path="/" element={<Features2 />} exact></Route>
                    <Route
                      path="/trial-account-created"
                      element={<AccountCreatedSuccessfully />}
                      exact
                    ></Route>

                    <Route
                      path="/TermsAndAgreementPage"
                      element={<TermsAndAgreementPage />}
                      isSidebar={false}
                    ></Route>
                    <Route path="/Logout" element={<Logout />}></Route>
                    <Route
                      path="/LanguagePage"
                      element={<LanguagePage />}
                    ></Route>
                    <Route
                      path="/Register"
                      element={<RegisterPage success={success} />}
                    ></Route>
                    <Route
                      path="/features"
                      element={<LandingFeaturesPage />}
                    ></Route>
                    {/* <Route path="/features2" element={<Features2 />}></Route> */}
                    <Route
                      path="/PrivacyPolicyPage"
                      element={<PrivacyPolicyPage />}
                    ></Route>
                    <Route path="/AboutPage" element={<AboutPage />}></Route>
                    <Route
                      path="/ContactPage"
                      element={<ContactPage />}
                    ></Route>
                    <Route path="/FAQPage" element={<FAQPage />}></Route>
                    <Route
                      exact
                      path="/ResetPassword/:id"
                      element={<ResetPasswordPage />}
                    ></Route>
                    <Route
                      path="/ForgotPassword"
                      element={<ForgotPasswordPage />}
                    ></Route>
                    <Route
                      path="/ResetPassword"
                      element={<ResetPasswordPage />}
                    ></Route>
                    <Route
                      path="/company/subscription"
                      element={<PaymentPage />}
                    ></Route>
                    <Route path="/pricing" element={<PricingPage />}></Route>
                    <Route path="/payment" element={<PaymentPage2 />}></Route>
                    <Route element={<PrivatePaidRoutes />}>
                      <Route
                        path="/TestDataGridPage"
                        element={<TestDataGridPage location={location} />}
                      ></Route>
                      <Route
                        path="/settings"
                        element={<SettingsPage />}
                        onEnter={requireAuth}
                      />
                      <Route
                        path="/TestPayrollCreatePage"
                        element={<TestPayrollCreatePage location={location} />}
                      ></Route>
                      <Route
                        path="/TestTimelinePage"
                        element={<TestTimelinePage location={location} />}
                      ></Route>
                      <Route
                        path="/Admin"
                        element={<AdminPage />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/Dashboard3"
                        element={<Dashboard3 />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/AdminPageUsers"
                        element={<AdminPageUsers />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/AdminPagePersonnel"
                        element={<AdminPagePersonnel />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/AdminChangeOrders"
                        element={<AdminChangeOrders />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route path="/cancel" element={<CancelPage />}></Route>
                      <Route path="/success" element={<SuccessPage />}></Route>
                      <Route
                        path="/AdminPageRFI"
                        element={<AdminPageRFI />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/RatebookPage"
                        element={<RatebookPage />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/PayrollCreatePage"
                        element={<PayrollCreatePage user={user} />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/PayrollCreatePageV3"
                        element={<PayrollCreatePageV3 user={user} />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/SettingsPage"
                        element={<SettingsPage />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/AdminPageTimecards"
                        element={<AdminPageTimecards user={user} />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/AdminPageTimecardsV3"
                        element={<AdminPageTimecardsV3 />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/Map"
                        element={<MappingPageDraggable />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/AdminPageProjects"
                        element={<AdminPageProjects />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/MobileViewPage"
                        element={<MobileViewPage />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/suggestions"
                        element={<SuggestionPage />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/PMPageTimecards"
                        element={<PMPageTimecards user={user} />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/SubscriptionSuccessPage"
                        element={<SubscriptionSuccessPage />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/PMPageProjects"
                        element={<PMPageProjects />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/TimeClockPage"
                        element={<TimeClockPage />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/UserPageTimecards"
                        element={<UserPageTimecards user={user} />}
                        onEnter={requireAuth}
                      ></Route>
                      {/* <Route
                        path="/dashboard"
                        element={<Dashboard2 />}
                        onEnter={requireAuth}
                      ></Route> */}
                      <Route
                        path="/RunPayrollPage"
                        element={<RunPayrollPage />}
                        onEnter={requireAuth}
                      ></Route>
                      <Route
                        path="/Email"
                        element={<Email />}
                        onEnter={requireAuth}
                      ></Route>
                    </Route>
                  </Routes>
                </Box>
              </Box>
            </div>
          </Box>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </SubscriptionProvider>
  );
}

export default App;

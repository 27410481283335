import { Box, Grid, useTheme, Stack, Typography } from "@mui/material";
import { tokens } from "../theme";
import { Link, Navigate } from "react-router-dom";

const LandingPageFooter = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  return (
    <Grid
      container
      spacing={0}
      direction="row"
      bottom={0}
      component="footer"
      alignItems="center"
      justifyContent="center"
      sx={{ p: 5, backgroundColor: "black" }}
    >
      <Stack direction="row" spacing={10}>
        <Box alignItems="center" justifyContent="center">
          <Typography variant="h5" sx={{ color: colors.greenAccent[300] }}>
            Company
          </Typography>

          <Link to={"/"}>
            <Typography variant="body2" sx={{ color: colors.grey[300] }}>
              Home
            </Typography>
          </Link>
          <Link to={"/AboutPage"}>
            <Typography variant="body2" sx={{ color: colors.grey[300] }}>
              About
            </Typography>
          </Link>
          <Link to={"/pricing"}>
            <Typography variant="body2" sx={{ color: colors.grey[300] }}>
              Pricing
            </Typography>
          </Link>
          <Link to={"/TermsAndAgreementPage"}>
            <Typography variant="body2" sx={{ color: colors.grey[300] }}>
              Terms and Agreements
            </Typography>
          </Link>
          <Link to={"/PrivacyPolicyPage"}>
            <Typography variant="body2" sx={{ color: colors.grey[300] }}>
              Privacy Policy
            </Typography>
          </Link>
        </Box>
        <Box alignItems="center" justifyContent="center" sx={{}}>
          <Typography variant="h5" sx={{ color: colors.greenAccent[300] }}>
            Resources
          </Typography>
          <Link to={"/ContactPage"}>
            <Typography variant="body2" sx={{ color: colors.grey[300] }}>
              Contact Us
            </Typography>
          </Link>
          <Link to={"/FAQPage"}>
            <Typography variant="body2" sx={{ color: colors.grey[300] }}>
              FAQ
            </Typography>
          </Link>
        </Box>
      </Stack>
    </Grid>
  );
};

export default LandingPageFooter;

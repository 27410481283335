// generatePayrollPDF.js
import {
  Document,
  Page,
  Text,
  View,
  pdf,
  StyleSheet,
} from "@react-pdf/renderer";
import dayjs from "dayjs";
import {
  formatNumber,
  calculateDollarAmt,
  calculateTotalBenefits,
} from "../../utils/runpayroll";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#E4E4E4",
  },
  column: {
    flex: 1, // Each column will take up equal space
    padding: 10,
    borderWidth: 1,
    borderColor: "#000",
  },
  row: {
    flexDirection: "row",
    paddingBottom: 8, // Optional: reduce vertical space
  },
  headline: {
    fontSize: "12px",
    px: 1,
  },
  text: {
    fontSize: "8px",
    px: 1,
  },
  textInactive: {
    fontSize: "8px",
    px: 1,
    color: "grey",
  },
  spacer: {
    padding: "0 10px",
  },
  project: {
    padding: "0 10px",
    alignItems: "right",
  },
  projectInactive: {
    padding: "0 10px",
    alignItems: "right",
    color: "grey",
  },
  personnelContainer: {
    paddingVertical: 3, // Optional: reduce vertical space
  },
  container: {
    flexDirection: "column", //for each project entry
  },
  rowContainer: {
    flexDirection: "row", //for each row dividing hours, deductions
    justifyContent: "space-between", // or "space-around" or "space-evenly"
    paddingHorizontal: 10, // Only left/right spacing
    paddingVertical: 2, // Optional: reduce vertical space
  },
  rowElement: {
    flex: 1, // equal width for all columns
    paddingHorizontal: 5,
  },
  section: {
    margin: 10,
    padding: 10,
    flexDirection: "row",
  },
  bold: {
    fontWeight: "bold",
  },
  wages: {
    flexDirection: "row",
    padding: "0px 10px",
  },
  boldSmall: {
    fontSize: 10, // Adjust size as needed
    fontWeight: "bold",
  },
  underline: {
    fontSize: 8,
    textDecoration: "underline",
  },
});

export const generatePayrollPDF = async (payrollData) => {
  console.log(payrollData);
  let benefitsdeductionTotal;
  const renderBenefits = (timecard, computedProperty) => {
    let key;
    computedProperty === "selectedRate"
      ? (key = "p1Hours")
      : computedProperty === "selectedRate2"
      ? (key = "p2Hours")
      : (key = "p3Hours");
    const rate = timecard[computedProperty] ? timecard.selectedRate : {};
    console.log(timecard);
    const vac = calculateDollarAmt(
      rate.vacationAndHoliday,
      timecard.calculations[key]
    );
    const health = calculateDollarAmt(
      rate.healthAndWelfare,
      timecard.calculations[key]
    );
    const pension = calculateDollarAmt(
      rate.pension,
      timecard.calculations[key]
    );
    const train = calculateDollarAmt(rate.training, timecard.calculations[key]);
    const other = calculateDollarAmt(
      rate.otherPayments,
      timecard.calculations[key]
    );
    const benefitsdeduction = [vac, health, pension, train, other];
    benefitsdeductionTotal = benefitsdeduction.reduce(
      (accumulator, currentValue) => accumulator + currentValue,
      0
    );
    return (
      <View style={styles.rowElement}>
        <Text style={styles.underline}>Benefits</Text>
        <Text
          style={
            timecard[computedProperty]?.vacationAndHoliday
              ? styles.text
              : styles.textInactive
          }
        >
          {`Vacation: ${timecard.calculations[key]} hrs @  $${
            rate?.vacationAndHoliday ?? 0
          } = $${vac}`}
        </Text>
        <Text
          style={
            timecard[computedProperty]?.pension
              ? styles.text
              : styles.textInactive
          }
        >
          {`Pension: ${timecard.calculations[key]} hrs @ $${
            rate?.pension ?? 0
          } = $${pension}`}
        </Text>
        <Text
          style={
            timecard[computedProperty]?.healthAndWelfare
              ? styles.text
              : styles.textInactive
          }
        >
          {`Health And Welfare: ${timecard.calculations[key]} hrs @ $${
            rate?.healthAndWelfare
              ? formatNumber(
                  rate?.healthAndWelfare * timecard.calculations[key]
                )
              : 0
          }= $${health}`}
        </Text>
        <Text
          style={
            timecard[computedProperty]?.training
              ? styles.text
              : styles.textInactive
          }
        >
          {`Training: ${timecard.calculations[key]} hrs @ $${
            rate?.training ?? 0
          } = $${train}`}
        </Text>
        <Text
          style={
            timecard[computedProperty]?.otherPayments
              ? styles.text
              : styles.textInactive
          }
        >
          {`Other: ${timecard.calculations[key]} hrs @ $${
            rate?.otherPayments ?? 0
          } = $${other}`}
        </Text>
        <Text
          style={benefitsdeductionTotal ? styles.text : styles.textInactive}
        >
          {`Benefits Total: $${formatNumber(benefitsdeductionTotal) ?? 0}`}
        </Text>
      </View>
    );
  };
  const doc = (
    <Document>
      {/* <Page sizre="A4">
        <View>
          <Text>Test</Text>
        </View>
      </Page> */}

      {Array.from(
        { length: Math.ceil(payrollData.length / 4) },
        (_, pageIndex) => (
          <Page key={`page-${pageIndex}`} size="A4">
            <View style={styles.column}>
              {payrollData
                .slice(pageIndex * 5, pageIndex * 5 + 5) // Get 5 entries per page
                .map((timecard, index) => {
                  const taxdeductions = [
                    timecard.federal,
                    timecard.fica,
                    timecard.state,
                    timecard.sdi,
                  ];
                  const taxdeductionTotal = taxdeductions.reduce(
                    (accumulator, currentValue) => accumulator + currentValue,
                    0
                  );
                  const totalBenefits = calculateTotalBenefits(timecard);
                  return (
                    <View key={index} style={styles.personnelContainer}>
                      <View style={styles.row}>
                        <View style={styles.spacer}>
                          <Text style={styles.headline}>
                            {`#${index + 1}. Name: ${timecard.firstName} ${
                              timecard.lastName
                            }`}
                          </Text>
                        </View>
                        <View style={styles.spacer}>
                          <Text style={styles.headline}>
                            {`Week Ending: ${dayjs(timecard.weekEnding).format(
                              "MM/DD/YYYY"
                            )}`}
                          </Text>
                        </View>

                        <View style={styles.spacer}>
                          <Text style={styles.headline}>
                            {`Total Hours: ${timecard.calculations.totalHours}`}
                          </Text>
                        </View>

                        <View style={styles.wages}>
                          <Text style={styles.headline}>
                            {`Payroll Total Wages: $${formatNumber(
                              timecard.totalWages
                            )}`}
                          </Text>
                        </View>
                      </View>
                      <br />
                      <View style={styles.container}>
                        <View style={styles.rowContainer}>
                          <View
                            style={
                              timecard.calculations.p1Hours > 0
                                ? styles.project
                                : styles.projectInactive
                            }
                          >
                            <Text style={styles.underline}>
                              {`Project: ${timecard.project1}`}
                            </Text>

                            <Text
                              style={
                                timecard.calculations.p1RegHours
                                  ? styles.text
                                  : styles.textInactive
                              }
                            >
                              {`Reg: ${
                                timecard.calculations.p1RegHours
                              } hrs @ $${formatNumber(
                                timecard.calculations.p1RegRate
                              )} = $${formatNumber(
                                timecard.calculations.p1RegWages
                              )}`}
                            </Text>

                            <Text
                              style={
                                timecard.calculations.p1OTHours
                                  ? styles.text
                                  : styles.textInactive
                              }
                            >
                              {`OT: ${
                                timecard.calculations.p1OTHours
                              } hrs @ $${formatNumber(
                                timecard.calculations.p1OTRate
                              )} = $${formatNumber(
                                timecard.calculations.p1OTWages
                              )}`}
                            </Text>

                            <Text
                              style={
                                timecard.calculations.p1SatHours
                                  ? styles.text
                                  : styles.textInactive
                              }
                            >
                              {`Sat: ${
                                timecard.calculations.p1SatHours
                              } hrs @ $${formatNumber(
                                timecard.calculations.p1SatRate
                              )} = $${formatNumber(
                                timecard.calculations.p1SatWages
                              )}`}
                            </Text>

                            <Text
                              style={
                                timecard.calculations.p1SunHours
                                  ? styles.text
                                  : styles.textInactive
                              }
                            >
                              {`Sun: ${
                                timecard.calculations.p1SunHours
                              } hrs @ $${formatNumber(
                                timecard.calculations.p1SunRate
                              )} = $${formatNumber(
                                timecard.calculations.p1SunWages
                              )}`}
                            </Text>

                            <Text
                              style={
                                timecard.calculations.p1Hours
                                  ? styles.text
                                  : styles.textInactive
                              }
                            >
                              {`Subtotal: ${timecard.calculations.p1Hours} hrs`}
                            </Text>

                            <Text
                              style={
                                timecard.calculations.p1TotalWages
                                  ? styles.text
                                  : styles.textInactive
                              }
                            >
                              {`Subtotal Wages: $${formatNumber(
                                timecard.calculations.p1TotalWages
                              )}`}
                            </Text>
                          </View>

                          {renderBenefits(timecard, "selectedRate")}

                          <View style={styles.rowElement}>
                            <Text style={styles.underline}>
                              Deductions (Estimate Only)
                            </Text>
                            <Text style={styles.text}>
                              {`Federal Tax: $${timecard.federal}`}
                            </Text>
                            <Text style={styles.text}>
                              {`FICA: $${timecard.fica}`}
                            </Text>
                            <Text style={styles.text}>
                              {`State Tax: $${timecard.state}`}
                            </Text>
                            <Text style={styles.text}>
                              {`SDI: $${timecard.sdi}`}
                            </Text>
                            <Text style={styles.text}>
                              {`Gross Wages: $${formatNumber(
                                timecard.totalWages
                              )}`}
                            </Text>
                            {/* <Text style={styles.text}>
                            {`Tax Deductions: $${(
                              timecard.federal +
                              timecard.fica +
                              timecard.state +
                              timecard.sdi
                            ).toFixed(2)}`}
                          </Text> */}
                            <Text style={styles.text}>
                              {`Tax Deductions: $${formatNumber(
                                taxdeductionTotal
                              )}`}
                            </Text>
                            <Text style={styles.text}>
                              {`Benefits Deductions: $${formatNumber(
                                totalBenefits
                              )}`}
                            </Text>

                            <Text style={styles.text}>
                              {`Net Wages: $${(
                                timecard.totalWages -
                                taxdeductionTotal -
                                totalBenefits
                              ).toFixed(2)}`}
                            </Text>
                          </View>
                        </View>
                        {timecard.calculations.p2Hours > 0 && (
                          <View style={styles.rowContainer}>
                            <View
                              style={
                                timecard.calculations.p2Hours > 0
                                  ? styles.project
                                  : styles.projectInactive
                              }
                            >
                              <Text style={styles.underline}>
                                {`Project: ${timecard.project2}`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p2RegHours
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`Reg: ${
                                  timecard.calculations.p2RegHours
                                } hrs @ $${formatNumber(
                                  timecard.calculations.p2RegRate
                                )} = $${formatNumber(
                                  formatNumber(timecard.calculations.p2RegWages)
                                )}`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p2OTHours
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`OT: ${
                                  timecard.calculations.p2OTHours
                                } hrs @ $${formatNumber(
                                  timecard.calculations.p2OTRate
                                )} = $${formatNumber(
                                  timecard.calculations.p2OTWages
                                )}`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p2SatHours
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`Sat: ${
                                  timecard.calculations.p2SatHours
                                } hrs @ $${formatNumber(
                                  timecard.calculations.p2SatRate
                                )} = $${formatNumber(
                                  timecard.calculations.p2SatWages
                                )}`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p2SunHours
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`Sun: ${
                                  timecard.calculations.p2SunHours
                                } hrs @ $${formatNumber(
                                  timecard.calculations.p2SunRate
                                )} = $${formatNumber(
                                  timecard.calculations.p2SunWages
                                )}`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p2Hours
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`Subtotal: ${timecard.calculations.p2Hours} hrs`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p2TotalWages
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`Subtotal Wages: $${formatNumber(
                                  timecard.calculations.p2TotalWages
                                )}`}
                              </Text>
                            </View>

                            {renderBenefits(timecard, "selectedRate2")}
                          </View>
                        )}
                        {timecard.calculations.p3Hours > 0 && (
                          <View style={styles.rowContainer}>
                            <View
                              style={
                                timecard.calculations.p3Hours > 0
                                  ? styles.project
                                  : styles.projectInactive
                              }
                            >
                              <Text style={styles.underline}>
                                {`Project: ${timecard.project3}`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p3RegHours
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`Regular Hours: ${
                                  timecard.calculations.p3RegHours
                                } hrs @ $${formatNumber(
                                  timecard.calculations.p3RegRate
                                )} = $${formatNumber(
                                  timecard.calculations.p3RegWages
                                )}`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p3OTHours
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`Overtime Hours: ${
                                  timecard.calculations.p3OTHours
                                } hrs @ $${formatNumber(
                                  timecard.calculations.p3OTRate
                                )} = $${formatNumber(
                                  timecard.calculations.p3OTWages
                                )}`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p3SatHours
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`Sat Hours: ${
                                  timecard.calculations.p3SatHours
                                } hrs @ $${formatNumber(
                                  timecard.calculations.p3SatRate
                                )} = $${formatNumber(
                                  timecard.calculations.p3SatWages
                                )}`}
                              </Text>

                              <Text
                                style={
                                  timecard.calculations.p3SunHours
                                    ? styles.text
                                    : styles.textInactive
                                }
                              >
                                {`Sunday Hours: ${
                                  timecard.calculations.p3SunHours
                                } hrs @ $${formatNumber(
                                  timecard.calculations.p3SunRate
                                )} = $${formatNumber(
                                  timecard.calculations.p3SunWages
                                )}`}
                              </Text>
                            </View>
                          </View>
                        )}
                      </View>
                      <View
                        style={{
                          height: 1,
                          backgroundColor: "#000", // black line
                          marginVertical: 10, // space above and below the divider
                          width: "100%",
                        }}
                      />
                    </View>
                  );
                })}
            </View>
          </Page>
        )
      )}
    </Document>
  );

  const blob = await pdf(doc).toBlob();
  return blob;
};

import { Box, Stack, Tab, Tabs } from "@mui/material";
import Header from "../components/Header";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/AuthProvider";
import Plans from "../components/Settings/Plans";
import CompanySettings from "../components/Settings/CompanySettings";
import UserProfile from "../components/Settings/UserProfile";
import SettingsInfo from "../components/Settings/SettingsInfo";
import CheckOutButton from "../components/CheckOutButton";
import UserChangePassword from "../components/Settings/UserChangePassword";
import Alert from "@mui/material/Alert";
import RunningWithErrorsIcon from "@mui/icons-material/RunningWithErrors";
import { Grid, Card, CardContent, Typography, Button } from "@mui/material";
import { productionPlans, developmentPlans } from "../assets/plans";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import { ColorModeContext } from "../theme"; // ✅ Ensure correct import
import { useContext } from "react";

const SettingsTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
      sx={{
        maxWidth: value === 4 ? "md" : "sm",
        marginX: "auto",
        height: "100%",
        overflow: value === 4 ? "auto" : "hidden",
      }}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </Box>
  );
};

const SettingsPage = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  const handleChange = (event, newValue) => setSelectedTab(newValue);
  const { api, user, subscription } = useAuth();
  const [errState, seterrState] = useState(false);
  const [company, setCompany] = useState({});
  const [plans, setPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState({});
  const [subIsActive, setSubIsActive] = useState(false);
  const { theme, toggleColorMode } = useContext(ColorModeContext);

  useEffect(() => {
    if (
      process.env.REACT_APP_NODE_ENV === "production" &&
      productionPlans &&
      subscription?.plan
    ) {
      setPlans(productionPlans);
      setSelectedPlan(
        productionPlans.find((plan) => plan.id === subscription.plan.id) || null
      );
    } else if (developmentPlans && subscription?.plan) {
      setPlans(developmentPlans);
      setSelectedPlan(
        developmentPlans.find((plan) => plan.id === subscription.plan.id) ||
          null
      );
    } else {
      setPlans(developmentPlans);
    }
  }, [
    developmentPlans,
    productionPlans,
    subscription,
    process.env.REACT_APP_NODE_ENV,
  ]);

  useEffect(() => {
    if (subscription) {
      if (Object.keys(subscription).length > 0) {
        setSubIsActive(true);
      } else {
        setSubIsActive(false);
      }
    }
  }, [subscription]);

  const handleConversionCheckout = async (priceId) => {
    //const stripe = await stripePromise;

    try {
      const response = await api.post(
        "/api/v1/subscription/create-checkout-session",
        { priceId }
      );
      const url = response.data.url;
      console.log(url);
      console.log(subscription);
      window.location.href = url;
      const sessionId = response.data.sessionId;

      //setSessionId(response.data.sessionId);

      // const { error } = await stripe.redirectToCheckout({
      // 	sessionId: sessionId,
      // });

      // if (error) {
      // 	console.error(error);
      // }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePlanSwitch = (planId) => {
    console.log(planId);
  };

  useEffect(() => {
    if (user.company?.id) {
      api
        .get(`/api/v1/company/${user.company.id}`)
        .then(({ data }) => {
          setCompany(data.data);
        })
        .catch((err) => {
          setCompany({});
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, user.company?.id]);

  const HeaderDetails = () => {
    return (
      <Stack direction="row">
        {subscription?.cardonfile === true ? (
          <Stack direction="row" sx={{ px: 1 }} spacing={1}>
            <CreditScoreIcon sx={{ color: "green", fontSize: 15 }} />
            <Typography sx={{ fontSize: 15 }} variant="joysmall">
              {" "}
              Card On File{" "}
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ px: 1 }} spacing={1}>
            <CreditCardOffIcon sx={{ color: "grey", fontSize: 15 }} />
            <Typography sx={{ fontSize: 15 }} variant="joysmall">
              {" "}
              No Card On File
            </Typography>
          </Stack>
        )}
        {subIsActive === true ? (
          <Stack direction="row" sx={{ px: 1 }} spacing={1}>
            <CheckCircleIcon sx={{ color: "green", fontSize: 15 }} />
            <Typography sx={{ fontSize: 15 }} variant="joysmall">
              {" "}
              Subscription Detected{" "}
            </Typography>
          </Stack>
        ) : (
          <Stack direction="row" sx={{ px: 1 }} spacing={1}>
            <WarningIcon sx={{ color: "yellow", fontSize: 15 }} />
            <Typography sx={{ fontSize: 15 }} variant="joysmall">
              {" "}
              No Subscription Detected
            </Typography>
          </Stack>
        )}
      </Stack>
    );
  };

  // const Plans = () => {
  //   return (
  //     <Box sx={{ p: 3 }}>
  //       <Grid
  //         container
  //         spacing={4}
  //         justifyContent="center"
  //         columns={{ xs: 4, sm: 8, md: 12 }}
  //       >
  //         {plans.map((plan, index) => (
  //           <Grid item key={index} xs={12} sm={6} md={4}>
  //             <Card
  //               sx={{
  //                 borderRadius: "12px",
  //                 boxShadow: 3,
  //                 transition: "transform 0.3s, box-shadow 0.3s",
  //                 "&:hover": {
  //                   transform: "scale(1.05)",
  //                   boxShadow: 6,
  //                 },
  //               }}
  //             >
  //               <CardContent sx={{ textAlign: "center" }}>
  //                 <Typography
  //                   variant="h7"
  //                   component="div"
  //                   sx={{ fontWeight: "bold", mb: 2 }}
  //                 >
  //                   {plan?.plan_name}
  //                 </Typography>
  //                 <Typography
  //                   variant="h6"
  //                   component="div"
  //                   sx={{ color: "success.main", mb: 3 }}
  //                 >
  //                   ${plan?.price} for {plan?.frequency_months} months
  //                 </Typography>
  //                 <Box component="ul" sx={{ listStyle: "none", p: 0, mb: 3 }}>
  //                   {plan?.features?.map((feature, i) => (
  //                     <Typography
  //                       component="li"
  //                       key={i}
  //                       sx={{ mb: 1, fontSize: "1rem" }}
  //                     >
  //                       {feature}
  //                     </Typography>
  //                   ))}
  //                 </Box>
  //                 <Typography
  //                   variant="h6"
  //                   component="div"
  //                   sx={{ color: "success.main", mb: 3 }}
  //                 >
  //                   ${plan?.per_day} per day
  //                 </Typography>
  //                 <Button
  //                   variant="contained"
  //                   color="success"
  //                   disabled={plan?.id === selectedPlan?._id ? true : false}
  //                   onClick={() => {
  //                     subscription?.status === "active"
  //                       ? handlePlanSwitch(plan.id)
  //                       : handleConversionCheckout(plan.id);
  //                   }}
  //                 >
  //                   {plan?.id === selectedPlan?.id ? "Selected" : "Choose Plan"}
  //                 </Button>
  //               </CardContent>
  //             </Card>
  //           </Grid>
  //         ))}
  //       </Grid>
  //     </Box>
  //   );
  // };

  return (
    <Box m="10px 20px 20px 20px">
      {errState ? (
        <Alert
          icon={<RunningWithErrorsIcon fontSize="inherit" />}
          severity="error"
        >
          {"Data Not Loaded!  Please refresh"}
        </Alert>
      ) : null}
      <Header title="Settings" subtitle={"Edit Your Settings"} />
      {HeaderDetails()}
      <Box
        sx={{
          borderBottom: 1,
          borderColor: "divider",
        }}
      >
        <Tabs
          value={selectedTab}
          onChange={handleChange}
          sx={{
            backgroundColor: theme.palette.primary[100], // ✅ Light blue background
            borderRadius: "8px", // ✅ Rounded borders
            borderBottom: `2px solid ${theme.palette.primary[500]}`, // ✅ Custom bottom border
            "& .MuiTabs-indicator": {
              backgroundColor: theme.palette.neutral[300], // ✅ Custom indicator color
              height: "4px", // ✅ Make the indicator line thicker
            },
          }}
        >
          <Tab
            sx={{
              color: theme.palette.neutral[500], // ✅ Default text color
              "&:hover": {
                color: theme.palette.primary[600], // ✅ Change text color on hover
              },
            }}
            label="Company Settings"
          />
          <Tab
            sx={{
              color: theme.palette.neutral[500], // ✅ Default text color
              "&:hover": {
                color: theme.palette.primary[600], // ✅ Change text color on hover
              },
            }}
            label="User Profile"
          />
          <Tab
            sx={{
              color: theme.palette.neutral[500], // ✅ Default text color
              "&:hover": {
                color: theme.palette.primary[600], // ✅ Change text color on hover
              },
            }}
            label="Subscription Info"
          />
          <Tab
            sx={{
              color: theme.palette.neutral[500], // ✅ Default text color
              "&:hover": {
                color: theme.palette.primary[600], // ✅ Change text color on hover
              },
            }}
            label="Payment Info"
          />
          <Tab
            sx={{
              color: theme.palette.neutral[500], // ✅ Default text color
              "&:hover": {
                color: theme.palette.primary[600], // ✅ Change text color on hover
              },
            }}
            label={
              subscription?.status === "active" ? "Switch Plans" : "Select Plan"
            }
          />
        </Tabs>
      </Box>
      <SettingsTabPanel value={selectedTab} index={0}>
        <Stack spacing={4}>
          <CompanySettings />
          {/* <CompanySubscription company={company} setCompany={setCompany} /> */}
        </Stack>
      </SettingsTabPanel>
      <SettingsTabPanel value={selectedTab} index={1}>
        <Stack spacing={4}>
          <UserProfile />
          <UserChangePassword />
        </Stack>
      </SettingsTabPanel>

      <SettingsTabPanel value={selectedTab} index={2}>
        <Stack spacing={4}>
          <SettingsInfo data={company} />
        </Stack>
      </SettingsTabPanel>

      <SettingsTabPanel value={selectedTab} index={3}>
        <Stack spacing={4}>
          <Typography>Payment Info</Typography>
          <Link to="/company/subscription">Pay Now</Link>
          <CheckOutButton
            priceId={"price_1OpI1kHiJ3dXu5Veqyko2QtM"}
            subscriptionId={subscription?.customer}
          />
        </Stack>
      </SettingsTabPanel>

      <SettingsTabPanel
        value={selectedTab}
        index={4}
        sx={{
          width: "500px", // ✅ Ensures full width
          display: "flex", // ✅ Allows content to expand
          justifyContent: "center", // ✅ Centers child components
          padding: "0px", // ✅ Removes default padding
        }}
      >
        <Plans />
      </SettingsTabPanel>
    </Box>
  );
};

export default SettingsPage;

import { Outlet, Navigate } from "react-router-dom";
import { useContext } from "react";
import { useAuth } from "../context/AuthProvider";
import { useSubscription } from "../context/SubscriptionProvider";

const PrivatePaidRoutes = () => {
  const { subscription, isLoading } = useSubscription();
  if (isLoading) return <div>Loading...</div>;

  // Redirect to payment page if no active subscription
  if (
    !subscription ||
    Object.keys(subscription).length === 0 ||
    [
      "incomplete",
      "incomplete_expired",
      "canceled",
      "past_due",
      "unpaid",
      "paused",
    ].includes(subscription?.status)
  ) {
    return <Navigate to="/payment" />;
  }

  //trialing, navigate to payment

  const hasActiveOrTrialSubscription = ["active", "trialing"].includes(
    subscription?.status
  );

  //has expired subscription, navigate to reactivate page
  // enum - incomplete, incomplete_expired,  past_due, canceled, unpaid, paused.
  // trialing, active,

  return <Outlet />;
};

export default PrivatePaidRoutes;

import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { useState, useEffect } from "react";
import { InputBase, Button } from "@mui/material";

import DarkModeOutlinedIcon from "@mui/icons-material/DarkMode";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import useMediaQuery from "@mui/material/useMediaQuery";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { Link } from "react-router-dom";
import Badge from "@mui/material/Badge";
import NotificationsPopover from "./NotificationsPopover";
import LogoutPopover from "./LogoutPopover";
import { useAuth } from "../context/AuthProvider";
import debounce from "../utils/debounce";

//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";
import LightModeIcon from "@mui/icons-material/LightMode";
import ColorSchemeToggle from "../joy/components/ColorSchemeToggle";
import { useRef } from "react";

const TopBarAdmin = ({ isLandingPage }) => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const [notificationsNumber, setNotificationsNumber] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const { api, user } = useAuth();
  const isMediumScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  //side bar menu
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  //notifications popout
  const [anchorElPopOut, setAnchorElPopOut] = useState(null);
  //logout popout
  const [anchorElLogOutPopOut, setAnchorElLogOutPopOut] = useState(null);
  const [options, setoptions] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(1);
  const anchorRef = useRef(null);
  //sidebar menu popout
  const handleClick = (event) => {
    console.log(event);
    setAnchorElMenu(event.currentTarget);
  };
  const open = Boolean(anchorElMenu);
  const handleClose = () => {
    setAnchorElMenu(null);
  };

  //Notifications Popover
  const openPopOut = Boolean(anchorElPopOut);
  const idPopOut = openPopOut ? "Pop Out" : undefined;

  //logout popout
  const openPopOut2 = Boolean(anchorElLogOutPopOut);
  const idPopOut2 = openPopOut2 ? "Pop Out" : undefined;

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setAnchorElMenu(null);
  };

  useEffect(() => {
    const userOptions = [
      { name: "Home", path: "/Dashboard3" },
      { name: "Create A Timecard", path: "/PayrollCreatePageV3" },
      { name: "My TimeCards", path: "/MobileViewPage" },
      { name: "Suggestions", path: "/suggestions" },
      { name: "LogOut", path: "/LogOut" },
    ];

    const PMOptions = [
      { name: "Home", path: "/Dashboard3" },
      { name: "Create A Timecard", path: "/PayrollCreatePageV3" },
      { name: "My TimeCards", path: "/MobileViewPage" },
      { name: "Suggestions", path: "/suggestions" },
      { name: "LogOut", path: "/LogOut" },
    ];

    const AdminOptions = [
      { name: "Home", path: "/Dashboard3" },
      { name: "Create A Timecard V3", path: "/PayrollCreatePageV3" },
      { name: "My Timecards", path: "/MobileViewPage" },
      { name: "Approve Timecards", path: "  /AdminPageTimecardsV3" },
      { name: "Run Payroll", path: "/RunPayrollPage" },
      // { name: "All TimeCards", path: "/AdminPageTimecards" },
      { name: "Users", path: "/AdminPageUsers" },
      { name: "Projects", path: "/AdminPageProjects" },
      { name: "Personnel", path: "/AdminPagePersonnel" },
      { name: "Mappings", path: "/MappingPage" },
      { name: "Ratebook", path: "/RatebookPage" },

      // { name: "Payroll", path: "/PayrollProcessPage" },
      // { name: "Create A Timecard (old)", path: "/PayrollCreatePage" },
      { name: "LogOut", path: "/LogOut" },
    ];

    if (user.role === "Company Admin") {
      setoptions(AdminOptions);
    } else if (user.role === "Project Manager") {
      setoptions(PMOptions);
    } else {
      setoptions(userOptions);
    }
  }, [user.role]);

  //notifications
  const handlePopOutClick = (event) => {
    setAnchorElPopOut(event.currentTarget);
  };

  //logout
  const handleLogOutPopOutClick = (event) => {
    setAnchorElLogOutPopOut(event.currentTarget);
  };
  useEffect(() => {
    if (api.authenticated) {
      const fetchNotifications = async () => {
        try {
          const response = await api.get(
            "/api/v1/notifications?page=1&limit=100"
          );
          setNotifications(response.data.data[0].results);
          setNotificationsNumber(response.data.data[0].results.length);
        } catch (err) {
          console.error(err);
          setNotifications([]);
          setNotificationsNumber(0);
        }
      };

      const debouncedFetch = debounce(fetchNotifications, 500);
      debouncedFetch();
    }
  }, [api, setNotificationsNumber, setNotifications]);

  //for migration to MUI V6
  const menuProps = {
    PaperProps: {
      sx: {
        backgroundColor: theme.palette.neutral[500], // ✅ Dropdown menu background
        borderRadius: "8px",
        color: theme.palette.neutral[600],
        boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
      },
    },
    MenuListProps: {
      sx: {
        padding: "4px 0",
      },
    },
  };

  return (
    <Box
      justifyContent="space-between"
      p={2}
      sx={{
        backgroundColor: theme.palette.mode === "dark" ? "white" : "black",
        width: "100%", // ✅ ensures full width
        flexShrink: 0, // ✅ prevents shrink
        display: isLandingPage ? "none" : "flex",
      }}
    >
      {/* SEARCH BAR */}
      {isMediumScreen ? (
        <Box
          display="flex"
          backgroundColor={
            theme.mode === "light"
              ? theme.palette.neutral[50]
              : theme.palette.neutral[50]
          }
          borderRadius="5px"
          border={{
            borderTop: `1px double ${theme.palette.neutral[100]}`,
            borderRight: `2px double ${theme.palette.neutral[100]}`,
            borderBottom: `2px double ${theme.palette.neutral[100]}`,
            borderLeft: `2px double ${theme.palette.neutral[100]}`,
          }}
        >
          <InputBase
            sx={{
              ml: 2,
              flex: 1,
              fontSize: "0.9rem", // ✅ Increase font size for visibility
              color: theme.palette.neutral[600], // ✅ Fix: Ensure text color is visible
              backgroundColor: "transparent", // ✅ Fix: No conflicting background

              "&::placeholder": {
                color: theme.palette.neutral[600], // ✅ Ensure placeholder has a visible color
                opacity: 1, // ✅ Ensure full opacity
              },
            }}
            placeholder="Search"
            inputProps={{ "aria-label": "search" }}
          />
          <IconButton type="button" sx={{ p: 1 }}>
            <SearchOutlinedIcon />
          </IconButton>
        </Box>
      ) : null}
      {isSmallScreen && (
        <Box id="box" sx={{ width: 1 }}>
          <IconButton
            ref={anchorRef}
            onClick={() => setAnchorElMenu(anchorRef.current)}
            sx={{
              color: theme.palette.neutral[500],
            }}
          >
            <MenuIcon />
          </IconButton>

          <Menu
            id="lock-menu"
            anchorEl={anchorElMenu}
            open={Boolean(anchorElMenu)}
            onClose={() => setAnchorElMenu(null)}
            MenuListProps={{
              "aria-labelledby": "lock-button",
              role: "listbox",
            }}
            disablePortal={false} // make sure this is not true accidentally
            PaperProps={{
              sx: {
                backgroundColor: theme.palette.neutral[600],
                color: "white",
                mt: 1, // margin top
                borderRadius: 2,
                minWidth: 180,
              },
            }}
          >
            {options.map((option, index) => (
              <MenuItem
                key={option.name}
                selected={index === selectedIndex}
                onClick={(event) => handleMenuItemClick(event, index)}
                sx={{
                  padding: "4px 8px",
                  minHeight: "30px",
                }}
              >
                <Link
                  to={option.path}
                  style={{
                    color: "inherit",
                    textDecoration: "none",
                    width: "100%", // ✅ Full width so it doesn't cause inline sizing
                    display: "block",
                  }}
                >
                  {option.name}
                </Link>
              </MenuItem>
            ))}
          </Menu>
        </Box>
      )}

      {/* ICONS */}
      <Box display="flex" alignItems="right" sx={{ px: "1rem" }}>
        {/* <ColorSchemeToggle
            sx={{ ml: "auto" }}
            onClick={() => {
              console.log("ok");
              toggleColorMode();
            }}
          /> */}
        <IconButton
          onClick={handlePopOutClick}
          sx={{ color: theme.palette.neutral[500] }}
        >
          <Badge
            aria-describedby={idPopOut}
            badgeContent={notificationsNumber}
            color={"error"}
          >
            <NotificationsNoneOutlinedIcon />
          </Badge>
        </IconButton>
        <NotificationsPopover
          message={notificationsNumber}
          anchorElPopOut={anchorElPopOut}
          openPopOut={openPopOut}
          idPopOut={idPopOut}
          setAnchorElPopOut={setAnchorElPopOut}
          notifications={notifications}
        />
        <IconButton
          component={Link}
          to="/settings"
          sx={{ color: theme.palette.neutral[500] }}
        >
          <SettingsOutlinedIcon />
        </IconButton>
        <IconButton
          onClick={handleLogOutPopOutClick}
          sx={{ color: theme.palette.neutral[500] }}
        >
          <PersonOutlineOutlinedIcon />
        </IconButton>
        <LogoutPopover
          message={notificationsNumber}
          anchorElPopOut={anchorElLogOutPopOut}
          openPopOut={openPopOut2}
          idPopOut={idPopOut2}
          setAnchorElPopOut={setAnchorElLogOutPopOut}
        />
      </Box>
    </Box>
  );
};

export default TopBarAdmin;

import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import { useAuth } from "../context/AuthProvider";
import { useState, useEffect, useMemo, useCallback } from "react";
import { Box, Typography, Stack } from "@mui/material";

const DeleteTimeCardModal = ({ open, handleClose, details, setTimecards }) => {
	const { api } = useAuth();
	const [errState, setErrState] = useState(false);
	const [errMsg, setErrMsg] = useState("");
	const [sentState, setSentState] = useState(false);
	// TimeClock Post
	const deleteTimecard = async (_id) => {
		try {
			const timecard = await api.delete(`/api/v1/timecardsv3/${_id}`);
			setSentState(true);
			setErrState(false);
			console.log(_id);
			setTimecards((prevItems) => prevItems.filter((item) => item._id !== _id));
			//update timecard in state
		} catch (error) {
			console.log("Error deleting timecard:", error);
			setErrState(true);
			if (error.response?.data) {
				setErrMsg(error.response.data.error);
			} else {
				setErrMsg("Error deleting time card");
			}
		}
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: "300px",
		bgcolor: "common.black",
		border: "2px solid #000",
		borderRadius: "10px",
		boxShadow: 24,
		p: 4,
		overflow: "auto",
		zIndex: 1000000,
		textAlign: "center",
	};
	return (
		<Modal
			open={open}
			onClose={handleClose}
			aria-labelledby="simple-modal-title"
			aria-describedby="simple-modal-description"
		>
			<Box sx={style}>
				<Typography variant={"h5"} color="grey">
					{" "}
					Delete Time Card{" "}
				</Typography>
				<Typography variant={"body1"} sx={{ p: 1 }}>
					Are you sure you want to delete time card for {details.fullName}?
				</Typography>
				<Stack spacing={2} direction="row" justifyContent="center">
					<Button
						variant="contained"
						color="primary"
						onClick={() => {
							deleteTimecard(details._id);
							handleClose();
						}}
					>
						Delete
					</Button>
					<Button variant="contained" color="error" onClick={handleClose}>
						Cancel
					</Button>
				</Stack>
				{errState ? errMsg : null}
				{sentState && !errState ? errMsg : null}
			</Box>
		</Modal>
	);
};
export default DeleteTimeCardModal;

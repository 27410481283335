import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  FormControl,
  Stack,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { companySizeData } from "../../assets/signupconstants";
import { countries } from "../../assets/countries";
import { states } from "../../assets/states";
import { useAuth } from "../../context/AuthProvider";
//theme imports
import { ColorModeContext, useMode } from "../../theme";
import { useContext } from "react";

const VALIDATION_SCHEMA = Yup.object().shape({
  companyName: Yup.string().required().label("Company Name"),
});

const Settings = () => {
  const [company, setCompany] = useState({
    companyName: "",
    companySize: "",
    country: "",
    state: "",
  });
  const [isProcessing, setIsProcessing] = useState(false);
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const { api, user } = useAuth();

  let formik = useFormik({
    initialValues: company,
    validationSchema: VALIDATION_SCHEMA,
    onSubmit: (values) => {
      setIsProcessing(true);

      api
        .put(`/api/v1/company/${company._id}`, values)
        .then(({ data }) => {
          setCompany(data.data);
        })
        .catch((err) => {
          console.log("FAIL", err);
        })
        .finally(() => {
          setIsProcessing(false);
        });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    formik.handleSubmit(formik.values);
  };

  useEffect(() => {
    if (api.authenticated && user.company?.id) {
      api
        .get(`/api/v1/company/${user.company.id}`)
        .then(({ data }) => {
          setCompany(data.data);
          formik.setValues(data.data);
        })
        .catch((err) => {
          setCompany({});
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, user.company.id]);

  const selectStyle = {
    backgroundColor: theme.palette.neutral[100],
    color: theme.palette.neutral[500], // ✅ Custom text color
    fontSize: "0.75rem", // ✅ Adjust text size
    borderRadius: "4px", // ✅ Rounded corners
    border: `1px solid ${theme.palette.neutral[400]}`, // ✅ Custom border
    "&:hover": {
      backgroundColor: theme.palette.primary[100], // ✅ Slightly darker blue on hover
      border: `2px solid ${theme.palette.primary[300]}`,
      color: theme.palette.neutral[600],
    },

    "& .MuiInputLabel-root": {
      color: `${theme.palette.neutral[600]}!important`, // ✅ Default label color
    },
    "& .MuiInputBase-input": {
      color: `${theme.palette.neutral[600]}!important`, // ✅ Ensures input text color
    },
  };

  return (
    <Card raised>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column", // ✅ Stacks content vertically
          justifyContent: "center", // ✅ Centers content vertically
          alignItems: "center", // ✅ Centers content horizontally
        }}
      >
        <CardHeader
          title="Company Settings"
          sx={{ color: theme.palette.neutral[400] }}
        ></CardHeader>
        <Divider sx={{ marginBottom: "16px" }} />
        <CardContent>
          <FormControl sx={{ width: "100%" }}>
            <Stack spacing={4}>
              <TextField
                id="company-name"
                name="companyName"
                label="Company's Name"
                variant="outlined"
                required
                disabled={isProcessing}
                value={formik.values.companyName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.companyName &&
                  Boolean(formik.errors.companyName)
                }
                helperText={
                  formik.touched.companyName && formik.errors.companyName
                }
                sx={selectStyle}
              />
              <TextField
                select
                id="company-size"
                name="companySize"
                label="Company Size"
                // variant='outlined'
                required
                disabled={isProcessing}
                value={formik.values.companySize}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.companySize &&
                  Boolean(formik.errors.companySize)
                }
                helperText={
                  formik.touched.companySize && formik.errors.companySize
                }
                SelectProps={{
                  native: true,
                }}
                sx={selectStyle}
              >
                <option value={""} sx={{ p: 4 }}></option>
                {companySizeData.map((name, index) => {
                  return (
                    <option key={index} value={name} sx={{ p: 4 }}>
                      {name}
                    </option>
                  );
                })}
              </TextField>
              <TextField
                select
                id="country"
                name="country"
                label="Country"
                variant="outlined"
                required
                disabled={isProcessing}
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={formik.touched.country && formik.errors.country}
                SelectProps={{
                  native: true,
                }}
                sx={selectStyle}
              >
                <option value={""} sx={{ p: 4 }}></option>
                {countries.map(({ name, code }) => {
                  return (
                    <option key={code} value={code} sx={{ p: 4 }}>
                      {name}
                    </option>
                  );
                })}
              </TextField>
              <TextField
                select
                id="state"
                name="state"
                label="State"
                variant="outlined"
                required
                disabled={isProcessing}
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
                SelectProps={{
                  native: true,
                }}
                sx={selectStyle}
              >
                <option value={""} sx={{ p: 4 }}></option>
                {states.map(({ name, abbreviation }) => {
                  return (
                    <option
                      key={abbreviation}
                      value={abbreviation}
                      sx={{ p: 4 }}
                    >
                      {name}
                    </option>
                  );
                })}
              </TextField>
              <Button
                onClick={handleSubmit}
                disabled={isProcessing || !formik.dirty || !formik.isValid}
                variant="contained"
                color="primary"
                type="submit"
                size="large"
              >
                {isProcessing ? (
                  <CircularProgress size={24} color="primary" />
                ) : (
                  "Update Company Settings"
                )}
              </Button>
            </Stack>
          </FormControl>
        </CardContent>
      </Box>
    </Card>
  );
};

export default Settings;

import { useState, useEffect } from "react";
import { Box, Grid, Typography, useTheme, Button, Stack } from "@mui/material";
import PayrollEditRow from "../components/PayrollEditRow";
import useFetch from "../hooks/fetchAPI";
import usePath from "../hooks/fetchPath";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import threeWeekLookBack from "../hooks/three-week-lookback";
import { tokens } from "../theme";
import BasicTabs from "../components/Tabs";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from "dayjs";
import FormHelperText from "@mui/material/FormHelperText";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { Link, Navigate } from "react-router-dom";
import AddPersonnelModal from "../components/AddPersonnelModal";
import CheckIcon from "@mui/icons-material/Check";
import ErrorIcon from "@mui/icons-material/Error";

//utils
import {
  getRandomInt,
  formatWeekArrayForDayjs,
} from "../utils/createObjectsInArray";
import { compilePayrollCreateDetails } from "../utils/compilePayollCreateDetails";
import {
  calculateObjectsInArray,
  calculateWeekendHoursArray,
} from "../utils/calculateObjectsInArray";
import { useAuth } from "../context/AuthProvider";
import AddBoxIcon from "@mui/icons-material/AddBox";

function PayrollEdit4({ user, initialDetails }) {
  console.log(initialDetails);
  const { api } = useAuth();
  const { baseURL } = usePath("/api/v1/projects/list");

  //themes
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const formattedInitialDetails = dayjs(
    initialDetails.weekEnd,
    "MM-DD-YYYY"
  ).format("MM/DD/YYYY");
  const { threeWkBk } = threeWeekLookBack();
  const [weekSelect, setWeekSelect] = useState(formattedInitialDetails);
  console.log(initialDetails);
  const [loadingHours, setloadingHours] = useState(true);
  //load all user data
  //const { data, loading, error } = useFetch('/api/v1/projects/list');
  const [errState, seterrState] = useState(false);
  const [errMsg, seterrMsg] = useState("");
  const [projectList, setProjectList] = useState([]);
  const [project1, setProject1] = useState("");
  const [project2, setProject2] = useState("");
  const [project3, setProject3] = useState("");
  const [project1Error, setproject1Error] = useState(false);
  const [project2Error, setproject2Error] = useState(false);
  const [project3Error, setproject3Error] = useState(false);
  const [consoleError, setconsoleError] = useState(false);
  const [sentPayroll, setsentPayroll] = useState(false);
  var customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);
  var weekday = require("dayjs/plugin/weekday");
  dayjs.extend(weekday);
  const [sundayDate, setsundayDate] = useState(
    dayjs(weekSelect, "MM/DD/YYYY").weekday(0).format("MM/DD/YYYY")
  );
  const [mondayDate, setmondayDate] = useState(
    dayjs(weekSelect, "MM/DD/YYYY").weekday(1).format("MM/DD/YYYY")
  );
  const [tuesdayDate, settuesdayDate] = useState(
    dayjs(weekSelect, "MM/DD/YYYY").weekday(2).format("MM/DD/YYYY")
  );
  const [wednesdayDate, setwednesdayDate] = useState(
    dayjs(weekSelect, "MM/DD/YYYY").weekday(3).format("MM/DD/YYYY")
  );
  const [thursdayDate, setthursdayDate] = useState(
    dayjs(weekSelect, "MM/DD/YYYY").weekday(4).format("MM/DD/YYYY")
  );
  const [fridayDate, setfridayDate] = useState(
    dayjs(weekSelect, "MM/DD/YYYY").weekday(5).format("MM/DD/YYYY")
  );
  const [saturdayDate, setsaturdayDate] = useState(
    dayjs(weekSelect, "MM/DD/YYYY").weekday(6).format("MM/DD/YYYY")
  );
  //project tab state
  const [personnelList, setPersonnelList] = useState([]);
  const [projectTab, setProjectTab] = useState(0);
  const [showProject2, setShowProject2] = useState(null);
  const [showProject3, setShowProject3] = useState(null);
  const [toggleTotalColor, setToggleTotalColor] = useState({
    backgroundColor: colors.primary[400],
    minWidth: "75px",
    overflow: "hidden",
  });
  const [showRowColor1, setShowRowColor1] = useState(null);
  const [showRowColor2, setShowRowColor2] = useState(null);
  const [showBottomRow1, setShowBottomRow1] = useState({
    backgroundColor: colors.grey[900],
  });
  const [showBottomRow2, setShowBottomRow2] = useState({
    backgroundColor: colors.grey[800],
  });

  const [details, setDetails] = useState(initialDetails);
  const [peopleDropdown, setPeopleDropdown] = useState("");
  const [person, setPerson] = useState("");
  const [employeeID, setemployeeID] = useState(user.employeeID);
  const [trade, setTrade] = useState(user.role);
  const [classification, setClassification] = useState(user.classification);
  //modal
  //modal
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [role, setRole] = useState("Carpenter");

  console.log("initial", initialDetails);
  console.log("details", details);

  const initweeklogs = {
    sun: formatWeekArrayForDayjs(initialDetails.sunday[0].log),
    mon: formatWeekArrayForDayjs(initialDetails.monday[0].log),
    tue: formatWeekArrayForDayjs(initialDetails.tuesday[0].log),
    wed: formatWeekArrayForDayjs(initialDetails.wednesday[0].log),
    thur: formatWeekArrayForDayjs(initialDetails.thursday[0].log),
    fri: formatWeekArrayForDayjs(initialDetails.friday[0].log),
    sat: formatWeekArrayForDayjs(initialDetails.saturday[0].log),
  };

  //Create Standard Work Days
  const [sundayLog, setsundayLog] = useState(initweeklogs.sun);
  const [mondayLog, setmondayLog] = useState(initweeklogs.mon);
  const [tuesdayLog, settuesdayLog] = useState(initweeklogs.tue);
  const [wednesdayLog, setwednesdayLog] = useState(initweeklogs.wed);
  const [thursdayLog, setthursdayLog] = useState(initweeklogs.thur);
  const [fridayLog, setfridayLog] = useState(initweeklogs.fri);
  const [saturdayLog, setsaturdayLog] = useState(initweeklogs.sat);

  //create hours subtotal states
  const [sundayHours, setsundayHours] = useState(
    calculateWeekendHoursArray(initweeklogs.sun)[0]
  );
  const [mondayRegHours, setmondayRegHours] = useState(
    calculateObjectsInArray(initweeklogs.mon)[1]
  );
  const [mondayOTHours, setmondayOTHours] = useState(
    calculateObjectsInArray(initweeklogs.mon)[2]
  );
  const [tuesdayRegHours, settuesdayRegHours] = useState(
    calculateObjectsInArray(initweeklogs.tue)[1]
  );
  const [tuesdayOTHours, settuesdayOTHours] = useState(
    calculateObjectsInArray(initweeklogs.tue)[2]
  );
  const [wednesdayRegHours, setwednesdayRegHours] = useState(
    calculateObjectsInArray(initweeklogs.wed)[1]
  );
  const [wednesdayOTHours, setwednesdayOTHours] = useState(
    calculateObjectsInArray(initweeklogs.wed)[2]
  );
  const [thursdayRegHours, setthursdayRegHours] = useState(
    calculateObjectsInArray(initweeklogs.thur)[1]
  );
  const [thursdayOTHours, setthursdayOTHours] = useState(
    calculateObjectsInArray(initweeklogs.thur)[2]
  );
  const [fridayRegHours, setfridayRegHours] = useState(
    calculateObjectsInArray(initweeklogs.fri)[1]
  );
  const [fridayOTHours, setfridayOTHours] = useState(
    calculateObjectsInArray(initweeklogs.fri)[2]
  );
  const [saturdayHours, setsaturdayHours] = useState(
    calculateWeekendHoursArray(initweeklogs.sat)[0]
  );

  //Set Week
  useEffect(() => {
    const dayofweek = dayjs().day();
    if (
      dayofweek === 0 ||
      dayofweek === 1 ||
      dayofweek === 2 ||
      dayofweek === 3 ||
      dayofweek === 4
    ) {
      const seed = formattedInitialDetails;
      setWeekSelect(seed);
      const sunday = dayjs(seed, "MM/DD/YYYY").weekday(0);
      const monday = dayjs(seed, "MM/DD/YYYY").weekday(1);
      const tuesday = dayjs(seed, "MM/DD/YYYY").weekday(2);
      const wednesday = dayjs(seed, "MM/DD/YYYY").weekday(3);
      const thursday = dayjs(seed, "MM/DD/YYYY").weekday(4);
      const friday = dayjs(seed, "MM/DD/YYYY").weekday(5);
      const saturday = dayjs(seed, "MM/DD/YYYY").weekday(6);
      setsundayDate(sunday.format("MM/DD/YYYY"));
      setmondayDate(monday.format("MM/DD/YYYY"));
      settuesdayDate(tuesday.format("MM/DD/YYYY"));
      setwednesdayDate(wednesday.format("MM/DD/YYYY"));
      setthursdayDate(thursday.format("MM/DD/YYYY"));
      setfridayDate(friday.format("MM/DD/YYYY"));
      setsaturdayDate(saturday.format("MM/DD/YYYY"));
      //change all reference
      setsundayDate(sunday.format("MM/DD/YYYY"));
    } else if (dayofweek === 5 || dayofweek === 6) {
      const seed = formattedInitialDetails;
      setWeekSelect(seed);
      const sunday = dayjs(seed, "MM/DD/YYYY").weekday(0);
      const monday = dayjs(seed, "MM/DD/YYYY").weekday(1);
      const tuesday = dayjs(seed, "MM/DD/YYYY").weekday(2);
      const wednesday = dayjs(seed, "MM/DD/YYYY").weekday(3);
      const thursday = dayjs(seed, "MM/DD/YYYY").weekday(4);
      const friday = dayjs(seed, "MM/DD/YYYY").weekday(5);
      const saturday = dayjs(seed, "MM/DD/YYYY").weekday(6);
      setsundayDate(sunday.format("MM/DD/YYYY"));
      setmondayDate(monday.format("MM/DD/YYYY"));
      settuesdayDate(tuesday.format("MM/DD/YYYY"));
      setwednesdayDate(wednesday.format("MM/DD/YYYY"));
      setthursdayDate(thursday.format("MM/DD/YYYY"));
      setfridayDate(friday.format("MM/DD/YYYY"));
      setsaturdayDate(saturday.format("MM/DD/YYYY"));
    }
  }, []);

  useEffect(() => {
    api
      .get(`/api/v1/projects/list`)
      .then((response) => {
        console.log(response);
        setProjectList(response.data.data);
        seterrState(false);
        seterrMsg("");
      })
      .catch((err) => {
        console.log("Failed", { err });
        seterrState(true);
        seterrMsg("Error Loading project list data");
      });
  }, [api]);

  useEffect(() => {
    const hiddenStyles = {
      display: "none",
      minWidth: "135px",
    };
    const altHeaderColor = {
      color: colors.grey[300],
      backgroundColor: colors.primary[600],
      minWidth: "75px",
    };

    if (projectTab === 0) {
      setShowProject2(hiddenStyles);
      setShowProject3(hiddenStyles);
      setToggleTotalColor(altHeaderColor);
      setShowRowColor1({ display: "none", minWidth: "125px" });
      setShowRowColor2({ display: "none", minWidth: "125px" });
      setShowBottomRow1({ display: "none", minWidth: "125px" });
      setShowBottomRow2({ display: "none", minWidth: "125px" });
    } else if (projectTab === 1) {
      setShowProject2({
        color: colors.grey[300],
        backgroundColor: colors.primary[600],
        minWidth: "135px",
      });
      setShowProject3(hiddenStyles);
      setToggleTotalColor({
        backgroundColor: colors.primary[400],
        color: colors.grey[500],
        minWidth: "75px",
      });
      setShowRowColor1({
        backgroundColor: colors.blueAccent[700],
        minWidth: "135px",
      });
      setShowRowColor2({ display: "none", minWidth: "135px" });
      setShowBottomRow1({
        backgroundColor: colors.grey[800],
        minWidth: "135px",
      });
      setShowBottomRow2({ display: "none", minWidth: "135px" });
    } else if (projectTab === 2) {
      setShowProject2({
        color: colors.grey[300],
        backgroundColor: colors.primary[600],
        minWidth: "135px",
      });
      setShowProject3({
        color: colors.grey[300],
        backgroundColor: colors.primary[600],
        minWidth: "135px",
      });
      setToggleTotalColor(altHeaderColor);
      setShowRowColor1({
        backgroundColor: colors.blueAccent[800],
        minWidth: "135px",
      });
      setShowRowColor2({
        backgroundColor: colors.blueAccent[900],
        minWidth: "135px",
      });
      setShowBottomRow1({
        backgroundColor: colors.grey[800],
        minWidth: "135px",
      });
      setShowBottomRow2({
        backgroundColor: colors.grey[900],
        minWidth: "135px",
      });
    }
  }, [projectTab]);

  useEffect(() => {
    if (api.authenticated) {
      api
        .get(`/api/v1/personnel?sort=fullName&limit=1000`)
        .then((response) => {
          setPersonnelList(response.data.data);
        })
        .catch((err) => {
          console.log("FAILED", { err });
        });
    }
  }, [baseURL, api.authenticated, api]);

  useEffect(() => {
    setPeopleDropdown(
      initialDetails.lastName + ", " + initialDetails.firstName
    );
  }, [initialDetails.firstName, initialDetails.lastName]);

  useEffect(() => {
    setDetails(
      compilePayrollCreateDetails(
        user,
        person,
        weekSelect,
        employeeID,
        project1,
        project2,
        project3,
        sundayLog,
        mondayLog,
        tuesdayLog,
        wednesdayLog,
        thursdayLog,
        fridayLog,
        saturdayLog,
        sundayHours,
        mondayRegHours,
        mondayOTHours,
        tuesdayRegHours,
        tuesdayOTHours,
        wednesdayRegHours,
        wednesdayOTHours,
        thursdayRegHours,
        thursdayOTHours,
        fridayRegHours,
        fridayOTHours,
        saturdayHours,
        trade,
        classification
      )
    );
    setloadingHours(false);
  }, [
    classification,
    employeeID,
    fridayLog,
    fridayOTHours,
    fridayRegHours,
    mondayLog,
    mondayOTHours,
    mondayRegHours,
    person,
    project1,
    project2,
    project3,
    saturdayHours,
    saturdayLog,
    sundayHours,
    sundayLog,
    thursdayLog,
    thursdayOTHours,
    thursdayRegHours,
    trade,
    tuesdayLog,
    tuesdayOTHours,
    tuesdayRegHours,
    user,
    wednesdayLog,
    wednesdayOTHours,
    wednesdayRegHours,
    weekSelect,
  ]);

  console.log("details", details);

  useEffect(() => {
    console.log(initialDetails);
    if (initialDetails?.analytics[0]?.project) {
      console.log(initialDetails.analytics[0].project);
      setProject1(initialDetails.analytics[0].project);
    }
    if (initialDetails?.analytics[1]?.project) {
      setProject2(initialDetails.analytics[1].project);
      setProjectTab(1);
    }
    if (initialDetails?.analytics[2]?.project) {
      setProject3(initialDetails.analytics[2].project);
      setProjectTab(2);
    }
  }, [initialDetails]);

  //when people dropdown changes, change classification and role
  const handlePeopleChange = (e) => {
    const newName = e.target.value;
    setPeopleDropdown(newName);
    setPerson(newName);

    const findFunction = async function (array) {
      const found = await array.find((elem) => elem.fullName === newName);
      return found;
    };

    findFunction(personnelList).then((found) => {
      setemployeeID(found.employeeID);
      setTrade(found.role);
      setClassification(found.classification);
      //use split function
      //   const names = found.fullName.split(',');
      //   const firstName = names[1].trim();
      //   const lastName = names[0].trim();
      //   const name = firstName + '_' + lastName + '_' + weekSelect;

      //   const final = {
      //     ...details,
      //     name: name,
      //     classification: found.classification,
      //     trade: found.role,
      //     employeeID: found.employeeID,
      //     fullName: found.fullName,
      //     firstName: firstName,
      //     lastName: lastName,
      //   };
      // console.log(final);
      //setPerson(found.fullName);
      //setDetails(final);
    });
  };

  // Payroll Create Stuff
  const submitPayroll = async () => {
    api
      .put(baseURL + `/api/v1/timecardsv2/${initialDetails.id}`, details)
      .then((response) => {
        console.log(response);
        setsentPayroll(true);
      })
      .catch((error) => {
        setconsoleError(true);
        if (error.response.data.error === "Duplicate field value entered") {
          seterrMsg(
            "Only 1 Timecard Per Week Per User (Duplicate Field Entered)"
          );
        } else {
          seterrState(true);
          seterrMsg(error.message);
        }
      });
  };

  const checkErrors = () => {
    seterrState(false);
    let error;
    if (project1 === "" && projectTab === 0) {
      seterrState(true);
      seterrMsg("Please select a project ");
      error = true;
    } else if (
      (project2 === "" && projectTab === 1) ||
      (project1 === "" && projectTab === 1)
    ) {
      seterrState(true);
      seterrMsg("Please select a project ");
      error = true;
    } else if (
      (project3 === "" || project2 === "" || project1 === "") &&
      projectTab === 2
    ) {
      seterrState(true);
      seterrMsg("Please select a project ");
      error = true;
    }
    return error;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const error = checkErrors();
    if (!error) {
      submitPayroll(details);
    }
  };

  //Create dropdown function for time pickers
  const CreateDropdownComponent = ({
    error,
    data,
    state,
    label,
    callBack,
    id,
  }) => {
    return (
      <FormControl
        variant="standard"
        error={error}
        sx={{
          m: 1,
          minWidth: "100px",
          width: "90%",
          "& .MuiInputLabel-formControl": {
            color: "grey",
          },
        }}
      >
        <InputLabel id="demo-simple-select-error-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={state}
          onChange={(prev) => {
            const newValue = prev.target.value;
            callBack(newValue);

            if (id === "project1") {
              sundayLog[0].project = prev.target.value;
              mondayLog[0].project = prev.target.value;
              tuesdayLog[0].project = prev.target.value;
              wednesdayLog[0].project = prev.target.value;
              thursdayLog[0].project = prev.target.value;
              fridayLog[0].project = prev.target.value;
              saturdayLog[0].project = prev.target.value;
            } else if (id === "project2") {
              sundayLog[1].project = prev.target.value;
              mondayLog[1].project = prev.target.value;
              tuesdayLog[1].project = prev.target.value;
              wednesdayLog[1].project = prev.target.value;
              thursdayLog[1].project = prev.target.value;
              fridayLog[1].project = prev.target.value;
              saturdayLog[1].project = prev.target.value;
            } else if (id === "project3") {
              sundayLog[2].project = prev.target.value;
              mondayLog[2].project = prev.target.value;
              tuesdayLog[2].project = prev.target.value;
              wednesdayLog[2].project = prev.target.value;
              thursdayLog[2].project = prev.target.value;
              fridayLog[2].project = prev.target.value;
              saturdayLog[2].project = prev.target.value;
            }

            if (newValue && id === "project1") {
              setproject1Error(false);
            } else if (newValue === "" && id === "project1") {
              setproject1Error(true);
            } else if (newValue && id === "project2") {
              setproject2Error(false);
            } else if (newValue === "" && id === "project1") {
              setproject2Error(true);
            } else if (newValue && id === "project3") {
              setproject3Error(false);
            } else if (newValue === "" && id === "project3") {
              setproject3Error(true);
            }
          }}
          label="Start"
        >
          {projectList &&
            projectList.map((value, index) => {
              return (
                <MenuItem key={index + getRandomInt(1, 1000)} value={value}>
                  {value}
                </MenuItem>
              );
            })}
        </Select>
        <FormHelperText>
          {project1Error && id === "project1" ? "Please enter a project" : null}
          {project2Error && id === "project2" ? "Please enter a project" : null}
          {project3Error && id === "project3" ? "Please enter a project" : null}
        </FormHelperText>
      </FormControl>
    );
  };

  useEffect(() => {
    if (person) {
      const found = personnelList.find((elem) => elem.fullName === person);
      setemployeeID(found.employeeID);
    }
  }, [person, personnelList]);

  const CreateWeekEndDropDown = ({ input, state, label, onChange }) => {
    return (
      <FormControl
        variant="standard"
        sx={{
          m: 1,
          minWidth: "100px",
          width: "90%",
          "& .MuiInputLabel-formControl": {
            color: "grey",
          },
        }}
      >
        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>
        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={state}
          onChange={onChange}
          label="Start"
        >
          {input.map((value, index) => {
            return (
              <MenuItem key={index + getRandomInt(1001, 2000)} value={value}>
                {value}
              </MenuItem>
            );
          })}
          <MenuItem
            key={formattedInitialDetails}
            value={formattedInitialDetails}
          >
            {formattedInitialDetails}
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  const CreatePeopleDropDown = ({ input, state, label, onChange }) => {
    return (
      <FormControl
        variant="standard"
        sx={{
          m: 1,
          minWidth: "100px",
          width: "90%",
          "& .MuiInputLabel-formControl": {
            color: "grey",
          },
        }}
      >
        <InputLabel id="demo-simple-select-standard-label">{label}</InputLabel>

        <Select
          labelId="demo-simple-select-standard-label"
          id="demo-simple-select-standard"
          value={state}
          onChange={(e) => handlePeopleChange(e)}
          label="Start"
        >
          {input.map((value, index) => {
            return (
              <MenuItem
                key={index + getRandomInt(2001, 3000)}
                value={value.fullName}
                name={value.employeeID}
              >
                {value.fullName}
              </MenuItem>
            );
          })}
        </Select>
        <Box sx={{ display: "flex", justifyContent: "left", pt: 1 }}>
          <Link component="button" variant="body2" onClick={handleOpen}>
            <AddBoxIcon />
          </Link>
        </Box>
      </FormControl>
    );
  };

  const updateLogsReferenceDate = (prevdaylog, dayjsObj, weekEnd, index) => {
    //provide new reference date
    const weekEndFormatted = dayjs(weekEnd, "MM/DD/YYYY").format("YYYYMMDD");
    const defaultStart = dayjs(weekEndFormatted + "T7:00").day(index);
    const defaultEnd = dayjs(weekEndFormatted + "T15:30").day(index);
    //loop through each array changing the reference date property

    let newDayLog = [...prevdaylog];
    //if 1 project

    newDayLog.map((elem) => {
      if (
        elem.id === "mon1" ||
        elem.id === "tue1" ||
        elem.id === "wed1" ||
        elem.id === "thur1" ||
        elem.id === "fri1"
      ) {
        elem.referenceDate = dayjsObj;
        elem.start = defaultStart;
        elem.end = defaultEnd;
      } else {
        elem.referenceDate = dayjsObj;
      }
      return elem;
    });
    return newDayLog;
  };

  return (
    <Box>
      <Box sx={{ width: "100%", pb: 3 }}>
        <form onSubmit={handleSubmit}>
          <Link to="/DashBoard" />
          {errState ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>{errMsg}!</strong>
            </Alert>
          ) : null}

          {consoleError ? (
            <Alert severity="error">
              <AlertTitle>Error</AlertTitle>
              <strong>{errMsg}!</strong>
            </Alert>
          ) : null}

          <BasicTabs value={projectTab} setValue={setProjectTab} />

          <AddPersonnelModal
            open={open}
            handleClose={handleClose}
            firstName={firstName}
            setFirstName={setFirstName}
            lastName={lastName}
            setLastName={setLastName}
            role={role}
            setRole={setRole}
            classification={classification}
            setClassification={setClassification}
          />
          <Grid
            container
            spacing={1}
            justifyContent="center"
            wrap="nowrap"
            sx={{ mx: 1, px: 1, py: 0.3, pt: 4 }}
          >
            {/* Column 1 Start */}

            <Grid
              item
              xs={2}
              sx={{
                backgroundColor: colors.primary[400],
                minWidth: "125px",
              }}
            >
              <CreateWeekEndDropDown
                input={threeWkBk}
                state={weekSelect}
                label="Week Ending"
                onChange={(e) => {
                  setWeekSelect(e.target.value);
                  const newDate = e.target.value;
                  const sunday = dayjs(newDate, "MM/DD/YYYY").weekday(0);
                  const monday = dayjs(newDate, "MM/DD/YYYY").weekday(1);
                  const tuesday = dayjs(newDate, "MM/DD/YYYY").weekday(2);
                  const wednesday = dayjs(newDate, "MM/DD/YYYY").weekday(3);
                  const thursday = dayjs(newDate, "MM/DD/YYYY").weekday(4);
                  const friday = dayjs(newDate, "MM/DD/YYYY").weekday(5);
                  const saturday = dayjs(newDate, "MM/DD/YYYY").weekday(6);
                  //change all reference
                  //sunday
                  setsundayDate(sunday.format("MM/DD/YYYY"));
                  setsundayLog(
                    updateLogsReferenceDate(sundayLog, sunday, newDate, 0)
                  );
                  //monday
                  setmondayDate(monday.format("MM/DD/YYYY"));
                  setmondayLog(
                    updateLogsReferenceDate(mondayLog, monday, newDate, 1)
                  );
                  //tuesday
                  settuesdayDate(tuesday.weekday(2).format("MM/DD/YYYY"));
                  settuesdayLog(
                    updateLogsReferenceDate(tuesdayLog, tuesday, newDate, 2)
                  );
                  //wednesday
                  setwednesdayDate(wednesday.format("MM/DD/YYYY"));
                  setwednesdayLog(
                    updateLogsReferenceDate(wednesdayLog, wednesday, newDate, 3)
                  );
                  //thursday
                  setthursdayDate(thursday.weekday(4).format("MM/DD/YYYY"));
                  setthursdayLog(
                    updateLogsReferenceDate(thursdayLog, thursday, newDate, 4)
                  );
                  //friday
                  setfridayDate(friday.weekday(5).format("MM/DD/YYYY"));
                  setfridayLog(
                    updateLogsReferenceDate(fridayLog, friday, newDate, 5)
                  );
                  //saturday
                  setsaturdayDate(saturday.format("MM/DD/YYYY"));
                  setsaturdayLog(
                    updateLogsReferenceDate(saturdayLog, saturday, newDate, 6)
                  );
                }}
              />
            </Grid>

            {/* Column 2 Start */}
            <Grid
              item
              xs={2.5}
              sx={{
                backgroundColor: colors.primary[500],
                minWidth: "135px",
              }}
            >
              <CreateDropdownComponent
                error={project1Error}
                data={projectList}
                state={project1}
                label="Project"
                callBack={setProject1}
                id="project1"
              />
            </Grid>
            <Grid item xs={2.5} sx={showProject2}>
              <CreateDropdownComponent
                error={project2Error}
                data={projectList}
                state={project2}
                label="Project"
                callBack={setProject2}
                id="project2"
              />
            </Grid>
            <Grid item xs={2.5} sx={showProject3}>
              <CreateDropdownComponent
                error={project3Error}
                data={projectList}
                state={project3}
                label="Project"
                callBack={setProject3}
                id="project3"
              />
            </Grid>
            {/* Column 3 Start */}
            <Grid
              item
              xs={1.5}
              sx={{
                backgroundColor: colors.primary[400],
                minWidth: "125px",
              }}
            >
              <CreatePeopleDropDown
                input={personnelList}
                state={peopleDropdown}
                label="Personnel"
                id="people"
              />
            </Grid>
          </Grid>

          <PayrollEditRow
            day="Sunday"
            date={sundayDate}
            alternateColors="true"
            log={sundayLog}
            setLog={setsundayLog}
            format="sunday"
            shorthand="sun"
            showRowColor1={showRowColor1}
            showRowColor2={showRowColor2}
            SunHours={sundayHours}
            setSunHours={setsundayHours}
            seterrState={seterrState}
            seterrMsg={seterrMsg}
          />
          <PayrollEditRow
            day="Monday"
            date={mondayDate}
            alternateColors="false"
            log={mondayLog}
            setLog={setmondayLog}
            format="weekday"
            shorthand="mon"
            showRowColor1={showRowColor1}
            showRowColor2={showRowColor2}
            regHours={mondayRegHours}
            setRegHours={setmondayRegHours}
            OTHours={mondayOTHours}
            setOTHours={setmondayOTHours}
            seterrState={seterrState}
            seterrMsg={seterrMsg}
          />
          <PayrollEditRow
            day="Tuesday"
            date={tuesdayDate}
            alternateColors="true"
            log={tuesdayLog}
            setLog={settuesdayLog}
            format="weekday"
            shorthand="tue"
            showRowColor1={showRowColor1}
            showRowColor2={showRowColor2}
            regHours={tuesdayRegHours}
            setRegHours={settuesdayRegHours}
            OTHours={tuesdayOTHours}
            setOTHours={settuesdayOTHours}
            seterrState={seterrState}
            seterrMsg={seterrMsg}
          />
          <PayrollEditRow
            day="Wednesday"
            date={wednesdayDate}
            alternateColors="false"
            log={wednesdayLog}
            setLog={setwednesdayLog}
            format="weekday"
            shorthand="wed"
            showRowColor1={showRowColor1}
            showRowColor2={showRowColor2}
            regHours={wednesdayRegHours}
            setRegHours={setwednesdayRegHours}
            OTHours={wednesdayOTHours}
            setOTHours={setwednesdayOTHours}
            seterrState={seterrState}
            seterrMsg={seterrMsg}
          />
          <PayrollEditRow
            day="Thursday"
            date={thursdayDate}
            alternateColors="true"
            log={thursdayLog}
            setLog={setthursdayLog}
            format="weekday"
            shorthand="thur"
            showRowColor1={showRowColor1}
            showRowColor2={showRowColor2}
            regHours={thursdayRegHours}
            setRegHours={setthursdayRegHours}
            OTHours={thursdayOTHours}
            setOTHours={setthursdayOTHours}
            seterrState={seterrState}
            seterrMsg={seterrMsg}
          />
          <PayrollEditRow
            day="Friday"
            date={fridayDate}
            alternateColors="false"
            log={fridayLog}
            setLog={setfridayLog}
            format="weekday"
            shorthand="fri"
            showRowColor1={showRowColor1}
            showRowColor2={showRowColor2}
            regHours={fridayRegHours}
            setRegHours={setfridayRegHours}
            OTHours={fridayOTHours}
            setOTHours={setfridayOTHours}
            seterrState={seterrState}
            seterrMsg={seterrMsg}
          />
          <PayrollEditRow
            day="Saturday"
            date={saturdayDate}
            alternateColors="true"
            log={saturdayLog}
            setLog={setsaturdayLog}
            format="saturday"
            shorthand="sat"
            showRowColor1={showRowColor1}
            showRowColor2={showRowColor2}
            SatHours={saturdayHours}
            setSatHours={setsaturdayHours}
            seterrState={seterrState}
            seterrMsg={seterrMsg}
          />

          <Grid
            container
            spacing={1}
            justifyContent="center"
            wrap="nowrap"
            sx={{ mx: 1, px: 1, py: 0.3 }}
          >
            <Grid
              item
              xs={2}
              sx={{
                backgroundColor: colors.grey[800],
                minWidth: "125px",
              }}
            ></Grid>
            <Grid
              item
              xs={2.5}
              sx={{
                backgroundColor: colors.grey[900],
                minWidth: "135px",
              }}
            >
              {loadingHours ? null : (
                <Typography>
                  {"Subtotal: "}
                  {isNaN(
                    details?.analytics[1]?.regTotal +
                      details?.analytics[1]?.OTTotal +
                      details?.analytics[1]?.satTotal +
                      details?.analytics[1]?.sunTotal
                  )
                    ? 0
                    : details?.analytics[1]?.regTotal +
                      details?.analytics[1]?.OTTotal +
                      details?.analytics[1]?.satTotal +
                      details?.analytics[1]?.sunTotal}
                </Typography>
              )}
            </Grid>
            <Grid item xs={2.5} sx={showBottomRow1}>
              <Typography sx={{ color: colors.greenAccent[500] }}>
                {"Subtotal: "}
                {isNaN(
                  details?.analytics[1]?.regTotal +
                    details?.analytics[1]?.OTTotal +
                    details?.analytics[1]?.satTotal +
                    details?.analytics[1]?.sunTotal
                )
                  ? 0
                  : details?.analytics[1]?.regTotal +
                    details?.analytics[1]?.OTTotal +
                    details?.analytics[1]?.satTotal +
                    details?.analytics[1]?.sunTotal}
              </Typography>
            </Grid>
            <Grid item xs={2.5} sx={showBottomRow2}>
              <Typography sx={{ color: colors.greenAccent[500] }}>
                {"Subtotal: "}
                {isNaN(
                  details?.analytics[2]?.regTotal +
                    details?.analytics[2]?.OTTotal +
                    details?.analytics[2]?.satTotal +
                    details?.analytics[2]?.sunTotal
                )
                  ? 0
                  : details?.analytics[2]?.regTotal +
                    details?.analytics[2]?.OTTotal +
                    details?.analytics[2]?.satTotal +
                    details?.analytics[2]?.sunTotal}
              </Typography>
            </Grid>
            <Grid
              item
              xs={1.5}
              sx={{ backgroundColor: colors.grey[800], minWidth: "125px" }}
            >
              <Stack direction="row">
                <Typography sx={{ px: 1 }}> Total: </Typography>
                <Typography sx={{ color: colors.greenAccent[500] }}>
                  {details.regHours +
                    details.overtimeHours +
                    details.satHours +
                    details.sunHours}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          {sentPayroll ? (
            <Grid container justifyContent="center" spacing={1} sx={{ pt: 2 }}>
              <Grid item xs={9.3}>
                <Alert
                  icon={<CheckIcon fontSize="inherit" />}
                  severity="success"
                >
                  Your Save Was Successful!
                </Alert>
              </Grid>
            </Grid>
          ) : null}
          {errState ? (
            <Grid container justifyContent="center" spacing={1} sx={{ pt: 2 }}>
              <Grid item xs={7}>
                <Alert icon={<ErrorIcon fontSize="inherit" />} severity="error">
                  {errMsg}
                </Alert>
              </Grid>
            </Grid>
          ) : null}

          <Grid container justifyContent="center" spacing={1} sx={{ pt: 2 }}>
            <Grid item xs={1.5}>
              <Button
                variant="contained"
                sx={{ backgroundColor: colors.greenAccent[500] }}
                onClick={handleSubmit}
                endIcon={<SaveIcon />}
              >
                Save
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Box>
  );
}

export default PayrollEdit4;

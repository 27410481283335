import Timecard from "../timecard";
import * as React from "react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import TopBarAdmin from "../components/TopBarAdmin";
import usePath from "../hooks/fetchPath";
import { useAuth } from "../context/AuthProvider";

function UserPageTimecards({ user }) {
  const { api, success } = useAuth();
  const [errMsg, setErrMsg] = useState([]);
  const [project, setProject] = React.useState("");
  const [startQry, setStartQry] = React.useState("");
  const [endQry, setEndQry] = React.useState("");
  const [pageState, setPageState] = useState({
    isLoading: false,
    data: [],
    total: 0,
    page: 0,
    pageSize: 50,
  });

  useEffect(() => {
    async function GetResults(data) {
      let results = await data.filter((x) => x.approved === false);
    }
    GetResults(pageState.data);
  }, [pageState.data]);

  useEffect(() => {
    if (api.authenticated) {
      const pageLimit = `&limit=${pageState.pageSize}`;
      api
        .get(
          `/api/v1/timecardsv2/mytimecards/?page=${pageState.page + 1}` +
            pageLimit
        )
        .then((response) => {
          const update = {
            data: response.data.data[0].results,
            page: response.data.data[0].pagination[0].page - 1,
            pageSize: response.data.data[0].pagination[0].limit,
            total: response.data.data[0].pagination[0].totaldocuments,
          };

          setPageState((old) => ({
            ...old,
            ...update,
          }));
        })
        .catch((err) => {
          console.log("FAILED", { err });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageState.page, pageState.pageSize, api?.authenticated]);

  return (
    <div>
      <Box sx={{ height: "100dvh" }}>
        {/* <Typography>{error}</Typography> */}
        <TopBarAdmin />
        <Timecard
          //data={data}
          user={user}
          project={project}
          setProject={setProject}
          startQry={startQry}
          setStartQry={setStartQry}
          endQry={endQry}
          setEndQry={setEndQry}
          pageState={pageState}
          setPageState={setPageState}
        />
        {errMsg}
      </Box>
    </div>
  );
}

export default UserPageTimecards;

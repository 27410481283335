import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Slide, Stack } from "@mui/material";

const CookieBanner = () => {
  const [showBanner, setShowBanner] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted cookies
    const acceptedCookies = localStorage.getItem("cookiesAccepted");
    if (!acceptedCookies) {
      setShowBanner(true);
    }
  }, []);

  const handleAccept = () => {
    localStorage.setItem("cookiesAccepted", "true");
    setShowBanner(false);
  };

  return (
    <Slide direction="down" in={showBanner} mountOnEnter unmountOnExit>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          left: 0,
          width: "100%",
          bgcolor: "rgba(7, 7, 16, .7)",
          color: "white",
          p: 2,

          alignItems: "center",
          zIndex: 1500,
        }}
      >
        <Stack
          direction="row"
          sx={{
            justifyContent: "space-between",
          }}
        >
          <Typography variant="body1" sx={{ pt: "10px" }}>
            🍪 This site uses cookies to enhance your experience.
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            size="small"
            onClick={handleAccept}
          >
            Got it
          </Button>
        </Stack>
      </Box>
    </Slide>
  );
};

export default CookieBanner;

import { useMemo, useState, useEffect } from "react";
import DashBoardBox from "../components/DashBoardBox";
import { useTheme, Box, Stack } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import Alert from "@mui/material/Alert";
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  AreaChart,
  Legend,
  Bar,
  BarChart,
  Area,
} from "recharts";
import AnalyticsBoxHeader3 from "../components/AnalyticsBoxHeader3";
import CustomSelect from "../styledcomponents/CustomSelect";
import dayjs from "dayjs";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

const Row2 = ({
  swData,
  error,
  errMsg,
  professionalSearchFilters,
  monthlySearchFilters,
}) => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  console.log(theme);
  const [options, setOptions] = useState([]);
  const [swDataInverted, setSwDataInverted] = useState([]);
  const [headerLabel, setHeaderLabel] = useState("");
  const isoWeek = require("dayjs/plugin/isoWeek");
  dayjs.extend(isoWeek);
  const renderedColumns = useMemo(() => {
    const headers = [
      "key",
      "month",
      "year",
      "reg",
      "ot",
      "sat",
      "sun",
      "totalHours",
    ];
    //create columns for each trade
    const columns = headers.map((elem) => {
      return {
        field: elem,
        headerName: elem,
        flex: 0.4,
      };
    });
    return columns;
  }, []);

  const COLORS = ["#97C3F0", "#4393E4", "#0B6BCB", "#185EA5"];
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    if (swData) {
      const updatedData = swData.map((item) => ({
        ...item,
        monthLetter: monthNames[item.month - 1][0], // Get first letter of month
      }));

      setSwDataInverted(updatedData.slice().reverse());
    }
  }, [swData]);

  // Function to get the start date of the given week and year
  function getWeekStartDate(year, month) {
    // Handle null or undefined values by setting defaults
    const validYear = year ?? dayjs().year(); // Use current year if year is null or undefined
    const validMonth = month ? month - 1 : 1; // Default to week 1 if Month is null or undefined

    // Ensure the inputs are valid before proceeding
    if (!validYear || !validMonth) {
      return dayjs(); // Return today's date if inputs are invalid
    }

    // Calculate the start of the week based on the provided year and weekNumber
    return dayjs().year(validYear).month(validMonth).format("MM/YYYY");
  }

  useEffect(() => {
    if (monthlySearchFilters) {
      const formattedStart = getWeekStartDate(
        monthlySearchFilters?.startYear,
        monthlySearchFilters?.startMonth
      );
      const formattedEnd = getWeekStartDate(
        monthlySearchFilters?.endYear,
        monthlySearchFilters?.endMonth
      );
      setHeaderLabel(formattedStart + "-" + formattedEnd);
    }
  }, [monthlySearchFilters]);

  return (
    <>
      <DashBoardBox gridArea="d">
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <AnalyticsBoxHeader3
            title="Total Hours Data Grid"
            subtitle={professionalSearchFilters?.project + "-" + headerLabel}
          />
        </Stack>
        {error?.sw === true ? (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            {errMsg.sw}
          </Alert>
        ) : null}

        {
          //if there is no data, show nothing, else show the
          //DataGrid with the data
          swData && swData.length === 0 ? (
            <Box height="0%" />
          ) : (
            <ResponsiveContainer width="95%" height="80%">
              <Box
                p="0 0.5rem"
                height="85%"
                sx={{
                  "& .MuiDataGrid-root": {
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.neutral[600] // ✅ White text for dark mode
                        : theme.palette.neutral[900], // ✅ Dark text for light mode
                    border: "none",
                    backgroundColor:
                      theme.palette.mode === "dark"
                        ? theme.palette.neutral[100] // ✅ Dark mode bg
                        : theme.palette.neutral[50], // ✅ Light mode bg
                  },
                  "& .MuiDataGrid-cell": {
                    borderBottom: `1px solid ${theme.palette.neutral[300]} !important`,
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.neutral[600] // ✅ White text for dark mode
                        : theme.palette.neutral[400], // ✅ Dark text for light mode
                  },
                  "& .MuiDataGrid-columnHeaders": {
                    color:
                      theme.palette.mode === "dark"
                        ? theme.palette.neutral[600] // ✅ Light header text in dark mode
                        : theme.palette.neutral[500], // ✅ Dark header text in light mode
                    fontWeight: "bold",
                  },
                  "& .MuiDataGrid-columnSeparator": {
                    visibility: "hidden",
                  },
                }}
              >
                <DataGrid
                  rowHeight={20}
                  columnHeaderHeight={25}
                  hideFooter={true}
                  rows={swData || []}
                  columns={renderedColumns || []}
                  pageSizeOptions={[-1]}
                />
              </Box>
            </ResponsiveContainer>
          )
        }
      </DashBoardBox>
      <DashBoardBox gridArea="e">
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <AnalyticsBoxHeader3
            title="Total Hours Line Graph"
            subtitle={professionalSearchFilters?.project + "-" + headerLabel}
          />
        </Stack>
        {error?.sw ? (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            {errMsg.sw}
          </Alert>
        ) : null}
        {swData && swData.length === 0 ? (
          <Box height="0%" />
        ) : (
          <ResponsiveContainer width="100%" height="80%">
            <AreaChart
              width={730}
              height={250}
              data={swDataInverted}
              margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
            >
              <defs>
                <linearGradient id="totalHours" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#FF7043" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#FF7043" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#82ca9d" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#82ca9d" stopOpacity={0} />
                </linearGradient>
              </defs>
              <XAxis
                dataKey="monthLetter"
                tick={({ x, y, payload, index }) => {
                  const dataItem = swDataInverted?.[index];
                  return (
                    <text
                      x={x}
                      y={y}
                      dy={16}
                      textAnchor="middle"
                      fill={
                        dataItem?.year === dayjs().year() ? "#C94B35" : "grey"
                      }
                      style={{
                        fontSize: ".75rem",
                        fontFamily: "IBM Plex Sans Condensed",
                      }}
                    >
                      {payload.value}
                    </text>
                  );
                }}
                stroke={theme.palette.neutral[500]}
              />
              <YAxis
                tickLine={true}
                axisLine={false}
                stroke={theme.palette.neutral[400]}
                style={{
                  fontSize: ".75rem",
                  fontFamily: "IBM Plex Sans Condensed",
                }}
              />

              <Tooltip />
              <Area
                type="monotone"
                dataKey="totalHours"
                stroke="#C94B35"
                fillOpacity={1}
                fill="url(#totalHours)"
              />
            </AreaChart>
          </ResponsiveContainer>
        )}
      </DashBoardBox>
      <DashBoardBox gridArea="f">
        <Stack direction="row" spacing={2} justifyContent="space-between">
          <AnalyticsBoxHeader3
            title="Total Hours Stacked Bar"
            subtitle={professionalSearchFilters?.project + "-" + headerLabel}
          />
        </Stack>
        {error?.sw ? (
          <Alert
            severity="warning"
            sx={{
              backgroundColor:
                theme.palette.mode === "dark"
                  ? "rgba(255, 165, 0, 0.2)"
                  : "rgba(255, 165, 0, 0.1)",
              color: theme.palette.mode === "dark" ? "black" : "white",
            }}
          >
            {errMsg.sw}
          </Alert>
        ) : null}

        {swDataInverted && swDataInverted.length === 0 ? (
          <Box height="0%" />
        ) : (
          <ResponsiveContainer width="100%" height="80%">
            <BarChart
              width={700}
              height={300}
              data={swDataInverted}
              margin={{ top: 20, right: 40, left: 10, bottom: 5 }}
              barSize={10}
            >
              <defs>
                {/* Regular Hours Gradient */}
                <linearGradient id="regGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#8884d8" stopOpacity={1} />
                  <stop offset="100%" stopColor="#8884d8" stopOpacity={0.6} />
                </linearGradient>

                {/* Overtime Hours Gradient */}
                <linearGradient
                  id="overtimeGradient"
                  x1="0"
                  y1="0"
                  x2="0"
                  y2="1"
                >
                  <stop offset="0%" stopColor="#82ca9d" stopOpacity={1} />
                  <stop offset="100%" stopColor="#82ca9d" stopOpacity={0.6} />
                </linearGradient>

                {/* Saturday Hours Gradient */}
                <linearGradient id="satGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#FFBB28" stopOpacity={1} />
                  <stop offset="100%" stopColor="#FFBB28" stopOpacity={0.6} />
                </linearGradient>

                {/* Sunday Hours Gradient */}
                <linearGradient id="sunGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#FF8042" stopOpacity={1} />
                  <stop offset="100%" stopColor="#FF8042" stopOpacity={0.6} />
                </linearGradient>

                {/* Total Hours Gradient */}
                <linearGradient id="totalGradient" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="0%" stopColor="#FF7043" stopOpacity={1} />
                  <stop offset="100%" stopColor="#FF7043" stopOpacity={0.6} />
                </linearGradient>

                {/* Drop Shadow Filter */}
                <filter
                  id="shadow"
                  x="-20%"
                  y="-20%"
                  width="140%"
                  height="140%"
                >
                  <feDropShadow
                    dx="2"
                    dy="2"
                    stdDeviation="3"
                    floodColor="rgba(0, 0, 0, 0.3)"
                  />
                </filter>
              </defs>
              {/* Solid Grid Lines */}
              <CartesianGrid
                strokeDasharray="0"
                stroke={theme.palette.neutral[100]}
              />

              {/* X-Axis: Conditionally Styled */}
              <XAxis
                dataKey="monthLetter"
                tick={({ x, y, payload, index }) => {
                  // Find the corresponding data entry
                  // Get the corresponding data item based on the index
                  const dataItem = swDataInverted?.[index];

                  return (
                    <text
                      x={x}
                      y={y}
                      dy={16}
                      textAnchor="middle"
                      fill={
                        dataItem?.year === dayjs().year() ? "white" : "grey"
                      }
                      style={{ fontSize: 12 }}
                    >
                      {payload.value}
                    </text>
                  );
                }}
                // Ensures tick labels are visible
                tickCount={12}
                interval={0}
              />

              {/* Y-Axis: Ensure stroke & tick visibility */}
              <YAxis
                stroke={theme.palette.neutral[400]}
                tick={{ fill: theme.palette.neutral[400] }}
                style={{
                  fontSize: ".75rem",
                  fontFamily: "IBM Plex Sans Condensed",
                }}
              />

              {/* Tooltip & Legend */}
              <Tooltip />
              <Legend />

              {/* Dynamically Generated Bars */}
              {options?.map((entry, index) => (
                <Bar
                  key={`bar-${index}`}
                  dataKey={entry}
                  stackId="a"
                  fill={COLORS[index % COLORS.length]}
                />
              ))}

              {/* Hardcoded Bars */}
              <Bar
                dataKey="reg"
                stackId="a"
                fill="url(#regGradient)"
                stroke="#4a2abb" // ✅ Darker shade of #8884d8
                strokeWidth={2}
                filter="url(#shadow)"
              />
              <Bar
                dataKey="ot"
                stackId="a"
                fill="url(#overtimeGradient)"
                stroke="#2d7a4e" // ✅ Darker shade of #82ca9d
                strokeWidth={2}
                filter="url(#shadow)"
              />
              <Bar
                dataKey="sat"
                stackId="a"
                fill="url(#satGradient)"
                stroke="#E69A00" // ✅ Darker shade of #FFBB28
                strokeWidth={2}
                filter="url(#shadow)"
              />
              <Bar
                dataKey="sun"
                stackId="a"
                fill="url(#sunGradient)"
                stroke="#D5642C" // ✅ Darker shade of #FF8042
                strokeWidth={2}
                filter="url(#shadow)"
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </DashBoardBox>
    </>
  );
};

export default Row2;

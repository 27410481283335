import {
  AppBar,
  Container,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  Box,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import { useState, useEffect } from "react";
import useScrollPosition from "../hooks/useScrollPosition";

import DTCLogo from "../assets/DTCLogo.png";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MenuIcon from "@mui/icons-material/Menu";
import LanguageIcon from "@mui/icons-material/Language";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Title from "./title";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";

const options = [
  { name: "Home", path: "/" },
  { name: "About", path: "/AboutPage" },
  { name: "Pricing", path: "/pricing" },
  { name: "Terms And Agreements", path: "/TermsAndAgreementPage" },
  { name: "Privacy Policy", path: "/PrivacyPolicyPage" },
  { name: "Contact", path: "/ContactPage" },
  { name: "FAQ", path: "/FAQPage" },
  { name: "Login", path: "/Login" },
];

const LinkButton = ({ children, ...props }) => (
  <Stack
    direction="row"
    alignItems="center"
    spacing={0.2}
    sx={{
      cursor: "pointer",
      color: "text.secondary",
      "&:hover": { color: "text.primary" },
    }}
    {...props}
  >
    {children}
  </Stack>
);

const LandingPageNavBar = () => {
  const scrollPosition = useScrollPosition();
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  //Menu Options
  const handleClick = (event) => {
    setAnchorElMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorElMenu(null);
  };
  const [anchorElMenu, setAnchorElMenu] = useState(null);
  const open = Boolean(anchorElMenu);
  const handleClick2 = (event) => {
    setAnchorElMenu2(event.currentTarget);
  };
  const handleClose2 = () => {
    setAnchorElMenu2(null);
  };
  const [anchorElMenu2, setAnchorElMenu2] = useState(null);
  const open2 = Boolean(anchorElMenu2);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const handleMenuItemClick = (event, index) => {
    console.log(event.target.value);
    setSelectedIndex(index);
    setAnchorElMenu(null);
  };

  useEffect(() => {
    if (window.location.pathname === "/") {
      setSelectedIndex(0);
    } else if (window.location.pathname === "/AboutPage") {
      setSelectedIndex(1);
    } else if (window.location.pathname === "/TermsAndAgreementPage") {
      setSelectedIndex(2);
    } else if (window.location.pathname === "/PrivacyPolicyPage") {
      setSelectedIndex(3);
    } else if (window.location.pathname === "/ContactPage") {
      setSelectedIndex(4);
    } else if (window.location.pathname === "/FAQPage") {
      setSelectedIndex(5);
    }
  }, []);

  return (
    <AppBar
      elevation={0}
      sx={{
        py: 1,
        height: 95,
        bgcolor: scrollPosition > 10 ? "rgba(7, 7, 16, .7)" : "transparent",
        backdropFilter: scrollPosition > 10 && "blur(60px)",
      }}
    >
      <Container
        sx={{
          [theme.breakpoints.up("lg")]: {
            maxWidth: "1380px!important",
          },
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          flexWrap="wrap"
          sx={{ py: 2 }}
        >
          <Link to={"/"}>
            <img
              src={DTCLogo}
              style={{ height: "50px", objectFit: "contain" }}
              alt="buildlogo"
            />
          </Link>
          {!isMobile ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={6}
              sx={{ flex: 1 }}
              flexWrap="wrap"
            >
              <Link to={"/"}>
                {" "}
                <Title variant={{ xs: "joysmall", md: "joymed" }}>
                  {" "}
                  Home{" "}
                </Title>{" "}
              </Link>

              <LinkButton onClick={handleClick}>
                <Stack direction="row">
                  <Title
                    sx={{
                      color:
                        scrollPosition > 10
                          ? theme.palette.primary[400]
                          : theme.palette.neutral[400],
                    }}
                    variant={{ xs: "joysmall", md: "joymed" }}
                  >
                    {" "}
                    Links{" "}
                  </Title>
                  <KeyboardArrowDownIcon
                    fontSize="small"
                    sx={{
                      color:
                        scrollPosition > 10
                          ? "primary.main"
                          : "rgba(7, 7, 16, .7)",
                    }}
                  />
                </Stack>
              </LinkButton>
              {/*  Links Drop Down */}
              <Box sx={{ position: "fixed" }}>
                <Menu
                  id="lock-menu"
                  anchorEl={anchorElMenu}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "lock-button",
                    role: "listbox",
                  }}
                  PaperProps={{
                    sx: {
                      backgroundColor: theme.palette.primary[600],
                      color: "white",
                      mt: 1, // margin top
                      borderRadius: 2,
                      minWidth: 180,
                    },
                  }}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      value={option.name}
                      selected={index === selectedIndex}

                      // onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Link
                        to={option.path}
                        sx={
                          index === selectedIndex ? { color: "success" } : null
                        }
                      >
                        {" "}
                        {option.name}{" "}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Stack>
          ) : null}
          {isMobile ? (
            <Box>
              <IconButton
                sx={{
                  backgroundColor: "hsl(220, 20%, 25%)",
                  border:
                    scrollPosition > 10
                      ? "2px solid hsl(210, 98%, 42%)"
                      : "transparent", // Border color
                  "&:hover": { borderColor: "blue" }, // Changes border on hover
                }}
                onClick={handleClick2}
              >
                <MenuIcon
                  sx={{
                    color: scrollPosition > 10 ? "white" : "hsl(220, 20%, 42%)",
                  }}
                />
              </IconButton>
              <Box sx={{ position: "fixed" }}>
                <Menu
                  id="lock-menu2"
                  anchorEl={anchorElMenu2}
                  open={open2}
                  onClose={handleClose2}
                  PaperProps={{
                    sx: {
                      backgroundColor: theme.palette.neutral[600],
                      color: "white",
                      borderRadius: 2,
                    },
                  }}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={option.name}
                      selected={index === selectedIndex}
                      sx={{
                        px: { xs: 2, sm: 3 }, // horizontal padding
                        py: { xs: 0.75, sm: 1 }, // tighter vertical padding on xs
                        minHeight: { xs: 36, sm: 38 }, // reduce overall item height on mobile
                        fontSize: { xs: "0.9rem", sm: "1rem" },
                        "&:hover": {
                          backgroundColor: theme.palette.neutral[400],
                        },
                      }}
                      //onClick={(event) => handleMenuItemClick(event, index)}
                    >
                      <Link
                        to={option.path}
                        style={{
                          color: "inherit",
                          textDecoration: "none",
                          width: "100%",
                          display: "block",
                        }}
                      >
                        {option.name}
                      </Link>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            </Box>
          ) : (
            <Stack direction="row" spacing={5} alignItems="center">
              <Link to={"/login"}>
                {" "}
                <Title> Login </Title>{" "}
              </Link>
              <LinkButton spacing={1}>
                <LanguageIcon
                  fontSize="small"
                  sx={{
                    color:
                      scrollPosition > 10
                        ? theme.palette.primary[400]
                        : theme.palette.neutral[400],
                  }}
                />
                <Typography
                  variant="body2"
                  sx={{
                    color:
                      scrollPosition > 10
                        ? theme.palette.primary[400]
                        : theme.palette.neutral[400],
                  }}
                >
                  EN
                </Typography>
              </LinkButton>
            </Stack>
          )}
        </Stack>
      </Container>
    </AppBar>
  );
};

export default LandingPageNavBar;

import React from "react";
import Modal from "@mui/material/Modal";
import TextField from "@mui/material/TextField";
import { AiOutlineUser } from "react-icons/ai";
import { Box, Typography, useTheme, Stack, MenuItem } from "@mui/material";
import { HiOutlineBriefcase, HiOutlineClipboardList } from "react-icons/hi";
import Select from "@mui/material/Select";
import { initialRole } from "../hooks/initial-class-role";
import Button from "@mui/material/Button";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import dayjs from "dayjs";
import usePath from "../hooks/fetchPath";
import { useState } from "react";
import { useAuth } from "../context/AuthProvider";
import Alert from "@mui/material/Alert";
import CheckIcon from "@mui/icons-material/Check";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const AddPersonnelModal = ({
  open,
  handleClose,
  firstName,
  setFirstName,
  lastName,
  setLastName,
  role,
  setRole,
  classification,
  setClassification,
}) => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: `2px solid ${theme.palette.neutral[50]}`,
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
  };
  const { api } = useAuth();
  const defaultStartTime = dayjs().hour(7).minute(0);
  const defaultEndTime = dayjs().hour(15).minute(30);
  const [sundaystart, setsundaystart] = useState(null);
  const [sundayend, setsundayend] = useState(null);
  const [mondaystart, setmondaystart] = useState(defaultStartTime);
  const [mondayend, setmondayend] = useState(defaultEndTime);
  const [tuesdaystart, settuesdaystart] = useState(defaultStartTime);
  const [tuesdayend, settuesdayend] = useState(defaultEndTime);
  const [wednesdaystart, setwednesdaystart] = useState(defaultStartTime);
  const [wednesdayend, setwednesdayend] = useState(defaultEndTime);
  const [thursdaystart, setthursdaystart] = useState(defaultStartTime);
  const [thursdayend, setthursdayend] = useState(defaultEndTime);
  const [fridaystart, setfridaystart] = useState(defaultStartTime);
  const [fridayend, setfridayend] = useState(defaultEndTime);
  const [saturdaystart, setsaturdaystart] = useState(null);
  const [saturdayend, setsaturdayend] = useState(null);
  const [statusMsg, setstatusMsg] = useState(null);
  const [isErr, setIsErr] = useState(false);
  const [sent, setsent] = useState(false);
  const { fullPathProjects, baseURL } = usePath("/api/v1/projects/");

  //Form Styles
  const formStyles = {
    "&:hover": {
      border: ".1px solid",
      borderRadius: 1,
      borderColor: theme.palette.neutral[500],
    },
    input: { color: theme.palette.neutral[500] },
    label: { color: theme.palette.neutral[300] },
    width: "185px",
    border: `1px solid ${
      theme.palette.mode === "dark"
        ? theme.palette.neutral[600] // Dark mode border
        : theme.palette.neutral[400] // Light mode border
    }`,
    borderRadius: 1,
    color: theme.palette.neutral[400],
    "& label": {
      fontSize: "0.8rem", // ✅ Custom font size for label
      color: "gray", // ✅ Custom label color
    },
    "& label.Mui-focused": {
      color: theme.palette.primary[800], // ✅ Label color when focused
    },
  };

  let typeClassification = null;
  let optionsClassification = null;

  const carpenter = [
    "Carpenters: Journeyman",
    "Carpenters: Foreman",
    "Carpenters: Apprentice 1",
    "Carpenters: Apprentice 2",
    "Carpenters: Apprentice 3",
    "Carpenters: Apprentice 4",
    "Carpenters: Apprentice 5",
    "Carpenters: Apprentice 6",
    "Carpenters: Apprentice 7",
    "Carpenters: Apprentice 8",
  ];

  const electrician = [
    "Inside Wireman",
    "Cable Splicer Welder",
    "Comm Installer",
  ];

  const laborer = [
    "Group 1 & 1B",
    "Group 1(A)",
    "Group 1(C)",
    "Group 1(E)",
    "Group 1(G)",
    "Group 2",
    "Group 3 & 3(A)",
    "Group 4 & 6(B)",
    "Group 6",
    "Group 6(A)",
    "Group 6(C)",
    "Group 6(D)",
    "Group 6(E)",
    "Group 7 - Stage 1 (1st 6 Months)",
    "Group 7 - Stage 2 (2nd 6 Months)",
    "Group 7 - Stage 3 (3rd 6 Months)",
  ];

  const electricianApprentice = [
    "Electrician Apprentice 1",
    "Electrician Apprentice 2",
    "Electrician Apprentice 3",
    "Electrician Apprentice 4",
    "Electrician Apprentice 5",
    "Electrician Apprentice 6",
    "Electrician Apprentice 7",
    "Electrician Apprentice 8",
    "Electrician Apprentice 9",
    "Electrician Apprentice 10",
  ];

  const electricalComm = ["Comm & System Installer", "Comm & System Tech"];

  const taper = ["Taper"];

  const plasterer = ["Plasterer"];
  const drywall = ["Drywall"];

  const cementMason = ["Cement Mason"];
  const projectManager = ["Project Manager"];

  const officeAdmin = ["Office Admin", "Biweekly"];

  if (role === "Carpenter") {
    typeClassification = carpenter;
  } else if (role === "Electrician") {
    typeClassification = electrician;
  } else if (role === "Laborer") {
    typeClassification = laborer;
  } else if (role === "Electrician Apprentice") {
    typeClassification = electricianApprentice;
  } else if (role === "ElectricalComm") {
    typeClassification = electricalComm;
  } else if (role === "Taper") {
    typeClassification = taper;
  } else if (role === "Plasterer") {
    typeClassification = plasterer;
  } else if (role === "Drywall") {
    typeClassification = drywall;
  } else if (role === "Project Manager") {
    typeClassification = projectManager;
  } else if (role === "Cement Mason") {
    typeClassification = cementMason;
  } else if (role === "Office Admin") {
    typeClassification = officeAdmin;
  } else {
    typeClassification = [];
  }

  if (typeClassification) {
    optionsClassification = typeClassification;
  }

  const renderTimePickerStyles = (param) => {
    return {
      backgroundColor: param
        ? theme.palette.neutral[50]
        : theme.palette.neutral[100], // ✅ Light blue background

      borderRadius: "8px", // ✅ Rounded border
      color: theme.palette.neutral[500], // ✅ Text color
      fontSize: "0.75rem", // ✅ Font size inside input
      "& label": {
        color: "darkblue", // ✅ Label text color
        fontSize: "0.8rem", // ✅ Smaller label font size
      },
      "& label.Mui-focused": {
        color: theme.palette.neutral[500], // ✅ Change label color on focus
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: theme.palette.neutral[200], // ✅ Border color for input field
        },
        "&:hover fieldset": {
          borderColor: theme.palette.primary[500], // ✅ Hover effect on border
        },
        "&.Mui-focused fieldset": {
          borderColor: "blue", // ✅ Focus effect on border
        },
      },
      "& input": {
        color: theme.palette.neutral[500], // ✅ Text color inside input field
        fontSize: "0.8rem", // ✅ Adjust font size of input text
      },
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.primary[200]} !important`, // ✅ Change clock icon color
        "&:hover": {
          color: theme.palette.primary[500], // ✅ Change color on hover
        },
      },
      "&.Mui-disabled": {
        color: "gray !important", // ✅ Change text color when disabled
        backgroundColor: "rgba(175, 65, 65, 0.4) !important", // ✅ Darker gray background
        opacity: 1, // ✅ Ensure full visibility
      },

      "& .MuiInputBase-input.Mui-disabled": {
        color: `${theme.palette.neutral[300]} !important`, // ✅ Force text color change
        WebkitTextFillColor: `${theme.palette.neutral[300]} !important`, // ✅ Fixes some browsers not applying color
      },
      width: "95%",
    };
  };

  const menuProps = {
    PaperProps: {
      sx: {
        backgroundColor: theme.palette.neutral[200], // ✅ Dropdown menu background
        borderRadius: "8px",
        color: theme.palette.neutral[600],
        boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
      },
    },
    MenuListProps: {
      sx: {
        padding: "4px 0",
      },
    },
  };

  const timePickerTheme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: "0.9rem", // ✅ Changes text size inside Digital Clock
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem", // ✅ Changes number size in Digital Clock
          },
        },
      },
      MuiPickersClockNumber: {
        styleOverrides: {
          root: {
            fontSize: "0.2rem", // ⏳ Adjusts font size of analog clock numbers
            color: "#ff9800", // 🔶 Custom clock number color
          },
        },
      },
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem", // ✅ Adjusts selected time (header)
            fontWeight: "bold",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem", // ✅ Adjusts OK & Cancel button text size
          },
        },
      },
      // ✅ Correct input text size override
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "0.5rem",
            color: "#666", // or theme.palette.neutral[500] if defined
          },
        },
      },
    },
  });

  const handleSubmit = (event) => {
    event.preventDefault();
    submitPersonnel();
  };

  const submitPersonnel = async () => {
    setsent(true);
    try {
      await api.post(
        baseURL + "/api/v1/personnel",
        JSON.stringify({
          firstName: firstName,
          lastName: lastName,
          role: role,
          classification: classification,
          sunStart: sundaystart,
          sunEnd: sundayend,
          monStart: mondaystart,
          monEnd: mondayend,
          tuesStart: tuesdaystart,
          tuesEnd: tuesdayend,
          wedStart: wednesdaystart,
          wedEnd: wednesdayend,
          thurStart: thursdaystart,
          thurEnd: thursdayend,
          friStart: fridaystart,
          friEnd: fridayend,
          satStart: saturdaystart,
          satEnd: saturdayend,
        })
      );
      setIsErr(false);
      setstatusMsg("Success!");
    } catch (error) {
      setIsErr(true);
      setstatusMsg(error.message);
    }
  };

  function SimpleAlert() {
    return (
      <Alert
        icon={<CheckIcon fontSize="inherit" />}
        severity="success"
        sx={{
          backgroundColor:
            theme.palette.mode === "dark" ? "#1e4620" : "#e6f4ea",
          color: theme.palette.mode === "dark" ? "#b9fbc0" : "#2e7d32",
          border: theme.palette.mode === "dark" ? "1px solid #2e7d32" : "none",
        }}
      >
        Success!
      </Alert>
    );
  }

  function ErrorAlert({ message }) {
    return (
      <Alert
        icon={<CheckIcon fontSize="inherit" />}
        severity="error"
        sx={{
          backgroundColor: theme.palette.mode ? "#471010" : "#fdecea",
          color: theme.palette.mode ? "#ffb4ab" : "#b71c1c",
          border: theme.palette.mode ? "1px solid #ff8a80" : "none",
        }}
      >
        {message}
      </Alert>
    );
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          component="div"
          sx={{ display: "flex", width: "100%", alignItems: "center" }}
        >
          <label htmlFor="firstName">
            {" "}
            <AiOutlineUser size={25} />{" "}
          </label>
          <TextField
            id="firstname"
            label="First Name"
            variant="outlined"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            required
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            style={{ minWidth: "185px" }}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            sx={formStyles}
          ></TextField>
        </Box>
        <Box
          component="div"
          sx={{ display: "flex", width: "100%", alignItems: "center" }}
        >
          <label htmlFor="lastName">
            {" "}
            <AiOutlineUser size={25} />{" "}
          </label>
          <TextField
            id="lastname"
            label="Last Name"
            variant="outlined"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            required
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            style={{ minWidth: "185px" }}
            inputProps={{
              style: {
                padding: 8,
              },
            }}
            sx={formStyles}
          ></TextField>
        </Box>
        <Box
          component="div"
          sx={{ display: "flex", width: "100%", alignItems: "center" }}
        >
          <label htmlFor="role">
            {" "}
            <HiOutlineBriefcase size={25} />{" "}
          </label>
          <Select
            labelId="Role-select-label"
            id="role select"
            value={role}
            onChange={(e) => {
              setRole(e.target.value);
            }}
            label="Start"
            size="small"
            defaultValue={role}
            onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
            style={{ minWidth: "185px", textalign: "left" }}
            sx={formStyles}
          >
            {initialRole.map((role, index) => {
              return (
                <MenuItem
                  key={index}
                  value={role}
                  sx={{ color: theme.palette.neutral[400] }}
                >
                  {role}
                </MenuItem>
              );
            })}
          </Select>
        </Box>

        <Box
          component="div"
          sx={{
            display: "flex",
            align: "left",
            width: "100%",
            alignItems: "center",
          }}
        >
          <label htmlFor="role">
            {" "}
            <HiOutlineClipboardList size={25} />{" "}
          </label>
          <Select
            labelId="Classification-select-label"
            id="classification select"
            placeholder="Select a classification"
            value={classification}
            onChange={(e) => {
              setClassification(e.target.value);
            }}
            label="Start"
            size="small"
            style={{ minWidth: "185px" }}
            sx={formStyles}
          >
            {optionsClassification.map((classificationitem, index) => {
              return (
                <MenuItem
                  key={index}
                  value={classificationitem}
                  sx={{ color: theme.palette.neutral[400] }}
                >
                  {classificationitem}
                </MenuItem>
              );
            })}
          </Select>
        </Box>
        <ThemeProvider theme={timePickerTheme}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Box display={"flex"}>
              <Stack spacing={1.8} sx={{ pt: 3 }}>
                <Typography>Sun</Typography>
                <Typography>Mon</Typography>
                <Typography>Tue</Typography>
                <Typography>Wed</Typography>
                <Typography>Thur</Typography>
                <Typography>Fri</Typography>
                <Typography>Sat</Typography>
              </Stack>
              <Stack>
                <Typography>Default Start Time</Typography>
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3start: e,
                    // }));
                  }}
                  value={sundaystart}
                  referenceDate={sundaystart}
                  defaultValue={sundaystart}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!sundaystart),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3start: e,
                    // }));
                  }}
                  value={mondaystart}
                  referenceDate={mondaystart}
                  defaultValue={mondaystart}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!mondaystart),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3start: e,
                    // }));
                  }}
                  value={tuesdaystart}
                  referenceDate={tuesdaystart}
                  defaultValue={tuesdaystart}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!tuesdaystart),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3start: e,
                    // }));
                  }}
                  value={wednesdaystart}
                  referenceDate={wednesdaystart}
                  defaultValue={wednesdaystart}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!wednesdaystart),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3start: e,
                    // }));
                  }}
                  value={thursdaystart}
                  referenceDate={thursdaystart}
                  defaultValue={thursdaystart}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!thursdaystart),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3start: e,
                    // }));
                  }}
                  value={fridaystart}
                  referenceDate={fridaystart}
                  defaultValue={fridaystart}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!fridaystart),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3start: e,
                    // }));
                  }}
                  value={sundaystart}
                  referenceDate={sundaystart}
                  defaultValue={sundaystart}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!sundaystart),
                    },
                  }}
                />
              </Stack>
              <Stack>
                <Typography>Default End Time</Typography>
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3start: e,
                    // }));
                  }}
                  value={sundayend}
                  referenceDate={sundayend}
                  defaultValue={sundayend}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!sundayend),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3end: e,
                    // }));
                  }}
                  value={mondayend}
                  referenceDate={mondayend}
                  defaultValue={mondayend}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!mondayend),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3end: e,
                    // }));
                  }}
                  value={tuesdayend}
                  referenceDate={tuesdayend}
                  defaultValue={tuesdayend}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!tuesdayend),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3end: e,
                    // }));
                  }}
                  value={wednesdayend}
                  referenceDate={wednesdayend}
                  defaultValue={wednesdayend}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!wednesdayend),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3end: e,
                    // }));
                  }}
                  value={thursdayend}
                  referenceDate={thursdayend}
                  defaultValue={thursdayend}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!thursdayend),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3end: e,
                    // }));
                  }}
                  value={fridayend}
                  referenceDate={fridayend}
                  defaultValue={fridayend}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!fridayend),
                    },
                  }}
                />
                <DesktopTimePicker
                  views={["hours", "minutes"]}
                  onChange={(e) => {
                    console.log(e);
                    // setDetailsRendered((prev) => ({
                    //   ...prev,
                    //   wedp3end: e,
                    // }));
                  }}
                  value={sundayend}
                  referenceDate={sundayend}
                  defaultValue={sundayend}
                  disabled={false}
                  closeOnSelect={false}
                  timeSteps={{ minutes: 15 }}
                  slotProps={{
                    actionBar: {
                      actions: ["clear", "accept"],
                    },
                    textField: {
                      size: "small",
                      sx: renderTimePickerStyles(!sundayend),
                    },
                  }}
                />
              </Stack>
            </Box>
          </LocalizationProvider>
        </ThemeProvider>
        {sent && !isErr ? <SimpleAlert /> : null}
        {sent && isErr ? <ErrorAlert message="Error!" /> : null}

        <Button variant="contained" sx={{ mt: "10px" }} onClick={handleSubmit}>
          Submit
        </Button>
        {/* End of Container Box*/}
      </Box>
    </Modal>
  );
};

export default AddPersonnelModal;

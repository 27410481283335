import axios from "axios";
import { createContext, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const AuthContext = createContext({});

//set state
//pass auth Prop to children components
export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [user, setUser] = useState(
    JSON.parse(localStorage.getItem("user")) || {}
  );
  const [success, setSuccess] = useState(Boolean(token));
  const [isLandingPage, setIsLandingPage] = useState(true);
  const [subscription, setSubscription] = useState({});
  const [mode] = useState(process.env.REACT_APP_NODE_ENV);
  console.log("mode", mode);
  console.log(
    "process.env.REACT_APP_FRONTEND_BASE_URL",
    process.env.REACT_APP_FRONTEND_BASE_URL
  );
  const api = axios.create({
    baseURL: `${process.env.REACT_APP_BACKEND_BASE_URL}`,
    withCredentials: true,
    crossDomain: true,
    headers: {
      "Access-Control-Allow-Origin": process.env.REACT_APP_FRONTEND_BASE_URL,
      "Content-Type": "application/json",
    },
  });
  api.authenticated = false;

  const setApiAccessToken = (token) => {
    if (token) {
      api.defaults.headers.common["Authorization"] = "Bearer " + token;
      api.authenticated = true;
      console.log("api authenticated", api.authenticated);
    } else {
      delete api.defaults.headers.common["Authorization"];
      api.authenticated = false;
    }
  };

  setApiAccessToken(token);

  api.interceptors.response.use(
    function (response) {
      // Optional: Do something with response data
      return response;
    },
    function (error) {
      return Promise.reject(error);
    }
  );

  // Interceptor to automatically refresh token if expired
  api.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { response, config } = error;
      console.log("error response", response);
      if (
        response?.data?.error === "Authentication token is expired" &&
        !config.retried
      ) {
        config.retried = true;

        try {
          const refreshResponse = await api.post(
            "/api/v1/auth/refresh",
            {},
            { withCredentials: true }
          );

          console.log(refreshResponse);
          const newToken = refreshResponse.data.token;
          console.log("Access token successfully refreshed");

          if (newToken) {
            login(newToken);

            config.headers = {
              ...config.headers,
              authorization: `Bearer ${newToken}`,
            };
            return api(config); // Retry the original request
          } else {
            console.log("Token missing from refresh response");
            logout();
          }
        } catch (error) {
          console.log("Token refresh failed", { error });
          logout();
        }
      } else {
        console.log("request failed", error.message);
        return Promise.reject(error);
      }
    }
  );

  useEffect(() => {
    if (token) {
      localStorage.setItem("token", token);
      setApiAccessToken(token);

      api
        .get("/api/v1/auth/me")
        .then(({ data }) => {
          const _user = data.data;

          setUser(_user);
          setSuccess(true);
          localStorage.setItem("user", JSON.stringify(_user));

          api.defaults.headers.common["Authorization"] = "Bearer " + token;
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      //this will remove local storage when logout is clicked
      localStorage.removeItem("token");
      localStorage.removeItem("auth");
      localStorage.removeItem("user");
      delete axios.defaults.headers.common["Authorization"];
      setUser({});

      setSuccess(false);
      setSubscription({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  useEffect(() => {
    if (token) {
      api
        .get("/api/v1/subscription")
        .then((res) => {
          console.log("getting subscription");
          console.log(res, "subscription");
          setSubscription(res.data.subscription);
        })
        .catch((err) => {
          console.log("Error getting subscription", err);
          setSubscription({});
        });
    }
  }, [token, api.authenticated]);

  const login = (accessToken) => setToken(accessToken);

  const logout = async () => {
    console.log("logout");

    setToken(null);

    try {
      // Optional: Notify the backend to invalidate the session
      await api.post(
        "/api/v1/auth/logout",
        {},
        { withCredentials: true } // Include cookies if needed
      );

      // Clear tokens from local storage
      localStorage.removeItem("accessToken");
      // Navigate user back to home
      window.location.href = "/";
    } catch (error) {
      console.error("Logout failed:", error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        api,
        success,
        setSuccess,
        user,
        setUser,
        login,
        logout,
        mode,
        isLandingPage,
        setIsLandingPage,
        subscription,
        setSubscription,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthProvider");
  }
  return context;
};

export default AuthContext;

export { useAuth };

import { Box, Typography, Stack } from "@mui/material";
import Modal from "@mui/material/Modal";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopTimePicker } from "@mui/x-date-pickers/DesktopTimePicker";
import { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import TwoToneInlineText from "./TwoToneInlineText";
import EditTimeCardWeekLabels from "./EditTimeCardWeekLabels";
// import { tokens } from "../theme";
import SendIcon from "@mui/icons-material/Send";
import Button from "@mui/material/Button";
import { useAuth } from "../context/AuthProvider";
import useMediaQuery from "@mui/material/useMediaQuery";
import useMeasure from "react-use-measure";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SettingsIcon from "@mui/icons-material/Settings";
import { updateDetailTimes } from "./../utils/PayrollCreateV3";
import Alert from "@mui/material/Alert";
import threeWeekLookBack from "../hooks/three-week-lookback";
//theme imports
import { ColorModeContext, useMode } from "../theme";
import { useContext } from "react";
import { ThemeProvider, createTheme } from "@mui/material/styles";

const EditTimeCardModal2 = ({
  open,
  handleClose,
  listData,
  initialDetails,
  weekEnding,
  setWeekEnding,
}) => {
  const { theme, toggleColorMode } = useContext(ColorModeContext);
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { api } = useAuth();
  const { sixWkBkDesc } = threeWeekLookBack();
  const [ref, { height }] = useMeasure();
  const [project1, setProject1] = useState(initialDetails?.project1);
  const [project1hour, setproject1hour] = useState(0);
  const [project2, setProject2] = useState(initialDetails?.project2);
  const [project2hour, setproject2hour] = useState(0);
  const [project3, setProject3] = useState(initialDetails?.project3);
  const [project3hour, setproject3hour] = useState(0);
  const [p1disabled, setP1disabled] = useState(false);
  const [p2disabled, setP2disabled] = useState(false);
  const [p3disabled, setP3disabled] = useState(false);
  const [errState, setErrState] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [sentState, setSentState] = useState(false);
  const [touched, setTouched] = useState(false);
  const [personnel, setPersonnel] = useState([]);
  const [project, setProject] = useState([]);
  const [detailsRendered, setDetailsRendered] = useState(initialDetails);
  const style = {
    width: isMobile ? 390 : "70%",
    height: isMobile ? "95%" : 700,
    minHeight: "700px",
    bgcolor: theme.palette.neutral[50],
    border: `2px solid ${theme.palette.neutral[100]}`,
    borderRadius: "15px",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.2)",
    p: 1,
    overflow: "auto",
    margin: "auto",
    transform: isMobile ? "translate(0px)" : "translate(100px)",
  };

  const selectStyle = {
    backgroundColor: theme.palette.neutral[100],
    color: theme.palette.neutral[400], // ✅ Custom text color
    fontSize: "0.75rem", // ✅ Adjust text size
    height: "35px",
    borderRadius: "4px", // ✅ Rounded corners
    border: `1px solid ${theme.palette.neutral[400]}`, // ✅ Custom border
    "&:hover": {
      backgroundColor: theme.palette.primary[100], // ✅ Slightly darker blue on hover
      border: `2px solid ${theme.palette.primary[300]}`,
      color: theme.palette.neutral[600],
    },
    "&.Mui-focused": {
      border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
    },
    "& .MuiSelect-icon": {
      color: theme.palette.primary[300], // 🎨 Change dropdown arrow color
    },
  };

  const selectStyle2 = {
    backgroundColor: p2disabled
      ? theme.palette.neutral[50]
      : theme.palette.neutral[100],
    color: theme.palette.neutral[400], // ✅ Custom text color
    fontSize: "0.75rem", // ✅ Adjust text size
    height: "35px",
    borderRadius: "4px", // ✅ Rounded corners
    border: `1px solid ${theme.palette.neutral[400]}`, // ✅ Custom border
    "&:hover": {
      backgroundColor: theme.palette.primary[100], // ✅ Slightly darker blue on hover
      border: `2px solid ${theme.palette.primary[300]}`,
      color: theme.palette.neutral[600],
    },
    "&.Mui-focused": {
      border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
    },
    "& .MuiSelect-icon": {
      color: theme.palette.primary[300], // 🎨 Change dropdown arrow color
    },
  };

  const selectStyle3 = {
    backgroundColor: p3disabled
      ? theme.palette.neutral[50]
      : theme.palette.neutral[100],
    color: theme.palette.neutral[400], // ✅ Custom text color
    fontSize: "0.75rem", // ✅ Adjust text size
    height: "35px",
    borderRadius: "4px", // ✅ Rounded corners
    border: `1px solid ${theme.palette.neutral[400]}`, // ✅ Custom border
    "&:hover": {
      backgroundColor: theme.palette.primary[100], // ✅ Slightly darker blue on hover
      border: `2px solid ${theme.palette.primary[300]}`,
      color: theme.palette.neutral[600],
    },
    "&.Mui-focused": {
      border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
    },
    "& .MuiSelect-icon": {
      color: theme.palette.primary[300], // 🎨 Change dropdown arrow color
    },
  };

  const handleWeekChange = (event) => {
    setTouched(true);
    setWeekEnding(event.target.value);
    setDetailsRendered(updateDetailTimes(detailsRendered, event.target.value));
  };
  const handleChange1 = (event) => {
    setTouched(true);
    setProject1(event.target.value);
    setErrState(false);
    setDetailsRendered((prevState) => ({
      ...prevState,
      project1: event.target.value,
    }));

    if (event.target.value === "" || event.target.value === null) {
      setP1disabled(true);
      setErrState(true);
      setErrMsg("Project 1 Must Be Defined");
    } else {
      setP1disabled(false);
      project1hour > 0 &&
        ((project2 && project2hour > 0) || project2hour === 0) &&
        ((project3 && project3hour > 0) || project3hour === 0) &&
        setErrState(false);
    }
  };
  const handleChange2 = (event) => {
    setTouched(true);
    setErrState(false);
    setProject2(event.target.value);
    setDetailsRendered((prevState) => ({
      ...prevState,
      project2: event.target.value,
    }));
    if (
      (event.target.value === "" || event.target.value === null) &&
      (project3 === "" || project3 === null) &&
      (project2 === "" || project2 === null)
    ) {
      setP2disabled(true);
      setP3disabled(true);
      setErrState(true);
      setErrMsg("Please Select A Project");
    } else if (event.target.value === "" || event.target.value === null) {
      setP2disabled(true);
    } else {
      setP3disabled(false);
      setP2disabled(false);
    }
  };
  const handleChange3 = (event) => {
    setErrState(false);
    setProject3(event.target.value);
    setDetailsRendered((prevState) => ({
      ...prevState,
      project3: event.target.value,
    }));
    if (
      (event.target.value === "" || event.target.value === null) &&
      (project1 === "" || project1 === null) &&
      (project2 === "" || project2 === null)
    ) {
      setP2disabled(true);
      setP3disabled(true);
      setErrState(true);
      setErrMsg("Please Select A Project");
    } else {
      setP3disabled(false);
    }
  };
  const handleChangeBreak = (event) => {
    setTouched(true);
    setDetailsRendered((prevState) => ({
      ...prevState, // Spread the existing properties
      [event.target.name]: event.target.value, // Update only the targeted property
    }));
  };

  const renderTimePickerStyles = (param) => {
    return {
      backgroundColor: param
        ? theme.palette.neutral[50]
        : theme.palette.neutral[100], // ✅ Light blue background

      borderRadius: "8px", // ✅ Rounded border
      color: theme.palette.neutral[500], // ✅ Text color
      fontSize: "0.75rem", // ✅ Font size inside input
      "& label": {
        color: "darkblue", // ✅ Label text color
        fontSize: "0.8rem", // ✅ Smaller label font size
      },
      "& label.Mui-focused": {
        color: theme.palette.neutral[500], // ✅ Change label color on focus
      },
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderColor: theme.palette.neutral[200], // ✅ Border color for input field
        },
        "&:hover fieldset": {
          borderColor: theme.palette.primary[500], // ✅ Hover effect on border
        },
        "&.Mui-focused fieldset": {
          borderColor: "blue", // ✅ Focus effect on border
        },
      },
      "& input": {
        color: theme.palette.neutral[500], // ✅ Text color inside input field
        fontSize: "0.8rem", // ✅ Adjust font size of input text
      },
      "& .MuiSvgIcon-root": {
        color: `${theme.palette.primary[200]} !important`, // ✅ Change clock icon color
        "&:hover": {
          color: theme.palette.primary[500], // ✅ Change color on hover
        },
      },
      "&.Mui-disabled": {
        color: "gray !important", // ✅ Change text color when disabled
        backgroundColor: "rgba(175, 65, 65, 0.4) !important", // ✅ Darker gray background
        opacity: 1, // ✅ Ensure full visibility
      },

      "& .MuiInputBase-input.Mui-disabled": {
        color: `${theme.palette.neutral[300]} !important`, // ✅ Force text color change
        WebkitTextFillColor: `${theme.palette.neutral[300]} !important`, // ✅ Fixes some browsers not applying color
      },
    };
  };

  const popperProps = {
    sx: {
      "& .MuiPaper-root": {
        backgroundColor: theme.palette.neutral[300], // 🎨 Dropdown background
        borderRadius: "8px", // ✅ Rounded corners
        boxShadow: "0px 4px 12px rgba(0,0,0,0.2)", // ✅ Custom shadow
      },
      "& .MuiClock-root": {
        backgroundColor: theme.palette.neutral[300], // ⏳ Clock background color
      },
      "& .MuiClockNumber-root": {
        color: theme.palette.neutral[500], // 🕐 Time number color
      },
      "& .MuiClockPointer-root": {
        backgroundColor: theme.palette.secondary[300], // ⏳ Clock pointer color
      },
      "& .MuiClockPointer-thumb": {
        backgroundColor: theme.palette.secondary[200], // 🔵 Thumb color
        border: `4px solid ${theme.palette.secondary[300]}`,
      },
      "& .MuiPickersArrowSwitcher-root": {
        color: theme.palette.secondary[300], // 🎯 Arrow switcher color (AM/PM toggle)
      },
    },
  };

  const timePickerTheme = createTheme({
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            fontSize: "0.9rem", // ✅ Changes text size inside Digital Clock
          },
        },
      },
      MuiButtonBase: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem", // ✅ Changes number size in Digital Clock
          },
        },
      },
      MuiPickersClockNumber: {
        styleOverrides: {
          root: {
            fontSize: "0.2rem", // ⏳ Adjusts font size of analog clock numbers
            color: "#ff9800", // 🔶 Custom clock number color
          },
        },
      },
      MuiPickersToolbar: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem", // ✅ Adjusts selected time (header)
            fontWeight: "bold",
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            fontSize: "0.8rem", // ✅ Adjusts OK & Cancel button text size
          },
        },
      },
      // ✅ Correct input text size override
      MuiInputBase: {
        styleOverrides: {
          input: {
            fontSize: "0.5rem",
            color: "#666", // or theme.palette.neutral[500] if defined
          },
        },
      },
    },
  });

  useEffect(() => {
    if (listData?.personnel?.data?.data?.length > 0) {
      setPersonnel(listData.personnel.data.data);
    }
    if (listData?.projects?.data?.data?.length > 0) {
      setProject(listData.projects.data.data);
    }
  }, [listData]);

  useEffect(() => {
    const calculateHours = (start, end) => {
      let answer = 0;
      if (start && end) {
        answer = end.diff(start, "hour", true);
      }
      return answer;
    };
    if (detailsRendered) {
      setproject1hour(
        calculateHours(detailsRendered?.sunp1start, detailsRendered?.sunp1end) +
          calculateHours(
            detailsRendered?.monp1start,
            detailsRendered?.monp1end
          ) +
          calculateHours(
            detailsRendered?.tuep1start,
            detailsRendered?.tuep1end
          ) +
          calculateHours(
            detailsRendered?.wedp1start,
            detailsRendered?.wedp1end
          ) +
          calculateHours(
            detailsRendered?.thurp1start,
            detailsRendered?.thurp1end
          ) +
          calculateHours(
            detailsRendered?.frip1start,
            detailsRendered?.frip1end
          ) +
          calculateHours(detailsRendered?.satp1start, detailsRendered?.satp1end)
      );

      setproject2hour(
        calculateHours(detailsRendered?.sunp2start, detailsRendered?.sunp2end) +
          calculateHours(
            detailsRendered?.monp2start,
            detailsRendered?.monp2end
          ) +
          calculateHours(
            detailsRendered?.tuep2start,
            detailsRendered?.tuep2end
          ) +
          calculateHours(
            detailsRendered?.wedp2start,
            detailsRendered?.wedp2end
          ) +
          calculateHours(
            detailsRendered?.thurp2start,
            detailsRendered?.thurp2end
          ) +
          calculateHours(
            detailsRendered?.frip2start,
            detailsRendered?.frip2end
          ) +
          calculateHours(detailsRendered?.satp2start, detailsRendered?.satp2end)
      );
      setproject3hour(
        calculateHours(detailsRendered?.sunp3start, detailsRendered?.sunp3end) +
          calculateHours(
            detailsRendered?.monp3start,
            detailsRendered?.monp3end
          ) +
          calculateHours(
            detailsRendered?.tuep3start,
            detailsRendered?.tuep3end
          ) +
          calculateHours(
            detailsRendered?.wedp3start,
            detailsRendered?.wedp3end
          ) +
          calculateHours(
            detailsRendered?.thurp3start,
            detailsRendered?.thurp3end
          ) +
          calculateHours(
            detailsRendered?.thurp3start,
            detailsRendered?.thurp3end
          ) +
          calculateHours(
            detailsRendered?.frip3start,
            detailsRendered?.frip3end
          ) +
          calculateHours(detailsRendered?.satp3start, detailsRendered?.satp3end)
      );
    }
  }, [detailsRendered]);

  const shouldDiableTimeStarts = useCallback(
    (value, view, p1end, p2start, p2end, p3start, p3end) => {
      if (!p1end && !p2start && !p2end && !p3start && !p3end) {
        // If any input is undefined, do not disable any times
        return false;
      }

      if (view === "hours") {
        const startAdjustmentp2 = p2start?.minute() > 0 ? 1 : 0;
        const startAdjustmentp3 = p3start?.minute() > 0 ? 1 : 0;
        return (
          //true when p2 is earlier than p1 start
          // Only apply if start and end hours are different
          (value.hour() >= p3start?.hour() + startAdjustmentp3 &&
            value.hour() <= p3end?.hour() - 1) ||
          //true when p2 is earlier than p1 start
          (value.hour() >= p2start?.hour() + startAdjustmentp2 &&
            value.hour() <= p2end?.hour() - 1) ||
          value.hour() > p1end?.hour()
        );
      }

      if (view === "minutes") {
        return (
          (value.hour() === p3start?.hour() &&
            value.minute() > p3start?.minute()) ||
          (value.hour() === p3end?.hour() &&
            value.minute() < p3end?.minute()) ||
          (value.hour() === p2start?.hour() &&
            value.minute() > p2start?.minute()) ||
          (value.hour() === p2end?.hour() && value.minute() < p2end?.minute())
        );
      }

      return false;
    },
    []
  );

  const shouldDisableTimeEnd = useCallback(
    (value, view, p1start, p2start, p2end, p3start, p3end) => {
      if (!p1start && !p2start && !p2end && !p3start && !p3end) {
        // If any input is undefined, do not disable any times
        return false;
      }

      if (view === "hours") {
        const endAdjustmentp2 = p2end?.minute() > 0 ? 1 : 0;
        const endAdjustmentp3 = p3end?.minute() > 0 ? 1 : 0;
        return (
          (value.hour() >= p3start?.hour() + 1 &&
            value.hour() <= p3end?.hour() - endAdjustmentp3) ||
          (value.hour() >= p2start?.hour() + 1 &&
            value.hour() <= p2end?.hour() - endAdjustmentp2) ||
          value.hour() < p1start?.hour()
        );
      }

      if (view === "minutes") {
        return (
          (value.hour() === p3start?.hour() &&
            value.minute() > p3start?.minute()) ||
          (value.hour() === p3end?.hour() &&
            value.minute() < p3end?.minute()) ||
          (value.hour() === p2start?.hour() &&
            value.minute() > p2start?.minute()) ||
          (value.hour() === p2end?.hour() && value.minute() < p2end?.minute())
        );
      }

      return false;
    },
    []
  );
  const breakSelect = (name, prop) => {
    return (
      <FormControl
        sx={{
          width: "5.5rem",
          height: "50%",
          mt: "5%",
        }}
        size="small"
      >
        <InputLabel
          id="break"
          sx={{
            color: theme.palette.primary[300], // ✅ Custom label color
            fontSize: "0.9rem", // ✅ Adjust label size
            "&.Mui-focused": { color: theme.palette.primary[300] }, // ✅ Change label color on focus
          }}
        >
          Break
        </InputLabel>
        <Select
          labelId={`${name}-label`}
          id={`${name}-select`}
          name={name}
          fullWidth
          value={detailsRendered?.[prop] ?? ""}
          onChange={handleChangeBreak}
          MenuProps={menuProps}
          sx={{
            backgroundColor:
              p1disabled && p2disabled && p3disabled
                ? theme.palette.neutral[50]
                : theme.palette.neutral[100],
            color: theme.palette.neutral[400], // ✅ Custom text color
            fontSize: "0.65rem", // ✅ Adjust text size
            height: "35px",
            borderRadius: "4px", // ✅ Rounded corners
            border: `1px solid ${theme.palette.neutral[400]}`, // ✅ Custom border
            "&:hover": {
              backgroundColor: theme.palette.primary[100], // ✅ Slightly darker blue on hover
              border: `2px solid ${theme.palette.primary[300]}`,
              color: theme.palette.neutral[600],
            },
            "&.Mui-focused": {
              border: `2px solid ${theme.palette.primary[300]}`, // ✅ Stronger border on focus
            },
            "& .MuiSelect-icon": {
              color: theme.palette.primary[300], // 🎨 Change dropdown arrow color
            },
          }}
        >
          <MenuItem value={0}>
            <em>None</em>
          </MenuItem>
          <MenuItem value={0.5}>30 Min</MenuItem>
          <MenuItem value={1}>1 Hour</MenuItem>
        </Select>
      </FormControl>
    );
  };

  const inputStyle = {
    color: theme.palette.primary[400], // ✅ Custom label color
    fontSize: "1rem", // ✅ Adjust label size
    "&.Mui-focused": { color: theme.palette.primary[400] }, // ✅ Change label color on focus
  };

  // TimeClock Post
  const submitTimeCard = async (details) => {
    const { _id } = details;
    try {
      await api.put(`/api/v1/timecardsv3/mytimecards/${_id}`, details);
      setSentState(true);
      setErrState(false);
      //update timecard in state
    } catch (error) {
      console.log("Error creating timecard:", error);
      setErrState(true);
      if (error.response?.data) {
        setErrMsg(error.response.data.error);
      } else {
        setErrMsg("Error creating time card");
      }
    }
  };

  const menuProps = {
    PaperProps: {
      sx: {
        backgroundColor: theme.palette.neutral[200], // ✅ Dropdown menu background
        borderRadius: "8px",
        color: theme.palette.neutral[600],
        boxShadow: "0px 4px 12px rgba(0,0,0,0.2)",
      },
    },
    MenuListProps: {
      sx: {
        padding: "4px 0",
      },
    },
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      height={"100px"}
    >
      <Box sx={style}>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            id="modal-modal-title"
            variant={isMobile ? "body2" : "h6"}
            component="h2"
            sx={{ color: theme.palette.neutral[600] }}
          >
            Edit Time Card
          </Typography>
          <FormControl size="small" sx={{ pb: 1, width: isMobile ? 130 : 300 }}>
            <InputLabel
              id="personnel"
              sx={{
                color: theme.palette.primary[400], // ✅ Custom label color
                fontSize: "1rem", // ✅ Adjust label size
                "&.Mui-focused": { color: theme.palette.primary[400] }, // ✅ Change label color on focus
              }}
            >
              Personnel
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={detailsRendered?.fullName}
              label="Week Ending"
              sx={selectStyle}
              onChange={handleWeekChange}
              MenuProps={menuProps}
            >
              {personnel.length > 0 &&
                personnel.map((person) => (
                  <MenuItem key={person._id} value={person.fullName}>
                    {person.fullName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          <Box
            sx={{
              display: isMobile ? "block" : "none",
              marginLeft: "auto",
            }}
          >
            <Stack direction="row">
              <FormControl size="small" sx={{ pb: 1 }}>
                <InputLabel id="demo-select-small-label" sx={inputStyle}>
                  Week Ending
                </InputLabel>
                <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  value={weekEnding ?? null}
                  label="Week Ending"
                  onChange={handleWeekChange}
                  sx={selectStyle}
                  MenuProps={menuProps}
                >
                  <MenuItem value={null}>
                    <em>Please Select A Week</em>
                  </MenuItem>
                  {sixWkBkDesc.map((week, index) => {
                    return (
                      <MenuItem key={"endDate" + index} value={week}>
                        {week}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box sx={{ display: isMobile ? "block" : "none" }}>
                <SettingsIcon />
              </Box>
            </Stack>
          </Box>
        </Stack>

        <Box
          sx={{
            display: isMobile ? "none" : "grid",
            gridAutoColumns: "1fr",
          }}
        >
          <FormControl size="small" sx={{ gridRow: "1", gridColumn: "1/2" }}>
            <InputLabel id="mobile-week-ending" sx={inputStyle}>
              Week Ending
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={weekEnding}
              label="Week Ending"
              onChange={handleWeekChange}
              sx={selectStyle}
              MenuProps={menuProps}
            >
              <MenuItem value={null}>
                <em>Please Select A Week</em>
              </MenuItem>
              {sixWkBkDesc.map((week, index) => {
                return (
                  <MenuItem key={"endDate" + index} value={week}>
                    {week}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <FormControl sx={{ gridRow: "1", gridColumn: "2/4" }} size="small">
            {project1 ? null : (
              <InputLabel
                id="demo-select-small-label"
                sx={{ color: theme.palette.neutral[500] }}
              >
                Select A Project
              </InputLabel>
            )}

            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={project1 ?? ""} // ✅ Ensure it's always defined
              label="Project 1"
              error={!project1 && !touched}
              onChange={handleChange1}
              sx={selectStyle}
              MenuProps={menuProps}
            >
              {project.length > 0 &&
                project.map((proj, index) => (
                  <MenuItem
                    key={index}
                    value={proj}
                    disabled={project2 === proj || project3 === proj}
                  >
                    {proj}
                  </MenuItem>
                ))}
              <MenuItem value={null}>
                <em>Please Select A Project</em>
              </MenuItem>
            </Select>
          </FormControl>
          {/* fULL SIZE P2 */}
          <FormControl sx={{ gridRow: "1", gridColumn: "4/6" }} size="small">
            {project2 ? null : (
              <InputLabel
                id="demo-select-small-label"
                sx={{ color: theme.palette.neutral[400] }}
              >
                Select A Project
              </InputLabel>
            )}
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={project2 ?? ""} // ✅ Ensure it's always defined
              label="Project 2"
              onChange={handleChange2}
              sx={selectStyle2}
              MenuProps={menuProps}
            >
              {project.length > 0 &&
                project.map((proj, index) => (
                  <MenuItem key={index} value={proj}>
                    {proj}
                  </MenuItem>
                ))}
              <MenuItem value={null}>
                <em>Please Select A Project</em>
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl sx={{ gridRow: "1", gridColumn: "6/8" }} size="small">
            {project3 ? null : (
              <InputLabel
                id="demo-select-small-label"
                sx={{ color: theme.palette.neutral[400] }}
              >
                Select A Project
              </InputLabel>
            )}
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={project3 ?? ""} // ✅ Ensure it's always defined
              label="Project 3"
              onChange={handleChange3}
              sx={selectStyle3}
              MenuProps={menuProps}
            >
              {project.length > 0 &&
                project.map((proj, index) => (
                  <MenuItem key={index} value={proj}>
                    {proj}
                  </MenuItem>
                ))}
              <MenuItem value={null}>
                <em>Please Select A Project</em>
              </MenuItem>
            </Select>
          </FormControl>
        </Box>

        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer
            components={["TimePicker", "TimePicker", "TimePicker"]}
          >
            <DemoItem>
              {/* Start of unit box 1 */}

              <Box
                sx={{
                  display: "grid",
                  py: 1,
                  gridTemplateColumns: isMobile
                    ? "repeat(3, 1fr)"
                    : "repeat(7, 1fr)",
                  overflow: "hidden",
                }}
              >
                <Stack
                  spacing={1}
                  sx={{
                    maxWidth: "130px",
                    color: "white",
                  }}
                >
                  {isMobile ? (
                    <FormControl
                      sx={{ width: isMobile ? "120px" : "100%" }}
                      size="small"
                    >
                      {project1 ? null : (
                        <InputLabel id="demo-select-small-label">
                          {isMobile ? "Project" : "Select A Project"}
                        </InputLabel>
                      )}
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={project1 ?? null} // ✅ Ensure it's always defined
                        label="Project 1"
                        onChange={handleChange1}
                        sx={selectStyle}
                      >
                        {project.length > 0 &&
                          project.map((proj, index) => (
                            <MenuItem key={index} value={proj}>
                              {proj}
                            </MenuItem>
                          ))}
                        <MenuItem value={null}>
                          <em>Please Select A Project</em>
                        </MenuItem>
                      </Select>
                    </FormControl>
                  ) : null}
                  {isMobile ? null : <Typography>Week</Typography>}
                  <Box
                    sx={{
                      mt: isMobile ? 0 : "1.5rem",
                    }}
                  >
                    <Stack direction="row">
                      <Box>
                        <Typography
                          sx={{
                            color: theme.palette.neutral[600],
                          }}
                        >
                          Sun
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary[500],
                            fontSize: "0.5rem", // ✅ Correct font size (8px)
                            lineHeight: "1", // ✅ Prevents extra spacing
                            pr: 0.5,
                          }}
                        >
                          {dayjs(weekEnding).day(0).format("MM/DD")}
                        </Typography>
                      </Box>
                      {breakSelect("breaksun", "breaksun")}
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row">
                      <Box>
                        <Typography sx={{ color: theme.palette.neutral[600] }}>
                          Mon
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary[500],
                            fontSize: "0.5rem", // ✅ Correct font size (8px)
                            lineHeight: "1", // ✅ Prevents extra spacing
                            pr: 0.5,
                          }}
                        >
                          {" "}
                          {dayjs(weekEnding).day(1).format("MM/DD")}
                        </Typography>
                      </Box>
                      {breakSelect("breakmon", "breakmon")}
                    </Stack>
                  </Box>

                  <Box>
                    <Stack direction="row">
                      <Box>
                        <Typography sx={{ color: theme.palette.neutral[600] }}>
                          Tue
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary[500],
                            fontSize: "0.5rem", // ✅ Correct font size (8px)
                            lineHeight: "1", // ✅ Prevents extra spacing
                            pr: 0.5,
                          }}
                        >
                          {" "}
                          {dayjs(weekEnding).day(2).format("MM/DD")}
                        </Typography>
                      </Box>
                      {breakSelect("breaktue", "breaktue")}
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row">
                      <Box>
                        <Typography sx={{ color: theme.palette.neutral[600] }}>
                          Wed
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary[500],
                            fontSize: "0.5rem", // ✅ Correct font size (8px)
                            lineHeight: "1", // ✅ Prevents extra spacing
                            pr: 0.5,
                          }}
                        >
                          {" "}
                          {dayjs(weekEnding).day(3).format("MM/DD")}
                        </Typography>
                      </Box>
                      {breakSelect("breakwed", "breakwed")}
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row">
                      <Box>
                        <Typography sx={{ color: theme.palette.neutral[600] }}>
                          Thur
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary[500],
                            fontSize: "0.5rem", // ✅ Correct font size (8px)
                            lineHeight: "1", // ✅ Prevents extra spacing
                            pr: 0.5,
                          }}
                        >
                          {" "}
                          {dayjs(weekEnding).day(4).format("MM/DD")}
                        </Typography>
                      </Box>
                      {breakSelect("breakthur", "breakthur")}
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row">
                      <Box>
                        <Typography sx={{ color: theme.palette.neutral[600] }}>
                          Fri
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary[500],
                            fontSize: "0.5rem", // ✅ Correct font size (8px)
                            lineHeight: "1", // ✅ Prevents extra spacing
                            pr: 0.5,
                          }}
                        >
                          {" "}
                          {dayjs(weekEnding).day(5).format("MM/DD")}
                        </Typography>
                      </Box>
                      {breakSelect("breakfri", "breakfri")}
                    </Stack>
                  </Box>
                  <Box>
                    <Stack direction="row">
                      <Box>
                        <Typography sx={{ color: theme.palette.neutral[600] }}>
                          Sat
                        </Typography>
                        <Typography
                          sx={{
                            color: theme.palette.primary[500],
                            fontSize: "0.5rem", // ✅ Correct font size (8px)
                            lineHeight: "1", // ✅ Prevents extra spacing
                            pr: 0.5,
                          }}
                        >
                          {" "}
                          {dayjs(weekEnding).day(6).format("MM/DD")}
                        </Typography>
                      </Box>
                      {breakSelect("breaksat", "breaksat")}
                    </Stack>
                  </Box>
                </Stack>
                <ThemeProvider theme={timePickerTheme}>
                  <div ref={ref}>
                    <Stack spacing={1.75} sx={{ mt: isMobile ? "1rem" : 0 }}>
                      <Typography sx={{ color: theme.palette.neutral[400] }}>
                        Hours Start
                      </Typography>
                      <DesktopTimePicker
                        id="unique"
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            sunp1start: e,
                          }));
                        }}
                        value={detailsRendered?.sunp1start}
                        referenceDate={dayjs(weekEnding).day(0)}
                        defaultValue={detailsRendered?.sunp1start}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDiableTimeStarts(
                            value,
                            view,
                            detailsRendered?.sunp1end,
                            detailsRendered?.sunp2start,
                            detailsRendered?.sunp2end,
                            detailsRendered?.sunp3start,
                            detailsRendered?.sunp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.sunp1start
                            ),
                          },
                        }}
                        // renderInput={(params) => (
                        //   <TextField
                        //     {...params}
                        //     size="small"
                        //     sx={renderTimePickerStyles(
                        //       !detailsRendered?.monp1start
                        //     )}
                        //   />
                        // )}
                        onClear={() => {
                          console.log("clearing");
                          setDetailsRendered((prev) => ({
                            ...prev,
                            sunp1start: null,
                          }));
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            monp1start: e,
                          }));
                        }}
                        value={detailsRendered?.monp1start}
                        referenceDate={dayjs(weekEnding).day(1)}
                        defaultValue={detailsRendered?.monp1start}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDiableTimeStarts(
                            value,
                            view,
                            detailsRendered.monp1end,
                            detailsRendered.monp2start,
                            detailsRendered.monp2end,
                            detailsRendered.monp3start,
                            detailsRendered.monp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.monp1start
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            tuep1start: e,
                          }));
                        }}
                        value={detailsRendered?.tuep1start}
                        referenceDate={dayjs(weekEnding).day(2)}
                        defaultValue={detailsRendered?.tuep1start}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDiableTimeStarts(
                            value,
                            view,
                            detailsRendered?.tuep1end,
                            detailsRendered?.tuep2start,
                            detailsRendered?.tuep2end,
                            detailsRendered?.tuep3start,
                            detailsRendered?.tuep3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.tuep1start
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            wedp1start: e,
                          }));
                        }}
                        value={detailsRendered?.wedp1start}
                        referenceDate={dayjs(weekEnding).day(3)}
                        defaultValue={detailsRendered?.wedp1start}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDiableTimeStarts(
                            value,
                            view,
                            detailsRendered?.wedp1end,
                            detailsRendered?.wedp2start,
                            detailsRendered?.wedp2end,
                            detailsRendered?.wedp3start,
                            detailsRendered?.wedp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.wedp1start
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            thurp1start: e,
                          }));
                        }}
                        value={detailsRendered?.thurp1start}
                        referenceDate={dayjs(weekEnding).day(4)}
                        defaultValue={detailsRendered?.thurp1start}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDiableTimeStarts(
                            value,
                            view,
                            detailsRendered?.thurp1end,
                            detailsRendered?.thurp2start,
                            detailsRendered?.thurp2end,
                            detailsRendered?.thurp3start,
                            detailsRendered?.thurp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.thurp1start
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            frip1start: e,
                          }));
                        }}
                        value={detailsRendered?.frip1start}
                        referenceDate={dayjs(weekEnding).day(5)}
                        defaultValue={detailsRendered?.frip1start}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDiableTimeStarts(
                            value,
                            view,
                            detailsRendered?.frip1end,
                            detailsRendered?.frip2start,
                            detailsRendered?.frip2end,
                            detailsRendered?.frip3start,
                            detailsRendered?.frip3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.frip1start
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            satp1start: e,
                          }));
                        }}
                        value={detailsRendered?.satp1start}
                        referenceDate={dayjs(weekEnding).day(6)}
                        defaultValue={detailsRendered?.satp1start}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDiableTimeStarts(
                            value,
                            view,
                            detailsRendered?.satp1end,
                            detailsRendered?.satp2start,
                            detailsRendered?.satp2end,
                            detailsRendered?.satp3start,
                            detailsRendered?.satp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },

                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.satp1start
                            ),
                          },
                        }}
                      />
                    </Stack>
                  </div>
                  <div>
                    <Stack spacing={1.75} sx={{ mt: isMobile ? "1rem" : 0 }}>
                      <Typography sx={{ color: theme.palette.neutral[400] }}>
                        Hours End
                      </Typography>
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            sunp1end: e,
                          }));
                        }}
                        value={detailsRendered?.sunp1end}
                        referenceDate={dayjs(weekEnding).day(0)}
                        defaultValue={detailsRendered?.sunp1end}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDisableTimeEnd(
                            value,
                            view,
                            detailsRendered?.sunp1start,
                            detailsRendered?.sunp2start,
                            detailsRendered?.sunp2end,
                            detailsRendered?.sunp3start,
                            detailsRendered?.sunp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.sunp1end
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            monp1end: e,
                          }));
                        }}
                        value={detailsRendered?.monp1end}
                        referenceDate={dayjs(weekEnding).day(1)}
                        defaultValue={detailsRendered?.monp1end}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDisableTimeEnd(
                            value,
                            view,
                            detailsRendered?.monp1start,
                            detailsRendered?.monp2start,
                            detailsRendered?.monp2end,
                            detailsRendered?.monp3start,
                            detailsRendered?.monp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.monp1end
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            tuep1end: e,
                          }));
                        }}
                        value={detailsRendered?.tuep1end}
                        referenceDate={dayjs(weekEnding).day(2)}
                        defaultValue={detailsRendered?.tuep1end}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDisableTimeEnd(
                            value,
                            view,
                            detailsRendered?.tuep1start,
                            detailsRendered?.tuep2start,
                            detailsRendered?.tuep2end,
                            detailsRendered?.tuep3start,
                            detailsRendered?.tuep3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.tuep1end
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            wedp1end: e,
                          }));
                        }}
                        value={detailsRendered?.wedp1end}
                        referenceDate={dayjs(weekEnding).day(3)}
                        defaultValue={detailsRendered?.wedp1end}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDisableTimeEnd(
                            value,
                            view,
                            detailsRendered?.wedp1start,
                            detailsRendered?.wedp2start,
                            detailsRendered?.wedp2end,
                            detailsRendered?.wedp3start,
                            detailsRendered?.wedp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.wedp1end
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            thurp1end: e,
                          }));
                        }}
                        value={detailsRendered?.thurp1end}
                        referenceDate={dayjs(weekEnding).day(4)}
                        defaultValue={detailsRendered?.thurp1end}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDisableTimeEnd(
                            value,
                            view,
                            detailsRendered?.thurp1start,
                            detailsRendered?.thurp2start,
                            detailsRendered?.thurp2end,
                            detailsRendered?.thurp3start,
                            detailsRendered?.thurp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.thurp1end
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            frip1end: e,
                          }));
                        }}
                        value={detailsRendered?.frip1end}
                        referenceDate={dayjs(weekEnding).day(5)}
                        defaultValue={detailsRendered?.frip1end}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDisableTimeEnd(
                            value,
                            view,
                            detailsRendered?.frip1start,
                            detailsRendered?.frip2start,
                            detailsRendered?.frip2end,
                            detailsRendered?.frip3start,
                            detailsRendered?.frip3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.frip1end
                            ),
                          },
                        }}
                      />
                      <DesktopTimePicker
                        views={["hours", "minutes"]}
                        onChange={(e) => {
                          setTouched(true);
                          setDetailsRendered((prev) => ({
                            ...prev,
                            satp1end: e,
                          }));
                        }}
                        value={detailsRendered?.satp1end}
                        referenceDate={dayjs(weekEnding).day(6)}
                        defaultValue={detailsRendered?.satp1end}
                        closeOnSelect={false}
                        timeSteps={{ minutes: 15 }}
                        shouldDisableTime={(value, view) =>
                          shouldDisableTimeEnd(
                            value,
                            view,
                            detailsRendered?.satp1start,
                            detailsRendered?.satp2start,
                            detailsRendered?.satp2end,
                            detailsRendered?.satp3start,
                            detailsRendered?.satp3end
                          )
                        }
                        slotProps={{
                          actionBar: {
                            actions: ["clear", "accept"],
                          },
                          textField: {
                            size: "small",
                            sx: renderTimePickerStyles(
                              !detailsRendered?.satp1end
                            ),
                          },
                        }}
                      />
                    </Stack>
                  </div>
                </ThemeProvider>
                <EditTimeCardWeekLabels
                  show={isMobile ? true : false}
                  height={height}
                  weekEnding={weekEnding}
                  project={project2}
                  handleChange={handleChange2}
                  projectList={project}
                  isMobile={isMobile}
                />

                <Stack spacing={1.75} sx={{ mt: isMobile ? "2rem" : 0 }}>
                  <Typography sx={{ color: theme.palette.neutral[400] }}>
                    Hours Start
                  </Typography>

                  <ThemeProvider theme={timePickerTheme}>
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          sunp2start: e,
                        }));
                      }}
                      value={detailsRendered?.sunp2start}
                      referenceDate={dayjs(weekEnding).day(0)}
                      defaultValue={detailsRendered?.sunp2start}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.sunp2end,
                          detailsRendered?.sunp1start,
                          detailsRendered?.sunp1end,
                          detailsRendered?.sunp3start,
                          detailsRendered?.sunp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.sunp2start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          monp2start: e,
                        }));
                      }}
                      value={detailsRendered?.monp2start}
                      referenceDate={dayjs(weekEnding).day(1)}
                      defaultValue={detailsRendered?.monp2start}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.monp2end,
                          detailsRendered?.monp1start,
                          detailsRendered?.monp1end,
                          detailsRendered?.monp3start,
                          detailsRendered?.monp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.monp2start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          tuep2start: e,
                        }));
                      }}
                      value={detailsRendered?.tuep2start}
                      referenceDate={dayjs(weekEnding).day(2)}
                      defaultValue={detailsRendered?.tuep2start}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.tuep2end,
                          detailsRendered?.tuep1start,
                          detailsRendered?.tuep1end,
                          detailsRendered?.tuep3start,
                          detailsRendered?.tuep3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.tuep2start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          wedp2start: e,
                        }));
                      }}
                      value={detailsRendered?.wedp2start}
                      referenceDate={dayjs(weekEnding).day(3)}
                      defaultValue={detailsRendered?.wedp2start}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.wedp2end,
                          detailsRendered?.wedp1start,
                          detailsRendered?.wedp1end,
                          detailsRendered?.wedp3start,
                          detailsRendered?.wedp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.wedp2start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          thurp2start: e,
                        }));
                      }}
                      value={detailsRendered?.thurp2start}
                      referenceDate={dayjs(weekEnding).day(4)}
                      defaultValue={detailsRendered?.thurp2start}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.thurp2end,
                          detailsRendered?.thurp1start,
                          detailsRendered?.thurp1end,
                          detailsRendered?.thurp3start,
                          detailsRendered?.thurp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.thurp2start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          frip2start: e,
                        }));
                      }}
                      value={detailsRendered?.frip2start}
                      referenceDate={dayjs(weekEnding).day(5)}
                      defaultValue={detailsRendered?.frip2start}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.frip2end,
                          detailsRendered?.frip1start,
                          detailsRendered?.frip1end,
                          detailsRendered?.frip3start,
                          detailsRendered?.frip3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.frip2start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          satp2start: e,
                        }));
                      }}
                      value={detailsRendered?.satp2start}
                      referenceDate={dayjs(weekEnding).day(6)}
                      defaultValue={detailsRendered?.satp2start}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.satp2end,
                          detailsRendered?.satp1start,
                          detailsRendered?.satp1end,
                          detailsRendered?.satp3start,
                          detailsRendered?.satp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.satp2start
                          ),
                        },
                      }}
                    />
                  </ThemeProvider>
                </Stack>

                <Stack spacing={1.75} sx={{ mt: isMobile ? "2rem" : 0 }}>
                  <Typography sx={{ color: theme.palette.neutral[400] }}>
                    Hours End
                  </Typography>
                  <ThemeProvider theme={timePickerTheme}>
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          sunp2end: e,
                        }));
                      }}
                      value={detailsRendered?.sunp2end}
                      referenceDate={dayjs(weekEnding).day(0)}
                      defaultValue={detailsRendered?.sunp2end}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.sunp2start,
                          detailsRendered?.sunp1start,
                          detailsRendered?.sunp1end,
                          detailsRendered?.sunp3start,
                          detailsRendered?.sunp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.sunp2end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          monp2end: e,
                        }));
                      }}
                      value={detailsRendered?.monp2end}
                      referenceDate={dayjs(weekEnding).day(1)}
                      defaultValue={detailsRendered?.monp2end}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.monp2start,
                          detailsRendered?.monp1start,
                          detailsRendered?.monp1end,
                          detailsRendered?.monp3start,
                          detailsRendered?.monp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.monp2end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          tuep2end: e,
                        }));
                      }}
                      value={detailsRendered?.tuep2end}
                      referenceDate={dayjs(weekEnding).day(2)}
                      defaultValue={detailsRendered?.tuep2end}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.tuep2start,
                          detailsRendered?.tuep1start,
                          detailsRendered?.tuep1end,
                          detailsRendered?.tuep3start,
                          detailsRendered?.tuep3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.tuep2end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          wedp2end: e,
                        }));
                      }}
                      value={detailsRendered?.wedp2end}
                      referenceDate={dayjs(weekEnding).day(3)}
                      defaultValue={detailsRendered?.wedp2end}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.wedp2start,
                          detailsRendered?.wedp1start,
                          detailsRendered?.wedp1end,
                          detailsRendered?.wedp3start,
                          detailsRendered?.wedp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.wedp2end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          thurp2end: e,
                        }));
                      }}
                      value={detailsRendered?.thurp2end}
                      referenceDate={dayjs(weekEnding).day(4)}
                      defaultValue={detailsRendered?.thurp2end}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.thurp2start,
                          detailsRendered?.thurp1start,
                          detailsRendered?.thurp1end,
                          detailsRendered?.thurp3start,
                          detailsRendered?.thurp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.thurp2end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          frip2end: e,
                        }));
                      }}
                      value={detailsRendered?.frip2end}
                      referenceDate={dayjs(weekEnding).day(5)}
                      defaultValue={detailsRendered?.frip2end}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.frip2start,
                          detailsRendered?.frip1start,
                          detailsRendered?.frip1end,
                          detailsRendered?.frip3start,
                          detailsRendered?.frip3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.frip2end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          satp2end: e,
                        }));
                      }}
                      value={detailsRendered?.satp2end}
                      referenceDate={dayjs(weekEnding).day(6)}
                      defaultValue={detailsRendered?.satp2end}
                      disabled={p2disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.satp2start,
                          detailsRendered?.satp1start,
                          detailsRendered?.satp1end,
                          detailsRendered?.satp3start,
                          detailsRendered?.satp3end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.satp2end
                          ),
                        },
                      }}
                    />
                  </ThemeProvider>
                </Stack>
                <EditTimeCardWeekLabels
                  show={isMobile ? true : false}
                  height={height}
                  weekEnding={weekEnding}
                  project={project3}
                  handleChange={handleChange3}
                  projectList={project}
                  isMobile={isMobile}
                />

                <Stack spacing={1.75} sx={{ mt: isMobile ? "2rem" : 0 }}>
                  <Typography sx={{ color: theme.palette.neutral[400] }}>
                    Hours Start
                  </Typography>
                  <ThemeProvider theme={timePickerTheme}>
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          sunp3start: e,
                        }));
                      }}
                      value={detailsRendered?.sunp3start}
                      referenceDate={dayjs(weekEnding).day(0)}
                      defaultValue={detailsRendered?.sunp3start}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.satp3end,
                          detailsRendered?.satp1start,
                          detailsRendered?.satp1end,
                          detailsRendered?.satp2start,
                          detailsRendered?.satp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.sunp3start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          monp3start: e,
                        }));
                      }}
                      value={detailsRendered?.monp3start}
                      referenceDate={dayjs(weekEnding).day(1)}
                      defaultValue={detailsRendered?.monp3start}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.monp3end,
                          detailsRendered?.monp1start,
                          detailsRendered?.monp1end,
                          detailsRendered?.monp2start,
                          detailsRendered?.monp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.monp3start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          tuep3start: e,
                        }));
                      }}
                      value={detailsRendered?.tuep3start}
                      referenceDate={dayjs(weekEnding).day(2)}
                      defaultValue={detailsRendered?.tuep3start}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.tuep3end,
                          detailsRendered?.tuep1start,
                          detailsRendered?.tuep1end,
                          detailsRendered?.tuep2start,
                          detailsRendered?.tuep2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.tuep3start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          wedp3start: e,
                        }));
                      }}
                      value={detailsRendered?.wedp3start}
                      referenceDate={dayjs(weekEnding).day(3)}
                      defaultValue={detailsRendered?.wedp3start}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.wedp3end,
                          detailsRendered?.wedp1start,
                          detailsRendered?.wedp1end,
                          detailsRendered?.wedp2start,
                          detailsRendered?.wedp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.wedp3start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          thurp3start: e,
                        }));
                      }}
                      value={detailsRendered?.thurp3start}
                      referenceDate={dayjs(weekEnding).day(4)}
                      defaultValue={detailsRendered?.thurp3start}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.thurp3end,
                          detailsRendered?.thurp1start,
                          detailsRendered?.thurp1end,
                          detailsRendered?.thurp2start,
                          detailsRendered?.thurp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.thurp3start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          frip3start: e,
                        }));
                      }}
                      value={detailsRendered?.frip3start}
                      referenceDate={dayjs(weekEnding).day(5)}
                      defaultValue={detailsRendered?.frip3start}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.frip3end,
                          detailsRendered?.frip1start,
                          detailsRendered?.frip1end,
                          detailsRendered?.frip2start,
                          detailsRendered?.frip2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.frip3start
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          satp3start: e,
                        }));
                      }}
                      value={detailsRendered?.satp3start}
                      referenceDate={dayjs(weekEnding).day(6)}
                      defaultValue={detailsRendered?.satp3start}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDiableTimeStarts(
                          value,
                          view,
                          detailsRendered?.satp3end,
                          detailsRendered?.satp1start,
                          detailsRendered?.satp1end,
                          detailsRendered?.satp2start,
                          detailsRendered?.satp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.satp3start
                          ),
                        },
                      }}
                    />
                  </ThemeProvider>
                </Stack>
                <Stack spacing={1.75} sx={{ mt: isMobile ? "2rem" : 0 }}>
                  <Typography sx={{ color: theme.palette.neutral[400] }}>
                    Hours End
                  </Typography>
                  <ThemeProvider theme={timePickerTheme}>
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          sunp3end: e,
                        }));
                      }}
                      value={detailsRendered?.sunp3end}
                      referenceDate={dayjs(weekEnding).day(0)}
                      defaultValue={detailsRendered?.sunp3end}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.sunp3start,
                          detailsRendered?.sunp1start,
                          detailsRendered?.sunp1end,
                          detailsRendered?.sunp2start,
                          detailsRendered?.sunp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.sunp3end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          monp3end: e,
                        }));
                      }}
                      value={detailsRendered?.monp3end}
                      referenceDate={dayjs(weekEnding).day(1)}
                      defaultValue={detailsRendered?.monp3end}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.monp3start,
                          detailsRendered?.monp1start,
                          detailsRendered?.monp1end,
                          detailsRendered?.monp2start,
                          detailsRendered?.monp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.monp3end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          tuep3end: e,
                        }));
                      }}
                      value={detailsRendered?.tuep3end}
                      referenceDate={dayjs(weekEnding).day(2)}
                      defaultValue={detailsRendered?.tuep3end}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.tuep3start,
                          detailsRendered?.tuep1start,
                          detailsRendered?.tuep1end,
                          detailsRendered?.tuep2start,
                          detailsRendered?.tuep2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.tuep3end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          wedp3end: e,
                        }));
                      }}
                      value={detailsRendered?.wedp3end}
                      referenceDate={dayjs(weekEnding).day(3)}
                      defaultValue={detailsRendered?.wedp3end}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.wedp3start,
                          detailsRendered?.wedp1start,
                          detailsRendered?.wedp1end,
                          detailsRendered?.wedp2start,
                          detailsRendered?.wedp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.wedp3end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          thurp3end: e,
                        }));
                      }}
                      value={detailsRendered?.thurp3end}
                      referenceDate={dayjs(weekEnding).day(4)}
                      defaultValue={detailsRendered?.thurp3end}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.thurp3start,
                          detailsRendered?.thurp1start,
                          detailsRendered?.thurp1end,
                          detailsRendered?.thurp2start,
                          detailsRendered?.thurp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.thurp3end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          frip3end: e,
                        }));
                      }}
                      value={detailsRendered?.frip3end}
                      referenceDate={dayjs(weekEnding).day(5)}
                      defaultValue={detailsRendered?.frip3end}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.frip3start,
                          detailsRendered?.frip1start,
                          detailsRendered?.frip1end,
                          detailsRendered?.frip2start,
                          detailsRendered?.frip2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.frip3end
                          ),
                        },
                      }}
                    />
                    <DesktopTimePicker
                      views={["hours", "minutes"]}
                      onChange={(e) => {
                        setTouched(true);
                        setDetailsRendered((prev) => ({
                          ...prev,
                          satp3end: e,
                        }));
                      }}
                      value={detailsRendered?.satp3end}
                      referenceDate={dayjs(weekEnding).day(6)}
                      defaultValue={detailsRendered?.satp3end}
                      disabled={p3disabled}
                      closeOnSelect={false}
                      timeSteps={{ minutes: 15 }}
                      shouldDisableTime={(value, view) =>
                        shouldDisableTimeEnd(
                          value,
                          view,
                          detailsRendered?.satp3start,
                          detailsRendered?.satp1start,
                          detailsRendered?.satp1end,
                          detailsRendered?.satp2start,
                          detailsRendered?.satp2end
                        )
                      }
                      slotProps={{
                        actionBar: {
                          actions: ["clear", "accept"],
                        },
                        textField: {
                          size: "small",
                          sx: renderTimePickerStyles(
                            !detailsRendered?.satp3end
                          ),
                        },
                      }}
                    />
                  </ThemeProvider>
                </Stack>
              </Box>
              {/* End of unit box 1 */}
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
        <Box
          sx={{
            display: isMobile ? "none" : "grid",
            gridAutoColumns: "1fr",
            gap: 1,
          }}
        >
          <Box sx={{ gridRow: "1", gridColumn: "2/4" }} size="small">
            {!touched ? null : (
              <TwoToneInlineText
                text1={`Project 1 Hours`}
                text2={project1hour}
              />
            )}
          </Box>

          <Box sx={{ gridRow: "1", gridColumn: "4/6" }} size="small">
            {!touched ? null : (
              <TwoToneInlineText
                text1={`Project 2 Hours`}
                text2={project2hour}
              />
            )}
          </Box>
          <Box sx={{ gridRow: "1", gridColumn: "6/8" }} size="small">
            {!touched ? null : (
              <TwoToneInlineText
                text1={`Project 3 Hours`}
                text2={project3hour}
              />
            )}
          </Box>
        </Box>
        {sentState ? (
          <Alert severity="success">
            Payroll saved successfully for Week Ending {weekEnding}
          </Alert>
        ) : null}
        {errState ? <Alert severity="error">{errMsg}</Alert> : null}
        <TwoToneInlineText text1="Name" text2={detailsRendered?.fullName} />
        <TwoToneInlineText
          text1="Week Ending"
          text2={dayjs(weekEnding).format("LLL")}
        />
        <TwoToneInlineText
          text1="Gross Time"
          text2={project1hour + project2hour + project3hour}
        />
        <TwoToneInlineText
          text1="Break Time"
          text2={
            detailsRendered?.breaksun +
            detailsRendered?.breakmon +
            detailsRendered?.breaktue +
            detailsRendered?.breakwed +
            detailsRendered?.breakthur +
            detailsRendered?.breakfri +
            detailsRendered?.breaksat
          }
        />

        <Button
          variant="contained"
          sx={{
            backgroundColor: "primary.main", // Uses theme color
            color: "white",
            padding: "10px 20px",
            fontSize: "14px",
            borderRadius: "8px",
            "&:hover": { backgroundColor: "primary.dark" }, // Custom hover effect
            "&.Mui-disabled": {
              backgroundColor: theme.palette.neutral[200], // Override default disabled color
              color: theme.palette.neutral[100],
            },
          }}
          disabled={!touched}
          onClick={() => submitTimeCard(detailsRendered)}
          endIcon={<SendIcon />}
        >
          Update Time Card
        </Button>
      </Box>
    </Modal>
  );
};

export default EditTimeCardModal2;
